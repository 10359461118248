import axios from 'axios';

let baseURL = process.env[`REACT_APP_TS_API_URL`];
let xApiKey = process.env[`REACT_APP_TS_API_KEY`];
let autV4URL = process.env[`REACT_APP_AUT_V4_URL`];
let AutV4ApiKey = process.env[`REACT_APP_AUT-V4_API_KEY`];
let qrcodeURL = process.env[`REACT_APP_QRCODE_URL`];

export const baseUrl = baseURL;
export const qrcodeUrl = qrcodeURL;
export const autV4Url = autV4URL;

export const httpTsApi = xApiKey
  ? axios.create({
      baseURL: baseURL,
      headers: {
        'x-api-key': xApiKey,
      },
    })
  : axios.create({
      baseURL: baseURL,
    });

export const httpAutV4Api = axios.create({
  baseURL: autV4URL,
});

export const autV4ApiKey = AutV4ApiKey;
