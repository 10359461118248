export const validarValor = (valor, tipo) => {
  try {
    if (valor === undefined) {
      return false;
    } else if (valor === null) {
      return false;
    } else if (tipo === 'number' && isNaN(valor)) {
      return false;
    } else if (tipo === 'string' && valor === '') {
      return false;
    }
  } catch (error) {
    return false;
  }

  return true;
};

export const validarForm = (valores, campos) => {
  let retorno = '';
  let valor = undefined;
  let campo = undefined;

  try {
    for (let key in campos) {
      campo = campos[key];
      valor = valores[key];

      if (campo.min > 0 && !validarValor(valor, campo.tipo)) {
        retorno = `${campo.name} - valor necessário`;
        break;
      }

      if (campo.tipo === 'string' && campo.max > 0) {
        if (valor && ('' + valor).length > campo.max) {
          retorno = `${campo.name} - tamanho excedido`;
          break;
        }
      }
    }
  } catch (error) {
    console.warn(` ... validarForm ERROR, campo: ${campo?.name} , tipo: ${campo?.tipo} valor: [ ${valor} ]`);
    console.warn(error);
    return 'EXCEPTION AO VALIDAR FORM, VERIFIQUE LOG';
  }

  return retorno || '';
};
