import * as React from 'react';
import contratoHttp from '../../util/http/contrato-http';
import {Link, Button, Box, Tabs, Tab, Paper} from '@mui/material';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import SelectCampo from '../../formulario/SelectCampo';
import ShowError from '../../formulario/ShowError';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { listaSituacoesBusca, listaProspectStatusBusca } from './contrato-lista'; 
import ShowAlert from '../../util/ShowAlert';

let ContratoList_Processando = false;

const propsPaper = {
    square: false, sx: {width: '100%', padding: 2, marginBottom: 2}, elevation: 2
}

const ContratoList = (props) => {
    const setDetail = props.setDetail;
    const detail = props.detail;
    const [rows, setRows] = React.useState([]);
    const [rowsProspects, setRowsProspects] = React.useState([]);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [executing, setExecuting] = React.useState(false); 
    const [serverPage, setServerPage] = React.useState(0);
    const serverPageSize = 50;
    const [objBusca, setObjBusca] = React.useState({busca: '', situacao: 'ALL'});
    const [queryid, setQueryid] = React.useState("");
    const [tabIndex, setTabIndex] = React.useState(detail.tipo === 'contrato' ? 0 : 1);

    const [situacaoContrato, setSituacaoContrato] = React.useState('0');
    const [situacaoProspect, setSituacaoProspect] = React.useState('-1');

    const setProcessando = (value) => {
        ContratoList_Processando = value;
        setExecuting(value);
    }

    const columnsProspects = [
        {
            name: "id",
            label: "#",
            format: ((value) => (<Link component={Button} onClick={(e) => {setDetail({open: true, id: value, tipo: 'prospect', pageTitle: "Detalhes do prospect"})}}><EventNoteIcon /></Link>))
        },
        {
            name: "razao_social",
            label: "Razão Social"
        },
        {
            name: "nome_fantasia",
            label: "Nome Fantasia"
        },
        {
            name: "dataInclusao",
            label: "Data"
        },
        {
            name: "status",
            label: "Situação"
        },
        {
            name: "email",
            label: "E-mail"
        },
        {
            name: "fone",
            label: "Celular"
        }
    ];

    const columns = [
        {
            name: "id",
            label: "#",
            format: ((value) => (<Link component={Button} onClick={(e) => {setDetail({open: true, id: value, tipo: 'contrato', pageTitle: "Detalhes do contrato"})}}><EventNoteIcon /></Link>))
        },
        {
            name: "entidadeNome",
            label: "Entidade"
        },
        {
            name: "tipoEmpresa",
            label: "Tipo"
        },
        {
            name: "situacao",
            label: "Situação"
        },
        {
            name: "dataInicio",
            label: "Inicio"
        },
        {
            name: "dataFinal",
            label: "Final"
        }
    ];

    const Buscar = async () => {
        if (ContratoList_Processando || tabIndex !== 0) {
            return;
        }
        ShowAlert.closeSnackbar();
        setQueryid("");
        setProcessando(true);
        
        try {
            setRows([]);
            let situacao = situacaoContrato || '';
            const query = { page: serverPage, nrecPage: serverPageSize, ativo: true, busca: objBusca.busca, situacao: situacao };
            const {data} = await contratoHttp.busca(query);
            if (data.sucesso === true && data.dados && data.dados.length > 0) {
                setRows(data.dados);
            }
            else {
                ShowAlert.error("Contratos - Nenhum registro encontrado");
            }
        } catch (error) {
            setUrl(contratoHttp.getUrl());
            setError(error);
        } 
        setProcessando(false);
        
    }

    const BuscarProspects = async () => {
        if (ContratoList_Processando || tabIndex !== 1) {
            return;
        }
        ShowAlert.closeSnackbar();
        setQueryid("");
        setProcessando(true);
        
        try {
            setRowsProspects([]);
            let situacao = situacaoProspect && situacaoProspect !== '-1'  ? situacaoProspect : '';

            if (situacao === 'ALL') situacao = '';
            const query = { page: serverPage, nrecPage: serverPageSize, ativo: true, busca: objBusca.busca, situacao: situacao };
            const {data} = await contratoHttp.buscaProspects(query);
            if (data.sucesso === true && data.dados && data.dados.length > 0) {
                setRowsProspects(data.dados);
            }
            else {
                ShowAlert.error("Propstecs - Nenhum registro encontrado");
            }
        } catch (error) {
            setUrl(contratoHttp.getUrl());
            setError(error);
        } 
        setProcessando(false);
        
    }

    const setDetailtabIndex = (index) => {
        setTabIndex(index);
        if (index === 0) {
            setDetail({open: false, id: 0, tipo: 'contrato', pageTitle: "Lista de contratos"});
            setSituacaoContrato('0');
        }   
        else {
            setDetail({open: false, id: 0, tipo: 'prospect', pageTitle: "Lista de prospect"});
            setSituacaoProspect('-1'); 
        }   
    };

    React.useEffect(() => {
        if (detail.open === false) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    React.useEffect(() => {
        if (detail.open === false) {
            if (tabIndex === 0) {
                Buscar();
            }
            else {
                BuscarProspects();
            }
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objBusca, tabIndex, situacaoContrato, situacaoProspect]);

    return (
    
        <Paper {...propsPaper} >
        <>
            <TsInputBusca
                name="input-busca"
                value={objBusca.busca}
                showBusca={true}
                showAtivo={false}
                submitSearch={(texto, ativo) => { setObjBusca({...objBusca, busca: texto, ativo: ativo}); }}
            >
                <Box sx={{flex: '0 0 auto', paddingRight: 2}}>
                    { 
                    tabIndex === 0 ? 
                    <SelectCampo
                        id="TsInputBusca-Situacao"
                        label="Situação"
                        name="situacao"
                        options={listaSituacoesBusca}
                        value={situacaoContrato || ''}
                        onChange={(e) => setSituacaoContrato(e.target.value)}
                    /> 
                    : 
                    <SelectCampo
                        id="TsInputBusca-Situacao-Prospect"
                        label="Situação"
                        name="situacao"
                        options={listaProspectStatusBusca}
                        value={situacaoProspect || ''}
                        onChange={(e) => setSituacaoProspect(e.target.value)}
                    />

                        // listaProspectStatus
                    }
                    
                </Box>
            </TsInputBusca>
        </>
        <Tabs value={tabIndex} onChange={(e, newIndex) => setDetailtabIndex(newIndex)} aria-label="Tipo busca">
            <Tab label="Contratos" id="cntr-tab-0" aria-labelledby={`cntr-tabpanel-0`} />
            <Tab label="Prospects" id="cntr-tab-1" aria-labelledby={`cntr-tabpanel-1`} />
        </Tabs>
        {tabIndex === 0 && <>
        <TsDataGrid
                rows={rows}
                columns={columns}
                queryid={queryid}
                serverPage={serverPage}
                serverPagesize={serverPageSize}
                onChangePage={(value) => setServerPage(value)}
                />
        </>}
        {tabIndex === 1 && <>
        <TsDataGrid
                rows={rowsProspects}
                columns={columnsProspects}
                queryid={queryid}
                serverPage={serverPage}
                serverPagesize={serverPageSize}
                onChangePage={(value) => setServerPage(value)}
                />
        </>}
        <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={executing} />
    </Paper>)
}

export default ContratoList;