// @flow 
import * as React from 'react';
import TsDialog from '../../formulario/TsDialog';
import { TableBuscar } from './produto-buscar';

// type Props = {
//     versaoApi: string,
//     open: boolean,
//     onSelect: (value: string, preco: number, nome: string) => void,
//     onClose: (e: any) => void
//     cnpj: string
// };
const ProdutoBuscarDialog = (props) => {

    const { versaoApi, open, onSelect, onClose, cnpj } = props;
    
    return (
        <TsDialog onClose={onClose} id="dialog-busca-produto" open={open} title="Busca de produto" minWidth={650} >
            {open &&
                <TableBuscar versaoApi={versaoApi} onSelect={onSelect} cnpj={cnpj} />
            }
        </TsDialog>
    );
};

export default ProdutoBuscarDialog;