import * as React from 'react';
import redeHttp from '../../util/http/rede-http';
import {Link, Button, Box} from '@mui/material';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';
import EventNoteIcon from '@mui/icons-material/EventNote';
import {getProjetoStorage} from '../../util/auth';
import SelectCampo from '../../formulario/SelectCampo';
import TsDialog from '../../formulario/TsDialog';
import RedeAdicionar from './rede-adicionar';

const RedeList = (props) => {

    const projAtual = getProjetoStorage();
    
    const {userLevel, setDetail, detail, softhouse, objBusca, setObjBusca} = props; 
    const softhouseid = softhouse;
    const serverPageSize = 50; 
    const [rows, setRows] = React.useState([]);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [processando, setProcessando] = React.useState(false); 
    const [serverPage, setServerPage] = React.useState(0);
    const [queryid, setQueryid] = React.useState("");
    const [addRede, setAddRede] = React.useState({open: false, title: 'Adicionar nova rede'});
    // const [softhouseid, setSofthouseid] = React.useState(softhouse);

    const columns = [
        {
            name: "id",
            label: "#",
            format: ((value) => (<Link component={Button} onClick={(e) => {setDetail({open: true, id: value, pageTitle: "Detalhes da rede"})}}><EventNoteIcon /></Link>))
        },
        {
            name: "id",
            label: "Código"
        },
        {
            name: "redeNome",
            label: "Rede"
        },
        {
            name: "status",
            label: "status"
        },
        {name: "comissaoFaturamento",label: "Comissão", format: ((value) => (<>{(value * 100).toFixed(2)}%</>))},
    ];

    const Buscar = async () => {
        if (processando) {
            return;
        }
        setQueryid("");
        setProcessando(true);
        
        try {
            setRows([]);

            let softId = null;

            if (softhouseid) {
                softId = softhouseid;
            }
            else {
                softId = objBusca.softhouseId  ? Number(objBusca.softhouseId) : null;
            }

            

            const query = { page: serverPage, nrecPage: serverPageSize, projetoId: projAtual.id, busca: objBusca.busca, softhouseId: softId };
            const {data} = await redeHttp.busca(query);
            if (data.sucesso === true && data.dados && data.dados.length > 0) {
                setRows(data.dados);
            }
            else {
                ShowAlert.info(data.mensagem);
            }
        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        } 
        setProcessando(false);
        
    }

    React.useEffect(() => {
        if (detail.open === false && objBusca.listaSofthouse !== undefined) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail, objBusca]);

    return (
    <div>
        <>
            <TsInputBusca
                name="input-busca"
                value={objBusca.busca}
                showBusca={true}
                showAtivo={false}
                showAdd={userLevel > 2 ? true: false}
                onClickAdd={(e) => {setAddRede({...addRede, open: true})}}
                submitSearch={(texto, ativo) => { setObjBusca({...objBusca, busca: texto}); }}
            >
                 
                <Box sx={{flex: '0 0 auto', paddingRight: 2}}>
                    <SelectCampo
                        id="TsInputBusca-Softhouse"
                        label="Softhouse"
                        name="softhouse"
                        options={objBusca.listaSofthouse || []}
                        disabled={objBusca.listaSofthouse && objBusca.listaSofthouse.length > 1 ? false: true}
                        value={objBusca.softhouseId && objBusca.softhouseId !== null ?  objBusca.softhouseId : ''}
                        onChange={(e) => setObjBusca({ ...objBusca,  softhouseId: e.target.value})}
                        />
                </Box> 
            </TsInputBusca>
        </>
        <TsDataGrid
                rows={rows}
                columns={columns}
                queryid={queryid}
                serverPage={serverPage}
                serverPagesize={serverPageSize}
                pageSize={25}
                onChangePage={(value) => setServerPage(value)}
                />
        <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={processando} />
        {userLevel > 2 && 
        <TsDialog sx={{backgroundColor: '#E7E7E7'}} onClose={() => setAddRede({...addRede, open: false})} id="dialog-detalhe-log" open={addRede.open} title={addRede.title} minWidth={800} >
            {addRede.open && <RedeAdicionar detail={addRede} setDetail={setAddRede} listaSofthouse={objBusca.listaSofthouse || []} />}
        </TsDialog>}
    </div>)
}

export default RedeList;