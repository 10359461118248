import * as React from 'react';
import { Box, Button, IconButton, Link, Table, TableBody, TableHead, TableRow, styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import httpQrcode from '../../util/http/qrcode-http';
import ShowAlert from '../../util/ShowAlert';
import {monitorCSS} from '../../style';
import BackIcon from '@mui/icons-material/ArrowBack';
import {TsTextField} from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import TsButton from '../../formulario/TsButton';
import { getUser } from '../../util/auth';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DeleteIcon from '@mui/icons-material/Delete';
import QrcodeAnalyzer from './qrcode-analyzer';
import TsDialog from '../../formulario/TsDialog';

const StyledTableCell = styled(TableCell)(({ theme, fontSize }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    }
    ,
    [`&.${tableCellClasses.body}`]: {
      fontSize: fontSize,
    },
  }));

const detailCss = {
    fontSize: '9pt', color: '#777', paddingBottom: 0.5
};
 
const tituloCss = { padding: 2, margin: 1}

const listaStatusUsuario = [
    {text: '... selecione', value: '0'},
    {text: 'Não pertence ao programa', value: '1'},
    {text: 'O EAN está correto', value: '2'},
    {text: 'Quero informar o EAN', value: '3'},
    {text: 'Desconheço a informação', value: '4'},
    {text: 'EAN em analize pelo sistema', value: '5'}
];

const listaMarcas = [
    {text: '... selecione', value: ''},
    {text: 'Johnson', value: '1'},
    {text: 'Barilla', value: '2'},
    {text: 'Heinken', value: '3'},
    {text: 'Coca Cola', value: '4'},
]


const QrCodeAdm_Detail = (props) => {

    const atual = getUser('admin');
    const token = atual.token;
    const setProcessando = props.setProcessando; 
    const setDetail = props.setDetail;
    const detail = props.detail;

    const [cupom, setCupom] = React.useState({itens: []});
    const [filterRows, setFilterRows] = React.useState([]);
    const [filter, setFilter] = React.useState('PEND');
    const [cupomItem, setCupomItem] = React.useState({usuarioStatusId: '0'});
    const [itemEan, setItemEan] = React.useState('');
    const [itemDetail, setItemDetail] = React.useState(false);
    const [enviarStatus, setEnviarStatus] = React.useState(false);
    const [showImg, setShowImg] = React.useState(false);
    const [imgUrl, setImgUrl] = React.useState('');
    const [imgData, setImgData] = React.useState('');
    const [chaveAcesso, setChaveAcesso] = React.useState('');
    const [sefazUrl, setSefazUrl] = React.useState('');
    const [openAnalyzer, setOpenAnalyzer] = React.useState(false);
 
    const openDetail = async () => {
        setProcessando(true);
        setCupom({itens: []});
        setFilterRows([]);
        setCupomItem({});
        setItemDetail(false);
        setItemEan('');
        try {
            const {data, status} = await httpQrcode.obterCupom(token, detail.id, detail.clientId);

            if (status === 200) {

                console.log(data);
 
                if (data.itens) {
                    data.itens.forEach(item => {
                        item.editMarca = false;
                        item.marca = 'desconhecido';
                        item.marcaId = '0';
                    }); 
                    if (filter === 'PEND') {
                        const rows = data.itens.filter(item => item.statusId !== 92);
                        setFilterRows([...rows]);
                    }
                    else {
                        setFilterRows([...data.itens]);
                    }
                    
                    
                }

                if (data.tipo === 'https' || data.tipo === 'direct') {
                    setSefazUrl(data.qrcodedata);
                }
                else {
                    setSefazUrl('');
                }

                setImgUrl(data.imgUrl);

                if (data.imgUrl) {
                    setShowImg(true);
                }
                else {
                    setShowImg(false);
                }

                setCupom(data);
                
            }
            else {
                ShowAlert.error(data.mensagem || 'falha ao obter cupom');
            } 
            setProcessando(false);
            
        } catch (error) {
            console.log(error);
            ShowAlert.error('Falha ao obter detalhes');
            setProcessando(false);
        }
    }

    const obterImgData = async () => {
        setImgData('');
        try {
            const ret = await httpQrcode.obterImg(token, imgUrl);
            if (ret.status === 200) {
                setImgData(ret.data);
            }
            else {
                setImgData(JSON.stringify(ret, null, 2));
            }
        } catch (error) {
            setImgData(`EXECPITON : ${error.message} `);
        }
    }

    const openDetailItem = (id) => {
        if (id !== undefined) {
            const item = filterRows[id];
            if (item !== undefined) {
                item.usuarioStatusId = item.usuarioStatusId + '';
                let ean = item.ean || '';
                if (!ean) ean = item.eanRede; 
                setItemEan(ean);
                setCupomItem(item);
                setItemDetail(true);
            }
        } 
    }

    const removerItem = async (index) => {
        if (index !== undefined) {
            const item = filterRows[index];
            if (item !== undefined) {
                item.usuarioStatusId = item.usuarioStatusId + '';
                let ean = item.ean || '';
                if (!ean) ean = item.eanRede; 
                setItemEan(ean);
                setCupomItem({...item, usuarioStatusId: '1'});
                setEnviarStatus(true);
            }
        } 
    }

    const processaChaveAcesso = async() => {
        // 00000000011111111112222222222333333333344444
        // 12345678901234567890123456789012345678901234
        // 35240401438784000601590004801732607904673788
        if (chaveAcesso.length !== 44) {
            ShowAlert.error('Chave acesso invalida, 44 caracteres');
            return;
        }
        setProcessando(true);
        try {
            const {data, status} = await httpQrcode.atualizarCupom(token, detail.id, detail.clientId, 0, '', chaveAcesso, sefazUrl);
            if (status === 200 && data.sucesso === true) {
                ShowAlert.info('Status Cupom atualizado');
                openDetail();
             }
             else {
                 ShowAlert.error(data.mensagem || 'falha ao atualizar');
             } 
             setProcessando(false);
        } catch (error) {
            ShowAlert.error('falha ao atualizar');
            setProcessando(false);
        }
    }

    const updateCupom = async (idStatus, mensagem) => {
        if (idStatus === undefined) idStatus = 0;
        if (mensagem === undefined) mensagem = '';
        setProcessando(true);
        try {
            const {data, status} = await httpQrcode.atualizarCupom(token, detail.id, detail.clientId, idStatus, mensagem, '');

            if (status === 200 && data.sucesso === true) {
               ShowAlert.info('Status Cupom atualizado');
               openDetail();
            }
            else {
                ShowAlert.error(data.mensagem || 'falha ao atualizar');
            } 
            setProcessando(false);
            
        } catch (error) {
            ShowAlert.error('falha ao atualizar');
            setProcessando(false);
        }
        
    }

    const openEditMarca = (index) => {
        const itens = filterRows;
        itens.forEach((it, ix) => {
            if (ix === index) {
                it.editMarca = true;
            } else {
                it.editMarca = false;
            }
            
        });
        setFilterRows({...cupom, itens: itens});
    }

    const changeItemMarca = (index, marcaId) => {
        const itens = filterRows;
        const valor = listaMarcas.find(m => m.value === marcaId);
        itens.forEach((it, ix) => {
            if (ix === index) {
                it.marcaId = valor.value;
                it.marca = valor.text;
            } 
            
        });
        setFilterRows({...cupom, itens: itens});
    }

    const processaSefazUrl = async () => {
        if (!sefazUrl) {
            ShowAlert.error('Url inválida');
            return;
        }
        setProcessando(true);
        try {
            const {data, status} = await httpQrcode.atualizarCupom(token, detail.id, detail.clientId, 0, '', '', sefazUrl);
            if (status === 200 && data.sucesso === true) {
                ShowAlert.info('Status Cupom atualizado');
                openDetail();
             }
             else {
                 ShowAlert.error(data.mensagem || 'falha ao atualizar');
             } 
             setProcessando(false);
        } catch (error) {
            ShowAlert.error('falha ao atualizar');
            setProcessando(false);
        }
    }

    React.useEffect(() => {
        if (detail?.show === true) {
            openDetail();
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);


    React.useEffect(() => {
        if (cupom?.itens?.length === 0) {
            openDetailItem(0);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cupom]);

    // await sendEanStatus();

    React.useEffect(() => {
        if (enviarStatus === true) {
            sendEanStatus();
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enviarStatus]);

    React.useEffect(() => {
        if (cupom.itens.length > 0) {
            if (filter === 'PEND') {
                const rows = cupom.itens.filter(item => item.statusId !== 92);
                setFilterRows([...rows]);
            }
            else {
                setFilterRows([...cupom.itens]);
            }
        }
        else {
            setFilterRows([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    React.useEffect(() => { 
        if (imgUrl) {
            obterImgData();
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imgUrl]);

    // obterImg

    // filter

    
    const sendEanStatus = async () => {
        setEnviarStatus(false);
        setProcessando(true);
        try {
            const {data, status} = 
            await httpQrcode.sendEanStatus(token, cupomItem.usuarioStatusId, itemEan, cupomItem.id, detail.clientId );

            setProcessando(false);
            if (status === 200) {
                ShowAlert.info('atualizado com sucesso');
                openDetail(cupom.id);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
        } catch (error) {
            setProcessando(false);
            ShowAlert.error(error.message);
        }
    }

    const formatJson = (texto) => {
        if (!texto) {
            return "NULL";
        }
        let s = texto;
        try {
            const obj = JSON.parse(s);
            s = JSON.stringify(obj, null, 2);
            return s;
        } catch (error) {
            return texto;
        }
    }


    return (<>
     <Box> 
        <Box sx={{display: 'flex'}}>
            <Box sx={{flex: '0 0 auto'}}>
                <Button variant='outlined' onClick={(e) => setDetail({...detail, show: false, id: 0, pageTitle: 'Lista de Cupons', clientId: '0'})} ><BackIcon /> Lista cupons</Button>
            </Box>
            <Box sx={{flex: '0 0 auto', paddingLeft: 2}}>
                <Button variant="outlined" onClick={(e) => updateCupom()} >Atualizar cupom</Button>
            </Box>
            <Box sx={{flex: '0 0 auto', paddingLeft: 2}}>
                <Button variant="outlined" onClick={(e) => openDetail()} >Refresh</Button>
            </Box>
        </Box>
        <Box sx={{...tituloCss, border: '1px solid #ccc'}}>Detalhes do Cupom # {cupom.id}</Box>
        <Box sx={detailCss}>tipo: {cupom.tipo}</Box>
        {cupom.tipo === 'IMG' && <Box sx={detailCss}>imgUrl: {imgUrl}</Box>}
        <Box sx={detailCss}>chaveAcesso: {cupom.chaveAcesso}</Box>
        <Box sx={detailCss}>data: {cupom.data}</Box>
        <Box sx={detailCss}>sefaz - status: {cupom.status_sefaz} retry: {cupom.query_nretry}</Box>
        {(cupom.tipo === 'https' || cupom.tipo === 'direct') &&
            <Box sx={detailCss}>SefazUrl: {cupom.qrcodedata}</Box>
        }
        <Box sx={detailCss}>cupom status: {cupom.status} - ({cupom.statusDescricao})</Box>
        <Box sx={detailCss}>emitente: {cupom.emitente ? cupom.emitente.razao_social : cupom.cnpj}</Box>
        <Box sx={detailCss}>nota fiscal: {cupom.nf_num}</Box>
        <Box sx={detailCss}>dclube-Mensagem: {cupom.dclubeMensagem}</Box>
        <Box sx={detailCss}>pontoApi-Mensagem: {cupom.pontoApiMensagem}</Box>
        <Box sx={detailCss}>pontoApi-retry: {cupom.pontoApiRetry}</Box>
        {cupom.status_sefaz !== 'concluido' &&  <>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField 
                    name="sefazUrl"
                    label="URL ou Chave de acesso"
                    sx={{margin: 0.5}}
                    value={sefazUrl}
                    onChange={(e) => setSefazUrl(e.target.value) }
                />
            </Box>
            <Box sx={{flex: "0 0 auto", padding: 1}}>
                <TsButton variant="contained" onClick={(e) => processaSefazUrl() } >Reprocessar</TsButton>
            </Box>
            <Box sx={{flex: "0 0 auto", padding: 1}}>
                <TsButton variant="contained" onClick={(e) => setOpenAnalyzer(true) } >Codigo Fonte HTML</TsButton>
            </Box>
        </Box>
        <Box sx={monitorCSS}>sefaz response: <br /> {formatJson(cupom.query_Response)}</Box>
        </>}
        {itemDetail &&
        <>
            <Button variant="outlined" onClick={(e) => setItemDetail(false)} ><BackIcon /> Lista itens</Button>
            <Box sx={monitorCSS}>{JSON.stringify(cupomItem, null, 2)}</Box>
            <SelectCampo
                    id='status_usuario'
                    label='Status segundo o usuario'
                    name='status_usuario'
                    options={listaStatusUsuario}
                    value={cupomItem.usuarioStatusId || '0'}
                    onChange={(e) => {
                        setCupomItem({...cupomItem, usuarioStatusId: e.target.value});
                    }}
                />
                {/*    */}
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto'}}>
                <TsTextField 
                    name="itemEan"
                    label="EAN"
                    disabled={cupomItem.usuarioStatusId !== '3'}
                    sx={{margin: 0.5}}
                    value={itemEan}
                    onChange={(e) => setItemEan(e.target.value) }
                />
                </Box>
                <Box sx={{padding: 0.5}}>
                    <TsButton variant="contained" onClick={(e) => sendEanStatus() } >Enviar</TsButton>
                </Box>
            </Box>
        </> 
        }
        { cupom.itens && cupom.itens.length > 0 && 
        <>
        <Box sx={{borderTop: '1px solid #ccc', display: 'flex'}}>
            <Box sx={{...tituloCss, flex: '1 1 auto'}}   >Itens (selecione para mais detalhes)
            </Box>
            <Box sx={{flex: '0 0 auto'}} >  <SelectCampo
                        id='filter_item'
                        label='Filtro'
                        name='filter_item'
                        options={[{value: '', text: 'Todos'}, {value: 'PEND', text: 'Pendentes'}]}
                        value={filter || ''}
                        onChange={(e) => {
                            setFilter(e.target.value);
                        }}
                    />

            </Box>
            
        </Box>
        
        <hr></hr>
        <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
            <TableRow>
                <StyledTableCell>Open</StyledTableCell>
                <StyledTableCell>Delete</StyledTableCell>
                <StyledTableCell>Marca</StyledTableCell>
                <StyledTableCell colSpan={2}>Detalhes</StyledTableCell>
            </TableRow>
        </TableHead>
        <TableBody>
        {filterRows.length > 0 &&  filterRows.map((item, index) => // onClick={(e) => openDetailItem(index)}
            
                <TableRow key={`item-${item.id}`}>
                    <TableCell>
                        <IconButton onClick={(e) => openDetailItem(index)} ><EventNoteIcon /></IconButton>
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={(e) => removerItem(index)} ><DeleteIcon /></IconButton>
                    </TableCell>
                    <TableCell>
                        {item.editMarca ? 
                        <><SelectCampo
                                id='item_marca'
                                label='Marca'
                                name='item_marca'
                                options={listaMarcas}
                                value={item.marcaId || ''}
                                onChange={(e) => {
                                    changeItemMarca(index, e.target.value);
                                }}
                                />
                        </> : 
                        <><Link component={Button} onClick={(e) => openEditMarca(index)}>{item.marca}</Link></>}
                        
                    </TableCell>
                    <TableCell>
                        <Box>[{item.id}] {item.codigoRede}:{item.nome}</Box>
                        <Box>ean: {item.ean || 'Indeterminado'}</Box>
                    </TableCell>
                    <TableCell>
                        <Box>{item.status} - ({item.statusDescricao})</Box>
                        <Box sx={{display: 'flex'}}>
                            <Box sx={{flex: '1 1 auto'}}>qtde: {item.quantidade}</Box>
                            <Box sx={{flex: '1 1 auto', textAlign: 'right'}}>Total: {item.total}</Box>
                        </Box>
                    </TableCell>
                </TableRow>
            
        )}
        </TableBody>
        </Table>
        </>}
        {showImg &&
        <>
            <Box>
                <TsTextField 
                    name="chaveAcesso"
                    label="Chave Acesso"
                    sx={{margin: 0.5}}
                    value={chaveAcesso}
                    onChange={(e) => setChaveAcesso(e.target.value) }
                />
            </Box>
            <Box>
                <TsButton variant="contained" onClick={(e) => processaChaveAcesso() } >Enviar</TsButton>
                <TsButton variant="contained" onClick={(e) => updateCupom(2, 'Imagem, chave acesso ilegível') } >Chave Acesso Ilegivel</TsButton>
                <TsButton variant="contained" onClick={(e) => updateCupom(2, 'Imagem incompativel') } >Imagem Incompativel</TsButton>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
                <Box sx={{flex: "1 8 auto", width: "45%"}}></Box>
                <Box sx={{flex: "1 0 auto", minHeight: "200px", Width: "500px", backgroundColor: "#ccc", textAlign: "center"}}>
                    <img src={imgData} alt='Imagem do cupom' width="500px" />
                </Box>
                <Box sx={{flex: "1 8 auto", width: "45%"}}></Box>
            </Box>
            
            { /* <Box sx={{...monitorCSS, maxHeight: '300px'}}>{imgData}</Box> */ }
        </> 
        }
    </Box>
    {openAnalyzer &&
        <TsDialog id="dialog-qrcode-analizer" onClose={(e) => {setOpenAnalyzer(false)}} open={openAnalyzer} title="Analizador HTML" minWidth={650} >
            <QrcodeAnalyzer chaveAcesso={cupom.chaveAcesso} cupomid={cupom.id} token={token} />
        </TsDialog>
    }
    
    </>);
}

export default QrCodeAdm_Detail;