import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { Box,   Link,   Tab,   Tabs,   TextField } from '@mui/material';
import { HOMEDIR, getProjetoStorage, getUser, getPermit  } from '../../util/auth'; 

/* http */
import produtoHttp from '../../util/http/produto-http';
import relatorioHttp from '../../util/http/relatorio-http';
import distribuidorHttp from '../../util/http/distribuidor-http'; 

import ShowAlert from '../../util/ShowAlert'; 
import ShowError from '../../formulario/ShowError';
import TsBackDrop from '../../formulario/TsBackDrop';
import SelectCampo from '../../formulario/SelectCampo';
import TsButton from '../../formulario/TsButton';
import {Image} from '@mui/icons-material';
import {formatNumber} from '../../util/Util';

import TsDataGrid from '../../formulario/TsDataGrid';

let ProdutoDetalhe_processando = false;



const produtoNull = {
    campanhas: [],
    produtoId: 0,
    produtoCodigo: '',
    produtoNome: '',
    marcaId: '',
    industriaId: '',
    categoriaId: '',
    preco: 0.00,
    projetoId: 5000
}

/* 
 
*/

const ProdutoDetalhe = (props) => {

    const tipoDescontos = ['SEM_DESCONTO', 'INTEGRAL', 'PERCENTUAL', 'PERCENTUAL_PDV', 'PROGRESSIVO'];

    const setDialogOpen = props.setDialogOpen;
    const produtoCodigo = props.produtoCodigo;
    const projeto = getProjetoStorage();
    const atual = getUser('produto');

    const permCampanha = getPermit(atual, 'campanha');
    const permProduto = atual.userLevel;
    // const permCampanha = 0;

    const [lstCategorias, setLstCategorias] = React.useState([]);
    const [lstMarcas, setLstMarcas] = React.useState([]);
    const [lstIndustrias, setLstIndustrias] = React.useState([]);
    const [abrirCampanha, setAbrirCampanha] = React.useState(false);
    const [lstRedes, setLstRedes] = React.useState([]);
    const [rede, setRede] = React.useState({id: 0, nome: '', distribuidorId: 0, status: 'Selecione a rede'});
    const [lstDistribuidores, setLstDistribuidores] = React.useState([]);

    const [showZicard, setShowZicard] = React.useState(false);
    const [produto, setProduto] = React.useState({...produtoNull, projetoId: Number(projeto.id)});

    const [rows, setRows] = React.useState([]);
    const [url, setUrl] = React.useState(""); 
    const [executing, setExeccuting] = React.useState(false);
    const [error, setError] = React.useState(undefined);

    const [tabIndex, setTabIndex] = React.useState(0);
    
    const columnsZicard = [
        {
            name: "campanhaId",
            label: "campanhaId",
            format: (value) => (permCampanha > 0 ? <Link onClick={(e) => setAbrirCampanha(value)}>{value}</Link> : <>{value}</>)
        },
        {
            name: "desconto",
            label: "desconto",
            format: (value) => (<>{formatNumber(value)}</>)
        },
        {
            name: "tipoDesconto",
            label: "tipoDesconto",
            format: (value) => (<>{tipoDescontos[value]}</>)
        },
        {
            name: "valorDescZicard",
            label: "Dclube",
            format: (value) => (<>{formatNumber(value)}</>)
        },
        {
            name: "ponto",
            label: "Ponto",
            format: (value) => (<>{formatNumber(value)}</>)
        },
        {
            name: "limite",
            label: "limite"
        }
    ];

    const columnsRede = [
        {
            name: "campanhaId",
            label: "campanhaId",
            format: (value) => (permCampanha > 0 ? <Link onClick={(e) => setAbrirCampanha(true)}>{value}</Link> : <>{value}</>)
        },
        {
            name: "desconto",
            label: "desconto",
            format: (value) => (<>{formatNumber(value)}</>)
        },
        {
            name: "tipoDesconto",
            label: "tipoDesconto",
            format: (value) => (<>{tipoDescontos[value]}</>)
        },
        {
            name: "ponto",
            label: "Ponto",
            format: (value) => (<>{formatNumber(value)}</>)
        },
        {
            name: "limite",
            label: "limite"
        }
    ];

    

    const setProcessando = (value) => {
        ProdutoDetalhe_processando = value;
        setExeccuting(value);
    }

    const getProcessando = () => {
        return ProdutoDetalhe_processando;
    }

    const carregarIndustrias = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('industria', undefined);
            const lista = [
                {text: '...Selecione', value: ''},
                ...data
            ];
            setLstIndustrias(lista);
        } catch (error) {
            setError(error);
        }
    }

    const carregarRedes = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('rede', projeto.id);
            const lista = [
                {text: '...Selecione', value: '0'},
                ...data
            ];
            setLstRedes(lista);
        } catch (error) {
            setError(error);
        }
    }

    const carregarMarcas = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('marca', undefined);
            const lista = [
                {text: '...Selecione', value: ''},
                ...data
            ];
            setLstMarcas(lista);
        } catch (error) {
            setError(error);
        }
    }

    const carregarCategorias = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('categoria', undefined);
            const lista = [
                {text: '...Selecione', value: ''},
                ...data
            ];
            setLstCategorias(lista);
        } catch (error) {
            setError(error);
        }
    }

    const carregarDistribuidores = async () => {
        // setLstDistribuidores
        try {
            const {data} = await relatorioHttp.obterLista('distribuidor', projeto.id);
            const lista = [
                {text: '...Selecione', value: ''},
                ...data
            ];
            setLstDistribuidores(lista);
        } catch (error) {
            setError(error);
        }
    }

    
    const Buscar = async () => {

        if (getProcessando()) {
            return;
        }

        setError(undefined);
        setProcessando(true);

        try {
            const {data} = await produtoHttp.obter(produtoCodigo, 0, Number(projeto.id));
            setProduto(data);
            setShowZicard(data.showValorZicard);
            if (data.campanhas && data.campanhas.length > 0) {
                data.campanhas.forEach(item => {
                    if (data.descontoAtivo) item.limite = 'Não';
                    else item.limite = 'Sim';
                });
                setRows(data.campanhas);
            }
            else {
                setRows([]);
            }
            
        } catch (error) {
            setUrl(produtoHttp.getUrl());
            setError(error);
            
        }

        setProcessando(false);
    };

    const salvarProduto = async() => {
        try {
            setError(undefined);
            /* 
             prod.dataAlteracao = agora;
                prod.produtoNome = produto.produtoDescricao;
                prod.marcaId = produto.marcaId;
                prod.fornecedorId = produto.industriaId;
                prod.categoriaId = produto.categoriaId;
            */
            setProcessando(true);
            const prod = {
                Id: produto.produtoId,
                produtoCodigo: produto.produtoCodigo,
                produtoDescricao: produto.produtoNome,
                marcaId: parseInt(produto.marcaId),
                industriaId: parseInt(produto.industriaId),
                categoriaId: parseInt(produto.categoriaId),
                projetoId: parseInt(produto.projetoId),
                valorBase: parseFloat(produto.preco),

            }
            if (prod.Id === 0) {
                const {data} = await produtoHttp.create(prod);
                console.log('create retorno', data);
                ShowAlert.info('Produto Incluido com sucesso');
            }
            else {
                const {data} = await produtoHttp.update(produto.produtoId, prod);
                console.log('atualizacao retorno', data);
                ShowAlert.info('Produto atualizado com sucesso');
            }
            
        } catch (error) {
            setUrl(produtoHttp.getUrl());
            setError(error);
        }
        setProcessando(false);
    }

    // executeMaint('produto', 'codigoProduto', 'add')
    const executeMaint = async (operacao) => {

        if (operacao === 'delete') {
            ShowAlert.error('Não implementado');
            return;
        }

        if (rede.id + '' === '0') {
            ShowAlert.error('Selecione uma rede valida');
            return;
        }

        if (rede.distribuidorId + '' === '') {
            ShowAlert.error('Selecione um distribuidor valido');
            return;
        }

        const valor = produto.produtoCodigo;
        const tipo = 'produto';
        console.log(`executeMaint - tipo: ${tipo}, valor: ${valor}, operacao: ${operacao}`);
        setProcessando(true);
        try {
            const {data} = await distribuidorHttp.executeMaint(rede.distribuidorId, rede.id, projeto.id, tipo, valor, operacao);
            setProcessando(false);
            if (data.sucesso === true) {
                ShowAlert.info(data.mensagem);
                selecionarRede(rede.id);
            } else {
                ShowAlert.error(data.mensagem);
            }
        } catch (error) {
            setProcessando(false);
            setUrl(distribuidorHttp.getUrl());
            setError(error);
        }

    }

    const selecionarRede = async(id) => {
        let distribuidorId = '';
        let status = 'Selecione a rede';
        let data = {};
        if (id + '' === '0') {
            setRede({...rede, id: '0', distribuidorId: distribuidorId, status: status});
            return;
        }
        try {
            const ret = await distribuidorHttp.listProdutos(0, id, {busca: produto.produtoCodigo, projetoId: projeto.id});
            data = ret.data;
        } catch (error) {
            if (error.data?.sucesso !== undefined) {
                data = error.data;
            }
            else {
                data = {sucesso: false, mensagem: `EXCEPTION: ${error.message}`}
            }
        }

        if (data.sucesso === true && data.dados?.length > 0) {
            const item = data.dados[0];
            distribuidorId = item.distribuidorId + '';
            status = `produto disponivel na rede, distribuidor: ${item.distribuidorNome}`;
        }
        else {
            console.log(data);
            status = `produto não disponivel na rede, [${data.mensagem}]`;
            
        }

        setRede({...rede, id: id + '', distribuidorId: distribuidorId, status: status});
        
    }

    React.useEffect(() => {

        if (lstCategorias.length === 0) {
            carregarIndustrias();
            carregarMarcas();
            carregarCategorias();
            carregarRedes();
            carregarDistribuidores();
        }

        if (produtoCodigo) {
            Buscar();
        }
        else {
            setProduto({...produtoNull, projetoId: Number(projeto.id)});
            setRows([]);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produtoCodigo]);

    return (
        <>
            <Box>
                <Box sx={{display: "flex"}}>
                    <Box sx={{margin:2, flex: "0 0 auto"}}>
                        <TextField
                            name="produtoCodigo"
                            id={"txt_produtoCodigo"}
                            label="Codigo"
                            fullWidth
                            value={produto.produtoCodigo + ''}
                            disabled={produtoCodigo ? true: false}
                            onChange={(e) => setProduto({...produto, produtoCodigo: e.target.value})}
                        />
                    </Box>
                    <Box sx={{margin:2, flex: "1 1 auto"}}>
                        <TextField
                            name="produtoNome"
                            id={"txt_produtoNome"}
                            label="Produto"
                            fullWidth
                            value={produto.produtoNome + ''}
                            onChange={(e) => setProduto({...produto, produtoNome: e.target.value})}  
                        />
                    </Box>
                </Box>
                    <Box sx={{display: "flex"}}>
                        <Box sx={{margin:2, flex: "1 1 auto"}}>
                            {produto.urlImagem ? <img src={produto.urlImagem} alt='Imagem do produto' width='300px' /> : <Image sx={{fontSize: '200px'}} />}
                            
                        </Box>
                        <Box sx={{margin:2, flex: "1 1 auto"}}>
                            <Box>
                                <Tabs value={tabIndex} onChange={(event, newValue) => {setTabIndex(newValue)}} aria-label="Opções da oferta">
                                <Tab label="Detalhes" id="dash-tab-0" aria-labelledby={`prod-tab-0`} />
                                <Tab label="Redes" id="dash-tab-1" aria-labelledby={`prod-tab-1`} /> 
                                </Tabs>
                            </Box>
                            {tabIndex === 0 &&
                            <Box sx={{minHeight: "340px", minWidth: "600px"}}>
                                <SelectCampo
                                    id="lst_industria"
                                    label="Industria"
                                    name="industria"
                                    debug={false}
                                    sx={{margin: "12px 0px"}}
                                    options={lstIndustrias}
                                    value={produto.industriaId || ''}
                                    onChange={(e) => setProduto({...produto, industriaId: e.target.value})}
                                />
                                <SelectCampo
                                    id="lst_marca"
                                    label="Marca"
                                    name="marca"
                                    debug={false}
                                    sx={{margin: "12px 0px"}}
                                    options={lstMarcas}
                                    value={produto.marcaId || ''}
                                    onChange={(e) => setProduto({...produto, marcaId: e.target.value})}
                                />
                                <SelectCampo
                                    id="lst_categoria"
                                    label="Categoria"
                                    name="categoria"
                                    debug={false}
                                    sx={{margin: "12px 0px"}}
                                    options={lstCategorias}
                                    value={produto.categoriaId || ''}
                                    onChange={(e) => setProduto({...produto, categoriaId: e.target.value})}
                                />
                                <TextField
                                    name="valorBase"
                                    id={"txt_valorBase"}
                                    label="valor base"
                                    fullWidth
                                    value={produto.preco + ''}
                                    onChange={(e) => setProduto({...produto, preco: e.target.value})}  
                                />
                                <Box sx={{padding: 1}} dir="rtl">
                                    {permProduto > 2 && <TsButton
                                        onClick={(e) => salvarProduto()}
                                    >Enviar</TsButton>}
                                    <TsButton
                                        onClick={(e) => setDialogOpen(false)}
                                        color='secondary'
                                    >Fechar</TsButton>
                                </Box>
                            </Box> }
                            {tabIndex === 1 && 
                            <Box sx={{minHeight: "340px", minWidth: "600px"}}>
                                <SelectCampo
                                    id="lst_rede"
                                    label="Rede"
                                    name="rede"
                                    debug={false}
                                    sx={{margin: "12px 0px"}}
                                    options={lstRedes}
                                    value={rede.id || ''}
                                    onChange={(e) => selecionarRede(e.target.value)}
                                />
                                <SelectCampo
                                    id="lst_Distribuidor"
                                    label="Distribuidor"
                                    name="distribuidor"
                                    disabled={rede.id + '' === '0' ? true: false}
                                    debug={false}
                                    sx={{margin: "12px 0px"}}
                                    options={lstDistribuidores}
                                    value={rede.distribuidorId || ''}
                                    onChange={(e) => setRede({...rede , distribuidorId: e.target.value})}
                                />
                                <TextField
                                    name="status"
                                    id={"rede_status"}
                                    label="status"
                                    fullWidth
                                    disabled={true}
                                    value={rede.status || ''}   
                                />
                                <Box sx={{padding: 1}} dir="rtl">  
                                    {permProduto > 2 && <> <TsButton
                                        onClick={(e) => executeMaint('add')}
                                    >Atualizar</TsButton>
                                    <TsButton
                                        onClick={(e) => executeMaint('delete')}
                                    >Remover</TsButton> </>}
                                </Box>
                            </Box>}
                        </Box> 
                        
                    </Box>
                    <TsDataGrid 
                            rows={rows}
                            columns={showZicard ? columnsZicard : columnsRede}
                            serverPage={0}
                            serverPagesize={50}
                            showAdd={false}
                            onChangePage={(value) => void(0) }
                        />
            </Box>
            
            <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
            <TsBackDrop processando={executing} />
            { abrirCampanha ? <Navigate to={`${HOMEDIR}/campanha/${abrirCampanha}/editar`}  replace={true} /> : null}
        </>
    );
 

}
 


export default ProdutoDetalhe;

