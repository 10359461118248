import React from 'react';
import { ThemeProvider } from '@mui/material';
import { Box, CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import { themeBase } from './theme';
import { SnackbarProvider } from './components/SnackbarProvider';
import { Navbar } from './components/Navbar';
import AppRouter from './components/routes/AppRouter';
import TsDialog from './components/formulario/TsDialog';
import PerfilUsuario from './components/pages/perfil';

import { HOMEDIR, getUser, usuarioNull } from './components/util/auth';

function App() {
  const [atual, setAtual] = React.useState(usuarioNull);
  const [openModalPerfil, setOpenModalPerfil] = React.useState(false);
  let hasNavBar = true;
  let appStyle = { paddingTop: '80px' };
  const pathname = window.location.pathname;

  // || pathname === `${HOMEDIR}/cadastro`
  if (pathname === `${HOMEDIR}/relatorio-comissao` || pathname === `${HOMEDIR}/qrcode`) {
    hasNavBar = false;
    appStyle = {};
  }

  const setOpenPerfil = (valor) => {
    if (valor !== openModalPerfil) {
      setOpenModalPerfil(valor);
    }
  };

  const checkUser = () => {
    const usuario = getUser();

    if (usuario.id === 0 && usuario.id !== atual.id) {
      setAtual(usuario);
      window.location.href = `${HOMEDIR}/`;
    }

    if (usuario.id !== atual.id) {
      setAtual(usuario);
    }
  };

  React.useEffect(() => {
    const usuario = getUser();
    setAtual(usuario);
  }, []);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={themeBase}>
        <SnackbarProvider>
          <BrowserRouter>
            {hasNavBar && <Navbar atual={atual} checkUser={checkUser} setOpenPerfil={(value) => setOpenPerfil(value)} />}
            <Box sx={appStyle}>
              <AppRouter setOpenPerfil={(value) => setOpenPerfil(value)} />
            </Box>
            <TsDialog
              onClose={() => setOpenPerfil(false)}
              id="dialog-perfil"
              open={openModalPerfil}
              title={`perfil do usuário ${atual.login}`}
              minWidth={800}
            >
              {openModalPerfil && <PerfilUsuario open={openModalPerfil} closeModal={() => setOpenPerfil(false)} />}
            </TsDialog>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
