import { Box} from '@mui/material';
import * as React from 'react';
import { Page } from '../Page';
import ContratoList from './contrato-list';
import ContratoDetail from './contrato-detail';
import ProspectDetail from './prospect-detail';

const ContratoPage = () => {
    const [detail, setDetail] = React.useState({open: false, id: 0, tipo: 'contrato', pageTitle: "Lista de contratos"}); 
    
    return (
        <Page title={detail.pageTitle} sx={{backgroundColor: '#ECECEC'}}>
            <Box>
                {detail.open && detail.tipo === 'contrato' &&
                <ContratoDetail setDetail={setDetail}  detail={detail}></ContratoDetail> }
                {detail.open && detail.tipo === 'prospect' &&
                <ProspectDetail setDetail={setDetail}  detail={detail}></ProspectDetail> } 
                {detail.open === false && 
                <ContratoList setDetail={setDetail} detail={detail}></ContratoList>}
            </Box>
        </Page>
    );
};

export default ContratoPage;