import * as React from 'react';
// 
import { Box, Link, Typography } from '@mui/material';
import TextForm from '../../formulario/TextForm';
import { TipoPontoCampos, validarTipoPonto } from '../../dominios/TipoPonto'; // validarTipoPonto
import EventNoteIcon from '@mui/icons-material/EventNote';
import TsButton from '../../formulario/TsButton';
import { subTitleCSS } from '../../style';

/* http */
import ofertaHttp from '../../util/http/oferta-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
// import { HOMEDIR } from '../../util/auth';
// import TsDataGridBusca from '../../formulario/TsDataGridBusca';
import TsDialog from '../../formulario/TsDialog';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import TsBackDrop from '../../formulario/TsBackDrop';
import ListaBase from '../ListaBase';
import ShowAlert from '../../util/ShowAlert';

const boxProps = {
    borderColor: '#B9B9B9',
    border: 1
};


// _type_ Props = {
//     idInicial,
//     open,
//     onClose: (valor ) => void
// };

// <TsDataGridx />
export class TipoPontoForm extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;
        this.state.editar = false;
        this.state.form = {};
        this.state.validating = false;
       
        this.columns = [
            {
                label: "id", name: "id",
                format: ((value) => (<Link component="button" onClick={() => this.selecionar(value)}><EventNoteIcon /></Link>))
            },
            { label: "descricao", name: "descricao" },
            { label: "formulaUnitario", name: "formulaUnitario" },
            { label: "formulaTotal", name: "formulaTotal" },
        ];
    }

    setEditar(editar) {
        this.setState({editar});
    }

    setForm(form) {
        this.setState({form});
    }

    setValidating(validating) {
        this.setState({validating});
    }

    onSubmit = (e) => {
        this.setError(undefined);
        this.setProcessando(true);

        this.setValidating(true);
        const msg = validarTipoPonto(this.state.form);

        if (msg) {
            ShowAlert.error(msg);
            return;
        }
        this.setValidating(false);

        const model = {
            id: 0,
            descricao: this.state.form.descricao,
            formulaTotal: this.state.form.formulaTotal,
            formulaUnitario: this.state.form.formulaUnitario
        };

        ofertaHttp.gravarTipoPonto(model)
            .then((retorno) => {
                this.Buscar();
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setUrl(ofertaHttp.getUrl());
            });

    }

    selecionar = (valor) => {
        this.props.onClose(valor);
    }

    Buscar = () => {
        this.iniciarBusca();

        this.setValidating(false);
        this.setEditar(false);
        
        ofertaHttp.listaTipoPonto()
            .then(({ data }) => {

                this.setRows(data);

                data.forEach((it, index) => {
                    if (it.id === this.props.idInicial) {
                        this.setForm({ ...it, index: index });
                    }

                });
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(ofertaHttp.getUrl());
            });
    }

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
    }
    
    render() {

        return (
            <TsDialog onClose={() => this.props.onClose(undefined)} id="dialog-tipo-oferta" open={this.props.open} title="Tipo de Oferta" minWidth={800}>
                <>
                    {this.state.editar &&
                        <form onSubmit={(e) => { this.onSubmit(e) }}>
                            <Box {...boxProps}
                                borderRadius={4}
                                padding={2}
                            >
                                <Typography sx={subTitleCSS}>
                                    Adicionar novo Tipo Ponto
                                </Typography>
                                <TextForm
                                    campo={TipoPontoCampos.descricao}
                                    formData={this.state.form}
                                    setFormData={(value) => this.setForm(value)}
                                    validating={this.state.validating}
                                />
                                <TextForm
                                    campo={TipoPontoCampos.formulaUnitario}
                                    formData={this.state.form}
                                    setFormData={(value) => this.setForm(value)}
                                    validating={this.state.validating}
                                />
                                <TextForm
                                    campo={TipoPontoCampos.formulaTotal}
                                    formData={this.state.form}
                                    setFormData={(value) => this.setForm(value)}
                                    validating={this.state.validating}
                                />
                                <Box dir="rtl">
                                    <TsButton
                                        onClick={(e) => this.onSubmit(e)}
                                    >Enviar</TsButton>
                                    <TsButton
                                        onClick={(e) => this.setEditar(false)}
                                        color='secondary'
                                    >Cancelar</TsButton>
                                </Box>
                            </Box>
                        </form>}
                    <>
                        <TsInputBusca
                            name="input-busca"
                            value={this.state.busca}
                            showBusca={false}
                            showAdd={true}
                            showAtivo={false}
                            onClickAdd={(e) => this.setEditar(true) }
                        ></TsInputBusca>
                    </>
                    <TsDataGrid
                        rows={this.state.rows}
                        columns={this.columns}
                        queryid={this.state.queryid}
                        serverPage={this.state.serverPage}
                        serverPagesize={this.serverPageSize}
                        onChangePage={(value) => this.setServerPage(value)}
                    />
                    {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
                    <TsBackDrop processando={this.state.processando} />
                </>
            </TsDialog>
        );
    }
};