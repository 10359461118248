import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class DescontoControleHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.url = resource;
    this.http = http;
    this.resource = resource;
  }

  // addProduto(campanhaId, ofertaId, dados) {
  //   this.url = `/${this.resource}/${campanhaId}/oferta/${ofertaId}/produto - PUT`;
  //   return this.http.put(`${this.resource}/${campanhaId}/oferta/${ofertaId}/produto`, dados, this.config);
  // }

  // [HttpGet]
  // [Route("reset-produtos/{id}")]
  resetProdutos(id) {
    this.url = `/${this.resource}/reset-produtos/${id} - GET`;
    return this.http.get(`${this.resource}/reset-produtos/${id}`, this.config);
  }

  // [HttpGet]
  // [Route("exec-validacao")]
  execValidacao() {
    this.url = `/${this.resource}/exec-validacao - GET`;
    return this.http.get(`${this.resource}/exec-validacao`, this.config);
  }
}

const descontocontroleHttp = new DescontoControleHttpResource(httpTsApi, 'descontocontrole');

export default descontocontroleHttp;
