import * as React from 'react';
import redeHttp from '../../util/http/rede-http'; 
import ShowAlert from '../../util/ShowAlert';
import TsDataGrid from '../../formulario/TsDataGrid';
import {Box, Button, Fab, Link} from '@mui/material';
import SelectCampo from '../../formulario/SelectCampo';
import TsInputBusca from '../../formulario/TsInputBusca';
import EventNoteIcon from '@mui/icons-material/EventNote'; 
import AddIcon from '@mui/icons-material/AddLocationAlt';
import EstabelecimentoDetail from '../estabelecimento/estabelecimento-detail'
import TsDialog from '../../formulario/TsDialog';
import { CSVLink } from "react-csv";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

let RedeEstabelecimentoProcessando = false;
 
const RedeEstabelecimento = (props) => {

    const {detail, projAtual, setError, setUrl, processando, setProcessando, open, userLevel} = props;
    const [rows, setRows] = React.useState([]);  
    const [serverPage, setServerPage] = React.useState(0);
    const serverPageSize = 50;
    const queryid = Math.random();
    const [objBusca, setObjBusca] = React.useState({busca: '', tipo: '1', projetoId: Number(projAtual.id)});
    const [estabelecimento, setEstabelecimento] = React.useState({open: false, title: 'detalhes'});
    const [downloadFileName, setDownloadFileName] = React.useState('lista-estabelecimentos.csv'); 
    const csvLinkEl = React.createRef();

    const columns = [
        {
            name: "index",
            label: "#",
            format: ((value) => (<Link component={Button} onClick={(e) => {openDetail(value)}}><EventNoteIcon /></Link>))
        },
        {name: "maskCnpj",label: "CNPJ"},
        {name: "tipo",label: "Tipo"},
        {name: "lojaId",label: "Loja"},
        {name: 'status',  label: 'Status'},
        {name: "rede",label: "Rede"},
        {name: "nome",label: "Nome"},
        {name: "bairro",label: "Bairro"},
        {name: "cidade",label: "Cidade"},
        {name: "uf",label: "Estado"},
        {name: "comissaoFaturamento",label: "Comissão", format: ((value) => (<>{(value * 100).toFixed(2)}%</>))},
        {
            name: "index",
            label: "add loja",
            format: ((value) => (linkAddLoja(value)))
        }
    ];

    const columnsCsv = [
        {name: 'maskCnpj',      key: 'maskCnpj',    show: true,  label: 'CNPJ'},
        {name: "tipo",          key: 'tipo',        show: true,  label: "Tipo"},
        {name: "lojaId",        key: 'lojaId',      show: true,  label: "Loja"},
        {name: "rede",          key: 'rede',        show: true,  label: "Rede"},
        {name: "status",        key: 'status',      show: true,  label: "Status"},
        {name: "nome",          key: 'nome',        show: true,  label: "Nome"},
        {name: "bairro",        key: 'bairro',      show: true,  label: "Bairro"},
        {name: "cidade",        key: 'cidade',      show: true,  label: "Cidade"},
        {name: "uf",            key: 'uf',          show: true,  label: "Estado"},
        {name: "terminais",     key: 'terminais',   show: true,  label: "terminais"},
        {name: "comissaoFaturamento", key: 'comissaoFaturamento', show: true, label: 'Comissão'}
    ];


    const linkAddLoja = (index) => {
        if (userLevel < 3) {
            return (<></>);
        } 
        const item = rows[index];
        const tipo = item.tipo ? item.tipo.toLowerCase() : 'estabelecimento';
        if (tipo === 'estabelecimento')
            return (<Link component={Button} onClick={(e) => {addLoja(index)}}><AddIcon /></Link>);
        else 
            return (<></>);
    }

    const openDetail = (index) => {
        const item = rows[index];
        const tipo = item.tipo ? item.tipo.toLowerCase() : 'estabelecimento';
        const title = item.tipo === 'estabelecimento' 
            ? `Rede: ${detail.id} - Detalhes do Estabelecimento` 
            : `Rede: ${detail.id} - Detalhes da Loja`;

        setEstabelecimento({open: true, cnpj: item.cnpj, maskCnpj: item.maskCnpj, tipo, lojaId: item.lojaId, redeId: detail.id, title});
    }

    const addEstabelecimento = () => {
        if (userLevel > 2) {
            setEstabelecimento({open: true, cnpj: 0, maskCnpj: '', tipo: 'estabelecimento', lojaId: '', redeId: detail.id, title: `Rede: ${detail.id} - Novo do Estabelecimento`});
        }
    }

    const addLoja = (index) => {
        if (userLevel > 2) {
            const item = rows[index];
            setEstabelecimento({open: true, cnpj: item.cnpj, maskCnpj: item.maskCnpj, tipo: 'loja', lojaId: '', redeId: detail.id, title: `Rede: ${detail.id} - Nova Loja`});
        }
    }

    const Buscar = async () => {

        if (processando === true || RedeEstabelecimentoProcessando === true) return;
        setProcessando(true);
        RedeEstabelecimentoProcessando = true;

        setDownloadFileName('lista-estabelecimentos.csv');
        let tempo = objBusca.busca || '';
        if (tempo.indexOf('/') > -1 && tempo.length > 8) {
            tempo = tempo.replace(/\//g, '').replace(/\./g, '').replace(/-/g, '');
        }
        
        try {
            const query = { 
                page: serverPage, 
                nrecPage: serverPageSize,
                tipo: objBusca.tipo,
                busca:  tempo, 
                projetoId: objBusca.projetoId, 
                redeId: detail.id 
            };
            const {data} = await redeHttp.buscaEstabelecimento(query);
            setProcessando(false);
            if (data.sucesso === true) {
                const estabs = [...data.dados];
                estabs.forEach((it, index) => {it.index = index});
                setRows(estabs);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
            
            
        } catch (error) {
            setProcessando(false);
            setUrl(redeHttp.getUrl());
            setError(error);
        }

        RedeEstabelecimentoProcessando = false;
        
    }

    const OnClickDownLoad = async () => {
        csvLinkEl.current.link.click(); 
    }

    React.useEffect(() => {
        if (open === true && !estabelecimento.open) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, objBusca, estabelecimento]);

    return (
    <>  
        <CSVLink
            headers={columnsCsv}
            filename={downloadFileName}
            data={rows}
            ref={csvLinkEl}
            separator={";"}
        />
        <Box sx={{display: 'flex'}}>
            <Box sx={{flex: '1 1 auto'}}>
                <TsInputBusca
                name="input-busca"
                value={objBusca.busca}
                showBusca={true}
                showAtivo={false}
                showAdd={userLevel > 2 ? true: false}
                onClickAdd={(e) => {addEstabelecimento()}}
                submitSearch={(texto, ativo) => { setObjBusca({...objBusca, busca: texto}); }}
                        />
            </Box>
            <Box sx={{flex: '0 0 auto', paddingLeft: 2}}>
                <SelectCampo
                    id="TsInputBusca-Tipo"
                    label="Tipo Listagem"
                    name="tipo-listagem"
                    options={[{ text: 'Somente Estabelecimentos', value: '0' },{ text: 'Estabelecimentos e Lojas', value: '1' }]}
                    value={objBusca.tipo || ''}
                    onChange={(e) => setObjBusca({ ...objBusca,  tipo: e.target.value})}
                    />
            </Box>
            <Box sx={{flex: "0 0 auto", paddingTop: 0.5, paddingLeft: 2}} ><Fab
                title="Download"
                color='alert'
                size="small"
                onClick={(e) => OnClickDownLoad()}
            >    
                    <CloudDownloadIcon />
                </Fab>
            </Box>
            
        </Box>
        
            
             
        <TsDataGrid
            rows={rows}
            columns={columns}
            queryid={queryid}
            serverPage={serverPage}
            serverPagesize={serverPageSize}
            onChangePage={(value) => setServerPage(value)}
        />
        <TsDialog onClose={() => setEstabelecimento({...estabelecimento, open: false})} id="dialog-detalhe-log" open={estabelecimento.open} title={estabelecimento.title} minWidth={800} >
            {estabelecimento.open && <EstabelecimentoDetail detail={estabelecimento} setDetail={setEstabelecimento} userLevel={userLevel} />}
        </TsDialog>
        
       
         
        
    </>
    )

}


export default RedeEstabelecimento;