import * as React from 'react';
import { Box, Grid } from '@mui/material';
import SelectCampo from '../../formulario/SelectCampo';
import { HOMEDIR } from '../../util/auth';
import TsButton from '../../formulario/TsButton';

const titleCSS = {
    background: "#000099",
    color: "#ccc",
    padding: "10px",
    textAlign: "center",
    margin: "4px"
};

// setProxy={setProxy} prx={prx} proxy={proxy}

const LeftPanel = (props) => {

    const {pedido, showInfo} = props; // setPedido

    const prx = JSON.parse(localStorage.getItem('LISTA_PROXY'));
    
    const handleChangeProxy = (e) => {
        const valor = e.target.value;
        const novo = prx.find(p => p.nome === valor);
        if (novo) {
            showInfo('processando', true);
            localStorage.setItem("chaveProxy", novo.nome);
            setTimeout(() => {
                window.location.href = `${HOMEDIR}/simulador`;
            }, 500);
        }
    }


    return (
    <>
    <Grid item xs={3}>
                    <Box>
                        <SelectCampo
                            name="proxy"
                            id='lst-proxy'
                            label='PROXY-URL'
                            value={pedido.proxy.nome}
                            onChange={(e) => handleChangeProxy(e)}
                            options={prx.map(p => { return { value: p.nome, text: p.nome } })}
                        />
                    </Box>
                    <Box hidden={pedido.tabIndex !== 0} >
                        <Box sx={titleCSS} >SELECIONAR PDV</Box>
                        <p>(*) Selecione a REDE, CNPJ na tela ao lado.</p>
                        <p>Selecionar o PDV não faz parte da comunicação com o autenticador, se trata de uma forma de voce especificar qual rede / cnpj vai transacionar com o mesmo.
                            A busca de CNPJ é feita diretamente no autenticador. O proxy não é envolvido nessa operação.
                        </p>
                    </Box>
                    <Box hidden={pedido.tabIndex !== 1} >
                        <Box sx={titleCSS} >ABRIR PDV</Box>
                        <p>(*) Método /api/entrar , pressione "ENVIAR REQUEST" para abrir o PDV e gerar um token</p>
                        <p>Abrir o PDV, inicia a comunicação desse PDV com o Autenticador, é enviado LOGIN, SENHA, CNPJ e TERMINALID. O LOGIN e SENHA utilizado é o mesmo que logon
                            no painel, sendo assim esse usuario deve ter direitos de transação para aquele CNPJ selecionado. Esse request é enviado ao PROXY, que redireciona ao Autenticador, Com o retorno SUCESSO do Autenticador o proxy considera a conexão valida.
                            O retorno é uma string JWT, o campo interno "TOKEN" do JWT é usado para autenticar o terminal com o PROXY nas proximas conexões. Conexões que são direcionadas
                            ao Autenticador será validado pelo JWT completo, inclusive a validade da criptografia. Por questão de compatibilidade com a versão 3 do Tracking Sales, o JWT
                            deve ser enviado como HEADER TOKEN no HTTP.
                        </p>
                    </Box>
                    <Box hidden={pedido.tabIndex !== 2} >
                        <Box sx={titleCSS} >ABRIR SESSÃO</Box>
                        <p>(*) Método /api/consultar_identificacao , Digite o CPF e pressione "ENVIAR REQUEST"</p>
                        <p>Ao iniciar uma nova sessão, o PDV deve iniciar pela consulta do CPF, para definir um novo ID da sessão e validar se o CPF pertence a um projeto do
                            Tracking Sales. O request é enviado ao Proxy, que redireciona ao Autenticador. Com o retorno SUCESSO do Autenticador o proxy grava em cache esse retorno
                            e guarda o ID da sessão e campanhas especificas desse CPF.
                        </p>
                    </Box>
                    <Box hidden={pedido.tabIndex !== 3} >
                        <Box sx={titleCSS} >ADICIONAR PRODUTOS</Box>
                        <p>(*) Método /api/consultar_desconto , utilize a tela ao lado para adicionar produtos a cesta <br />
                            pressione "+" para consultar o produto e adiciona-lo a cesta.</p>
                        <p>A consulta de produtos é processada somente no PROXY, para definir qual será o desconto atribuido ao produto consultado. O request sempre deve ser feito
                            com o carrinho completo para que o proxy identifique possiveis limites e KITS envolvidos.
                        </p>
                    </Box>
                    <Box hidden={pedido.tabIndex !== 4} >
                        <Box sx={titleCSS} >REGISTRAR DESCONTO</Box>
                        <p>(*) Método /api/resgistar_desconto, Pressione Enviar Pedido para finalizar a compra e receber o retorno do autenticador.</p>
                        <p>O PDV envia a finalização da compra, chamando o metodo registar desconto, esse request é direcionado ao Autenticador. Com o retorno de SUCESSO do
                            Autenticador o proxy finaliza o cache de sessão, tirando de pendente e devolve ao PDV.
                        </p>
                    </Box>
                    <Box hidden={pedido.tabIndex !== 5} >
                        <Box sx={titleCSS} >INSERIR IDENTIFICAÇÃO</Box>
                        <p>(*) Método /api/inserir_identificacao, Para inserir um novo CPF na base.</p>
                        <p>Esse método é utilizado para efeitos de testes, é enviado para um end point especial do Autenticador para inclua o CPF no programa. o Proxy não é
                            envolvido nesse metodo.
                        </p>
                    </Box>
                    <Box>
                        <Box sx={titleCSS} >ADICIONAL</Box>
                        <Box>
                            <TsButton onClick={(e) => showInfo('handleTabPanel' ,5)} variant='text' >Inserir Identificação</TsButton></Box>
                        <Box sx={titleCSS} >REFERÊNCIA</Box>
                        <p style={{ fontSize: 12 }}>Documentação do proxy: <a href="http://54.233.209.153:3000/api-docs" target="top">CLIQUE</a></p>
                        <p style={{ fontSize: 12 }}>Documentação do Autenticador: <a href="http://dev-ts.zicardapi.com.br/swagger/index.html" target="top">CLIQUE</a></p>
                        <p style={{ fontSize: 12 }}>Decode JWT: <a href="https://jwt.io/" target="top">CLIQUE</a></p>
                    </Box>

    </Grid>
    </>);
}

export default LeftPanel;