
import * as React from 'react';
import { Box } from '@mui/material';
import { preWrapCSS } from '../../style';
import { getUser } from '../../util/auth';

const titleCSS = {
    background: "#000099",
    color: "#ccc",
    padding: "10px",
    textAlign: "center",
    margin: "4px"
};


const OpenClosePdv = (props) => {
    const {pedido} = props; // const {pedido, showInfo} = props; // setPedido
    const usuario = getUser('simulador');

    return (<Box>
        <Box sx={titleCSS}>ABRIR/FECHAR PDV</Box>
        <Box padding={4} sx={{ ...preWrapCSS, fontSize: 12 }}>{
            pedido.token ?
                "PDV está aberto, pressione continuar" :
                <>Json do request:<br />{JSON.stringify({ login: usuario.login, senha: "********", cnpj: pedido.cnpj, terminalId: "SIMULADOR" }, null, 4)}</>}
        </Box>
    </Box>);
}

export default OpenClosePdv;