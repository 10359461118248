import * as React from 'react';
import { Box, Link } from '@mui/material';

import { format, parseISO } from 'date-fns';
import EventNoteIcon from '@mui/icons-material/EventNote';

/* http */
import transacaoHttp from '../../util/http/transacao-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import { monitorCSS } from '../../style';
import TsDialog from '../../formulario/TsDialog';
import ListaBase from '../ListaBase';
import {formatNumber} from '../../util/Util';

const selectorCSS = {
  cursor: 'pointer',
};

class ListaTransacao extends ListaBase {

  constructor(props) {
    super(props);
    this.serverPageSize = 50;

    this.state.open = false;
    this.state.detalhes = '';

    this.columns = [
      {
        name: 'id',
        label: 'id',
        format: (value) => (
          <Link component="button" onClick={() => this.openDetail(value)}>
            <EventNoteIcon />
          </Link>
        ),
      },
      {
        name: 'cnpj',
        label: 'CNPJ',
        format: (value) => {
          return (
            <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cnpj: "' + value + '"'})} >
              {value}
            </span>
          );
        },
      },
      {
        name: 'clienteId',
        label: 'ClienteID',
        format: (value) => {
          return (
            <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cpf: "' + value + '"'})}>
              {value}
            </span>
          );
        },
      },
      {
        name: 'dataTransacao',
        label: 'DATA',
        format: (value) => {
          return <span style={selectorCSS}>{format(parseISO(value), 'dd/MM HH:mm:ss')}</span>;
        },
      },
      { name: 'rede', label: 'Rede' },
      { name: 'terminal', label: 'Terminal' },
      {
        name: 'numCupom',
        label: 'numCupom',
        format: (value) => {
          return (
            <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cupom: "' + value + '"'})}>
              {value}
            </span>
          );
        },
      },
      {
        name: 'codigoProduto',
        label: 'codigoProduto',
        format: (value) => {
          return (
            <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'produto: "' + value + '"'})}>
              {value}
            </span>
          );
        },
      },
      { name: 'marca', label: 'Marca'},
      { name: 'qtde', label: 'Qtde' },
      { name: 'valorUnitario', label: 'Valor',
        format: (value) => (<>{formatNumber(value)}</>)
      },
      { name: 'desconto', label: 'Desconto',
        format: (value) => (<>{formatNumber(value)}</>)
      },
      { name: 'statusId', label: 'st'},
    ];
  }

  Buscar () {
    this.iniciarBusca();

    const query = { page: this.state.serverPage, nrecPage: this.serverPageSize, ativo: true, busca: this.state.objBusca.busca };

    transacaoHttp
      .busca(query)
      .then(({ data }) => {
        this.setRows(data.dados);
      })
      .catch((error) => {
        this.setError(error);
      })
      .finally(() => {
        this.setProcessando(false);
        this.setBuscando(false);
        this.setUrl(transacaoHttp.getUrl());
      });
  };

  setDetalhes (detalhes)  {
    this.setState({detalhes: detalhes});
  }

  setOpen (open)  {
    this.setState({open: open});
  }

  openDetail(Id)  {
    this.setError(undefined);
    this.setProcessando(true);
    this.setDetalhes(`... obtendo detalhes da transação ${Id} , aguarde`);
    this.setOpen(true);

    transacaoHttp
      .get(Id)
      .then(({ data }) => {
        this.setDetalhes(JSON.stringify(data, null, 4));
      })
      .catch((error) => {
        this.setUrl(transacaoHttp.getUrl());
        this.setOpen(false);
        this.setError(error);
      })
      .finally(() => {
        this.setProcessando(false);
      });
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
      super.componentWillUnmount();
      
  }

  componentDidUpdate(prevProps, prevState) {
      super.componentDidUpdate(prevProps, prevState);
      if (this.state.execBuscar === true) this.Buscar();
  }

  render() {

    return (
      <>
        <Box>
          <>
            <TsInputBusca
              name="input-busca"
              value={this.state.objBusca.busca}
              showAtivo={false}
              showBusca={true}
              submitSearch={(texto, ativo) => {
                this.setObjBusca({...this.state.objBusca, busca: texto});
              }}
            ></TsInputBusca>
          </>
          <TsDataGrid
            rows={this.state.rows}
            columns={this.columns}
            queryid={this.state.queryid}
            serverPage={this.state.serverPage}
            serverPagesize={this.serverPageSize}
            onChangePage={(value) => this.setServerPage(value)}
            fontSize={'10pt'}
          />
  
          {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
        </Box>
        <TsBackDrop processando={this.state.processando} />
        <TsDialog onClose={() => this.setOpen(false)} id="dialog-detalhe-log" open={this.state.open} title="Detalhes do log" minWidth={800}>
          <Box sx={monitorCSS}>{this.state.detalhes}</Box>
        </TsDialog>
      </>
    );

  }

}

export default ListaTransacao;

