import * as React from 'react';
import TsBackDrop from '../../formulario/TsBackDrop';
import { Page } from '../Page';
import ListCupom from './qrcode-list';
import DetailCupom from './qrcode-detail';

const QrCodeAdm_Cupons = (props) => {

    const [processando, setProcessando] = React.useState(false); 
    const [detail, setDetail] = React.useState({pageTitle: 'Lista de Cupons', id: 0, show: false, cpf: '0'}); 


    return (<Page title={detail.pageTitle}>
    {detail.show ?
    <DetailCupom setProcessando={setProcessando} setDetail={setDetail} detail={detail} />
    : 
    <ListCupom setProcessando={setProcessando}  setDetail={setDetail} detail={detail} />
    }
    <TsBackDrop processando={processando} />
    </Page>
    );
}

export default QrCodeAdm_Cupons;

/*
https://proxy-hml.trackingsales.com.br/ts4qrcode/cupom?page=0&pageSize=2&status=1
{
        "indice": "1",
        "id": 1,
        "clientId": "00583325882",
        "tipo": "CFe",
        "data": "26/04/2024",
        "status_sefaz": "concluido",
        "status": "pesquisando EAN",
        "cnpj": "01438784000601",
        "nf_num": "260790",
        "total": 136.66
    }
*/