import * as React from 'react';
import {Link, Button, Box} from '@mui/material';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TsDialog from '../../formulario/TsDialog'; 
import {TsTextField} from '../../formulario/TsTextField';
import AddButtonEvent from '../../formulario/AddButtonEvent';
import ShowAlert from '../../util/ShowAlert';
import TsButton from '../../formulario/TsButton';
import { listaContratoSignerTipo } from './cadastro-lista'; 
import SelectCampo from '../../formulario/SelectCampo';
import {TsTextMask} from '../../formulario/TsTextMask';

let ContratoSigner_Processando = false;

const signer_vazio = {id: 0, contratoSignerTipoId: 1, cpf: '', rg_num: '', rg_emissor: '', fone: '', email: '', nome: '' };

const CadastroSigner = (props) => {
    const {open, signers, setSigners} = props;
    
    const serverPageSize = 50;
    const {serverPage, setServerPage} = React.useState(0); 
    const [executing, setExeccuting] = React.useState(false);
    const [detail, setDetail] = React.useState({open: false, id: 0, pageTitle: 'Lista de signatários'});
    const [signer, setSigner] = React.useState(signer_vazio);

    const columns = [
        {  name: "id", 
           label: "#",
           format: ((value) => (<Link component={Button} onClick={(e) => {setDetail({open: true, id: Number(value), pageTitle: "Detalhes do signatário"})}}><EventNoteIcon /></Link>))
        },
        {name: "nome", label: "Nome"},
        {name: "email", label: "E-mail"},
        {name: "fone", label: "Celular"},
        {name: "contratoSignerTipoId", label: "Tipo", format: ((value) => (<>{obterTipo(value)}</>))},
    ];

    const obterTipo = (id) => {
        const tipoId = id + '';
        for(let i = 0; i < listaContratoSignerTipo.length; i++) {
            if (tipoId === listaContratoSignerTipo[i].value)
                return listaContratoSignerTipo[i].text;
        }
        return 'Desconhecido';
    }


    const setProcessando = (value) => {
        ContratoSigner_Processando = value;
        setExeccuting(value);
    }

    const openAddItem = (e) => {
        const id = -1;
        setSigner({...signer_vazio, id: id});
        // const lista = [...signers];
        // lista.push({...signer_vazio, id: id});
        // setSigners(lista);
        setDetail({open: true, id: id, pageTitle: "Adicionar signatário"});
    }


    const AtualizarCad = async () => {
        if (ContratoSigner_Processando) {
            return;
        }
        setProcessando(true);
        
        const lista = [...signers];

        let find = false;
        if (signer.id === -1) {
            find = true;
        }
        else {
            for (let i = 0; i < lista.length; i++) {
                const item = lista[i];
                if (item.id === signer.id) {
                    lista[i] = {...signer};
                    find = true;
                }
            } 
        }

        if (!find) {
            ShowAlert.error('Atualizar signer, id invalido');
        }
        else {
            if (signer.contratoSignerTipoId === 1) {
                const representante = signers.find(it => it.contratoSignerTipoId === 1);
                if (representante !== undefined && representante.id !== signer.id) {
                    ShowAlert.error("Somente um representante legal deve ser indicado");
                    setProcessando(false);
                    return;
                }
            }
            if (signer.id === -1) {
                signer.id = lista.length;
                lista.push(signer); 
            }
            setSigners(lista);
            setDetail({open: false, id: 0, pageTitle: "Lista de signinatários"});
        }
        
        setProcessando(false);
    }

    const ObterDetalhes = async () => {
        if (detail.id === -1)
            return;
        
        const item = signers.find(r => r.id === detail.id);
        if (item !== undefined) {
            setSigner(item);
        }
        else {
            ShowAlert.error('Detalhes signer invalido');
        }
    }

    React.useEffect(() => {

        if (open === true) {
            if (detail.open === true) {
                ObterDetalhes();
            }
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, detail]);


    if (open) return (<>
        <Box dir="rtl"><AddButtonEvent title="Adicionar item" onClick={openAddItem} /></Box>
        <TsDataGrid
            rows={signers}
            columns={columns}
            queryid={''}
            serverPage={serverPage}
            serverPagesize={serverPageSize}
            onChangePage={(value) => setServerPage(value)}
        />
        <TsDialog onClose={() => setDetail({open: false, id: 0, pageTitle: "Lista de signinatários"})} id="dialog-detalhe-signer" open={detail.open} title={detail.pageTitle} minWidth={800}>
          <Box sx={{minHeight: '300px'}}>
            <Box sx={{display: 'flex'}}>
            <Box sx={{flex: '1 1 auto', padding: 2}}>
                <TsTextMask
                    name="cpf"
                    label="CPF" 
                    fullWidth
                    mask="000.000.000-00"
                    inputProps={{ maxLength: 14 }}
                    value={signer.cpf || ''} 
                    disabled={false}
                    onChangeValue={(v) => {setSigner({...signer, cpf: v})}}
                />
             </Box>
             <Box sx={{flex: '1 1 auto', padding: 2}}><TsTextField
                    name="nome"
                    label="Nome"
                    fullWidth
                    value={signer.nome || ''}
                    disabled={false}
                    onChange={(e) => {setSigner({...signer, nome: e.target.value})}}
             /></Box>
             </Box>

             <Box sx={{display: 'flex'}}>
             <Box sx={{flex: '1 1 auto', padding: 2}}><TsTextField
                    name="email"
                    label="E-mail"
                    fullWidth
                    value={signer.email || ''}
                    disabled={false}
                    onChange={(e) => {setSigner({...signer, email: e.target.value})}}
             /></Box>
             <Box sx={{flex: '1 1 auto', padding: 2}}>
                <TsTextMask
                    name="fone"
                    label="Celular"
                    mask="(00)00000-0000"
                    fullWidth
                    value={signer.fone || ''}
                    disabled={false}
                    onChangeValue={(v) => {setSigner({...signer, fone: v})}}
                    />
                </Box>
             </Box>

             <Box sx={{display: 'flex'}}>
             <Box sx={{flex: '8 1 auto', padding: 2}}><TsTextField
                    name="rg_num"
                    label="Numero rg"
                    fullWidth
                    value={signer.rg_num || ''}
                    disabled={false}
                    onChange={(e) => {setSigner({...signer, rg_num: e.target.value})}}
             /></Box>
             <Box sx={{flex: '1 0 auto', padding: 2}}><TsTextField
                    name="rg_emissor"
                    label="Emissor rg"
                    fullWidth
                    value={signer.rg_emissor || ''}
                    disabled={false}
                    onChange={(e) => {setSigner({...signer, rg_emissor: e.target.value})}}
             /></Box>
             <Box sx={{flex: '1 0 auto', padding: 2}}>
                <SelectCampo
                        id='signerTipo'
                        label='Signer Tipo'
                        name='signerTipo'
                        disabled={false}
                        options={listaContratoSignerTipo}
                        value={signer.contratoSignerTipoId + '' || ''}
                        onChange={(e) => {setSigner({...signer, contratoSignerTipoId: Number(e.target.value)})}}
                    />
             </Box>
             </Box>
            <Box sx={{display:'flex'}} >
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                        
                </Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}} >
                    <TsButton
                        onClick={(e) => setDetail({open: false, id: 0, pageTitle: "Lista de signinatários"})}
                        color="secondary"
                    >Cancelar</TsButton>
                </Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}} >
                    <TsButton
                        onClick={(e) => AtualizarCad()}
                    >Enviar</TsButton>
                </Box>
            </Box>
          </Box>
        </TsDialog>
        <TsBackDrop processando={executing} />
        </>);
        else return null;
}

export default CadastroSigner;

