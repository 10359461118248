import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class OfertaHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.http = http;
    this.resource = resource;
    this.url = baseUrl;
  }

  incluirSegmentacao(campanhaId, model) {
    this.url = `/campanha/${campanhaId}/segmentacao - PUT`;

    return this.http.put(`campanha/${campanhaId}/segmentacao`, [model], this.Config());
  }

  deleteSegmentacao(campanhaId, segmentacaoId) {
    this.url = `/campanha/${campanhaId}/segmentacao/${segmentacaoId} - DELETE`;

    return this.http.delete(`campanha/${campanhaId}/segmentacao/${segmentacaoId}`, this.Config());
  }
}

const segmentacaoHttp = new OfertaHttpResource(httpTsApi, 'segmentacao');

export default segmentacaoHttp;
