import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';

const formControlCSS = {
  margin: 1,
  minWidth: 120
};

// _type_ SelectFormProps = {
//   campo,
//   formData ,
//   setFormData: (formData ) => void,
//   validating 
// };

const SelectForm = (props) => {

  const campo = props.campo || { label: "Desconhecido", name: "", max: 0, min: 0, tipo: "string" };
  const name = campo.name;
  const label = campo.label;
  const id = `sel_${name}_form`;
  const labelId = `label_sel_${name}_form`;
  // const [error, setError] = React.useState(false);  
  // const [helperText, setHelperText] = React.useState(""); 
  const [valor, setValor] = React.useState('');


  const handleChange = (val) => {

    setValor(val);
    const tempData = props.formData;
    if (campo.tipo === "number") {
      let v = parseFloat(val);
      if (isNaN(v)) {
        v = undefined;
      }
      tempData[name] = v;
    }
    else {
      tempData[name] = val;
    }

    props.setFormData(tempData);

  };

  React.useEffect(() => {
    const v = props.formData[name];
    let s = "";
    if (typeof v === "undefined" || v === null) {
      s = "";
    }
    else if (typeof v === "string") {
      s = v;
    }
    else {
      s = v.toString();
    }

    setValor(s);
  }, [props.formData, name]);

  if (!name) {
    return (<div>Campo indefinido</div>);
  }

  return (
    <TextField
      value={valor}
      onChange={(e) => handleChange(e.target.value)}
      select // tell TextField to render select
      label={label}
      id={id}
      name={name}
      >
      {campo.opcoes?.map((item, i) => {
          // return (<option key={i} value={item.valor}>{item.label}</option>)
          return (<MenuItem key={i} value={item.valor}>
            {item.label}
          </MenuItem>)
      })}
  </TextField>
  );

  /*
  return (
    <FormControl fullWidth sx={formControlCSS}>
      <InputLabel htmlFor={id} id={labelId}>{label}</InputLabel>
      <Select
        native
        labelId={labelId}
        label={label}
        id={id}
        name={name}
        value={valor}
        onChange={(event, child) => handleChange(event.target.value)}
      >
        {campo.opcoes?.map((item, i) => {
          return (<option key={i} value={item.valor}>{item.label}</option>)
        })}
      </Select>
    </FormControl>
  );
  */


}

export default SelectForm;
