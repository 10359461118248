
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import * as React from 'react';

const formControlCSS = {
  margin: 2,
  paddingRight: 2,
  minWidth: 120,
};


const TsTextReadOnly = (props) => {
    const {value, label, sx} = props;
    const mainId = 'txt-' + label;
    return (
        <FormControl sx={formControlCSS} variant="outlined" fullWidth>
        <InputLabel htmlFor={mainId}>{label}</InputLabel>
        <OutlinedInput
            id={mainId}
            value={value}
            label={label}
            aria-describedby="base-name-helper-text"
            inputProps={{
             'aria-label': 'weight',
            }}
      />
   </FormControl>
    )
}

export default TsTextReadOnly;