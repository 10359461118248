import * as React from 'react';
import { Link } from '@mui/material'; //  Table, TableBody, TableCell, TableHead, TableRow 

/* http */
import estabelecimentoHttp from '../../util/http/estabelecimento-http'
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsDialog from '../../formulario/TsDialog';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';  

// _type_ Props = {
//   rede;
//   open;
//   valorAtual;
//   versaoApi;
//   onClose: (value) => void;
// };


// {/* <TsDataGridx /> */}
class Busca extends ListaBase {

  constructor(props) {
    super(props);
    this.serverPageSize = 50;
    this.state.objBusca = {busca: '', ativo: true, rede: this.props.rede + ''};
    
    this.columns = [
      {
        name: "cnpj", label: "CNPJ",
        format: (value) => { return <Link component="button" onClick={(e) => this.selecionarCnpj(value)} >{value}</Link> }
      },
      { name: "nome", label: "Nome" },
      { name: "rede", label: "Rede" }
    ];
  }

  handleClose = () => {
    this.props.onClose(this.props.valorAtual);
  };

  selecionarCnpj = (value) => {
    this.props.onClose(value);
  };

  Buscar = () => {
 
    this.iniciarBusca();

    const query = { page: this.state.serverPage, nrecPage: this.serverPageSize, ativo: this.state.objBusca.ativo, rede: this.state.objBusca.rede };

    estabelecimentoHttp.busca(query)
      .then(({ data }) => {
        this.setRows(data.dados);
      })
      .catch((error) => {
        this.setError(error);
      })
      .finally(() => {
        this.setProcessando(false);
        this.setBuscando(false);
        this.setUrl(estabelecimentoHttp.getUrl());
      });
  };

  componentDidMount() {
    super.componentDidMount();
}

componentWillUnmount() {
    super.componentWillUnmount();
}

componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

  if (prevProps.rede !== this.props.rede) {
    this.setObjBusca({...this.state.objBusca, rede: this.props.rede + ''});
  }

  if (this.state.execBuscar === true) this.Buscar();
}

  load() {
    //React.useEffect(() => {
    //  if (open)
    //    buscarEstabelecimentos();
    //}, []);
  
    //React.useEffect(() => {
    //  if (open)
    //    buscarEstabelecimentos();
    //}, [rede, open, versaoApi]);
  }

  render() {
    return (
      <TsDialog onClose={this.handleClose} minWidth={650} id="dialog-busca-estabelecimento" open={this.props.open} title="Busca de estabelecimento" >
        <>
          <TsInputBusca
              name="input-busca"
              value={this.state.busca}
              showAtivo={false}
              showBusca={true}
              submitSearch={(texto, ativo) => { }}
          ></TsInputBusca>
        </>
        <TsDataGrid
          rows={this.state.rows}
          columns={this.columns}
          queryid={this.state.queryid}
          serverPage={this.state.serverPage}
          serverPagesize={this.serverPageSize}
          showPagging={false}
          onChangePage={(value) => this.setServerPage(value)}
        />
        {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
        <TsBackDrop processando={this.state.processando} />
        
      </TsDialog>
    );
  }
};

export default Busca;

