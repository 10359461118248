import { styled, TableRow } from '@mui/material'; 
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const DataTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const DataTableCell = styled(TableCell)(({ theme, fontSize }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    }
    ,
    [`&.${tableCellClasses.body}`]: {
      fontSize: fontSize,
    },
  }));

  const TsDataCell = (props) => {
    
    const {value, column, row, index, fontSize} = props;
    let child = value;
    
    if (column.format) {
      if (value === undefined || value === null) child = '';
      else  child = column.format(value, row, index);
    }
  
    return <DataTableCell fontSize={fontSize}>{child}</DataTableCell>;
  };

  const DataRow = (props) => {
    const {fontSize, columns, row, rowIndex} = props;
  
    return (<DataTableRow fontSize={fontSize} >
      {columns.map((col, colIndex) => (
        <TsDataCell fontSize={fontSize} key={`cell-${rowIndex}-${colIndex}`} value={row[col.name]} column={col} row={row} index={colIndex} />
      ))}
    </DataTableRow>);
  } 

  export default DataRow;