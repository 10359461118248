import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class LogHttpResource extends HttpResource {
  constructor(http, resource, uri) {
    super(http, resource, uri);
    this.http = http;
    this.resource = resource;
    this.uri = uri;
  }

  query(page, pagesize, index, busca) {
    const config = {
      headers: {
        'x-token': '6ecf6aeb210749a88058fba02b198c19',
      },
    };

    // curl -X GET "http://dev-ts.zicardapi.com.br/api/log?page=0&pagesize=10&index=log_acesso" -H  "accept: text/plain" -H  "x-token: 6ecf6aeb210749a88058fba02b198c19"

    let url = `${this.resource}?page=${page}&pagesize=${pagesize}&index=${encodeURIComponent(index)}`;

    if (busca) url = `${url}&q=${encodeURIComponent(busca)}`;

    return this.http.get(url, config);
  }
}

// http://dev-ts.zicardapi.com.br/api/log
export const logHttpBackOffice = new LogHttpResource(httpTsApi, 'log', baseUrl);
