import * as React from 'react';
import { Page } from '../Page';
import { Box, Grid } from '@mui/material';
import { pedidoInicializado } from '../../dominios/Pedido';
import TsBackDrop from '../../formulario/TsBackDrop';
import LeftPanel from './LeftPanel';
import SelecaoPdv from './SelecaoPdv';
import PdvHeader from './PdvHeader';
import OpenClosePdv from './OpenClosePdv';
import ConsultarIdentificacao from './ConsultarIdentificacao';
import CestaProdutos from './CestaProdutos';
import { getProxy } from '../../util/auth';
import FlowControl from './FlowControl';
import ShowInfo from './ShowInfo';
import ShowAlert from '../../util/ShowAlert';

const Simulador = (props) => {
    
    const proxy = getProxy();

    const [processando, setProcessando] = React.useState(false);
    
    const [pedido, setPedido] = React.useState({ ...pedidoInicializado, rede: (proxy.rede + '').padStart(4, '0'), proxy: proxy });
    const [handleIndex, setHandleIndex] = React.useState(0);
    const [debugMsg, setDebugMsg] = React.useState([' ... SELECIONE UM CNPJ, PARA INICIALIZAR O SIMULADOR']);
    
  
    const showInfo = (tipo, mensagem) => {
        if (tipo === 'processando') {
            setProcessando(mensagem);
            return;
        } else if (tipo === 'handleTabPanel') {
            setHandleIndex(mensagem);
            return;
        } else if (tipo === 'cls') {
            setDebugMsg(debugMsg => []);
            
            for(let i=0; i<100000;i++) {
                if (debugMsg.length === 0)
                    break;
            }
            
            return;
        } else if (tipo === 'pop-error') {
            ShowAlert.error(mensagem);
            return;
        } else if (mensagem) {
            setDebugMsg(debugMsg => [...debugMsg, `${tipo} - ${mensagem}`]);
        }
        else {
            console.warn(` ### showInfo TIPO DESCONHECIDO tipo: ${tipo} , mensagem: ${mensagem}`)
        }
        
    };


    return (
        <Page title={'Simulador'}>

            <Grid container spacing={8}>
                <LeftPanel setPedido={setPedido} pedido={pedido} showInfo={showInfo} />
                <Grid item xs={9}>
                    <Box minHeight={400}>
                        {pedido.tabIndex === 0 && <Box>
                            <SelecaoPdv pedido={pedido} setPedido={setPedido} showInfo={showInfo} />
                        </Box>}
                        {pedido.tabIndex > 0 && <PdvHeader pedido={pedido} />}
                        {pedido.tabIndex === 1 &&
                            <OpenClosePdv pedido={pedido} setPedido={setPedido} showInfo={showInfo} />
                        }
                        {(pedido.tabIndex === 5 || pedido.tabIndex === 2) &&
                            <ConsultarIdentificacao pedido={pedido} setPedido={setPedido} showInfo={showInfo} />
                            }
                        {(pedido.tabIndex === 3 || pedido.tabIndex === 4) &&
                            <CestaProdutos pedido={pedido} setPedido={setPedido} showInfo={showInfo} />
                        }
                    </Box> 
                    <FlowControl pedido={pedido} setPedido={setPedido} showInfo={showInfo} handleIndex={handleIndex} />

                    <ShowInfo pedido={pedido} debugMsg={debugMsg} />

                </Grid>
            </Grid>
            
            <TsBackDrop processando={processando} />

        </Page>
    );
};

export default Simulador;



