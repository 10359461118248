import SelectCampo from '../../formulario/SelectCampo';
import * as React from 'react';
import { TsTextField } from '../../formulario/TsTextField';
import { Box, IconButton, InputAdornment} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BuscaEstabelecimento from '../estabelecimento/Busca';
import relatorioHttp from '../../util/http/relatorio-http';
import {getProjetoStorage} from '../../util/auth';

const titleCSS = {
    background: "#000099",
    color: "#ccc",
    padding: "10px",
    textAlign: "center",
    margin: "4px"
};

const SelecaoPdv = (props) => {

    const {pedido, setPedido} = props; // showInfo

    const [cnpj, setCnpj] = React.useState("");
    const [rede, setRede] = React.useState((pedido.proxy.rede + '').padStart(4, '0'));
    const [openBuscaEstabelecimento, setOpenBuscaEstabelecimento] = React.useState(false);
    const [listaRede, setListaRede] = React.useState([]);

    const projAtual = getProjetoStorage();

    const closeBuscaEstabelecimento = (value) => {
        setOpenBuscaEstabelecimento(false);
        setPedido(pedido => ({...pedido, cnpj: value}));
        setCnpj(value);
    }

    const ChangeRede = (event) => {
        const valor = event.target.value;
        setCnpj("");
        setRede(valor);
        setPedido(pedido => ({ ...pedido, rede: valor, cnpj: ''}));
    }

    const carregarRedes = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('rede', projAtual.id);
            const redeAtual = Number(pedido.proxy.rede);
            let vRede = '';
            // {text: '...Selecione', value: ''},
            const lista = [
                ...data
            ];
            setListaRede(lista);
            lista.forEach(it => {
                if (redeAtual === Number(it.value)) {
                    vRede = (redeAtual + '').padStart(4, '0');
                }
            });
            if (!vRede) {
                if (lista.length > 0)
                    vRede = (lista[0].value + '').padStart(4, '0');
                else 
                    vRede = (pedido.proxy.rede + '').padStart(4, '0');
            }

            setRede(vRede);
            setPedido(pedido => ({ ...pedido, rede: vRede, cnpj: ''}));
            
        } catch (error) {
            console.error('erro ao carregar redes');
            console.error(error);
        }
    }

    React.useEffect(() => {
        if (listaRede.length === 0) {
            carregarRedes();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedido]);

    return (
        <>
        <Box sx={titleCSS} >SELEÇÃO PDV:</Box>
            <Box>
                <SelectCampo
                    name="canalVenda"
                    label="Canal de vendas"
                    id="lst-canalVenda"
                    options={[{ value: 1, text: "PDV" }, { value: 2, text: "ECOMMERCE" }, { value: 3, text: "APP" }, { value: 4, text: "TELEVENDAS" }, { value: 99, text: "OUTROS" }]}
                />
                <SelectCampo
                    name="rede"
                    label="Rede"
                    id="lst-rede"
                    value={rede}
                    onChange={ChangeRede}
                    options={listaRede}
                />
                <TsTextField
                    label="CNPJ estabelecimento"
                    name="cnpj"
                    value={cnpj}
                    onChange={(e) => {
                        const valor = e.target.value;
                        setCnpj(valor);
                        setPedido(pedido => ({ ...pedido, cnpj: valor}));
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={(e) => setOpenBuscaEstabelecimento(true)}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            <BuscaEstabelecimento versaoApi={"4"} rede={pedido.rede} open={openBuscaEstabelecimento} onClose={closeBuscaEstabelecimento} valorAtual={pedido.cnpj} />

        </>

    );

}

export default SelecaoPdv;
