import * as React from 'react';
import { Box } from '@mui/material';
import TsButton from '../../formulario/TsButton';
import { TsTextField } from '../../formulario/TsTextField'; 
import redeHttp from '../../util/http/rede-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';  
import RedeList from './rede-list';
import RedeDetail from './rede-detail';
import {getProjetoStorage, getUser} from '../../util/auth';
import ShowAlert from '../../util/ShowAlert';
import TsDialog from '../../formulario/TsDialog';

let RedeDetail_Processando = false;
let softhouseId = '';

const SofthouseDetail = (props) => {

    const setDetail = props.setDetail;
    const detail = props.detail;

    const projAtual = getProjetoStorage();
    const atual = getUser('rede');
   
    const [executing, setExcecuting] = React.useState(false);
    const [softhouseData, setSofthouseData] = React.useState({id: 0, nome: 'N/A', comissaoFaturamento: '0.00'});
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [redeDetail, setRedeDetail] = React.useState({open: false, id: 0, title: "Lista de redes", mainTitle: detail.title}); 
    const [redeobjBusca, setRedeobjBusca] = React.useState({busca: '', projetoId: Number(projAtual.id), softhouseId: '', listaSofthouse: []}); 
    
    const setProcessando = (value) => {
        RedeDetail_Processando = value;
        setExcecuting(value);
    }

    const getProcessando = () => {
        return RedeDetail_Processando;
    }

    const Atualizar = async () => {
        const data = {...softhouseData};
 
        if (typeof(data.comissaoFaturamento) === 'string')
            data.comissaoFaturamento = Number(data.comissaoFaturamento);
        
        if (isNaN(data.comissaoFaturamento)) data.comissaoFaturamento = 0.00; 

        
        try {
            const retorno = await redeHttp.updateSofthouse(data.id, data);
            if (retorno.status === 200) {
                ShowAlert.info('Item atualizado');
            }
        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        }
    }

    const Buscar = async () => {
        if (getProcessando())
            return;

        setProcessando(true);

        try {
            const {data} = await redeHttp.getSofthouse(detail.id);
            if (data.dado.comissaoFaturamento === undefined || data.dado.comissaoFaturamento === null) {
                data.dado.comissaoFaturamento = 0;
            }
            data.dado.comissaoFaturamento = data.dado.comissaoFaturamento.toFixed(2);
            setSofthouseData(data.dado);
            
        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        }

        setProcessando(false);

    }

    React.useEffect(() => {
        if (detail.open === true &&  redeDetail.open !== true) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail])


    React.useEffect(() => {
    
        if (detail.open && softhouseId !== softhouseData.id + '') {
            softhouseId = softhouseData.id + '';
            setDetail({...detail, title: 'Detalhes da Softhouse'});
            setRedeobjBusca({...redeobjBusca, softhouseId: softhouseId, listaSofthouse: [{value: softhouseId, text: softhouseData.nome}]});
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [softhouseData])

    // softhouseData


    return (<>
     <>
    <form>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                <TsTextField
                    name="id"
                    label="Codigo"
                    fullWidth
                    value={softhouseData.id || ''}
                    disabled={true}
                    
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="nome"
                    label="Nome"
                    fullWidth
                    value={softhouseData.nome || ''}
                    disabled={true}
                    
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="dataAtivacao"
                    label="Data Ativação"
                    fullWidth
                    value={softhouseData.dataAtivacao || ''}
                    onChange={(e) => {setSofthouseData({...softhouseData, dataAtivacao: e.target.value})}}
                    
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="comissaoFaturamento"
                    label="Comissão Faturamento"
                    fullWidth
                    value={softhouseData.comissaoFaturamento || ''}
                    onChange={(e) => {setSofthouseData({...softhouseData, comissaoFaturamento: e.target.value})}}
                />
            </Box>
        </Box>
       
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}></Box>
            <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                <TsButton
                    color='secondary'
                    onClick={(e) => setDetail({open: false, id: 0, pageTitle: "Lista de Softhouses"})}
                >Voltar</TsButton>
            </Box>
            <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                <TsButton 
                    onClick={(e) => Atualizar()}
                >Atualizar</TsButton>
            </Box>
            

        </Box>
    </form>
    <Box>
        <Box>Lista das redes</Box>
        <RedeList setDetail={setRedeDetail}  detail={redeDetail} userLevel={atual.userLevel} objBusca={redeobjBusca} setObjBusca={setRedeobjBusca} softhouse={detail.id}></RedeList>
    </Box>
    </> 
    {redeDetail.open === true &&
    <>
        <TsDialog onClose={() => setRedeDetail({...redeDetail, open: false})} id="dialog-detalhe-log" open={redeDetail.open} title="Detalhes da rede" minWidth={960}>
            <RedeDetail setDetail={setRedeDetail} detail={redeDetail} userLevel={atual.userLevel}></RedeDetail>
        </TsDialog>
    </>
    }
 
   <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
    <TsBackDrop processando={executing} />
    </>)
}

export default SofthouseDetail;
