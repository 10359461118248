// @flow 
import * as React from 'react';
import { Link, Box, Stack, Paper, IconButton, Popover, Typography } from '@mui/material';
import { Page } from '../Page';
import ProdutoDetalhe from './produto-detalhe';
import TsDialog from '../../formulario/TsDialog';

import produtoHttp from '../../util/http/produto-http';
import relatorioHttp from '../../util/http/relatorio-http';
import { toNumber } from '../../util/Util';
import DataGrid from '../../datagrid/data-grid';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';
import TsInputBusca from '../../formulario/TsInputBusca';
import {formatNumber, timeout} from '../../util/Util';
import { getProjetoStorage } from '../../util/auth';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import FilterIcon from '@mui/icons-material/FilterAlt';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import OffIcon from '@mui/icons-material/VisibilityOff';
import SendIcon from '@mui/icons-material/PlayCircleOutline';
import SelectCampo from '../../formulario/SelectCampo';

const selectorCSS = {
    cursor: 'pointer',
  };

const ProdutoBuscar = (props) => {

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [produtoCodigo, setProdutoCodigo] = React.useState("");

    const SelecionarProduto = (value) => {
        setProdutoCodigo(value);
        setDialogOpen(true);
    }

    if (props.fullScreen) {

        return (
            <>
                <div>
                    <TableBuscar versaoApi="4" onSelect={SelecionarProduto} cnpj="" pageSize={props.pageSize} showPagging={props.showPagging} />
                </div>
                <TsDialog onClose={() => { setDialogOpen(false) }} id="dialog-detalhe-produto" open={dialogOpen} title="Detalhes do produto" minWidth={800} >
                    {dialogOpen && <ProdutoDetalhe produtoCodigo={produtoCodigo}></ProdutoDetalhe>}
                </TsDialog>
            </>
        );

    }
    else {

        return (
            <Page title="Listar produtos">
                <div>
                    <TableBuscar versaoApi="4" onSelect={SelecionarProduto} cnpj="" pageSize={props.pageSize} showPagging={props.showPagging} />
                </div>
                {/*  */}
                <TsDialog id="dialog-detalhe-produto" onClose={() => { setDialogOpen(false) }} open={dialogOpen} title="Detalhes do produto" minWidth={800} >
                    {dialogOpen && <ProdutoDetalhe produtoCodigo={produtoCodigo} setDialogOpen={setDialogOpen}></ProdutoDetalhe>}
                </TsDialog>
            </Page>
        );
    }
};


let TableBuscar_processando = false;
const listFiltroChaves = [
    {text: 'industria', value: 'industria'},
    {text: 'marca', value: 'marca'},
    {text: 'rede', value: 'rede'}
];

export const TableBuscar = (props) => {
 
    const [listFiltroValores, setListFiltroValores] = React.useState({industria: [], marca: [], rede: []});

    const [showValorZicard, setShowValorZicard] = React.useState(false);

    
    const showImage = (image) => {
        if (image)
            return (<img src={image} alt="produto" width={"60px"}></img>); 
        
        else 
            return (<OffIcon sx={{fontSize: "32px"}} />);
        // (value ? <OffIcon /> : <OffIcon />)
    }

    const projeto = getProjetoStorage();

    const [objBusca, setObjBusca] = React.useState({busca: '', situacao: 'ALL', serverPageSize: 200,  serverPage: 0 });
    const [rows, setRows] = React.useState([]);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const pageSize = props.pageSize || 25;
    const showPagging = true; // props.showPagging === undefined ? true :  props.showPagging;
    const [executing, setExeccuting] = React.useState(false); 


    // detalhes
    const [filtros, setFiltros] = React.useState([]);
    
    // popup filtro
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openFilterDetail, setOpenFilterDetail] = React.useState(false);
    const [filtroDetalheChave, setFiltroDetalheChave] = React.useState(false);
    const [filtroDetalheValor, setFiltroDetalheValor] = React.useState(false);

    const [popOverMessage, setPopOverMessage] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const openPopover = Boolean(anchorEl);

    const handlePopoverOpen = (e) => {
        if (e?.currentTarget) {
            const msg = e.currentTarget.getAttribute('aria-label');
            if (msg) {
                setPopOverMessage(msg);
                setAnchorEl(e.currentTarget);
            }
        }
    };

    const handlePopoverClose = () => {
        setPopOverMessage("");
        setAnchorEl(null);
    };

    const propsPopOver = {
        "aria-owns": openPopover ? 'mouse-over-popover' : undefined,
        "aria-haspopup": "true",
        "onMouseEnter": handlePopoverOpen,
        "onMouseLeave": handlePopoverClose
    }

    const columnsFiltros = [
        {
            name: "chave",
            label: "#",
            format: (value) => { return <IconButton onClick={(e) => filterDetail_onclick(value)} ><EventNoteIcon /></IconButton> }
        },
        {
            name: "chave",
            label: "chave"
        },
        {
            name: "valor",
            label: "valor"
        }
    ];
    
    const filterDetail_onclick = (chave) => {
        
        const item = !chave ? undefined : filtros.find(f => f.chave === chave)
        if (item === undefined) {
            onChange_ListFiltro("", "");
        }
        else {
            onChange_ListFiltro(item.chave, item.valor);
        }
        setOpenFilterDetail(true);
    }

    const addFiltro = (chave, valor) => {

        if (valor === undefined || valor === '' || valor === '_') {
            ShowAlert.error('Selecione um valor');
            return;
        }

        let add = true;
        const arr = filtros;
        arr.forEach(f => {
            if (f.chave === chave) {
                f.valor = valor;
                add = false;
            }
        });
        if (add) {
            arr.push({chave, valor});
        }
        setFiltros([...arr]);
        setObjBusca(b => ({...b, [chave]: valor}));
    }

    const removeFiltro = (index) => {
      
        const arr = filtros;
        const chave = arr[index].chave;
        arr.splice(index, 1)
        setFiltros([...arr]);
        setObjBusca(b => ({...b, [chave]: undefined}));
    }

    const setProcessando = (value) => {
        TableBuscar_processando = value;
        setExeccuting(value);
    }

    const selecionarCodigo = (value, row) => {

        if (!value) {
            return;
        }

        const prod = row;

        if (prod) {
            console.log('detalhe do produto selecionado:', prod);
            const preco = prod.preco;
            props.onSelect(value, preco, prod.produtoNome);
        }

    };

    const onChange_ListFiltro = async (k, v) => {



        if (k !== undefined) setFiltroDetalheChave(k);
        if (v !== undefined) setFiltroDetalheValor(v);

        const chave = k === undefined ? filtroDetalheChave : k;
        const valor = v === undefined ? filtroDetalheValor : v;

        if (chave && (!listFiltroValores[chave] || listFiltroValores[chave].length === 0)) {

            if (TableBuscar_processando) {
                return;
            }
            setProcessando(true);
            setError(undefined);

            try {
                const {data} = await relatorioHttp.obterLista(chave, undefined);
                
                if (chave === 'industria' || chave === 'marca') {
                    data.forEach(item => {
                        item.value = item.text;
                    });
                }
                const lista = [
                    {text: '...Selecione', value: ''},
                    ...data
                ];

                setListFiltroValores(l => ({...l, [chave]: lista}));

            } catch (error) {
                setUrl(relatorioHttp.getUrl());
                setError(error);
            }
            setProcessando(false);

        }

        await timeout(500);

        if (listFiltroChaves[chave] !== undefined && listFiltroChaves[chave].find(l => l.value === valor) === undefined) {
            const lista = [
                ...listFiltroChaves[chave],
                {text: valor, value: valor}
            ];
            setListFiltroValores(l => ({...l, [chave]: lista}));
        }

    }

    const onResult = (result) => {
        console.log('onResult:', result);
        if (result === undefined || result === null)
            return;

        if (result.processando) {
            setRows([]);
            setExeccuting(true);
            return;
        }

        setExeccuting(false);
        

        if (result.sucesso === false) {
            if (result.error) {
                setError(result.error);
                setUrl(result.url);
                return;
            }
            ShowAlert.error(result.mensagem || 'Falha ao processar request'); 
        }

        if (result.extendData) {
            let extendData = {};
            if (typeof(result.extendData) === 'object') {
                extendData = result.extendData;
            }
            else {
                try {
                    extendData = JSON.parse(result.extendData);
                } catch   {
                    extendData = {};
                }
            }

            setShowValorZicard(extendData.showValorZicard ? true: false)
        }

        const dados = result.dados;

        if (dados === undefined || !Array.isArray(dados) || dados.length === 0) 
            return;
        else {
            dados.forEach(it => {
                let codigoKit = "";
                let desconto = formatNumber(it.desconto);
                if (it.ofertaCampanhas && it.ofertaCampanhas.length > 0) {
                    codigoKit = it.ofertaCampanhas[0].codigoKit;
                }
                if (codigoKit) {
                    it.desconto = `(${codigoKit}) ${desconto}`;
                }
                else  {
                    it.desconto = `${desconto}`;
                }
                it.urlImagem = it.urlImagem === null || it.urlImagem === undefined ? '' : it.urlImagem;
            });
            setRows(dados);
        }
    }

    const columns = [
        {
            name: "urlImagem",
            label: "#",
            // format: (value) => { value ?  (<img src={value} alt="produto" width={"40px"}></img>) : (<OffIcon />)  } // OffIcon
            format: (value) => showImage(value) 
        },
        {
            name: "produtoCodigo",
            label: "Código",
            format: (value, row) => { return <Link component="button" onClick={(e) => selecionarCodigo(value, row)} >{value}</Link> }
        },
        {
            name: "produtoNome",
            label: "Nome",
            format: (value) => (<>{value.length > 40 ? `${value.substring(0, 36)} ...` : value}</>)
        },
        {
            name: "industria",
            label: "Indústria",
            format: (value) => {
                return (
                  <span {...propsPopOver} aria-label='filtrar industria' style={selectorCSS} onClick={() => addFiltro('industria', value)} >
                    {value}
                  </span>
                );
            },
        },
        {
            name: "marca",
            label: "Marca",
            format: (value) => {
                return (
                  <span {...propsPopOver} aria-label='filtrar marca' style={selectorCSS} onClick={() => addFiltro('marca', value)} >
                    {value}
                  </span>
                );
            },
        },
        {
            name: "descontoAtivo",
            label: "Limite",
            format: (value) => (<>{value || value == null ? 'NÃO': 'SIM'}</>)
        },
        {
            name: "desconto",
            label: "Desconto"
        },
        {
            name: "valorDescZicard",
            label: "Dclube",
            show: showValorZicard,
            format: (value) => (<>{value}</>)
        },
        {
            name: "ponto",
            label: "Ponto"
        },
        {
            name: "preco",
            label: "Valor",
            format: (value) => (<>{formatNumber(value)}</>)
        }
    ];

    const query = { page: objBusca.serverPage, 
                    nrecPage: objBusca.serverPageSize, 
                    ativo: true, 
                    projetoId: toNumber(projeto.id), 
                    busca: objBusca.busca, 
                    industria: objBusca.industria, 
                    marca: objBusca.marca, 
                    rede: toNumber(objBusca.rede) 
                };

    const nFiltros = filtros.length;

    return (
        <React.Fragment>
            <TsInputBusca
                name="input-busca"
                value={objBusca.busca}
                showBusca={true}
                submitSearch={(texto, ativo) => {
                    setObjBusca(b => ({...b, busca: texto}));
                }}
            >
               <Box sx={{flex: '0 0 auto', paddingRight: 2, paddingTop: 1.5}}>
                    <IconButton {...propsPopOver} aria-label='adicionar filtro' onClick={(e) => setOpenFilter(true)}>
                        <FilterIcon  />
                    </IconButton>
                </Box>
            </TsInputBusca>
            <Box>
             {nFiltros > 0 ? <>
                <div>filtros ativos</div>
                <Stack direction="row" spacing={1} sx={{marginBottom: 1}} >
                    {
                        filtros.map((item, index) => (
                             <Paper  {...propsPopOver} aria-label='remover filtro' key={index} sx={{ backgroundColor: '#dadceb', paddingLeft: 1, paddingRight: 1, textAlign: 'center', fontSize: '10pt'}}>
                                <IconButton onClick={(e) => removeFiltro(index)} >
                                    <DeleteIcon />
                                </IconButton>
                                    {item.chave}: {item.valor}
                            </Paper>
                        ))
                    }
                </Stack>
             
             </> : null}

            </Box>
            <DataGrid
                columns={columns}
                rows={rows}
                pageSize={pageSize}
                showPagging={showPagging}
                query={query}
                onResult={(result) => onResult(result)}
                endPoint={{instancia: produtoHttp, metodo: 'busca'}}
            />
            
            <TsDialog onClose={(e) => setOpenFilter(false)} id="dialog-filter-produto" open={openFilter} title="Filtros produtos" minWidth={650} >
                <h3>FILTROS</h3>
                <Box sx={{display: 'flex'}}>
                {openFilterDetail ? <>
                    <SelectCampo
                        id="lst_filtro_chave"
                        label="chave"
                        name="filtro_chave"
                        debug={false}
                        sx={{flex: '4 1 auto'}}
                        options={listFiltroChaves}
                        value={filtroDetalheChave || ''}
                        onChange={(e) => onChange_ListFiltro(e.target.value, undefined)}
                        />
                    <SelectCampo
                        id="lst_filtro_valor"
                        label="valor"
                        name="filtro_valor"
                        debug={false}
                        sx={{flex: '4 1 auto'}}
                        options={listFiltroValores[filtroDetalheChave]}
                        value={filtroDetalheValor || ''}
                        onChange={(e) => { onChange_ListFiltro(undefined, e.target.value) }}
                        />
                    <Box sx={{flex: '0 0 auto'}} >
                        <IconButton onClick={(e) =>  { 
                            addFiltro(filtroDetalheChave, filtroDetalheValor);
                            setOpenFilterDetail(false);
                            setOpenFilter(false);
                        }}><SendIcon  /></IconButton>
                        <IconButton onClick={(e) => setOpenFilterDetail(false)}><DeleteIcon /></IconButton>
                    </Box>
                    </> :
                    <>
                    <Box sx={{flex: '1 1 auto'}}></Box>
                    
                    <Box sx={{flex: '0 0 auto'}}>
                    <IconButton onClick={(e) => {
                            onChange_ListFiltro('rede', '');
                            setOpenFilterDetail(true);
                        }}>
                        <AddIcon  />
                    </IconButton>
                    </Box>
                    </>
                }
                    
                </Box>
                <DataGrid
                    rows={filtros}
                    columns={columnsFiltros}
                    pageSize={1000}
                    showPagging={false}
                 />
            </TsDialog> 
            
            <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
            <TsBackDrop processando={executing} />
            <Popover
                id="mouse-over-popover"
                sx={{
                pointerEvents: 'none',
                marginTop: '-50px', 
                marginLeft: '0px'
                }}
                open={openPopover}
                anchorEl={anchorEl} 
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1, color: '#db088f', fontWeight: '600' }}>{popOverMessage}</Typography>
            </Popover>
        </React.Fragment>
    );
}

export default ProdutoBuscar;