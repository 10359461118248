import React from "react";
import {Box} from '@mui/material';
import ExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {  tituloBarra } from "./dash-style";
import { monitorCSS } from "../../style";
import TsDataGrid from "../../formulario/TsDataGrid";
import {formatNumber} from '../../util/Util';


const DashTransacao = (props) => {

    const {transacaoData, executing} = props;

    const columns = [
        {name: 'dataTransacao',label: 'Data'},
        {name: 'nsuHost',label: 'Nsu'},
        {name: 'clienteId',label: 'CPF'},
        {name: 'cnpj',label: 'CNPJ'},
        {name: 'rede', label: 'Rede' },
        {name: 'lojaId', label: 'Loja' },
        {name: 'terminal', label: 'Terminal' },
        {name: 'numCupom',label: 'Cupom'},
        {name: 'codigoProduto',label: 'Ean'},
        {name: 'marca', label: 'Marca'},
        {name: 'qtde', label: 'Qtde' },
        {name: 'valorUnitario', label: 'Valor',format: (value) => (<>{formatNumber(value)}</>)},
        { name: 'desconto', label: 'Desconto',format: (value) => (<>{formatNumber(value)}</>)},
        { name: 'statusId', label: 'status'},
      ];

    return (<Box>
        {transacaoData && <>
        <Box sx={tituloBarra}><ExchangeIcon /> Transações</Box>
        <Box>
            <TsDataGrid 
                rows={transacaoData}
                columns={columns}
                serverPage={0}
                serverPagesize={200}
                onChangePage={(value) => void(0)}
                showPagging={true}
                pageSize={25}
                initialPageSize={50}
                fontSize={'9pt'}
            />
        </Box>
        </>}
        {transacaoData === undefined && <Box sx={{minHeight: '300px'}}>...Processando</Box>}
    </Box>)
}

export default DashTransacao;