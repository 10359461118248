import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class OfertaHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.http = http;
    this.resource = resource;
    this.url = resource;
  }

  // http://localhost:5000/oferta/tipoPonto
  listaTipoPonto() {
    this.url = `/${this.resource}/tipoPonto - GET`;
    return this.http.get(`${this.resource}/tipoPonto`, this.Config());
  }

  gravarTipoPonto(model) {
    this.url = `/${this.resource}/tipoPonto - POST`;
    return this.http.post(`${this.resource}/tipoPonto`, model, this.Config());
  }
}

const ofertaHttp = new OfertaHttpResource(httpTsApi, 'oferta');

export default ofertaHttp;
