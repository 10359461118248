import { Box } from '@mui/material';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import { isAuthenticated, getProjetoStorage, HOMEDIR } from '../util/auth';
import { Menu } from './Menu';
import MenuUsuario from './menu-usuario';
import { toolbarPainelCSS, toolbarProxyCSS, titleMenuCSS } from '../style';
import SupportIcon from '@mui/icons-material/Support';

export const Navbar = (props) => {
  const { atual } = props;
  const setOpenPerfil = props.setOpenPerfil;
  const [mode, setMode] = React.useState('painel');
  const [changeMode, setChangeMode] = React.useState(false);
  const projAtual = getProjetoStorage();
  const [logado, setLogado] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState('Efetuar login');
  const pathname = window.location.pathname;

  const flipMode = () => {
    let tempoMode = mode === 'proxy' ? 'painel' : 'proxy';
    localStorage.setItem('Painel_Mode', tempoMode);
    setMode(tempoMode);
    setChangeMode(true);
  };

  React.useEffect(() => {
    let tempoMode = localStorage.getItem('Painel_Mode') || 'painel';

    if (atual.id === 0 || (!atual.modePainel && !atual.modeProxy)) tempoMode = '';
    else {
      if (!atual.modePainel && tempoMode === 'painel') tempoMode = 'proxy';
      if (!atual.modeProxy && tempoMode === 'proxy') tempoMode = 'painel';
    }

    if (tempoMode) localStorage.setItem('Painel_Mode', tempoMode);

    setMode(tempoMode);
  }, [atual]);

  React.useEffect(() => {
    const ativo = isAuthenticated();

    if (!ativo) {
      if (pathname.indexOf(`${HOMEDIR}/cadastro`) === 0) {
        setPageTitle('Cadastro');
      } else if (pathname === `${HOMEDIR}/`) {
        setPageTitle('Efetuar Login');
      } else {
        setTimeout(() => {
          window.location.href = `${HOMEDIR}/`;
        }, 30);
      }
    } else if (ativo === logado) return;
    setLogado(ativo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppBar>
        <Toolbar className='navbar' sx={mode === 'proxy' ? toolbarProxyCSS : toolbarPainelCSS}>
          <img src="/logo.png" alt="Dclube" width={60} className="logotipo" />
          {logado && (
            <>
              <Menu mode={mode} atual={atual} checkUser={props.checkUser} changeMode={changeMode} />
            </>
          )}
          <div className='page-titulo-text mh-8' style={{color: '#DDD', flex: '40%'}}>
            TS {mode && mode.toUpperCase()} {projAtual.nome ? `- ${projAtual.nome} ` : ' - Tracking Sales'}
          </div>
          {logado 
           ? <MenuUsuario setOpenPerfil={(value) => setOpenPerfil(value)}></MenuUsuario>
           : <Typography sx={titleMenuCSS}>{pageTitle}</Typography>
          }
          <Box sx={{ padding: 2 }}>
            <img src="/LogoAtmosphera.png" alt="atmosphera" height="60px" />
          </Box>
          {atual.id > 0 && atual.modePainel && atual.modeProxy && atual.role !== 'powerbi' && (
            <div style={{flex: '0 0 auto', width: '40px'}}>
              <Button className='btn-alternar-modo' color={'inherit'} onClick={() => flipMode()}>
                <SupportIcon />
              </Button><br />
              {process.env.REACT_APP_VERSAO}
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};
