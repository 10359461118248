/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import React from "react";
import relatorioHttp from '../../util/http/relatorio-http';
import ShowError from '../../formulario/ShowError';
import * as pbi from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { PageFull } from '../Page-full';
import { Fade } from '@mui/material';
import { getUser } from '../../util/auth';
import { timeout } from '../../util/Util';
import MiniMenu from './mini-menu';
import SailingIcon from '@mui/icons-material/Sailing';
import UltimasTransacoes from './ultimas-transacoes';
import ProdutoBuscar from '../produto/produto-buscar';
import { useNavigate } from 'react-router-dom';
import { HOMEDIR } from '../../util/auth';

const relatorioTitleCSS = {
    fontSize: 24,
    fontWeight: 400,
    fontStyle: 'italic',
    color: '#777',
  };

  const mainTitleCSS = {
    fontSize: 20,
    fontWeight: 400,
    fontStyle: 'italic',
    color: '#DDD',
  };

const DashPowerbi = (props) => {

    const setOpenPerfil = props.setOpenPerfil;
    const nodata = {
        type: 'report',   
		id: undefined, 
		embedUrl: undefined,
		accessToken: undefined,   
		tokenType: pbi.models.TokenType.Embed,
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false, // pode ser true ?
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        },
                        pageNavigation: {
                            visible: false
                        }
                    },
                    background: pbi.models.BackgroundType.Transparent, // ou Transparent
                    localeSettings: {
                        language: "pt-br"
                    }
                }
    };
 
    const navegate = useNavigate();
    const [error, setError] = React.useState(undefined); 
    const [url, setUrl] = React.useState("");
    // const [processando, setProcessando] = React.useState(false);
     
    const [splash, setSplash] = React.useState(true);
    const [embedConfig, setEmbedConfig] = React.useState(nodata);
    const [report, setReport] = React.useState(undefined);
    const [pages, setPages] = React.useState(undefined);
    
    const [pnItens, setPnItens] = React.useState([]);
    const [titulo, setTitulo] = React.useState('Carregando relatórios');
    const [urlRelatorio, setUrlRelatorio] = React.useState('/powerbi');
    const [pageSelected, setPageSelected] = React.useState(-1);
    const [showPowerbi, setShowPowerbi] = React.useState(true);
    const atual = getUser('powerbi'); 
    const logotipo = atual.logotipo;

    if (atual.perfilPowerBI === '') {
        navegate(`${HOMEDIR}/dashboard`);
    }
   

    const waitSplash = async () => {
        setSplash(true);
        await getConfig();
        await timeout(5000);

        if (error === undefined) {
            waitShow();
        }
        
    }

    const waitShow = async () => {
        if (!splash) return;

        await timeout(2000);
        setSplash(false); 
        setError(undefined);
    }

    const listPages = async ()  => {
        // await timeout(10);
        try { 
            const arr = await report.getPages();
            if (arr && arr.length > 0) {

                console.log('pages on powerbi:', arr);

                const pages =  [];

                pnItens.forEach((item) => {
                    let page = arr.find(pg => pg.displayName === item.nomeChave);
                    if (page  === undefined || item.url !== '/powerbi') {
                        page = {...item, 
                            setActive: () => void(0),
                            isActive: false,
                            visibility: 0
                        };
                    }
                    else {
                        page.titulo = item.titulo;
                        page.menuLabel = item.menuLabel;
                        page.posicao = item.posicao;
                        page.menuIcone = item.menuIcone;
                        page.url = item.url;
                    }
                    pages.push(page);
                    
                });
                // console.log('pages', pages);
                setPages(pages);
            }
        } catch (error) {
            console.log('***************************************************');
            console.log('Erro ao obter paginas');
            console.log(error);
            console.log(report);
            console.log('***************************************************');
        }
        
        // pages[1].setActive();
    }

    const getConfig = async () => {
        setError(undefined);
        setSplash(true); 
        setEmbedConfig(nodata);
        try {
            const { data } = await relatorioHttp.obterTokenPowerBi();
            await getPagesFromDb(data.moduloNome);

            const econfig = {
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: data.reportId,
                embedUrl: data.embedUrl,
                accessToken: data.accessToken,
                tokenType: pbi.models.TokenType.Embed,
                permissions: pbi.models.Permissions.All,
                viewMode: pbi.models.ViewMode.View,
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false, // pode ser true ?
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        },
                        pageNavigation: {
                            visible: false
                        }
                    },
                    background: pbi.models.BackgroundType.Transparent, // ou Transparent
                    localeSettings: {
                        language: "pt-br"
                    }
                }
            };

            

            setEmbedConfig(econfig);

        } catch (error) {
            setSplash(false);
            setUrl(relatorioHttp.url);
            setError(error);
        }
    }

    const getPagesFromDb = async (modulo) => {
        
        const { data } = await relatorioHttp.obterPainelItem(modulo);
        setPnItens(data);
    }

 
    const selectPage = (index) => {
        if (pageSelected !== index && index >= 0) {
            const page = pages[index];
            // console.log(`selectPage - ${index}`, page);
            setUrlRelatorio(page.url);

            if (page.url === '/powerbi') {
                setShowPowerbi(true);
                page.setActive();
            }
            else {
                setShowPowerbi(false);
            }
            
            setTitulo(page.titulo);
            setPageSelected(index);
            window.scrollTo(0, 0);
        }
      };

    React.useEffect(() => {
        if (report !== undefined) {
            // The loaded event is raised when the report initializes. Loading is complete when the Power BI logo disappears.
            report.on('loaded', () => {
                listPages(report);
            });

            // The rendered event is raised when a report is fully rendered. For example, if all visuals are rendered upon loading a report or after a user interaction.
            report.on('rendered', () => {
                waitShow();
            });
            report.on('error', (event) => {
                console.log('error on report:', event);
            });
            
        }
    }, [report]);

    React.useEffect(() => {
        waitSplash();
    }, []);

    React.useEffect(() => {
        if (pages !== undefined && pages.length > 0) {
            selectPage(0); 
        }
    }, [pages]);

    const RelatorioLocal = () => {
        const titleStyle = {
            fontSize: '16pt', color: '#FFF', backgroundColor: '#E61414', borderRadius: '10px', padding: 0.5, paddingLeft: 2, margin: 2 
        };

        if (urlRelatorio === '/ultimas-transacoes') {
            return (
                <div>
                    <Box sx={titleStyle}>Últimas transações processadas</Box>
                    <UltimasTransacoes></UltimasTransacoes>
                </div>);
        }
        else if (urlRelatorio === '/descontos') {
            return (
                <div>
                    <Box sx={titleStyle}>Descontos vigentes</Box>
                    <ProdutoBuscar fullScreen={true} pageSize={1000} showPagging={false}></ProdutoBuscar>
                </div>        
            );
        } 
        else {
            return (<></>);
        }
        
        
    };


  return (
    <PageFull title="">
    <MiniMenu 
        itens={pages?.filter(p => p.visibility === 0).map((p, index) => ({menuIcone: p.menuIcone, menuLabel: p.menuLabel, index}))} 
        selected={pageSelected} 
        show={!splash} 
        setOpenPerfil={(e) => setOpenPerfil(true)}
        onClick={(index) => selectPage(index) } 
    />
    <div className='powerbi-titulo'><SailingIcon fontSize='large' sx={{color: 'white', marginRight: 4}} /><Typography sx={mainTitleCSS}> Nave de comando Dclube</Typography></div>
    <Box className='powerbi-header'>
        <div>
            <Box paddingTop={4} sx={{flex: '1 8 auto', marginLeft: 4, paddingTop: 5, paddingLeft: 12}}>
                <Typography sx={relatorioTitleCSS}>{splash ? '... Aguarde, processando' : titulo}</Typography>
            </Box>
            <Box sx={{flex: '0 0 auto', width: '300px', padding: 0.5}}>
                <img src={logotipo} alt='parceiro' height={'120px'} />
            </Box>
        </div>
    </Box>
   
    <Box sx={{flex: '1 8 auto', position: 'relative', marginLeft: 9}}>
        <Fade in={splash} timeout={{appear: 0, exit: 1500}} id='div-fade' unmountOnExit={true} >
                <div className='splash'><img id='img-fade' src='./splash.png' alt='' /></div>
        </Fade>
        <div className='powerbi-container'>
            { !showPowerbi && <div className='powerbi'><RelatorioLocal /></div> }
            <PowerBIEmbed 
                embedConfig={embedConfig} 
                cssClassName={showPowerbi ? 'powerbi' : 'powerbi-hidden'}
                eventHandlers = { 
                    new Map([
                        ['error', function (event) {console.log('Report ERROR:', event.detail);}]
                    ])
                }
                getEmbeddedComponent={ (embeddedReport) => {
                    if (embeddedReport?.config?.id) {
                        setReport(embeddedReport);
                    }
                    
                }}
            />
        </div>
    </Box>
    {error !== undefined && <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />}
    </PageFull>
  );
}

export default DashPowerbi;
