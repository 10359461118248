import * as React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, DialogTitle, Dialog } from '@mui/material';
import { dialogTitleCSS } from '../style';

const TsDialog = (props) => {
  const sx = props.sx || {backgroundColor: '#FFF'};
  const paperProps = {sx};
  const maxWidth = props.maxWidth || 'xl';
  if (!props.children || !props.open) {
    return null;
  }

  return (
    <Dialog PaperProps={paperProps} open={props.open} onClose={(e) => props.onClose(e)} aria-labelledby={props.id} maxWidth={maxWidth}>
      <DialogTitle sx={{...dialogTitleCSS}} id={props.id}>
        <Box paddingRight={2}>
          <SettingsIcon fontSize="medium" />
        </Box>
        <Box> {props.title}</Box>
      </DialogTitle>
      <Box minWidth={props.minWidth} sx={{ marginLeft: 4, marginRight: 4, marginBottom: 4 }}>
        {props.children}
      </Box>
    </Dialog>
  );
};

export default TsDialog;
