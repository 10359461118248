import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class RedeHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.http = http;
    this.resource = resource;
    this.url = baseUrl;
  }

  // categoria/{redeId} - lista filtros de uma rede
  obterCategorias(id) {
    this.url = `/${this.resource}/categoria/${id} - GET`;
    return this.http.get(`${this.resource}/categoria/${id}`, this.config);
  }

  // produto-update/{redeId}?projetoId={projeto}
  execProdutoUpdate(id, projetoId) {
    this.url = `/${this.resource}/produto-update/${id}?projetoid=${projetoId} - GET`;
    return this.http.get(`${this.resource}/produto-update/${id}?projetoid=${projetoId}`, this.config);
  }

  getSofthouse(id) {
    this.url = `/${this.resource}/softhouse/${id} - GET`;
    return this.http.get(`${this.resource}/softhouse/${id}`, this.config);
  }

  updateSofthouse(id, data) {
    this.url = `/${this.resource}/softhouse/${id} - PUT`;
    return this.http.put(`${this.resource}/softhouse/${id}`, data, this.config);
  }

  buscaSofthouse(filtro) {
    this.url = `/${this.resource}/softhouse/busca - POST`;
    return this.http.post(`${this.resource}/softhouse/busca`, filtro, this.config);
  }

  buscaEstabelecimento(filtro) {
    this.url = `/${this.resource}/estabelecimento/busca - POST`;
    return this.http.post(`${this.resource}/estabelecimento/busca`, filtro, this.config);
  }
  obterEstabelecimento(cnpj, tipo, lojaId) {
    this.url = `/${this.resource}/estabelecimento/${cnpj}/${tipo}/${lojaId}`;
    return this.http.get(`${this.resource}/estabelecimento/${cnpj}/${tipo}/${lojaId}`, this.config);
  }

  estabelecimentoAtualizar(model) {
    this.url = `/${this.resource}/estabelecimento - PUT`;
    return this.http.put(`${this.resource}/estabelecimento`, model, this.config);
  }

  obterResumo(projetoId, softhouseId, redeId) {
    // resumo/{projetoId}/{softhouseId}/{redeId}
    this.url = `/${this.resource}/resumo/${projetoId}/${softhouseId}/${redeId} - GET`;
    return this.http.get(`${this.resource}/resumo/${projetoId}/${softhouseId}/${redeId}`, this.config);
  }

  estabelecimentoUpdateStatus() {
    // estabelecimento/update_status
    this.url = `/${this.resource}/estabelecimento/update_status - GET`;
    return this.http.get(`${this.resource}/estabelecimento/update_status`, this.config);
  }
}

const redeHttp = new RedeHttpResource(httpTsApi, 'rede');

export default redeHttp;
