
import { httpTsApi, baseUrl } from ".";
import HttpResource from "./http-resource";

class EstabelecimentoHttpResource extends HttpResource {

    constructor(http, resource) {
        super(http, resource, baseUrl);
    }
 
    buscaV3(filtro ) {
        this.url = `/${this.resource}/buscaV3 - POST`;
        return this.http.post(`${this.resource}/buscaV3`, filtro, this.config); 
    }

}


const estabelecimentoHttp = new EstabelecimentoHttpResource(httpTsApi, "estabelecimento");

export default estabelecimentoHttp;
