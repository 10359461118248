import { Checkbox, FormControlLabel } from '@mui/material';
import * as React from 'react';
// type CheckFormProps = {
//     campo,
//     f_ormData: any,
//     setF_ormData: (f_ormData: any) => void,
// };
const CheckForm = (props) => {

    const name = props.campo.name;
    const label = props.campo.label;
   
    const [checked, setChecked] = React.useState(undefined);

    const handleChange = (event) => {
        const ck = event.target.checked;
        setChecked(ck);

        const tempData = props.formData;
        tempData[name] = ck.toString();
        props.setFormData(tempData);
 
      };

    React.useEffect(() => {
        const valor = props.formData[name];

        if (typeof valor === "undefined" || valor === null || valor === "") {
            setChecked(undefined);
        } else if (typeof valor === "string") {

            if (valor.toLowerCase() === "true") {
                setChecked(true);
            }
            else {
                setChecked(false);
            }

        } else if (valor) {
            setChecked(true);
        } else {
            setChecked(false);
        }
      
         
    }, [props.formData, name]);
    

    if (checked === undefined)
    {
        return (<div>...Loading</div>);
    }

    

    return (
        <div>
            <FormControlLabel
            control={<Checkbox 
                        checked={checked} 
                        color="primary" 
                        onChange={handleChange} 
                        name={name}  
                        />}
            label={label}
                />
        </div>
    );
};

export default CheckForm;
