import * as React from 'react';
import TsDataGrid from '../../formulario/TsDataGrid';

const tipoSigner = {
    1: 'Representante Legal',
    3: 'Testemunha',
    4: 'Sócio',
};
 
const ProspectSigner = (props) => {
    const {open,  rows} = props;
    
    const columns = [
        {name: "nome", label: "Nome"},
        {name: "email", label: "E-mail"},
        {name: "fone", label: "Celular"},
        {name: "contratoSignerTipoId", label: "Tipo", format: (value) => <>{tipoSigner[value]}</>},
    ];

     /*
     "id": 3,
            "cpf": "200100144",
            "contratoSignerTipoId": 1,
            "rg_num": null,
            "rg_emissor": null,
            "fone": "11947030194",
            "email": "quim@malinator.com",
            "nome": "Joaquim da SIlva"
            */

    if (open) return (<>
       
        <TsDataGrid
            rows={rows}
            columns={columns}
            queryid={''}
            serverPage={0}
            serverPagesize={200}
            onChangePage={(value) => void(0)}
        />
     
        </>);
        else return null;
}

export default ProspectSigner;

