import * as React from 'react';
import contratoHttp from '../../util/http/contrato-http';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';

let ContratoHist_Processando = false;

const ContratoHist = (props) => {
    const {open, contratoId, setError} = props;
    const serverPageSize = 50;
    const {serverPage, setServerPage} = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [executing, setExeccuting] = React.useState(false);

    /*
    dataInclusao
    situacao
    usuario
    detalhes
    */

    const columns = [
        {name: "dataInclusao", label: "Data"},
        {name: "situacao", label: "Situação"},
        {name: "usuario", label: "Usuário"},
        {name: "detalhes", label: "Detalhes"},
    ];

    const setProcessando = (value) => {
        ContratoHist_Processando = value;
        setExeccuting(value);
    }

    const Buscar = async () => {
        if (ContratoHist_Processando) {
            return;
        }
        setProcessando(true);
        setRows([]);

        try {
            const {status, data} = await contratoHttp.getHist(contratoId);
            if (status === 200 && data.length > 0) {
                setRows(data);
            }
            else {

            }
            //  [HttpGet]
            // [Route("{id}/hist")]
        } catch (error) {
            setError(error);
        }
        setProcessando(false);
    }

    React.useEffect(() => {
        if (open === true) {
            Buscar();
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]);

    if (open) return (<>
    <TsDataGrid
        rows={rows}
        columns={columns}
        queryid={''}
        serverPage={serverPage}
        serverPagesize={serverPageSize}
        onChangePage={(value) => setServerPage(value)}
    />
    <TsBackDrop processando={executing} />
    </>);
    else return null;

}

export default ContratoHist;