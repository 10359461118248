// @flow 
import * as React from 'react';
import { Page } from '../Page';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Link } from '@mui/material';
import { format } from 'date-fns'; // parseISO
import EventNoteIcon from '@mui/icons-material/EventNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import ProdutoDescontoEditar from './produto-desconto-editar';
/* http */
import redeHttp from '../../util/http/rede-http';
import campanhaHttp from '../../util/http/campanha-http';
import gestaodescontoHttp from '../../util/http/gestaodesconto-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
// import { HOMEDIR } from '../../util/auth';
// import TsDataGridBusca from '../../formulario/TsDataGridBusca';
// import AddButtonRouterLink from '../../formulario/AddButtonRouterLink';
import TsButton from '../../formulario/TsButton';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';
import ShowAlert from '../../util/ShowAlert';

// <TsDataGridx />
class ProdutoDesconto extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;

        this.state.redes = [{ id: "", redeNome: " ... aguarde carregando" }];
        this.state.objBusca = {redeId: "", ufAtual: "BR", mesAtual: "", busca: ""};
        this.state.meses =[];
        this.state.openEdit =false;
        this.state.dataInicio ="";
        this.state.dataFinal ="";
        this.state.campanhaId ="";
        this.state.ofertaId ="";
        this.state.produtoDescontoId ="";
        this.state.refresh =Math.random();

        this.columns = [
            {
                label: " ",
                name: "campanhaId",
                format: (value) => { return value ? (<Link component="button" onClick={(e) => this.openDetail(0, value)}><AddIcon /></Link>) : null },
            },
            {
                label: "CAMPANHA",
                name: "campanhaId"
            },
            { label: "DE", name: "dataInicio" },
            { label: "ATÉ", name: "dataFinal" },
            {
                label: " ", name: "id",
                format: (value) => { return value ? (<Link component="button" onClick={(e) => this.openDetail(value, 0)}><EventNoteIcon /></Link>) : null }
            },
            { label: "EAN", name: "ean" },
            { 
                label: "PRODUTO", 
                name: "produtoNome",
                format: (value) => { return value && value.length > 24 ? value.substr(0, 20) + ' ...' : value} 
            },
            { label: "PREÇO", name: "precoRef" },
            { label: "DESCONTO", name: "desconto" },
            { label: "ZICARD", name: "descontoZicard" },
            { label: "KIT", name: "kit" },
            {
                label: " ", name: "id",
                format: (value) => { return value ? (<Link component="button" onClick={(e) => this.deleteItem(value)}><DeleteForeverIcon /></Link>) : null }
            }
        ];
    }
 
        setRedes(redes) {
            this.setState({redes});
        }
        
        setMeses(meses) {
            this.setState({meses});
        }
        
        setOpenEdit(openEdit) {
            this.setState({openEdit});
        }
        
        setDataInicio(dataInicio) {
            this.setState({dataInicio});
        }
        
        setDataFinal(dataFinal) {
            this.setState({dataFinal});
        }
        
        setCampanhaId(campanhaId) {
            this.setState({campanhaId});
        }
        
        setOfertaId(ofertaId) {
            this.setState({ofertaId});
        }
        
        setProdutoDescontoId(produtoDescontoId) {
            this.setState({produtoDescontoId});
        }
        
        setRefresh(refresh) {
            this.setState({refresh});
        }

    Buscar = () => {
        

        if (!this.state.objBusca.redeId) {
            // this.setError({sucesso: false, mensagem: 'Nenhuma rede selecionada'});
            return;
        }

        if (!this.state.objBusca.mesAtual) {
            // this.setError({sucesso: false, mensagem: 'Nenhum mes selecionado'});
            return;
        }
        
        if (!this.state.objBusca.ufAtual) {
            // this.setError({sucesso: false, mensagem: 'Nenhuma UF selecionada'});
            return;
        }

        this.setOpenEdit(false);
        this.iniciarBusca();
        
        gestaodescontoHttp.obterCampanhaDescontos(this.state.objBusca.redeId, this.state.objBusca.mesAtual, this.state.objBusca.ufAtual, this.projeto.id)
            .then(({ data }) => {
                this.setProcessando(false);
                const lista = [];
                if (data.sucesso === true && data.dados?.length > 0) {



                    data.dados.forEach((el) => {
                        let head = {
                            campanhaId: el.campanhaChaveId + '',
                            dataInicio: el.dataInicio,
                            dataFinal: el.dataFinal,
                            flgClassificacao: el.flgClassificacao,
                        };
                        const descontos = el.descontos || [];

                        if (descontos.length === 0) {
                            descontos.push({ id: 0, ean: '', produtoNome: ' *** Campanha sem ofertas' });
                        }

                        descontos.forEach(desc => {
                            lista.push({
                                ...head,
                                id: desc.id,
                                ean: desc.ean,
                                desconto: desc.desconto,
                                descontoZicard: desc.descontoZicard,
                                kit: desc.kit,
                                produtoNome: desc.produtoNome,
                                precoRef: desc.precoRef,
                                campanhaChaveId: desc.campanhaChaveId,
                                ofertaItemId: desc.ofertaItemId

                            })

                            head = {
                                campanhaId: "",
                                dataInicio: "",
                                dataFinal: "",
                                flgClassificacao: "",
                            };

                        });

                    });

                }

                this.setRows(lista);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(campanhaHttp.getUrl());
            });
    }

    openDetail = async (id, campanhaChaveId) => {
        let item = id + '';
        let campanha = campanhaChaveId + '';

        item = item === "0" ? "" : item;
        campanha = campanha === "0" ? "" : campanha;

        if (!item && !campanha) {
            return;
        }

        this.setRefresh(Math.random());
        this.setProdutoDescontoId(item);
        this.setCampanhaId(campanha);

        this.setOpenEdit(true);

    }

    openNovo = () => {

        if (this.state.objBusca.redeId === "") {
            ShowAlert.error("Rede não selecionada");
        }

        let dInicio = this.state.dataInicio;
        let dFinal = this.state.dataFinal;

        if (dInicio === "" || dFinal === "") {
            const arrMes = this.state.objBusca.mesAtual.split("-");
            let diaFinal = "30";
            dInicio = `${arrMes[0]}-${arrMes[1]}-01`;

            if (arrMes[1] === "02") {
                diaFinal = "28";
            }
            else if (["01", "03", "05", "07", "08", "10", "12"].indexOf(arrMes[1]) >= 0) {
                diaFinal = "31";
            }

            dFinal = `${arrMes[0]}-${arrMes[1]}-${diaFinal}`;

            this.setDataFinal(dFinal);
            this.setDataInicio(dInicio);

        }
        this.setCampanhaId('');
        this.setOfertaId('');
        this.setProdutoDescontoId('');

        this.setOpenEdit(true);

    }

    deleteItem = (Id) => {
        const item = this.state.rows.find(el => el.id === Number(Id));

        if (!item || !item.id || !item.campanhaChaveId || !item.ofertaItemId) {
            ShowAlert.error("Falha ao obter dados do item");
            return;
        }
        this.setProcessando(true);
        this.setError(undefined);
        gestaodescontoHttp.deleteProduto(item.id, item.campanhaChaveId, item.ofertaItemId)
            .then((retorno) => {
                ShowAlert.info("Desconto deletado com sucesso");
                this.Buscar();
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setUrl(campanhaHttp.getUrl());
            });
    }

    carregarMeses = () => {
        let m = [];
        const hoje = new Date();

        for (let i = -2; i < 4; i++) {
            const newDate = new Date();
            newDate.setMonth(hoje.getMonth() + i);

            m.push({ text: format(newDate, "yyyy-MM"), value: format(newDate, "yyyy-MM") });
        }

        this.setMeses(m);
        // hoje.setMonth(hoje.getMonth() + 1);
        this.setObjBusca({...this.state.objBusca, mesAtual: format(hoje, "yyyy-MM")});
    }

    carregarRedes = () => {
        this.setProcessando(true);
        this.setError(undefined);
        this.setRedes([{ id: "", redeNome: " ... aguarde carregando" }]);
        redeHttp.busca({ projetoId: this.projeto.id })
            .then((retorno) => {
                this.setProcessando(false);
                this.setRedes(retorno.data.dados);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setUrl(redeHttp.getUrl());
            });
    };

    componentDidMount() {
        super.componentDidMount();
        if (this.state.redes.length < 2)
            this.carregarRedes();
        if (this.state.meses.length < 2)
            this.carregarMeses();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
        
    }

    render() {
        return (
            <Page title="Gestão de descontos">
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl fullWidth >
                            <InputLabel id="rede-select-label">Rede</InputLabel>
                            <Select
                                labelId="rede-select-label"
                                id="rede-select"
                                value={this.state.objBusca.redeId}
                                onChange={(e) => { this.setObjBusca({...this.state.objBusca, redeId: e.target.value}) }}
                                fullWidth
                            >
                                <MenuItem key={-1} value="">... selecionar</MenuItem>
                                {this.state.redes.map((it, index) => (
                                    <MenuItem key={index} value={it.id}>{it.id} - {it.redeNome}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth >
                            <InputLabel id="mes-select-label">Mês</InputLabel>
                            <Select
                                labelId="mes-select-label"
                                id="mes-select"
                                value={this.state.objBusca.mesAtual}
                                onChange={(e) => { 
                                    this.setObjBusca({...this.state.objBusca, mesAtual: e.target.value}); 
                                    this.setDataInicio(""); 
                                    this.setDataFinal(""); 
                                }}
                                fullWidth
                            >
                                <MenuItem key={-1} value="">... selecionar</MenuItem>
                                {this.state.meses.map((it, index) => (
                                    <MenuItem key={index} value={it.value}>{it.text}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth >
                            <InputLabel id="uf-select-label">UF</InputLabel>
                            <Select
                                labelId="uf-select-label"
                                id="uf-select"
                                value={this.state.objBusca.ufAtual}
                                onChange={(e) => { this.setObjBusca({...this.state.objBusca, ufAtual: e.target.value})}}
                                fullWidth
                            >
                                <MenuItem value="BR">BR</MenuItem>
                                <MenuItem value="AC">Acre</MenuItem>
                                <MenuItem value="AL">Alagoas</MenuItem>
                                <MenuItem value="AP">Amapá</MenuItem>
                                <MenuItem value="AM">Amazonas</MenuItem>
                                <MenuItem value="BA">Bahia</MenuItem>
                                <MenuItem value="CE">Ceará</MenuItem>
                                <MenuItem value="DF">Distrito Federal</MenuItem>
                                <MenuItem value="ES">Espírito Santo</MenuItem>
                                <MenuItem value="GO">Goiás</MenuItem>
                                <MenuItem value="MA">Maranhão</MenuItem>
                                <MenuItem value="MT">Mato Grosso</MenuItem>
                                <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                                <MenuItem value="MG">Minas Gerais</MenuItem>
                                <MenuItem value="PA">Pará</MenuItem>
                                <MenuItem value="PB">Paraíba</MenuItem>
                                <MenuItem value="PR">Paraná</MenuItem>
                                <MenuItem value="PE">Pernambuco</MenuItem>
                                <MenuItem value="PI">Piauí</MenuItem>
                                <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                                <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                                <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                                <MenuItem value="RO">Rondônia</MenuItem>
                                <MenuItem value="RR">Roraima</MenuItem>
                                <MenuItem value="SC">Santa Catarina</MenuItem>
                                <MenuItem value="SP">São Paulo</MenuItem>
                                <MenuItem value="SE">Sergipe</MenuItem>
                                <MenuItem value="TO">Tocantins</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TsButton
                            onClick={(e) => this.setObjBusca({...this.state.objBusca, busca: Math.random() + ''})}
                        >Buscar</TsButton>
                    </Grid>
                </Grid>
                <Box marginTop="2">
                    <TsInputBusca
                        name="input-busca"
                        value={this.state.objBusca.busca}
                        showAtivo={false}
                        showBusca={false}
                        showAdd={true}
                        onClickAdd={(e) => this.openNovo()}
                    ></TsInputBusca>
                    <TsDataGrid
                        rows={this.state.rows}
                        columns={this.columns}
                        queryid={this.state.queryid}
                        serverPage={this.state.serverPage}
                        serverPagesize={this.serverPageSize}
                        onChangePage={(value) => this.setServerPage(value)}
                    />
                </Box>

                <ProdutoDescontoEditar
                    open={this.state.openEdit}
                    onClose={() => this.Buscar()}
                    campanhaId={this.state.campanhaId}
                    ofertaId={this.state.ofertaId}
                    default={{ final: this.state.dataFinal, inicio: this.state.dataInicio, rede: this.state.objBusca.redeId, uf: this.state.objBusca.ufAtual }}
                    produtoDescontoId={this.state.produtoDescontoId}
                    refresh={this.state.refresh}
                />

                <TsBackDrop processando={this.state.processando} />
                <ShowError error={this.state.error} mensagemPadrao='Falha ao processar request' url={this.state.url} />
            </Page>
        );
    }
};

export default ProdutoDesconto;