/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import * as React from 'react';
import { Page } from './Page';
import { getUser, setUser, usuarioNull, setProjetoStorage } from '../util/auth';
import { Box, Tabs, Tab, Link } from '@mui/material';
import authHttp from '../util/http/auth-http';

import { HOMEDIR } from '../util/auth';
import { formatISO } from 'date-fns';
import routes from '../routes';
import { TsTextField } from '../formulario/TsTextField';
import TsButton from '../formulario/TsButton';
import TsBackDrop from '../formulario/TsBackDrop';
import { httpProxy } from '../util/http/proxy-http';
import ListaTransacao from './dashes/lista-transacao';
import ViewLogs from './dashes/ViewLogs';
import ShowError from '../formulario/ShowError';
import ShowAlert from '../util/ShowAlert';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import DashPage from './dashes/dash-page';
import { useNavigate } from 'react-router-dom';

const Dashboard = (props) => {

  const [errorLogin, setErrorLogin] = React.useState(false);
  const [msgLogin, setMsgLogin] = React.useState('');
  const [usuario, setUsuario] = React.useState('');
  const [senha, setSenha] = React.useState('');
  const [permit, setPermit] = React.useState(0);
  const [processando, setProcessando] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(2);
  const [error, setError] = React.useState(undefined);
  const [url, setUrl] = React.useState('');
  const [loginAs, setLoginAs] = React.useState(localStorage.getItem("login-as"));
  const navegate = useNavigate();

  const atual = getUser('home');
  const perm = atual.usuarioPermit.find((pu) => pu.modulo === 'admin');
  const adminPerm = perm ? perm.permit : 0;

  const deleteLoginAs = () => {
    localStorage.removeItem('login-as');
    setLoginAs(undefined);
  }
   
  const efetuarLogin = async (event) => {
    if (event) 
      event.preventDefault();

    setUrl('efetuarLogin');
    setProcessando(true);
    setError(undefined);
    localStorage.removeItem('projeto');
    localStorage.removeItem('login-as');

    try {
      const response = await authHttp.login(usuario, senha, loginAs);
      
      if (!response.data.token) {
        setProcessando(false);
        setUsuario('');
        setSenha('');
        setUser(usuarioNull);
        setErrorLogin(true);
        setMsgLogin('Usuario ou senha inválidos ...');
        ShowAlert.error('Usuario ou senha inválidos');
        return;
      }
      
      let perfilPowerBI = response.data.perfilPowerBI;
      perfilPowerBI = !perfilPowerBI || perfilPowerBI === null ? '' : perfilPowerBI.toLowerCase();
   
      /*
      "rede": null,
      "industria": null,
      "softhouse": 1,
      */

      // console.log('login usuario:', JSON.stringify(response.data));

      const urlDashLogo = response.data.urlDashLogo && response.data.urlDashLogo !== null 
        ?  response.data.urlDashLogo
        : 'https://backoffice.trackingsales.com.br/rsl_todos.png';

      let logotipo = urlDashLogo;

      const retUser = {
        id: response.data.id,
        usuarioPermit: response.data.usuarioPermit,
        authorization: response.data.authorization,
        login: response.data.login,
        role: response.data.role,
        token: response.data.token,
        modePainel: false,
        modeProxy: false,
        rede: response.data.rede,
        industria: response.data.industria,
        softhouse: response.data.softhouse,
        logotipo: logotipo,
        perfilPowerBI: perfilPowerBI,
        urlDashLogo 
      };

      const agora = new Date();
      agora.setMinutes(agora.getMinutes() + 100);
      retUser.expires = formatISO(agora);
      retUser.senha = senha;

      routes.forEach((r) => {
        retUser.usuarioPermit.forEach((up) => {
          if (up.modulo === r.modulo && up.permit > 0) {
            if (r.mode === 'painel') {
              retUser.modePainel = true;
            } else if (r.mode === 'proxy') {
              retUser.modeProxy = true;
            }
          }
        });
      });

      if (retUser.modeProxy === false && retUser.modePainel === false) {
        ShowAlert.error('Nenhum perfil associado ao usuário');
        return;
      }

      setUser(retUser);
      setUsuario('');
      setSenha('');
      setErrorLogin(false);
      setMsgLogin('Login com sucesso ...');

      // const { data } = await projetoHttp.busca({ page: 0, nrecPage: 100, ativo: true });
      // const lista = data.dados;

      setProjetoStorage('5000', 'Dclube');

      const retProxy = await httpProxy.buscaConfig({ page: 0, nrecPage: 100 });
      if (retProxy.data && retProxy.data.length > 0) {
        const listaProxy = retProxy.data
          .filter((p) => p.serverId)
          .map((p) => {
            // {"nome": "AWS-DERMACLUB", "url": "http://54.233.209.153:3000/api", "monitor": "http://54.233.209.153:3000/monitor", "rede": "1013"}
            return {
              nome: p.nome,
              url: p.serverUrl,
              monitor: p.monitorUrl,
              rede: p.redeId + '',
              cnpj: p.cnpj,
              descricao: p.descricao,
            };
          });
        localStorage.setItem('LISTA_PROXY', JSON.stringify(listaProxy));
      }

      if (retUser.role === 'powerbi') {
        setTimeout(() => {
          setProcessando(false);
          window.location.href = `${HOMEDIR}/dashboard`;
        }, 500);
      }
      else {
        setTimeout(() => {
          setProcessando(false);
          window.location.href = `${HOMEDIR}/`;
        }, 500);
      }

      
    } catch (error) {
      setUrl(authHttp.url);
      setError(error);
      setProcessando(false);
      setUsuario('');
      setSenha('');
      setUser(usuarioNull);
      setErrorLogin(true);
      setMsgLogin('Erro ao efetuar login ...');
      ShowAlert.error('Erro ao efetuar LOGIN');
    }
  };

  const handleTabPanel = (event, newValue) => {
    if (permit >= 1) {
      if (newValue === 0) {
        navegate(`${HOMEDIR}/dashboard`);
      }
      else 
        setTabIndex(newValue);
    }
  };

  React.useEffect(() => {

    if (atual.id > 0) {

      let tmpPermit = 0;
      const per = atual.usuarioPermit.find((up) => up.modulo === 'home');
      if (per) tmpPermit = per.permit;
      
      setPermit(tmpPermit);

      if (atual.role === 'powerbi' || tmpPermit <= 1) {
        setTimeout(() => {
          window.location.href = `${HOMEDIR}/dashboard`;
        }, 100);
      }

    }
    else {
      setPermit(0);
    }

     
  }, []);

  return (
    <Page title="Home">
      {atual.id > 0 && permit > 1 && (
        <Box>
           
              <Box>
                <Tabs value={tabIndex} onChange={handleTabPanel} aria-label="Opções da oferta">
                  <Tab label="Dashboard" id="dash-tab-0" aria-labelledby={`dash-tabpanel-0`} />
                  <Tab label="Transações" id="dash-tab-1" aria-labelledby={`dash-tabpanel-1`} />
                  <Tab label="Log Autorizador" id="dash-tab-2" aria-labelledby={`dash-tabpanel-2`} />
                </Tabs>
              </Box>
          {tabIndex === 0 && <DashPage />}
          {tabIndex === 1 && <ListaTransacao />}
          {tabIndex === 2 && <ViewLogs />}
        </Box>
      )}
      {atual.id > 0 && permit <= 1 && (
        <Box>
           Home - Painel de gerenciamento TrackingSales
        </Box>
      )}
      {atual.id === 0 && (
        <>
          <form onSubmit={(e) => efetuarLogin(e)}>
            <TsTextField
              label="Usuário"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              error={errorLogin}
              helperText={msgLogin}
            />
            <TsTextField label="Senha" value={senha} type="password" onChange={(e) => setSenha(e.target.value)} />
            <Box sx={{display: "flex"}} paddingTop={4}>
              <Box sx={{flex: "8 1 auto"}}>
                
              </Box>
              <Box sx={{flex: "1 0 auto"}} paddingRight={2}>
                {loginAs && <Box>Login as : {loginAs} <Link component="button" onClick={() => deleteLoginAs()}> <DeleteIcon /></Link> </Box>}
              </Box>
              <Box sx={{flex: "1 0 auto"}} dir="rtl" >
                <TsButton type="submit" onClick={(e) => efetuarLogin(undefined)}>Login</TsButton>
              </Box>
            </Box>
          </form>
        </>
      )}
      {error !== undefined && <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />}
      <TsBackDrop processando={processando} />
    </Page>
  );
};

export default Dashboard;
