import { Box} from '@mui/material';
import * as React from 'react';
import { Page } from '../Page';
import DistribuidorList from './distribuidor-list';
import DistribuidorDetail from './distribuidor-detail';

const DistribuidorPage = () => {
    const [detail, setDetail] = React.useState({open: false, id: 0, pageTitle: "Lista de distribuidores"}); 
    
    return (
        <Page title={detail.pageTitle}>
            <Box>
                {detail.open ? 
                <DistribuidorDetail setDetail={setDetail}  detail={detail}></DistribuidorDetail> : 
                <DistribuidorList setDetail={setDetail} detail={detail}></DistribuidorList>}
            </Box>
        </Page>
    );
};

export default DistribuidorPage;
