/* eslint-disable react-hooks/exhaustive-deps */
// @flow 
import * as React from 'react';
import { Box } from '@mui/material';
import { validarLimitador, LimitadorCampos } from '../../dominios/Limitador';
import { Page } from '../Page';
import { useNavigate, useParams } from 'react-router-dom';
import { HOMEDIR } from '../../util/auth';
import limiteHttp from '../../util/http/limite-http';
import TextForm from '../../formulario/TextForm';
import ShowError from '../../formulario/ShowError';
import TsButton from '../../formulario/TsButton';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowAlert from '../../util/ShowAlert';
import SelectCampo from '../../formulario/SelectCampo';


const LimiteForm = (props) => {


    const navigate = useNavigate();

    const [processando, setProcessando] = React.useState(false);
    const { id } = useParams();
    const [error, setError] = React.useState(undefined);
    const [limitadorItemid, setLimitadorItemid] = React.useState(parseInt(id || '') || 0);

    const [form, setForm] = React.useState({});
    const [validating, setValidating] = React.useState(false);

    const handleSubmit = (event) => {

        if (event !== undefined) {
            event.preventDefault();
        }

        setError(undefined);

        setValidating(true);
        const msg = validarLimitador(form);
        if (msg) {
            ShowAlert.error(msg);
            return;
        }
        setValidating(false);

        const limitador = {
            limitadorItemid: limitadorItemid,
            projetoId: parseInt(form.projetoId || ''),
            intervaloMinutos: parseInt(form.intervaloMinutos || ''),
            nItensTotalPeriodo: parseInt(form.nItensTotalPeriodo || ''),
            qtdeEanPeriodo: parseInt(form.qtdeEanPeriodo || ''),
            nCompraPeriodo: parseInt(form.nCompraPeriodo || ''),
            descricao: form.descricao || '',
            chaveLimitador: form.chaveLimitador || '',
            ativo: form.ativo ? true: false
        };

        if (isNaN(limitador.limitadorItemid)) {
            limitador.limitadorItemid = 0;
        }

        if (isNaN(limitador.projetoId)) {
            ShowAlert.error('projetoId inválido');
            return;
        }

        if (isNaN(limitador.intervaloMinutos)) {
            ShowAlert.error('intervaloMinutos inválido');
            return;
        }

        if (isNaN(limitador.nItensTotalPeriodo) || limitador.nItensTotalPeriodo <= 0) {
            limitador.nItensTotalPeriodo = -1;
        }

        if (isNaN(limitador.qtdeEanPeriodo) || limitador.qtdeEanPeriodo <= 0) {
            limitador.qtdeEanPeriodo = -1;
        }

        if (isNaN(limitador.nCompraPeriodo) || limitador.nCompraPeriodo <= 0) {
            limitador.nCompraPeriodo = -1;
        }

        if (limitador.nCompraPeriodo === -1 && limitador.qtdeEanPeriodo === -1 && limitador.nItensTotalPeriodo === -1) {
            ShowAlert.error('Nenhum valor valido para esse limitador');
            return;
        }

        setProcessando(true);
        limiteHttp.update(limitador.limitadorItemid, limitador)
            .then((retorno) => {
                setProcessando(false);
                updateData(retorno.data);
                ShowAlert.error('Atualizado com sucesso');
            })
            .catch((error) => {
                setProcessando(false);
                setError(error);
            });


    }

    const updateData = (data) => {
        setLimitadorItemid(data.limitadorItemid);
        const tmpLimitador = {
            limitadorItemid: data.limitadorItemid,
            chaveLimitador: data.chaveLimitador,
            projetoId: data.projetoId,
            descricao: data.descricao,
            intervaloMinutos: data.intervaloMinutos,
            nItensTotalPeriodo: data.nItensTotalPeriodo,
            qtdeEanPeriodo: data.qtdeEanPeriodo,
            nCompraPeriodo: data.nCompraPeriodo,
            ativo: data.ativo
        };

        const tmpForm = form;

        tmpForm.limitadorItemid = tmpLimitador.limitadorItemid.toString();
        tmpForm.chaveLimitador = tmpLimitador.chaveLimitador;
        tmpForm.projetoId = tmpLimitador.projetoId.toString();
        tmpForm.descricao = tmpLimitador.descricao;
        tmpForm.intervaloMinutos = tmpLimitador.intervaloMinutos.toString();
        tmpForm.nItensTotalPeriodo = tmpLimitador.nItensTotalPeriodo === -1 ? "" : tmpLimitador.nItensTotalPeriodo.toString();
        tmpForm.qtdeEanPeriodo = tmpLimitador.qtdeEanPeriodo === -1 ? "" : tmpLimitador.qtdeEanPeriodo.toString();
        tmpForm.nCompraPeriodo = tmpLimitador.nCompraPeriodo === -1 ? "" : tmpLimitador.nCompraPeriodo.toString();
        tmpForm.ativo = tmpLimitador.ativo;

        setForm(tmpForm);
    }

    React.useEffect(() => {

        setForm({});

        if (limitadorItemid) {
            setProcessando(true);
            setError(undefined);
            limiteHttp.get(limitadorItemid)
                .then((retorno) => {
                    setProcessando(false);
                    updateData(retorno.data);
                })
                .catch((error) => {
                    setProcessando(false);
                    setError(error);
                });


        }
    }, [id]);

    return (
        <Page title={`Editar Limitador - ${limitadorItemid}`}>
            <form onSubmit={handleSubmit}>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{flex: '1 1 auto'}}>
                        <TextForm
                            campo={LimitadorCampos.chaveLimitador}
                            formData={form}
                            setFormData={(value) => setForm(value)}
                            validating={validating}
                        />
                    </Box>
                    <Box sx={{flex: '0 0 auto', marginLeft: 1}}>
                        <SelectCampo
                            id='lstAtivo'
                            label='Ativo'
                            name='ativo'
                            options={[{value: 'true', text: 'Ativo'}, {value: 'false', text: 'Inativo'}]}
                            value={form.ativo ? 'true' : 'false'}
                            onChange={(e) => setForm({...form, ativo: e.target.value === 'true' ? true: false})}
                        />
                    </Box>
                </Box>
                <TextForm

                    campo={LimitadorCampos.projetoId}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}

                />
                <TextForm

                    campo={LimitadorCampos.descricao}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}

                />
                <TextForm

                    campo={LimitadorCampos.intervaloMinutos}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}

                />
                <TextForm

                    campo={LimitadorCampos.nItensTotalPeriodo}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}

                />
                <TextForm

                    campo={LimitadorCampos.qtdeEanPeriodo}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}

                />
                <TextForm

                    campo={LimitadorCampos.nCompraPeriodo}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}

                />

                <Box dir="rtl">
                    <TsButton
                        onClick={(e) => navigate(`${HOMEDIR}/limitador`)}
                        color='secondary'
                    >Voltar</TsButton>
                    <TsButton
                        type="submit"
                    >Salvar</TsButton>
                </Box>

            </form>
            <TsBackDrop processando={processando} />
            {error !== undefined &&
                <ShowError error={error} url="/limitador/put" mensagemPadrao="Falha ao processar limites" />
            }
        </Page>
    );
};

export default LimiteForm;