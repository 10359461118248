/* eslint-disable react-hooks/exhaustive-deps */
import { TsTextField } from './TsTextField';
import * as React from 'react';

// let w: number = 0;

// type TextFormProps = {
//     campo,
//     formData: any,
//     setFormData: (formData: any) => void,
//     validating: boolean,
//     multiline?: boolean,
//     InputProps?: any,
//     minRows?: any,
//     fixed?: number,
//     disabled?: boolean,
//     onChange?: (value: string) => void
// };

const TextForm = (props) => {

    const campo = props.campo || { label: "", name: "", max: 0, min: 0, tipo: "string" };
    const name = campo.name;
    const label = campo.label;
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState("");
    const [valor, setValor] = React.useState('');


    const handleChange = (e) => {
        setValor(e.target.value);
        const tempData = props.formData;
        if (campo.tipo === "number") {
            let v = parseFloat(e.target.value);
            if (isNaN(v)) {
                v = undefined;
            }
            tempData[name] = v;
        }
        else {
            tempData[name] = e.target.value;
        }

        props.setFormData(tempData);
        if (props.onChange) props.onChange(e.target.value);
    }

    React.useEffect(() => {

        if (name) {
            let v = '' + props.formData[name];
            if (campo.tipo === "number") {
                let n = parseFloat(v);
                // console.l og(` ... TextForm , tipo number , valor: ${n}`);
                if (!isNaN(n)) {
                    setValor(props.fixed ? n.toFixed(props.fixed) : v);
                }
                else {
                    setValor("");
                }
            }
            else {
                setValor(v);
            }
            // setValor(props.formData[name] ? '' +  props.formData[name].toString() : '');
        }

    }, [props.formData, name]);

    React.useEffect(() => {
        if (props.validating === true) {
            if (campo.min > 0 && !valor) {
                setError(true);
                setHelperText("Campo necessário");
            }
        }
        else {
            setError(false);
            setHelperText("");
        }
    }, [props.validating]);

    if (!name) {
        return (<div> ... campo indefinido</div>)
    }

    return (
        <TsTextField
            name={name}
            label={label}
            error={error}
            value={valor || ''}
            onChange={(e) => handleChange(e)}
            helperText={helperText}
            fullWidth
            variant="outlined"
            margin="normal"
            multiline={props.multiline}
            InputProps={props.InputProps}
            minRows={props.minRows}
            disabled={props.disabled}
        />
    );
};

export default TextForm
