import descontocontroleHttp from '../../util/http/descontocontrole-http';

export const ResetProdutos = async (id, setUrl, setError) => {
  try {
    const ret = await descontocontroleHttp.resetProdutos(id);
    setUrl(descontocontroleHttp.getUrl());
    setError(ret.data);
    console.log('retorno:', ret.data);
  } catch (error) {
    setUrl(descontocontroleHttp.getUrl());
    setError(error);
  }
};

export const ExecValidacao = async (setUrl, setError) => {
  try {
    const ret = await descontocontroleHttp.execValidacao();
    setUrl(descontocontroleHttp.getUrl());
    if (ret.data.sucesso) {
      setError(JSON.parse(ret.data.dado));
    } else {
      setError(ret.data);
    }

    console.log('retorno:', ret.data);
  } catch (error) {
    setUrl(descontocontroleHttp.getUrl());
    setError(error);
  }
};
