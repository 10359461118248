import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Page } from '../Page';
import { mainTitleCSS } from '../../style';

/* http */
import faturamentoHttp from '../../util/http/faturamento-http';
/* datatable */
import TsBackDrop from '../../formulario/TsBackDrop';
import TsButton from '../../formulario/TsButton';
import ShowError from '../../formulario/ShowError';
import SelectCampo from '../../formulario/SelectCampo';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import FatGeral from './fat-geral';
import FatDetail from './fat-detail';

import {valorText} from '../../util/Util';
import {getProjetoStorage, getUser} from '../../util/auth';
import ShowAlert from '../../util/ShowAlert';

// import { useSnackbar } from 'notistack';


// <TsDataGridx />
function Faturamento(props) {

        // const { enqueueSnackbar } = useSnackbar();
   
        const [filtering, setFiltering] = React.useState(false);
        const [openDetail, setOpenDetail] = React.useState(false);
        const [openCount, setOpenCount] = React.useState(0);
        const prevCount = React.useRef(-1);

        const [rows, setRows] = React.useState([]);
        const [rowsDetalhes, setRowsDetalhes] = React.useState([]);
        const [tipo, setTipo] = React.useState('');
        const [id, setId] = React.useState(0);
        const [nome, setNome] = React.useState('');

        const [lstMesFechamento, setLstMesFechamento] = React.useState([]);
        const [fechamentoId, setFechamentoId] = React.useState('');

        const projAtual = getProjetoStorage();

        const atual = getUser('faturamento');
        const flagRede = atual.rede || atual.softhouse ? true : false;

        // const [industriaId, setIndustriaId] = React.useState('');
        // const [redeId, setRedeId] = React.useState('');
        // const [marca, setMarca] = React.useState('');
        // const [categoria, setCategoria] = React.useState('');
        const [filterItem, setFilterItem] = React.useState({industriaId: '', redeId: '', softhouseId: '', distribuidorId: '', marca: '', categoria: ''});
   
        const [processando, setProcessando] = React.useState(false);
        const [url, setUrl] = React.useState('');
        const [error, setError] = React.useState(undefined);
        const [total, setTotal] = React.useState(0);
        const [desconto, setDesconto] = React.useState(0);
        const [zicard, setZicard] = React.useState(0);
         
        const FillListMesFechamento = () => {

            if (lstMesFechamento.length === 0) {
                const lista = [];
                let agora = new Date();
                let x = agora.getMonth();
        
                for (let i = 0; i < 12; i++) {
                    const tDate = agora.setMonth(x);
                    const valor = format(tDate, 'yyyyMM');
                    const texto = i === 0 ? `${format(tDate, 'yyyy-MM')} - Parcial` : format(tDate, 'yyyy-MM');
                    lista.push({value: valor, text: texto});
                    if (valor === '202302') break;
                    x = x - 1;
                }
        
                setLstMesFechamento(lista);
                setFechamentoId(lista[1].value);
            }
            
        }        
              
    const Buscar = async () => {
        
        setOpenDetail(false);
        setProcessando(true);

        try {
            const {data} = await faturamentoHttp.sumario(fechamentoId);
            const lista = [];

            const sucesso = data.sucesso;
            const mensagem = data.mensagem;
             const items = data.lista;

             if (sucesso === false) {
                setError({sucesso, mensagem});
             }

            let chaveAtual = '';
            let tmpTotal = 0;
            let tmpDesconto = 0;
            let tmpZicard = 0;
            // loop para total geral ( somar apenas INDUSTRIA OU REDE, o que aparecer antes)
            for (let i = 0; i < items.length; i++) {
                const it = items[i];
                if (!chaveAtual && (it.chave === 'INDUSTRIA' || it.chave === 'REDE' || it.chave === 'ESTABELECIMENTO')) {
                    chaveAtual = it.chave;
                }

                if (chaveAtual === it.chave) {
                    tmpTotal += it.totalVendido;
                    tmpDesconto += it.totalDesconto;
                    tmpZicard += it.zicardDesconto;
                }

            }

            // loop para subtotal
            chaveAtual = '';
            let subTotal = 0;
            let subDesconto = 0;
            let subZicard = 0;

             

            for (let i = 0; i < items.length; i++) {
                const it = items[i];

                // ocorreu mudanca de chave, fechamento do subtotal
                if(chaveAtual && it.chave !== chaveAtual) {
                    lista.push({id: -1, chave: chaveAtual, valor: 'SUB-TOTAL', totalVendido: subTotal, totalDesconto: subDesconto, zicardDesconto: subZicard});
                    subTotal = 0;
                    subDesconto = 0;
                    subZicard = 0;
                }
                chaveAtual = it.chave;

                subTotal += it.totalVendido;
                subDesconto += it.totalDesconto;
                subZicard += it.zicardDesconto;
                
                if (chaveAtual === 'INDUSTRIA') {
                    it.valor = it.industria;
                }
                else if (chaveAtual === 'REDE') {
                    it.valor = it.rede;        
                }
                else if (chaveAtual === 'ESTABELECIMENTO') {
                    it.valor = it.rede;        
                }
                else if (chaveAtual === 'SOFTHOUSE') {
                    it.valor = it.softhouse;
                }
                else if (chaveAtual === 'DISTRIBUIDOR') {
                    it.valor = it.distribuidor;
                }

                lista.push(it);
            }
            lista.push({id: -1, chave: chaveAtual, valor: 'SUB-TOTAL', totalVendido: subTotal, totalDesconto: subDesconto, zicardDesconto: subZicard});
        
            setRows(lista);
            setTotal(tmpTotal);
            setDesconto(tmpDesconto);
            setZicard(tmpZicard);

        } catch (error) {
            console.error(error);
            setError(error);
        }

        setProcessando(false);
        setUrl(faturamentoHttp.getUrl());
        
    };

    const onOpenDetail = async (id) => {
        const sum = rows.find(r => r.id === id);
        if (sum === undefined) {
            window.alert("Detalhes não definido");
            return;
        }
        
        // setFechamentoId(sum.fechamentoId);
        setOpenCount(openCount + 1);
        await timeout(30);
        setFilterItem({
            industriaId: sum.industriaId === null ? '' : sum.industriaId  + '', 
            redeId: sum.redeTsId === null ? '' : sum.redeTsId  + '',
            softhouseId: sum.softhouseId === null ? '' : sum.softhouseId + '',
            distribuidorId: sum.distribuidorId === null ? '': sum.distribuidorId + '', 
            cnpj: sum.cnpj === null ? '': sum.cnpj + '',
            marca: '', 
            categoria: ''
        });
        let tp = '';
        let ind = 0;
        if (sum.industriaId) {
            tp = 'INDUSTRIA';
            ind = sum.industriaId;
        }
        else if (sum.cnpj) {
            tp = 'ESTABELECIMENTO';
            ind = sum.cnpj;
        }
        else if (sum.redeTsId) {
            tp = 'REDE';
            ind = sum.redeTsId;
        }
        else if (sum.softhouseId) {
            tp = 'SOFTHOUSE';
            ind = sum.softhouseId;
        }
        else if (sum.distribuidorId) {
            tp = 'DISTRIBUIDOR';
            ind = sum.distribuidorId;
        }
        setTipo(tp);
        setId(ind);
        setNome(sum.valor);
        setOpenDetail(true);
    
    };


    const filterDetail = async () => {

        if (filtering) return;
        await timeout(100);
        if (filtering) return;
        setFiltering(true);
        await timeout(100);

        setError(undefined);
        setProcessando(true);
        

        faturamentoHttp.detalhe(fechamentoId, filterItem.redeId, filterItem.industriaId, filterItem.softhouseId, filterItem.distribuidorId, undefined, undefined)
            .then(({ data }) => {

                const lista = [];

                data.forEach(it => {
                    it.desconto = it.descontoTotal;
                    // marca, categoria
                    if (
                        (!filterItem.marca || filterItem.marca === it.marca) && 
                        (!filterItem.categoria || filterItem.categoria === it.categoria)
                       ) {
                        lista.push(it);
                    }
                });


                setRowsDetalhes(lista);

              
                prevCount.current = openCount;
       
                
            })
            .catch((error) => {
                console.log('retorno detalhe error:', error);
                setUrl(faturamentoHttp.getUrl());
                setError(error);
            })
            .finally(() => {
                setFiltering(false);
                setProcessando(false);
            });
    }

    const timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const ExecuteFechamento = async () => {
        if (atual.login !== 'egoncalez') {
            ShowAlert.error('acesso negado');
            return;
        }
        setProcessando(true);
        setError(undefined);
        try {
            const {data} = await faturamentoHttp.executeFechamento(fechamentoId, projAtual.id);
            if (data.sucesso !== true) {
                setUrl(faturamentoHttp.getUrl());
                setError(data);
            }
            else {
                ShowAlert.info(data.mensagem);
                Buscar();
            }
        } catch (error) {
            setUrl(faturamentoHttp.getUrl());
            setError(error);
        } finally {
            setProcessando(false);
        }
    }

    const CancelFechamento = async () => {
        if (atual.login !== 'egoncalez') {
            ShowAlert.error('acesso negado');
            return;
        }
        setProcessando(true);
        setError(undefined);
        try {
            const {data} = await faturamentoHttp.cancelFechamento(fechamentoId, projAtual.id);
            if (data.sucesso !== true) {
                setUrl(faturamentoHttp.getUrl());
                setError(data);
            }
            else {
                ShowAlert.info(data.mensagem);
                Buscar();
            }
            
        } catch (error) {
            setUrl(faturamentoHttp.getUrl());
            setError(error);
        } finally {
            setProcessando(false);
        }
    }

    React.useEffect(() => {
        FillListMesFechamento();
        
    });

    React.useEffect(() => {
        if (openDetail) {
            filterDetail();    
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDetail, filterItem]);



    React.useEffect(() => {
        setOpenDetail(false);
        if (fechamentoId) Buscar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fechamentoId]);

 

    const FiltroDetalhe = () => {
        
    if (openDetail === false) return (<> 
        {atual.login === 'egoncalez' ? 
            <Box sx={{paddingLeft: 2, paddingTop: 3}}>
                <TsButton 
                    onClick={(e) => ExecuteFechamento()}
                >Executar fechamento</TsButton>
                <TsButton 
                    color='alert' 
                    onClick={(e) => CancelFechamento()}
                >Cancelar fechamento</TsButton>
            </Box> : 
            <></>
        } </>);
    else return (   
        <Box sx={{padding: 2, paddingTop: 4, fontSize: '1.5rem'}}>Detalhes de {tipo} , {nome}</Box> );
    }

    const Sumario = () => {
        return (<>
            <Box sx={{display: "flex"}}>
                <Box sx={{paddingLeft: 2, paddingTop: 1.5}}>SUMÁRIO</Box>
                <Box sx={{paddingLeft: 5, paddingTop: 1.5}}></Box>
                <Box sx={{padding: 2, margin: 1, backgroundColor: "#e61e28"}}>
                    <Box sx={{color: "#ddd"}}>Valor Total: <Typography component={"span"} sx={valorText}>R$ {total.toFixed(2)}</Typography></Box>
                </Box>
                <Box sx={{padding: 2, margin: 1, backgroundColor: "#e61e28"}}>
                    <Box sx={{color: "#ddd"}} >Desconto Total: <Typography component={"span"} sx={valorText}>R$ {desconto.toFixed(2)}</Typography></Box>
                </Box>
                {!flagRede &&
                <Box sx={{padding: 2, margin: 1, backgroundColor: "#e61e28"}}>
                    <Box sx={{color: "#ddd"}} >Desconto Zicard: <Typography component={"span"} sx={valorText}>R$ {zicard.toFixed(2)}</Typography></Box>
                </Box> }
            </Box>
            
            <FatGeral
                rows={rows}
                visible={true}
                flagRede={flagRede}
                onOpenDetail={onOpenDetail}
            />
        </>)
    }

    return (
        <Page title="">
            <Box>
                <>
                    <Box sx={{display: 'flex'}}>
                        <Box sx={{paddingLeft: 2, paddingTop: 4.5}}>
                        <Typography sx={mainTitleCSS}>FECHAMENTO</Typography>
                        </Box>
                        <Box sx={{padding: 2}}>
                        <SelectCampo
                            id="select-mes-fechamento"
                            label="Mes Fechamento"
                            name="mes-fechamento"
                            options={lstMesFechamento}
                            value={fechamentoId}
                            onChange={(e) => {
                                setFechamentoId(e.target.value)
                            }}
                        />
                        </Box>
                        <FiltroDetalhe />
                    </Box>
                    { openDetail && fechamentoId ?
                    <FatDetail 
                        rows={rowsDetalhes}
                        fechamentoId={fechamentoId}
                        visible={openDetail}
                        flagRede={flagRede}
                        onClose={() => {setOpenDetail(false)}}
                        id={id}
                        tipo={tipo}
                    />
                    :
                    <Sumario />
                    } 
                </>
                <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
            </Box>
            <TsBackDrop processando={processando} />
        </Page>
    );
    
};

export default Faturamento;