import { httpTsApi } from '.';
import { responseException, responseHttp } from './util-http';
import { getToken, getProxy } from '../auth';
import axios from 'axios';

class HttpProxy {
  // protected resource: string
  constructor() {
    this.proxy = getProxy();
    this.proxy_token = '6735c21fc97c45a58323e210b6d42599';
    this.resource = '';
    this.resourceConfig = 'proxy';
    this.httpConfig = httpTsApi;
    this.url = '';
    this.baseURL = this.proxy.monitor;
  }

  httpProxyApi = () => {
    return axios.create({
      baseURL: this.baseURL,
    });
  };

  setDefaultProxy() {
    const prx = JSON.parse(localStorage.getItem('LISTA_PROXY'));
    const chave = prx[0].nome;
    localStorage.setItem('chaveProxy', chave);
    this.proxy = prx[0];
    this.baseURL = this.proxy.monitor;
    console.log('default proxy:', this.baseURL);
  }

  async obterFechamento(tipo, id, fechamentoId) {
    // https://proxy-hml.trackingsales.com.br/monitor/relatorio-comissao?tipo=rede&id=2000&mes=2024-01
    const mes = fechamentoId.substring(0, 4) + '-' + fechamentoId.substring(4, 6);
    try {
      const config = {
        headers: {
          'x-api-key': 'D1X8WGuXK4q85L2b2wgppFKv/WhfiffwF+aA4/LV',
        },
      };

      this.url = `${this.baseURL}/relatorio-comissao?tipo=${tipo}&id=${id}&mes=${mes}`;
      console.log('URL:' + this.url);
      const ret = await this.httpProxyApi().get(`relatorio-comissao?tipo=${tipo}&id=${id}&mes=${mes}`, config);
      if (ret.status === 200) {
        return { status: ret.status, data: ret.data };
      } else {
        return { status: ret.status, data: `<html><body><div>URL: ${this.url} </div><div> data: ${ret.data}</div></body></html>` };
      }
    } catch (error) {
      if (error.response) {
        return {
          status: error.response.status,
          data: `<html><body><div>URL: ${this.url} </div><div> data: ${error.response.data}</div></body></html>`,
        };
      } else {
        return { status: -1, data: `<html><body><div>URL: ${this.url} </div><div> data: ${error.message}</div></body></html>` };
      }

      // return responseException('consultarStatus', error, 'Falha interna - consultarStatus', { token: this.proxy_token });
    }
  }

  async consultarStatus() {
    try {
      const config = {
        headers: {
          token: this.proxy_token,
        },
      };

      this.url = `httpProxyApi/${this.resource}/status - GET`;
      const ret = await this.httpProxyApi().get(`${this.resource}/status`, config);
      return responseHttp('consultarStatus', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('consultarStatus', error, 'Falha interna - consultarStatus', { token: this.proxy_token });
    }
  }

  async enableProxy() {
    try {
      const config = {
        headers: {
          token: this.proxy_token,
        },
      };

      this.url = `httpProxyApi/${this.resource}/habilitar - GET`;
      const ret = await this.httpProxyApi().get(`${this.resource}/habilitar`, config);
      return responseHttp('enableProxy', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('enableProxy', error, 'Falha interna - enableProxy', { token: this.proxy_token });
    }
  }

  async disableProxy() {
    try {
      const config = {
        headers: {
          token: this.proxy_token,
        },
      };

      this.url = `httpProxyApi/${this.resource}/desabilitar - GET`;
      const ret = await this.httpProxyApi().get(`${this.resource}/desabilitar`, config);
      return responseHttp('disableProxy', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('disableProxy', error, 'Falha interna - disableProxy', { token: this.proxy_token });
    }
  }

  async syncProxy() {
    try {
      const config = {
        headers: {
          token: this.proxy_token,
        },
      };

      this.url = `httpProxyApi/${this.resource}/sincronizar - GET`;
      const ret = await this.httpProxyApi().get(`${this.resource}/sincronizar`, config);
      return responseHttp('syncProxy', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('syncProxy', error, 'Falha interna - syncProxy', { token: this.proxy_token });
    }
  }

  async buscaProduto(projetoId, redeId, texto) {
    try {
      const config = {
        headers: {
          token: this.proxy_token,
        },
      };

      this.url = `httpProxyApi/${this.resource}/busca-produto - POST`;
      const ret = await this.httpProxyApi().post(
        `${this.resource}/busca-produto`,
        { projetoId: projetoId, redeId: redeId, texto: texto },
        config
      );

      return responseHttp('buscaProduto', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('buscaProduto', error, 'Falha interna - buscaProduto', { token: this.proxy_token });
    }
  }

  async buscaCampanha(projetoId, redeId, texto) {
    try {
      const config = {
        headers: {
          token: this.proxy_token,
        },
      };

      this.url = `httpProxyApi/${this.resource}/busca-campanha - POST`;
      const ret = await this.httpProxyApi().post(
        `${this.resource}/busca-campanha`,
        { projetoId: projetoId, redeId: redeId, texto: texto },
        config
      );

      return responseHttp('buscaCampanha', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('buscaCampanha', error, 'Falha interna - buscaCampanha', { token: this.proxy_token });
    }
  }

  async buscaConfig(filtro) {
    try {
      const cToken = getToken();

      const config = {
        headers: {
          Authorization: cToken,
        },
      };

      this.url = `httpConfig/${this.resourceConfig}/busca - POST`;
      const ret = await this.httpConfig.post(`${this.resourceConfig}/busca`, filtro, config);

      if (ret.status === 200)
        return {
          codigoResposta: '00',
          data: ret.data.dados,
          mensagem: ret.statusText,
          mensagemResposta: ret.statusText,
          status: ret.status,
          sucesso: true,
        };
      else
        return {
          codigoResposta: '99',
          data: [],
          mensagem: ret.statusText,
          mensagemResposta: ret.statusText,
          status: ret.status,
          sucesso: false,
        };

      // return responseHttp("buscaConfig", ret);
    } catch (error) {
      console.warn('buscaConfig - erro', error);

      return {
        codigoResposta: '99',
        data: [],
        mensagem: 'EXCEPTION',
        mensagemResposta: error.message,
        status: 500,
        sucesso: false,
      };

      //return responseException("buscaConfig", error, "Falha interna - buscaConfig");
    }
  }

  async reset(proxyConfigId, authToken) {
    try {
      const cToken = getToken();

      const config = {
        headers: {
          Authorization: cToken,
        },
      };

      this.url = `httpConfig/${this.resourceConfig}/reset/{token}/${proxyConfigId} - POST`;
      const ret = await this.httpConfig.get(`${this.resourceConfig}/reset/${authToken}/${proxyConfigId}`, config);

      if (ret.status === 200)
        return {
          codigoResposta: '00',
          data: ret.data.dados,
          mensagem: ret.statusText,
          mensagemResposta: ret.statusText,
          status: ret.status,
          sucesso: true,
        };
      else
        return {
          codigoResposta: '99',
          data: [],
          mensagem: ret.statusText,
          mensagemResposta: ret.statusText,
          status: ret.status,
          sucesso: false,
        };

      // return responseHttp("buscaConfig", ret);
    } catch (error) {
      console.warn('resetConfig - erro', error);

      return {
        codigoResposta: '99',
        data: [],
        mensagem: 'EXCEPTION',
        mensagemResposta: error.message,
        status: 500,
        sucesso: false,
      };

      //return responseException("buscaConfig", error, "Falha interna - buscaConfig");
    }
  }

  async updateConfig(serverId, token, cnpj, rede) {
    try {
      const config = {
        headers: {
          token: this.proxy_token,
        },
      };

      if (!serverId || !token || !cnpj || !rede) {
        return {
          sucesso: false,
          codigoResposta: '99',
          data: undefined,
          mensagem: 'Parametro inválido',
          mensagemResposta: 'Parametro inválido',
          status: 400,
        };
      }

      this.url = `httpProxyApi/${this.resource}/config - POST`;
      const ret = await this.httpProxyApi().post(
        `${this.resource}/config`,
        { serverId: serverId, token: token, cnpj: cnpj, rede: rede },
        config
      );
      return responseHttp('updateConfig', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('updateConfig', error, 'Falha interna - updateConfig', { token: this.proxy_token });
    }
  }

  async zapsignDoc(docId) {
    try {
      const config = {
        headers: {
          token: this.proxy_token,
        },
      };

      // /zapsign/:doc
      this.url = `httpProxyApi/${this.resource}/zapsign/${docId} - GET`;
      const ret = await this.httpProxyApi().get(`${this.resource}/zapsign/${docId}`, config);

      return { status: ret.status, data: ret.data };

      // return responseHttp("buscaConfig", ret);
    } catch (error) {
      if (error.retCod && error.mensagem) return { status: error.retCod, mensagem: error.mensagem, data: error.data };
      else {
        console.log('zapsignDoc ERROR:', error);
        return { status: 900, data: { exception: error.message } };
      }
    }
  }

  getUrl() {
    return this.url;
  }
}

export const httpProxy = new HttpProxy();
