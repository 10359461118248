import * as React from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { TsTextField } from '../../formulario/TsTextField'; 
import redeHttp from '../../util/http/rede-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError'; 
import { getProjetoStorage } from '../../util/auth';
import RedeProdutoFiltro from './rede-produto-filtro';
import RedeDistribuidor from './rede-distribuidor';
import RedeProduto from './rede-produto';
import RedeEstabelecimento from './rede-estabelecimento';
import TsButton from '../../formulario/TsButton';
import ShowAlert from '../../util/ShowAlert';
import ChaveBancaria from '../faturamento/chave-bancaria';
// import { getProjetoStorage, getUser } from '../../util/auth';


let RedeDetail_Processando = false;

const RedeDetail = (props) => {

    const {userLevel, setDetail, detail} = props;
    const projAtual = getProjetoStorage();
    const [executing, setExcecuting] = React.useState(false);
    const [redeData, setRedeData] = React.useState({id: 0, redeNome: 'N/A'});
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    
    const [tabIndex, setTabIndex] = React.useState(0); 

    const setProcessando = (value) => {
        RedeDetail_Processando = value;
        setExcecuting(value);
    }

    const Buscar = async () => {
        if (RedeDetail_Processando === true)
            return;

        setProcessando(true);

        try {
            const {data} = await redeHttp.get(detail.id);
            setProcessando(false);
            setRedeData(data.dado);
            console.log('detalhes da rede:', data.dado);
        } catch (error) {
            setProcessando(false);
            setUrl(redeHttp.getUrl());
            setError(error);
        }
   
    }

    const atualizar = async () => {
        if (RedeDetail_Processando === true) {
            console.log('atualizacao duplicada')
            return;
        }
        ShowAlert.closeSnackbar();    
        const dados = {...redeData};
        if (typeof(dados.comissaoFaturamento) === 'string')
            dados.comissaoFaturamento = Number(dados.comissaoFaturamento);
        
        if (isNaN(dados.comissaoFaturamento)) dados.comissaoFaturamento = 0.00; 
        
        
        if (dados.comissaoFaturamento < 0 || dados.comissaoFaturamento > 0.02) {
            ShowAlert.error('Valor da comissão inválido');
            return;
        }

        setProcessando(true);
        try {
            const retUpdate = await redeHttp.update(detail.id, dados);
            if (retUpdate.data.sucesso === true) {
                ShowAlert.info('item atualizado com sucesso!');
                setDetail({...detail, open: false, id: 0, pageTitle: "Lista de redes"});
            }
            else 
                ShowAlert.error(retUpdate.data.mensagem);
        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        }
        setProcessando(false);
    }

    const handleTabPanel = (event, newValue) => {
        setTabIndex(newValue);
    };

    React.useEffect(() => {
        if (detail.open === true) {
            Buscar();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail])


    return (<>
    
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                <TsTextField
                    name="id"
                    label="Codigo"
                    fullWidth
                    value={redeData.id || ''}
                    disabled={true}
                    
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="rede"
                    label="Rede"
                    fullWidth
                    value={redeData.redeNome || ''}
                    disabled={true}
                    
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="proxy"
                    label="proxy"
                    fullWidth
                    value={redeData.proxyName || ''}
                    disabled={true}
                    
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="nProdutos"
                    label="nro produtos"
                    fullWidth
                    value={redeData.nProdutos || ''}
                    disabled={true}
                    
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="ultimaCarga"
                    label="ultima carga"
                    fullWidth
                    value={redeData.ultimaCarga || ''}
                    disabled={true}
                    
                />
            </Box>
        </Box>
        
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="nomeAdmin"
                    label="Nome Administrador"
                    fullWidth
                    disabled={userLevel > 2 ? false: true}
                    value={redeData.nomeAdmin || ''}
                    onChange={(e) => {setRedeData({...redeData, nomeAdmin: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="emailAdmin"
                    label="E-mail Administrador"
                    fullWidth
                    disabled={userLevel > 2 ? false: true}
                    value={redeData.emailAdmin || ''}
                    onChange={(e) => {setRedeData({...redeData, emailAdmin: e.target.value})}}
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="foneAdmin"
                    label="Celular Administrador"
                    fullWidth
                    disabled={userLevel > 2 ? false: true}
                    value={redeData.foneAdmin || ''}
                    onChange={(e) => {setRedeData({...redeData, foneAdmin: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '200px', paddingRight: 2}}>
                <ChaveBancaria
                    name="chaveBancaria"
                    disabled={userLevel > 2 ? false: true}
                    userLevel={userLevel}
                    value={redeData.chaveBancaria || ''}
                    onChange={(valor) => {setRedeData({...redeData, chaveBancaria: valor})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '200px', paddingRight: 2}}>
                <TsTextField
                    name="comissaoFaturamento"
                    label="Comissão"
                    fullWidth
                    disabled={userLevel > 2 ? false: true}
                    value={redeData.comissaoFaturamento * 100 || '0'}
                    onChange={(e) => {setRedeData({...redeData, comissaoFaturamento: Number(e.target.value) / 100})}}
                />
            </Box>
        </Box>
        <Box>(*) será computado a comissão sobre o percentual menos a comissão paga aos estabelecimentos (máx 2%)</Box>
        <form>
        <Box sx={{display: 'flex'}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}></Box>
            <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                {userLevel > 2 && <TsButton
                    onClick={(e) => atualizar()}
                    
                >Atualizar</TsButton> }
            </Box>
            <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                <TsButton
                    onClick={(e) => setDetail({...detail, open: false, id: 0, pageTitle: "Lista de redes"})}
                    color='secondary'
                >Voltar</TsButton>
            </Box>
        </Box>
        </form>
        
            { userLevel > 2 ?
            <Tabs value={tabIndex} onChange={handleTabPanel} aria-label="Opções da rede">
                <Tab label="Estabelecimentos" id="rede-tab-0" aria-labelledby={`rede-tabpanel-0`} />
                <Tab label="Produtos" id="rede-tab-1" aria-labelledby={`rede-tabpanel-1`} />
                <Tab label="Filtros produtos" id="rede-tab-2" aria-labelledby={`rede-tabpanel-2`} />
                <Tab label="Distribuidor" id="rede-tab-3" aria-labelledby={`rede-tabpanel-3`} />
            </Tabs>
            :
            <Tabs value={tabIndex} onChange={handleTabPanel} aria-label="Opções da rede">
                <Tab label="Estabelecimentos" id="rede-tab-0" aria-labelledby={`rede-tabpanel-0`} />
                <Tab label="Produtos" id="rede-tab-1" aria-labelledby={`rede-tabpanel-1`} />
            </Tabs>
            }

            {tabIndex === 0 && 
            <RedeEstabelecimento
                open={redeData.id > 0}
                detail={detail}
                setDetail={setDetail}
                projAtual={projAtual}
                setError={setError}
                setUrl={setUrl}
                redeNome={redeData.redeNome}
                setProcessando={setProcessando}
                processando={executing}
                userLevel={userLevel}
            />
            }
            {tabIndex === 1 && 
            <RedeProduto
                open={redeData.id > 0}
                detail={detail}
                setDetail={setDetail}
                projAtual={projAtual}
                setError={setError}
                setUrl={setUrl}
                redeNome={redeData.redeNome}
                setProcessando={setProcessando}
                processando={executing}
                userLevel={userLevel}
            />
            }
            {tabIndex === 2 && 
            <RedeProdutoFiltro
                open={redeData.id > 0}
                detail={detail}
                setDetail={setDetail}
                projAtual={projAtual}
                setError={setError}
                setUrl={setUrl}
                redeNome={redeData.redeNome}
                setProcessando={setProcessando}
                processando={executing}
                userLevel={userLevel}
            /> }
            
            {tabIndex === 3 && 
            <RedeDistribuidor 
                open={redeData.id > 0}
                detail={detail}
                setDetail={setDetail}
                projAtual={projAtual}
                setError={setError}
                setUrl={setUrl}
                redeNome={redeData.redeNome}
                setProcessando={setProcessando}
                processando={executing}
                userLevel={userLevel}
            /> }
  
    <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
    <TsBackDrop processando={executing} />

    </>)
}

export default RedeDetail;
