export const listaTipo = [
  { value: 'backoffice', text: 'backoffice' },
  { value: 'api-pos', text: 'api-pos' },
  { value: 'powerbi', text: 'powerbi' },
];

export const listaFiltro = [
  { value: 'rede', text: 'rede' },
  { value: 'cnpj', text: 'cnpj' },
  { value: 'softhouse', text: 'softhouse' },
  { value: 'industria', text: 'industria' },
  { value: 'projeto', text: 'projeto' },
  { value: 'admin', text: 'admin' }, //admin
];

export const permOptions = [
  { value: '', text: '... Selecionar' },
  { value: '0', text: 'Sem permissão' },
  { value: '1', text: 'Leitura' },
  { value: '2', text: 'Gravação' },
  { value: '3', text: 'Avançado' },
  { value: '4', text: 'Administrador' },
];
