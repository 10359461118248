import * as React from 'react';
import { Box, Button, Link } from '@mui/material';
import httpQrcode from '../../util/http/qrcode-http';
import ShowAlert from '../../util/ShowAlert';
import { getUser } from '../../util/auth';
import {TsTextField} from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import TsButton from '../../formulario/TsButton';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TsDataGrid from '../../formulario/TsDataGrid';

const tituloCss = {border: '1px solid #ccc', padding: 1, margin: 1}

const lstStatus = [
    {value: '0', text: 'Todos'},
    {value: '1', text: 'Pendentes'},
    {value: '2', text: 'Erros'},
];
 

const QrCodeAdm_List = (props) => {

    const atual = getUser('admin');
    const token = atual.token;
    const setProcessando = props.setProcessando;
    const setDetail = props.setDetail;
    const detail = props.detail;

    const [cpf, setCpf] = React.useState('0');
    const [cupomStatus, setStatus] = React.useState('1'); // DECLARE @status int = 1 --- 0 - todos, 1 - pendentes, 2 - erros
    const [rows, setRows] = React.useState([]);
    const [serverPage, setServerPage] = React.useState(0);
    const [flagImg, setFlagImg] = React.useState(1);
    const serverPageSize = 50;

    //  id estabelecimento data total.toFixed(2) status status_sefaz
    const columns = [
        {
            name: "id",
            label: "#",
            format: ((value) => (<Link component={Button} onClick={(e) => {openDetail(value)}}><EventNoteIcon /></Link>))
        },
        {
            name: "id",
            label: "id"
        },
        {
            name: "clientId",
            label: "CPF"
        },
        {
            name: "estabelecimento",
            label: "estabelecimento"
        },
        {
            name: "data",
            label: "data"
        },
        {
            name: "total",
            label: "total",
            format: ((value) => <>{value.toFixed(2)}</>)
        },
        {
            name: "tipo",
            label: "tipo"
        },
        {
            name: "status",
            label: "status"
        }
    ];
    

    const readCupons = async () => {
        setProcessando(true);
        try {
            const {data, status} = await httpQrcode.listaCupom(token, cpf, flagImg, cupomStatus, serverPage); // atual.token

            if (status === 200) {
                data.forEach(item => {
                    item.cnpj = item.cnpj === null ? '...pesquisando' : item.cnpj;
                    item.total = item.total === null || !item.total ? 0.00 : item.total;
                    if (item.emitente?.razao_social) {
                        item.estabelecimento = item.emitente.razao_social;
                        if (item.estabelecimento.length > 43) {
                            item.estabelecimento = item.estabelecimento.substring(0, 40) + '...';
                        }
                    }
                    else {
                        item.estabelecimento = `cnpj: ${item.cnpj}`;
                    }
                    
                });
                setRows(data);
            }
            else {
                ShowAlert.error(data.mensagem || 'falha ao obter cupons');
            } 
            setProcessando(false);
        } catch (error) {
            ShowAlert.error(error.message);
        }
    }

    const openDetail = (id) => {
        const item = rows.find(r => r.id + '' === id + '');
        if (item) {
            setDetail({...detail, pageTitle: 'Detalhes do cupom', show: true, id: item.id, clientId: item.clientId});
        }
    }
    
    React.useEffect(() => {
        readCupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cupomStatus, serverPage]);

    return ( 
    <Box>
        <Box sx={tituloCss}>Lista de cupons</Box>
        <Box sx={{display: 'flex'}}>
            <Box sx={{flex: '1 1 auto'}}><TsTextField 
                    name="cpf"
                    label="CPF"
                    sx={{margin: 0.5}}
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value) }
                /></Box>
            <Box sx={{flex: '1 1 auto'}}><SelectCampo
                    id='status_cupom'
                    label='Status'
                    name='status_cupom'
                    options={lstStatus}
                    value={cupomStatus}
                    onChange={(e) => { setStatus(e.target.value)}}
                />
            </Box>
            <Box sx={{padding: 0.5}}>
                    <TsButton variant="contained" onClick={(e) => readCupons() } >Buscar</TsButton>
            </Box>
        </Box>
        <TsDataGrid
                rows={rows}
                columns={columns}
                queryid={''}
                serverPage={serverPage}
                serverPagesize={serverPageSize}
                onChangePage={(value) => setServerPage(value)}
                />
    </Box>
    );
}

export default QrCodeAdm_List;

/*
https://proxy-hml.trackingsales.com.br/ts4qrcode/cupom?page=0&pageSize=2&status=1
{
        "indice": "1",
        "id": 1,
        "clientId": "00583325882",
        "tipo": "CFe",
        "data": "26/04/2024",
        "status_sefaz": "concluido",
        "status": "pesquisando EAN",
        "cnpj": "01438784000601",
        "nf_num": "260790",
        "total": 136.66
    }
*/