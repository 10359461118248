// @flow 
import * as React from 'react';
import { Page } from '../Page';
import { Link } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
/* http */
import { httpProxy } from '../../util/http/proxy-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';
import ShowAlert from '../../util/ShowAlert';

// <TsDataGridx />
class ProxyConfig extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;

        // const [objetoBusca, setObjetoBusca] = React.useState({ page: 0, nrecPage: 100 });

        this.columns = [
            {
                name: "nome",
                label: "Nome"
            },
            {
                name: "redeId",
                label: "redeId"
            },
            {
                name: "serverId",
                label: "serverId"
            },
            {
                name: "token",
                label: "token"
            },
            {
                name: "descricao",
                label: "descricao"
            },
            {
                name: "cnpj",
                label: "cnpj"
            },
            {
                name: "serverUrl",
                label: "URL"
            },
            {
                name: "proxyConfigId",
                label: "reset",
                format: ((value) => (<Link component="button" onClick={() => this.ResetProxy(value)} ><ClearIcon /></Link>))
            }
        ];

    }

    ResetProxy = (proxyConfigId) => {

        const config = this.state.rows.find(c => c.proxyConfigId === proxyConfigId);
        if (config.cnpj === 0 || config.nome === 'MASTER') {
            ShowAlert.error('Proxy MASTER não deve ser resetado');
            return;
        }

        const authToken = config.token;
        this.setRows([]);
        this.setProcessando(true);
        this.setError(undefined);

        httpProxy.reset(proxyConfigId, authToken)
            .then((retorno) => {
                this.setObjBusca({...this.state.objBusca, busca: Math.random() + ''});
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
            });
    }
    
    Buscar = () => {
        this.iniciarBusca();

        httpProxy.buscaConfig({ page: 0, nrecPage: 100 })
            .then((retorno) => {
                if (retorno.sucesso === true && retorno.data !== undefined) {
                    
                    const listaProxy = retorno.data
                        .map(p => {
                            // {"nome": "AWS-DERMACLUB", "url": "http://54.233.209.153:3000/api", "monitor": "http://54.233.209.153:3000/monitor", "rede": "1013"}
                            return {
                                nome: p.nome,
                                url: p.serverUrl,
                                monitor: p.monitorUrl,
                                rede: p.redeId + '',
                                cnpj: p.cnpj,
                                descricao: p.descricao
                            }
                        });
                    localStorage.setItem('LISTA_PROXY', JSON.stringify(listaProxy));
                    this.setRows(retorno.data);
                } else {
                    this.setRows([]);
                    //  mensagem: "Falha ao obter configuracoes" });
                }
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(httpProxy.getUrl());
            });
    }

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
    }

    render() {
        return (
            <Page title="Listar config proxy">
                <TsInputBusca
                    name="input-busca"
                    value={this.state.objBusca.busca}
                    showAtivo={false}
                    showBusca={false}
                    showAdd={false}
                ></TsInputBusca>
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                />
                {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
                <TsBackDrop processando={this.state.processando} />
            </Page>
        );
    }
};

export default ProxyConfig;