import React from "react";

import {Box} from '@mui/material';
// import TsBigNumber from '../../formulario/TsBigNumber'
import { tituloBarra, tituloBarChart, contentBarChar, optionsBar } from "./dash-style";
import { monitorCSS } from "../../style";
import { Chart } from "react-google-charts";

const chartProps = {
    chartType: "BarChart",
    width: "100%",
    height: 360,
    legendToggle: true,
    options: {...optionsBar, title: ""},
}

// Detalhes por Canal, Redes e categorias
const DashRede = (props) => {

    const {redeData, executing} = props;

    return (
    <Box>
        {redeData && <>
        <Box sx={tituloBarra}>Canal</Box>
        <Box className='dash-flex-container' sx={{margin: 1}}>
            <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                <Box sx={tituloBarChart}>Quantidade de Vendas</Box>
                <Box sx={contentBarChar}>
                    <Chart
                        {...chartProps}
                        data={redeData.canal_qtdeVendas}
                    />
                </Box>
            </Box>
            <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                <Box sx={tituloBarChart}>Valor das Vendas (R$)</Box>
                <Box sx={contentBarChar}>
                    <Chart
                         {...chartProps}
                        data={redeData.canal_valorVendas} 
                    />
                </Box>
            </Box>
            <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                <Box sx={tituloBarChart}>Desconto total das Vendas (R$)</Box>
                <Box sx={contentBarChar}>
                    <Chart
                         {...chartProps}
                        data={redeData.canal_descontoVendas} 
                    />
                </Box>
            </Box>
        </Box>
        <Box sx={tituloBarra}>Rede</Box>
        <Box className='dash-flex-container' sx={{margin: 1}}>
            <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                <Box sx={tituloBarChart}>Quantidade de Vendas</Box>
                <Box sx={contentBarChar}>
                    <Chart
                         {...chartProps}
                        data={redeData.rede_qtdeVendas} 
                    />
                </Box>
            </Box>
            <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                <Box sx={tituloBarChart}>Valor das Vendas (R$)</Box>
                <Box sx={contentBarChar}>
                    <Chart
                         {...chartProps}
                        data={redeData.rede_valorVendas} 
                    />
                </Box>
            </Box>
            <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                <Box sx={tituloBarChart}>Desconto total das Vendas (R$)</Box>
                <Box sx={contentBarChar}>
                    <Chart
                         {...chartProps}
                        data={redeData.rede_descontoVendas} 
                    />
                </Box>
            </Box>
        </Box>
        <Box sx={tituloBarra}>Categoria</Box>
        <Box className='dash-flex-container' sx={{margin: 1}}>
            <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                <Box sx={tituloBarChart}>Quantidade de Vendas</Box>
                <Box sx={contentBarChar}>
                    <Chart
                        {...chartProps}
                        data={redeData.cat_qtdeVendas} 
                    />
                </Box>
            </Box>
            <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                <Box sx={tituloBarChart}>Valor das Vendas (R$)</Box>
                <Box sx={contentBarChar}>
                    <Chart
                        {...chartProps}
                        data={redeData.cat_valorVendas} 
                    />
                </Box>
            </Box>
            <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                <Box sx={tituloBarChart}>Desconto total das Vendas (R$)</Box>
                <Box sx={contentBarChar}>
                    <Chart
                        {...chartProps}
                        data={redeData.cat_descontoVendas} 
                    />
                </Box>
            </Box>
        </Box>
        </>}    
    {redeData === undefined 
    ? <Box sx={{minHeight: '300px'}}>...Processando</Box> 
    : <>{props.login === 'egoncalez' && <Box sx={{...monitorCSS, marginTop: 2}}>{JSON.stringify(redeData, null, 2)}</Box>}</>}
    </Box>);
}

export default DashRede;

