import * as React from 'react';
import { Box, Button, Link } from '@mui/material';
import { logHttpBackOffice } from '../../util/http/log-http';

import TsDataGrid from '../../formulario/TsDataGrid';

import EventNoteIcon from '@mui/icons-material/EventNote';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { format, monthsInQuarter, parseISO } from 'date-fns';
import { monitorCSS } from '../../style';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsDialog from '../../formulario/TsDialog';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import SelectCampo from '../../formulario/SelectCampo';
import ListaBase from '../ListaBase';

class ViewLogs extends ListaBase {

  constructor(props) {
    super(props);
    this.serverPageSize = 200;

    this.state.open = false;
    this.state.detalhes = '';
    this.state.objBusca = {busca: '', indexName: 'log_acesso_proxy'};
    this.state.openDica = false;
    this.state.logQuery = {};

    this.columns = [
      {
        name: 'index',
        label: 'ID',
        format: (value) => {
          return (
            <Link component="button" onClick={() => this.openDetail(value)}><EventNoteIcon /></Link>
          );
        },
      },
      {
        name: 'timestamp',
        label: 'DATA',
        format: (value) => {
          return <>{format(parseISO(value), 'dd/MM HH:mm:ss')}</>;
        },
      },
      { name: 'serverHost', label: 'serverHost' },
      { name: 'user', label: 'user' },
      { name: 'url', label: 'url' },
      { name: 'metodo', label: 'metodo' },
      { name: 'statusCode', label: 'statusCode' },
    ];
  }

  setLogQuery(logQuery) {
    this.setState({logQuery});
  }

  setDetalhes(detalhes) {
    this.setState({detalhes});
  }

  setOpen(open) {
    this.setState({open});
  }

  openDetail = (index) => {
    this.setState({detalhes: ''});

    const found = this.state.rows[index];

    // const found = this.state.rows.find((it) => it.id === Id);
    if (found) {
      const item = { ...found };

      try {
        item.request = this.tryParse(item.request);
        item.response = this.tryParse(item.response);
        if (item.request.RequestBody) item.request.RequestBody = this.tryParse(item.request.RequestBody);
        if (item.response.ResponseBody) item.response.ResponseBody = this.tryParse(item.response.ResponseBody);
      } catch (error) {}
      this.setState({detalhes: JSON.stringify(item, null, 2)});
      this.setState({open: true});
    } else {
      this.setState({error: { sucesso: false, mensagem: `ID: ${index} nao encontrado` }});
    }
  };

  tryParse = (data) => {
    if (!data) return {};
    if (typeof data === 'object') return data;

    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  };

  Buscar = async () => {
    try {
      
      this.iniciarBusca();

      const { data } = await logHttpBackOffice.query(this.state.serverPage, this.serverPageSize, this.state.objBusca.indexName, this.state.objBusca.busca);
      this.setProcessando(false);
      this.setBuscando(false);

      if (!data || !data.success) {
        // console.log(data);
        this.setError({ sucesso: false, mensagem: data?.data || 'falha ao processar request' });
        return;
      }

      console.log('logQuery', JSON.parse(data.logQuery));
      this.setLogQuery(JSON.parse(data.logQuery));

      const result = JSON.parse(data.data);

      if (!result?.hits?.hits) {
        this.setError({ sucesso: false, mensagem: 'Nenhum registro encontrado' });
        return;
      }
      const hits = result.hits.hits;

      if (hits.length === 0 && this.state.serverPage > 0) {
        this.setQueryid('' + Math.floor(Math.random() * 1000000000));
        this.setServerPage(0);
        return;
      }

      const dt = hits.map((it, index) => { 
        let user = '';

        if (it._source.user) {
          user = it._source.user;
        }
        else {
          const request = this.tryParse(it._source.request);
          if (request && request.token) {
            const token = this.tryParse(request.token);
            if (token) {
              user = token.USER;
            }
            
          }
          else if (request && request.RequestBody) {
            const reqData = this.tryParse(request.RequestBody);
            if (reqData && reqData.login) {
              user = reqData.login;
            }
          }
        }
        
        return {...it._source, index: index, user: user, url: it._source.url, _index: it._index, id: it._id}; 
      });

      this.setRows(dt);

    } catch (error) {
      this.setBuscando(false);
      this.setProcessando(false);
      if (this.state.serverPage > 0) {
        this.setQueryid('' + Math.floor(Math.random() * 1000000000));
        this.setServerPage(0);
        return;
      }
      this.setUrl(logHttpBackOffice.url);
      console.log(error);
      this.setError(error);
    }
    
    
  };

  buscaTexto(texto) {
    this.setObjBusca({...this.state.objBusca, busca: texto});
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
      super.componentWillUnmount();
      
  }

  componentDidUpdate(prevProps, prevState) {

      super.componentDidUpdate(prevProps, prevState);
      if (this.state.execBuscar === true) this.Buscar();
  }

  render() {
    return (
      <Box padding={4}>
        <Box>
          <TsInputBusca
            name="input-busca"
            value={this.state.objBusca.busca}
            showAtivo={false}
            showBusca={true}
            submitSearch={(texto, ativo) => {
              this.setObjBusca({...this.state.objBusca, busca: texto});
            }}
          >
            <Box sx={{display: "flex"}}>
              <Box sx={{flex: '0 0 auto', paddingTop: 2}}>
                <Link  component={Button} onClick={(e) => this.setState({openDica: !this.state.openDica})}><HelpIcon /></Link>
              </Box>
              <Box sx={{flex: '1 1 auto'}}>
                <SelectCampo
                id="TsInputBusca-Indice"
                label="indexName"
                name="indexName"
                options={[
                  { value: 'log_acesso_proxy', text: 'Proxy LOG' },
                  { value: 'log_transacao', text: 'Autorizador LOG' },
                  { value: 'log_autorizador', text: 'ERROS e DEBUG' },
                  { value: 'log_backoffice', text: 'Back Office'},
                ]}
                value={this.state.objBusca.indexName}
                onChange={(e) => this.setObjBusca({ ...this.state.objBusca,  indexName: e.target.value})}
              />
              </Box>
            </Box>
          </TsInputBusca>
        </Box>
        {this.state.openDica && 
        <Box padding={2} >
          <Box>Metodos:
            &nbsp; | &nbsp; <Link component="button" onClick={(e) => this.buscaTexto('entrar')}>entrar</Link> 
            &nbsp; | &nbsp; <Link component="button" onClick={(e) => this.buscaTexto('carga_produtos')}>carga_produtos</Link> 
            &nbsp; | &nbsp; <Link component="button" onClick={(e) => this.buscaTexto('consultar_desconto')}>consultar_desconto</Link>
            &nbsp; | &nbsp; <Link component="button" onClick={(e) => this.buscaTexto('consultar_identificacao')}>consultar_identificacao</Link>
            &nbsp; | &nbsp; <Link component="button" onClick={(e) => this.buscaTexto('registrar_descontos')}>registrar_descontos</Link>
            &nbsp; | &nbsp; <Link component="button" onClick={(e) => this.buscaTexto('atualizar_status_transacao')}>atualizar_status_transacao</Link>
          </Box>
          <Box>Busca multipla PROD: (texto) AND (texto)</Box>
          <Box>Busca multipla HML: "texto" "texto"</Box>
        </Box>}
        <TsDataGrid
          rows={this.state.rows}
          columns={this.columns}
          queryid={this.state.queryid}
          serverPage={this.state.serverPage}
          serverPagesize={this.serverPageSize}
          onChangePage={(value) => this.setServerPage(value)}
        />
        {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
  
        <TsDialog
          onClose={() => {
            this.setDetalhes('');
            this.setState({ open: false});
          }}
          open={this.state.open}
          minWidth={960}
          title="Detalhes do log"
          id="dialog-detalhe-log"
        >
          <Box sx={monitorCSS}>{this.state.detalhes}</Box>
        </TsDialog>
        <TsBackDrop processando={this.state.processando} />
        {/*<Box sx={monitorCSS}>{JSON.stringify(this.state.logQuery, null, 2)}</Box>*/}
      </Box>
    );
  }
}

export default ViewLogs;
