import * as React from 'react';
import { Box } from '@mui/material';
import { TsTextField } from '../../formulario/TsTextField';  // '../formulario/TsTextField';
import TsButton from '../../formulario/TsButton';
import TsBackDrop from '../../formulario/TsBackDrop';
import httpQrcode from '../../util/http/qrcode-http';
import ShowAlert from '../../util/ShowAlert';
 

import { usuarioNull } from '../../util/auth-qrcode';
import {formatISO} from 'date-fns';

const QrCode_Login = (props) => {
    const setUser = props.setUser;

    const [login, setLogin] = React.useState('');
    const [cpf, setCpf] = React.useState(''); 
    const [senha, setSenha] = React.useState('');
    const [msgLogin, setMsgLogin] = React.useState('');
    const [processando, setProcessando] = React.useState(false);
    const [error, setError] = React.useState(undefined);
   

    const efetuarLogin = async () => {
        console.log('efetuando login');
        setProcessando(true);
        setError(false);
         
        try {
          const response = await httpQrcode.entrar(login, senha);

          console.log('response:', response);
          
          if (!response.data.token) {
            setProcessando(false);
            setLogin('');
            setCpf('');
            setSenha('');
            setUser(usuarioNull);
            setError(true);
            setMsgLogin('Usuario ou senha inválidos ...');
            ShowAlert.error(response.data.mensagem || 'Usuario ou senha inválidos');
            return;
          }
         
          /*
          {
                sucesso: true,
                id: cliente.id,
                nome: cliente.nome,
                sobrenome: cliente.sobrenome,
                email: cliente.email,
                documento: cliente.documento,
                token: token
            }
          */

          const agora = new Date();
          agora.setMinutes(agora.getMinutes() + 100);
          const expires = formatISO(agora);
          
          const retUser = {
            login: response.data.login,
            cpf: cpf,
            token: response.data.token,
            expires 
          };
    
          setUser(retUser);
          setLogin('');
          setCpf('');
          setSenha('');
          setError(false);
          setMsgLogin('Login com sucesso ...');

    
          
        } catch (err) {
          console.log('error:', err);
          setError(true);
          setProcessando(false);
          setLogin('');
          setCpf('');
          setSenha('');
          setUser(usuarioNull);
          setError(true);
          setMsgLogin('Erro ao efetuar login ...');
          ShowAlert.error('Erro ao efetuar LOGIN');
        }
      };

    return (
        <>
        <form>
          <TsTextField
            label="Login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            error={error}
            helperText={msgLogin}
          />
          <TsTextField label="Senha" value={senha} type="password" onChange={(e) => setSenha(e.target.value)} />
          <TsTextField
            label="CPF"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          />
          <Box sx={{display: "flex"}} paddingTop={4}>
            <Box sx={{flex: "8 1 auto"}}>
              
            </Box>
            <Box sx={{flex: "1 0 auto"}} dir="rtl" >
              <TsButton onClick={(e) => efetuarLogin()}>Login</TsButton>
            </Box>
          </Box>
        </form>
        
        <TsBackDrop processando={processando} />
      </>

    )
}

export default QrCode_Login;