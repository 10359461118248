
import * as React from 'react';
import { Link} from '@mui/material';
import TsDataGrid from '../../formulario/TsDataGrid';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ListaBase from '../ListaBase';


class FatGeral extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;
        this.rows = props.rows;
        this.visible = props.visible;
        this.onOpenDetail = props.onOpenDetail;

        this.columns = [
            {
                name: "id", label: "",
                format: ((value) => (value === -1 ? <></>: <Link component="button" onClick={() => this.onOpenDetail(value)}><EventNoteIcon /></Link>))
            },
            { name: "chave", label: "Escopo"},
            { name: "valor", label: "Entidade"},
            { name: "totalVendido", label: "Total Vendido", format: ((value) => (value.toFixed(2)))},
            { name: "totalDesconto", label: "Total Desconto", format: ((value) => (value.toFixed(2)))},
            { name: "zicardDesconto", label: "Desconto Zicard", format: ((value) => (value.toFixed(2))) }
        ];

        this.columnsRede = [
            {
                name: "id", label: "",
                format: ((value) => (value === -1 ? <></>: <Link component="button" onClick={() => this.onOpenDetail(value)}><EventNoteIcon /></Link>))
            },
            { name: "chave", label: "Escopo"},
            { name: "valor", label: "Entidade"},
            { name: "totalVendido", label: "Total Vendido", format: ((value) => (value.toFixed(2)))},
            { name: "totalDesconto", label: "Total Desconto", format: ((value) => (value.toFixed(2)))}
        ];

    }

    render() {
        if (!this.visible) return null;
        else 
        return (<>
            <TsDataGrid
                    rows={this.rows}
                    columns={this.props.flagRede ? this.columnsRede : this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                    pageSize={50}
                />
        </>);
    }

    

}

export default FatGeral;