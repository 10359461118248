const listaSituacoes = [
  { text: 'NOVO', value: 'NEW' },
  { text: 'EXECUTANDO', value: 'DOING' },
  { text: 'PENDENTE', value: 'PENDING' },
  { text: 'PARCIAL', value: 'PARTIAL' },
  { text: 'CANCELADO', value: 'CANCELED' },
  { text: 'PARADO', value: 'STOPPED' },
  { text: 'FINALIZADO', value: 'ENDDED' },
];

export default listaSituacoes;
