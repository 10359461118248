import * as React from 'react';
import { Box, Button } from '@mui/material';
import TsBackDrop from '../../formulario/TsBackDrop';
import httpQrcode from '../../util/http/qrcode-http';
import ShowAlert from '../../util/ShowAlert';
import {monitorCSS} from '../../style';
import BackIcon from '@mui/icons-material/ArrowBack';
import {TsTextField} from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import TsButton from '../../formulario/TsButton';

const detailCss = {
    fontSize: '9pt', color: '#777'
};
const detailCssSmall = {
    fontSize: '8pt', color: '#777'
}

const tituloCss = {border: '1px solid #ccc', padding: 1, margin: 1}

const listaStatusUsuario = [
    {text: '... selecione', value: '0'},
    {text: 'Não pertence ao programa', value: '1'},
    {text: 'O EAN está correto', value: '2'},
    {text: 'Quero informar o EAN', value: '3'},
    {text: 'Desconheço a informação', value: '4'},
    {text: 'EAN em analize pelo sistema', value: '5'}
];

const QrCode_Cupons = (props) => {
    const atual = props.atual;
    const [rows, setRows] = React.useState([]);
    const [processando, setProcessando] = React.useState(false);
    const [cupom, setCupom] = React.useState({itens: []});
    const [cupomItem, setCupomItem] = React.useState({usuarioStatusId: '0'});
    const [detail, setDetail] = React.useState(false);
    const [itemDetail, setItemDetail] = React.useState(false);
    const [itemEan, setItemEan] = React.useState('');

    const readCupons = async () => {
        setProcessando(true);
        try {
            const {data, status} = await httpQrcode.listaCupom(atual.token, atual.cpf); // atual.token

            if (status === 200) {
                data.forEach(item => {
                    item.cnpj = item.cnpj === null ? '...pesquisando' : item.cnpj;
                    item.total = item.total === null || !item.total ? 0.00 : item.total;
                    if (item.emitente?.razao_social) {
                        item.estabelecimento = item.emitente.razao_social;
                        if (item.estabelecimento.length > 43) {
                            item.estabelecimento = item.estabelecimento.substring(0, 40) + '...';
                        }
                    }
                    else {
                        item.estabelecimento = `cnpj: ${item.cnpj}`;
                    }
                    
                });
                setRows(data);
            }
            else {
                ShowAlert.error(data.mensagem || 'falha ao obter cupons');
            } 
            setProcessando(false);
        } catch (error) {
            ShowAlert.error(error.message);
        }
    }
    
    const openDetail = async (id) => {
        setProcessando(true);
        setCupom({itens: []});
        setCupomItem({});
        setItemDetail(false);
        setItemEan('');
        try {
            const {data, status} = await httpQrcode.obterCupom(atual.token, id, atual.cpf);

            if (status === 200) {
                setDetail(true)
                setCupom(data);
            }
            else {
                ShowAlert.error(data.mensagem || 'falha ao obter cupom');
            } 
            setProcessando(false);
            
        } catch (error) {
            
        }
    }

    const openDetailItem = (id) => {
        if (id !== undefined) {
            const item = cupom.itens[id];
            if (item !== undefined) {
                item.usuarioStatusId = item.usuarioStatusId + '';
                let ean = item.ean || '';
                if (!ean) ean = item.eanRede; 
                setItemEan(ean);
                setCupomItem(item);
                setItemDetail(true);
            }
        } 
    }

    const sendEanStatus = async () => {
        setProcessando(true);
        try {
            const {data, status} = 
            await httpQrcode.sendEanStatus(atual.token, cupomItem.usuarioStatusId, itemEan, cupomItem.id, atual.cpf );

            setProcessando(false);
            if (status === 200) {
                ShowAlert.info('atualizado com sucesso');
                openDetail(cupom.id);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
        } catch (error) {
            setProcessando(false);
            ShowAlert.error(error.message);
        }
    }

    React.useEffect(() => {
        readCupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (cupom?.itens?.length === 0) {
            openDetailItem(0);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cupom]);



    return (<>
    {detail ?
    <Box> 
        { /* <Box sx={monitorCSS}>{JSON.stringify(cupom, null, 4)}</Box> */ }
        <Button variant='outlined' onClick={(e) => setDetail(false)} ><BackIcon /> Lista cupons</Button>
        <Box sx={tituloCss}>Detalhes do Cupom # {cupom.id}</Box>
        <Box sx={detailCss}>data: {cupom.data}</Box>
        <Box sx={detailCss}>sefaz: {cupom.status_sefaz}</Box>
        <Box sx={detailCss}>status: {cupom.status}</Box>
        <Box sx={detailCss}>emitente: {cupom.emitente ? cupom.emitente.razao_social : cupom.cnpj}</Box>
        <Box sx={detailCss}>nota fiscal: {cupom.nf_num}</Box>
        {itemDetail ? 
        <>
            <Button variant="outlined" onClick={(e) => setItemDetail(false)} ><BackIcon /> Lista itens</Button>
            <Box sx={monitorCSS}>{JSON.stringify(cupomItem, null, 2)}</Box>
            <SelectCampo
                    id='status_usuario'
                    label='Status segundo o usuario'
                    name='status_usuario'
                    disabled={cupomItem.usuarioStatusComplete}
                    options={listaStatusUsuario}
                    value={cupomItem.usuarioStatusId || '0'}
                    onChange={(e) => {
                        setCupomItem({...cupomItem, usuarioStatusId: e.target.value});
                    }}
                />
                {/*    */}
            {!cupomItem.usuarioStatusComplete  && 
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto'}}>
                <TsTextField 
                    name="itemEan"
                    label="EAN"
                    disabled={cupomItem.usuarioStatusId !== '3'}
                    sx={{margin: 0.5}}
                    value={itemEan}
                    onChange={(e) => setItemEan(e.target.value) }
                />
                </Box>
                <Box sx={{padding: 0.5}}>
                    <TsButton variant="contained" onClick={(e) => sendEanStatus() } >Enviar</TsButton>
                </Box>
            </Box>
            }
            
        </> 
        : 
        <>
        <Box  sx={tituloCss}>Itens (selecione para mais detalhes)</Box>
        <hr></hr>
        {cupom?.itens?.length > 0 &&  cupom.itens.map((item, index) =>
            <Box
            onClick={(e) => openDetailItem(index)} 
            key={`item-${item.id}`} 
            sx={{marginTop: 1,
                cursor: 'pointer', 
                '&:hover': {backgroundColor: '#ddddff'}
            }}>
                <Box sx={detailCssSmall}>{item.codigoRede}:{item.nome}</Box>
                <Box sx={detailCss}>analise usuario:{item.usuarioStatus}</Box>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{...detailCss, flex: '1 1 auto'}}>ean: {item.ean || 'Indeterminado'}</Box>
                    <Box sx={{...detailCss, flex: '1 1 auto', textAlign: 'right'}}>[status:{item.status}]</Box>
                </Box>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{...detailCss, flex: '1 1 auto'}}>qtde: {item.quantidade}</Box>
                    <Box sx={{...detailCss, flex: '1 1 auto', textAlign: 'right'}}>Total: {item.total}</Box>
                </Box>
                <hr></hr>
            </Box>
        )}
        </>}
    </Box>
    : 
    <Box>
        <Box sx={tituloCss}>Lista de cupons</Box>
        {rows.map((item, index) => 
        <Box
            onClick={(e) => openDetail(item.id)} 
            key={`cupom-${index}`} 
            sx={{marginTop: 1,
                cursor: 'pointer', 
                '&:hover': {backgroundColor: '#ddddff'}
            }}
        >   
            <Box sx={{...detailCss}}>{item.estabelecimento}</Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{...detailCss, flex: '1 1 auto'}}>{item.data}</Box>
                <Box sx={{...detailCss, flex: '1 1 auto', textAlign: 'right'}}>valor:{item.total.toFixed(2)}</Box>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{...detailCss, flex: '1 1 auto'}}>STATUS:{item.status}</Box>
                <Box sx={{...detailCss, flex: '1 1 auto', textAlign: 'right'}}>SEFAZ:{item.status_sefaz}</Box>
            </Box>
            <hr></hr>
        </Box>)}
    </Box>
    }
    <TsBackDrop processando={processando} />
    </>
    );
}

export default QrCode_Cupons;

/*
https://proxy-hml.trackingsales.com.br/ts4qrcode/cupom?page=0&pageSize=2&status=1
{
        "indice": "1",
        "id": 1,
        "clientId": "00583325882",
        "tipo": "CFe",
        "data": "26/04/2024",
        "status_sefaz": "concluido",
        "status": "pesquisando EAN",
        "cnpj": "01438784000601",
        "nf_num": "260790",
        "total": 136.66
    }
*/