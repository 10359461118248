import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class GestaoDescontoHttp extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.http = http;
    this.resource = resource;
  }

  obterDescontos(redeId, mes, uf, projetoId) {
    this.url = `/${this.resource} - GET`;
    return this.http.get(`${this.resource}?redeId=${redeId}&mes=${mes}&uf=${uf}&projetoId=${projetoId}`, this.Config());
  }

  obterCampanhaDescontos(redeId, mes, uf, projetoId) {
    this.url = `/${this.resource}/campanhas - GET`;
    return this.http.get(`${this.resource}/campanhas?redeId=${redeId}&mes=${mes}&uf=${uf}&projetoId=${projetoId}`, this.Config());
  }

  obterDescontoProduto(id) {
    this.url = `/${this.resource}/produto/${id} - GET`;
    return this.http.get(`${this.resource}/produto/${id}`, this.Config());
  }

  obterDescontoCampanha(id) {
    this.url = `/${this.resource}/campanha/${id} - GET`;
    return this.http.get(`${this.resource}/campanha/${id}`, this.Config());
  }

  obterDescontoOferta(id, campanhaId) {
    this.url = `/${this.resource}/campanha/${campanhaId}/oferta/${id} - GET`;
    return this.http.get(`${this.resource}/campanha/${campanhaId}/oferta/${id}`, this.Config());
  }

  atualizaDescontoCampanha(data) {
    this.url = `/${this.resource}/campanha - POST`;
    return this.http.post(`${this.resource}/campanha`, data, this.Config());
  }

  atualizaDescontoProduto(data) {
    this.url = `/${this.resource}/produto - POST`;
    return this.http.post(`${this.resource}/produto`, data, this.Config());
  }

  atualizaDescontoOferta(id, data) {
    this.url = `/${this.resource}/campanha/${id}/oferta - POST`;
    return this.http.post(`${this.resource}/campanha/${id}/oferta`, data, this.Config());
  }

  deleteProduto(produtoDescontoId, campanhaId, ofertaId) {
    this.url = `/${this.resource}/campanha/${campanhaId}/oferta/${ofertaId}/produto/${produtoDescontoId} - DELETE`;
    return this.http.delete(
      `${this.resource}/campanha/${campanhaId}/oferta/${ofertaId}/produto/${produtoDescontoId}`,
      this.Config()
    );
  }
}

const gestaoDescontoHttp = new GestaoDescontoHttp(httpTsApi, 'gestaodesconto');

export default gestaoDescontoHttp;
