import { validarForm } from './CampoBase';

export const TipoPontoDefault = {
  id: 0,
  descricao: '',
  formulaUnitario: '',
  formulaTotal: '',
};

export const TipoPontoCampos = {
  descricao: { name: 'descricao', label: 'Descrição', tipo: 'string', max: 150, min: 1 },
  formulaUnitario: { name: 'formulaUnitario', label: 'Fórmula Unitário', tipo: 'string', max: 2048, min: 1 },
  formulaTotal: { name: 'formulaTotal', label: 'Fórmula Total', tipo: 'string', max: 2048, min: 1 },
};

export const validarTipoPonto = (tipoPonto) => {
  return validarForm(tipoPonto, TipoPontoCampos);
};
