import { parseISO } from 'date-fns';

export const TOKEN_KEY_QRCODE = '@qrcode-Token';

export const HOMEDIR = process.env.REACT_APP_HOMEDIR || '';

export const isAuthenticatedQrcode = () => {
  const token = getTokenQrcode();
  if (!token) return false;
  return true;
};

export const getTokenQrcode = () => {
  const user = getUserQrCode();
  if (user && user.token) return user.token;
  else return '';
};

export const usuarioNull = {
  login: '',
  cpf: '',
  token: '',
};

export const getUserQrCode = () => {
  const json = localStorage.getItem(TOKEN_KEY_QRCODE);
  if (json) {
    try {
      const user = JSON.parse(json);
      if (!user || !user.token || !user.expires) {
        localStorage.removeItem(TOKEN_KEY_QRCODE);
        return usuarioNull;
      }

      const agora = new Date();
      const expires = parseISO(user.expires);

      const delta = (expires.valueOf() - agora.valueOf()) / (1000 * 60);

      if (delta <= 1 || delta > 121) {
        localStorage.removeItem(TOKEN_KEY_QRCODE);
        return usuarioNull;
      }

      return user;
    } catch (error) {
      localStorage.removeItem(TOKEN_KEY_QRCODE);
      return usuarioNull;
    }
  } else {
    //logTimeout("TOKEN INEXISTENTE EM localStorage");
    return usuarioNull;
  }
};

export const setUserQrCode = (usuario) => {
  if (usuario.token) localStorage.setItem(TOKEN_KEY_QRCODE, JSON.stringify(usuario));
  else localStorage.removeItem(TOKEN_KEY_QRCODE);
};
