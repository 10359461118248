// @flow 
import * as React from 'react';
import { Box, Fab} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// type Props = {
//     title: string,
//     onClick: (e: any) => void
// };
const AddButtonEvent = (props) => {
    return (
        <Box sx={{flex: "0 0 auto", paddingTop: 0.5, paddingLeft: 0.5}}>
            <Fab
                title={props.title}
                color='alert'
                size="small"
                onClick={props.onClick}
            > 
                <AddIcon></AddIcon>
            </Fab>
        </Box>
    );
};

export default AddButtonEvent;