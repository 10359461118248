/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Box, Fab, IconButton, InputAdornment, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TsButton from '../../formulario/TsButton';
import TsDialog from '../../formulario/TsDialog';
import TsDataGrid from '../../formulario/TsDataGrid';
import { TsTextField } from '../../formulario/TsTextField';
import SearchIcon from '@mui/icons-material/Search';
import { getProjetoStorage } from '../../util/auth';
import ProdutoBuscarDialog from '../proxy/table-produto';

import produtoHttp from '../../util/http/produto-http';

const titleCSS = {
    background: "#000099",
    color: "#ccc",
    padding: "10px",
    textAlign: "center",
    margin: "4px"
};


const CestaProdutos = (props) => {

    const {pedido, setPedido, showInfo} = props; // 
    const projeto = getProjetoStorage();
    
    const [itemIndex, setItemIndex] = React.useState(0);
    const [openAddProduto, setOpenAddProduto] = React.useState(false);
    //const [rows, setRows] = React.useState([]);
    const [produto, setProduto] = React.useState("");
    const [produtoPreco, setProdutoPreco] = React.useState("");
    const [prodDescr, setProdDescr] = React.useState("");
    const [openBuscaProduto, setOpenBuscaProduto] = React.useState(false);
    const [itemError, setItemError] = React.useState("");
 
    const [alterProduto, setAlterProduto] = React.useState({});
    const [openAlterProduto, setOpenAlterProduto] = React.useState(false);


    const selecionaProduto = async (value, preco, nome) => {
        setOpenBuscaProduto(false);
        setOpenAddProduto(true);
        setProduto(value);
    }

    const changeProduto = async () => {

        if (!produto) {
            return;
        }

        if (!pedido.cnpj) {
            showInfo('pop-error', `CNPJ não especificado`);
            return;
        }
        showInfo('processando', true);
        setProdDescr("");
        setProdutoPreco("");
        var prod = await obterProduto(produto, pedido.cnpj);

        if (prod === undefined) {
            showInfo('pop-error', `produto ${produto}, não encontrado`);
            showInfo('processando', false);
            return;
        }

        setProdDescr(prod.produtoNome);
        if (prod !== undefined && prod.preco) {
            setProdutoPreco((prod.preco).toFixed(2));
        }
        showInfo('processando', false);
    }

    const addItem = async () => {

        showInfo('cls', '');
        showInfo('debug', "... A CESTA DEVE SER VALIDADA CHAMANDO CONSULTAR DESCONTO");

        showInfo('processando', true);
        setItemError("");
        const codigo = (document.getElementById('simulador-add-codigo')).value;
        const qtde = (document.getElementById('simulador-add-qtde')).value;
        const valor = (document.getElementById('simulador-add-valor')).value;

        if (!codigo || !qtde || !valor) {
            setItemError("Preencha os valores corretamente");
            showInfo('processando', false);
            return;
        }

        const nQtde = parseFloat(qtde);

        if (isNaN(nQtde)) {
            setItemError("Quantidade , formato incorreto");
            showInfo('processando', false);
            return;
        }

        let nValor = parseFloat(valor);

        if (isNaN(nValor)) {
            setItemError("Valor , formato incorreto");
            showInfo('processando', false);
            return;
        }

        addItemRetorno(codigo, prodDescr, nQtde, nValor);
        setOpenAddProduto(false);

        setProduto("");
        setProdutoPreco("");
        setProdDescr("");
        showInfo('processando', false);


    }

    const columns = [
        {
            name: "id", label: "x",
            // format : (value) => (<Fab color='alert' size="small" aria-label="edit" onClick={(e) => deleteItem('{value}')}><CheckCircleOutlineOutlinedIcon sx={{ color: '#090' }} /></Fab>)
            format: (value) => (  <IconButton onClick={(e) => editItem(value)}>{pedido.cestaValida ? <CheckCircleOutlineOutlinedIcon sx={{ color: '#090' }} /> : <WarningOutlinedIcon sx={{ color: '#fc0303' }} />}</IconButton> )
        },
        { name: "codigo", label: "Código"
        },
        { name: "produto", label: "Produto" },
        {
            name: "qtde", label: "Qtde" 
        },
        {
            name: "qtdeAprovada", label: "Aprovada"
        },
        {
            name: "valorUtitario", label: "Unitário",
            format: (value) => { return (value).toFixed(2) }
        },
        {
            name: "valorTotal", label: "Valor",
            format: (value) => { return (value).toFixed(2) }
        },
        {
            name: "desconto", label: "Desconto unidade",
            format: (value) => { return pedido.cestaValida ? (value).toFixed(2) : "???" }
        },
        {
            name: "codigo", label: "x",
            format: (value) => { return <Fab color='alert' size="small" aria-label="delete" onClick={(e) => deleteItem(value)}><DeleteForeverIcon /></Fab> }
        }
    ]

    const editItem = (id) => {
        const item = pedido.itens.find(item => item.id === parseInt(id));
        if (item !== undefined) {
            setAlterProduto(item);
        }
        setOpenAlterProduto(true);
    }

    const alterItem = () => {
        const ped = {...pedido};
        try {
            const index = alterProduto.id;
            ped.itens[index].desconto = parseFloat(alterProduto.desconto + '');
            ped.itens[index].qtdeAprovada = parseFloat(alterProduto.qtdeAprovada + '');
            ped.cestaValida = true;
            setPedido(ped);
            setOpenAlterProduto(false);
        } catch (error) {
            window.alert('Erro ao alterar item, verifique console');
            console.log('Erro ao alterar item', error);
        }
        

    }

    const deleteItem = (codigo) => {
        const ped = {...pedido};
        ped.itens = ped.itens.filter(it => it.codigo !== codigo);
        ped.cestaValida = false;
        if (ped.tabIndex === 4) {
            ped.tabIndex = 3;
        }
        setItemIndex(itemIndex + 1);
        setPedido(ped);
         
    }

    const obterProduto = async (codigo, cnpj) => {

        if (!codigo || !cnpj) {
            return undefined;
        }

        const nCnpj = obterCnpjNum(cnpj);

        try {
            const prod = await produtoHttp.obter(codigo, nCnpj, parseInt(projeto.id));
           return prod.data;
        }
        catch (error) {
            return undefined;
        }

    }

    const addItemRetorno = (codigo, nome, nQtde, nValor) => {
        let tempItem = pedido.itens;
        let id = itemIndex === undefined ? 0 : itemIndex;

        tempItem.push({id, codigo: codigo, produto: nome, qtde: nQtde, qtdeAprovada: 0, valorUtitario: nValor, valorTotal: nValor * nQtde, desconto: 0, campanhaId: 0, mensagemItem: "" });
        setItemIndex(id++);

        setPedido(pedido => ({...pedido, itens: tempItem, cestaValida: false}));

        (document.getElementById('simulador-add-codigo')).value = "";
        (document.getElementById('simulador-add-qtde')).value = "";
        (document.getElementById('simulador-add-valor')).value = "";

    }

    React.useEffect(() => {
        changeProduto();
    }, [produto]);

    const obterCnpjNum = (cnpj) => {
        let nCnpj = cnpj;
        if (typeof cnpj === 'string') {
          nCnpj = parseFloat(pedido.cnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''));
        }
        return nCnpj;
      };
    
 return (<>
    <Box>
        <Box sx={titleCSS}>CESTA PRODUTOS</Box>

        <Box>

            <Box sx={{ display: "flex" }}>
                <TsButton
                    sx={{ display: "inline-flex" }}
                    size="small"
                    variant="outlined"
                    color='secondary'
                    disabled={pedido.itens.length > 0 ? false : true}
                    onClick={(e) => {
                        const tabIndex = pedido.tabIndex === 4 ? 3: pedido.tabIndex;
                        setPedido(pedido => ({ ...pedido, itens: [], cestaValida: false, tabIndex: tabIndex }));
                    }}
                >Limpar</TsButton>
                <TsButton
                    sx={{ display: "inline-flex" }}
                    size="small"
                    variant="outlined"
                    onClick={(e) => {
                        setPedido(pedido => ({ ...pedido, cestaValida: false }));
                        setOpenAddProduto(true);
                        if (pedido.tabIndex === 4) {
                            setPedido(pedido => ({...pedido, tabIndex: 3}));
                        }
                    }}
                >Adicionar</TsButton>
            </Box>
            <TsDataGrid
                rows={pedido.itens}
                columns={columns}
                queryid={Math.random() + ''}
                showPagging={false}
                serverPage={0}
                serverPagesize={20}
                onChangePage={(value) => {}}
            />
        </Box>
    </Box>
    <TsDialog id="dialog-alter-item-cesta" minWidth={800} onClose={() => setOpenAlterProduto(false)} open={openAlterProduto} title='Alterar item na cesta'>
        <Box sx={{ display: "flex" }}>
            <Box sx={{margin: 1, padding: 2, border: "1px solid #555", fontSize: "12px"}} >Codigo: <br /> {alterProduto.codigo}</Box>
            <Box sx={{margin: 1, padding: 2, border: "1px solid #555", fontSize: "12px", flex: "1 8 auto"}} >Produto: <br /> {alterProduto.produto}</Box>
            <Box sx={{margin: 1, padding: 2, border: "1px solid #555", fontSize: "12px"}} >Qtde: <br /> {alterProduto.qtde}</Box>
        </Box>
        <Box sx={{ display: "flex" }}>
            <TsTextField
                id="simulador-alter-item-id"
                label="Qtde Aprovada"
                variant="standard"
                value={alterProduto.qtdeAprovada}
                onChange={(e) => setAlterProduto({...alterProduto, qtdeAprovada: e.target.value})}
                sx={{ width: 80 }}
                fullWidth={false} />
            <TsTextField
                id="simulador-alter-item-id"
                label="Desconto"
                variant="standard"
                value={alterProduto.desconto}
                onChange={(e) => setAlterProduto({...alterProduto, desconto: e.target.value })}
                sx={{ width: 80 }}
                fullWidth={false} />
            <Box sx={{flex: "1 8 auto"}}></Box>
            <TsButton
                onClick={(e) => alterItem()}
            >Alterar</TsButton>
        </Box>
    </TsDialog>
    <TsDialog id="dialog-add-item-cesta" minWidth={800} onClose={() => setOpenAddProduto(false)} open={openAddProduto} title='Adicionar produto na cesta'>
        <Box sx={{ display: "flex" }}>

            <TsTextField
                id="simulador-add-codigo"
                label="codigo"
                variant="standard"
                sx={{ minWidth: 140, flex: '1 1 auto', padding: 1 }}
                value={produto}
                fullWidth={false}
                onChange={(e) => {
                    const valor = e.target.value;
                    setProduto(valor);
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={(e) => {
                                setOpenAddProduto(false);
                                setOpenBuscaProduto(true);
                            }}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <TsTextField
                id="simulador-add-qtde"
                label="quantidade"
                variant="standard"
                sx={{ minWidth: 80, flex: '1 1 auto', padding: 1 }}
                fullWidth={false} />
            <TsTextField
                id="simulador-add-valor"
                label="valor"
                variant="standard"
                sx={{ minWidth: 100, flex: '1 1 auto', padding: 1 }}
                fullWidth={false}
                onChange={(e) => setProdutoPreco(e.target.value)}
                value={produtoPreco} />
            <TsButton
                onClick={(e) => addItem()}
            >Adicionar</TsButton>
        </Box>
        <Box><Typography>{itemError}</Typography></Box>
    </TsDialog>
    <TsDialog onClose={() => {setOpenAddProduto(true);setOpenBuscaProduto(false);}} id="dialog-busca-produto" open={openBuscaProduto} title="Busca de produto" minWidth={650} >
        {openBuscaProduto ? <ProdutoBuscarDialog
            onSelect={(value, preco, nome) => selecionaProduto(value, preco, nome)}
            redeId={pedido.redeId}
            projetoId={projeto.id}
             /> : null}
    </TsDialog>
    
    
</>);
}

export default CestaProdutos;