import * as React from 'react';
import segmentacaoHttp from '../../util/http/segmentacao-http'; 
import TsDataGrid from '../../formulario/TsDataGrid';
import TsInputBusca from '../../formulario/TsInputBusca';
import { Box, Fab } from '@mui/material';
import TsButton from '../../formulario/TsButton';
import ShowError from '../../formulario/ShowError';
import TsBackDrop from '../../formulario/TsBackDrop';
import ListaBase from '../ListaBase';
import ShowAlert from '../../util/ShowAlert';
import SelectCampo from '../../formulario/SelectCampo';
import { TsTextField } from '../../formulario/TsTextField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

class Table extends ListaBase {

    constructor(props) {
      super(props);
      this.serverPageSize = 50;

      this.state.adicionar = false;
      this.state.tipoSegmentacao = 2; // padrao REDE
      this.state.valor = '';
      this.state.objBusca = {busca: '', campanhaId: this.props.campanhaId};
   
      this.columns = [
            {
                name: "campanhaChaveId",
                label: "Campanha"
                
            },
            {
                name: "tipo",
                label: "Tipo"
            },
            {
                name: "valor",
                label: "Valor"
            },
            {
                name: "descricao",
                label: "Descrição"
            },
            {
                name: "rede",
                label: "Rede"
            },
            {
                name: "id",
                label: "x",
                format: (value) => { return <Fab color='alert' size="small" aria-label="delete" onClick={(e) => this.deleteItem(value)}><DeleteForeverIcon /></Fab> }            
            }
        ];
    }

    setAdicionar(adicionar) {
        this.setState({adicionar});
    }

    setValor(valor) {
        this.setState({valor});
    }

    setTipoSegmentacao(tipoSegmentacao) {
        this.setState({tipoSegmentacao});
    }

    Buscar () {
        this.setExecBuscar(false);
        if (!this.props.campanhaId) {
            ShowAlert.error('A Campanha não foi especificada');
            return;
        }
        this.setProcessando(true);
        this.setRows([]);
        const query = { page: this.state.serverPage, nrecPage: this.serverPageSize, ativo: true, campanhaId: this.state.objBusca.campanhaId, busca: this.state.objBusca.busca };

        segmentacaoHttp.busca(query)
        .then(({data}) => {
            this.setRows(data.dados);
        })
        .catch((error) => {
            this.setError(error);
        })
        .finally(() => 
        {
            this.setUrl(segmentacaoHttp.getUrl())
            this.setProcessando(false);
        });
        
    }

    async AdicionarSubmit() {
        this.setProcessando(true);
        try {

            const model = {
                campanhaChaveId: Number(this.props.campanhaId),
                segmentacaoTipoId: Number(this.state.tipoSegmentacao),
                operacao: '=',
                valor: Number(this.state.valor),
            };

            const ret = await segmentacaoHttp.incluirSegmentacao(this.props.campanhaId, model);
            if (ret.data?.sucesso === true) {
                ShowAlert.info('Segmentação incluida com sucesso');
                this.Buscar();
            } else {
                ShowAlert.error(ret.data?.mensagem || 'Erro ao inserir segmentação');
            }
        } catch (error) {
            this.setError(error);  
        } finally {
            this.setProcessando(false);
        }
        
    }

    async deleteItem(id) {
        this.setProcessando(true);
        try {
            const item = this.state.rows.find(it => it.id === Number(id));

            if (item === undefined) {
                ShowAlert.error('Segmentação não encontrada');
                return;
            }

            const ret = await segmentacaoHttp.deleteSegmentacao(this.props.campanhaId, item.id);
            if (ret.data?.sucesso === true) {
                ShowAlert.error('Segmentação excluida com sucesso');
                this.Buscar();
            } else {
                ShowAlert.error(ret.data?.mensagem || 'Erro ao excluir segmentação');
            }
        } catch (error) {
            this.setError(error);  
        } finally {
            this.setProcessando(false);
        }
    }

    componentDidMount() {
        super.componentDidMount();
      }
    
      componentWillUnmount() {
          super.componentWillUnmount();
          
      }
    
      componentDidUpdate(prevProps, prevState) {
    
          super.componentDidUpdate(prevProps, prevState);

          if (this.props.campanhaId !== prevProps.campanhaId) {
            this.setObjBusca({...this.state.objBusca, campanhaId: this.props.campanhaId});
          }

          if (this.state.execBuscar === true) this.Buscar();
     
      }
 

    render() {
        return (
            <div>
                { this.state.adicionar && 
            <div>
                <form>
                    <Box sx={{display: "flex"}}>
                        <Box sx={{flex: "2 0 auto"}} padding={4}><SelectCampo
                            id="select-tipo-segmentacao"
                            label="Tipo"
                            name="tipoSegmentacao"
                            options={[
                                { value: '2', text: 'REDE' },
                                { value: '1', text: 'CNPJ' },
                            ]}
                            value={this.state.tipoSegmentacao}
                            onChange={(e) => this.setTipoSegmentacao(e.target.value)}
                        /></Box>
                        <Box sx={{flex: "3 0 auto"}}  padding={4}><TsTextField
                            label="Valor"
                            value={this.state.valor}
                            onChange={(e) => this.setValor(e.target.value)}
                            /></Box>
                        <Box dir="rtl" sx={{flex: "1 0 auto"}}  padding={4}>
                            <TsButton
                                onClick={(e) => this.AdicionarSubmit()}
                            >Enviar</TsButton>
                            <TsButton 
                                onClick={(e) => this.setAdicionar(false)}
                                color='secondary'
                            >Cancelar</TsButton>
                        </Box>
                    </Box>    
                </form>
            </div>}
                <TsInputBusca
                    name="input-busca"
                    value={this.state.objBusca.busca}
                    showAtivo={true}
                    showAdd={true}
                    onClickAdd={(e) => this.setAdicionar(true)}
                    showBusca={true}
                    submitSearch={(texto, ativo) => {
                        this.setObjBusca({...this.state.objBusca, ativo: ativo, busca: texto});
                    }}
                ></TsInputBusca>
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                    />
                {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
                <TsBackDrop processando={this.state.processando} />
            </div>
        );
    }
}

export default Table;

 

