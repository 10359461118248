import React from 'react';
import { getProjetoStorage } from '../util/auth';
import lodash from 'lodash';

class ListaBase extends React.Component {

    constructor(props) {
        super(props);
        this.label = "super (ListaBase)";
        this.state = {serverPage: -1, rows: [], queryid: '', execBuscar: undefined, processando: false, error: undefined, url: ''};
        this.state.objBusca = {busca: '', ativo: true};
        this.state.buscando = false;
        this.serverPageSize = 50;
        this.columns = [];
        this.projeto = getProjetoStorage(); 
    }

    setRows(rows) {
        this.setState({rows: rows});
    }

    setError(error) {
        this.setState({error: error});
    }

    setProcessando(processando) {
        this.setState({processando: processando});
    }

    setUrl(url) {
        this.setState({url: url});
    }

    setObjBusca(objBusca) {
        this.setState({objBusca})
    }

    setBuscando(buscando) {
        this.setState({buscando})
    }

    setServerPage(serverPage) {
        if (serverPage < 0)
        serverPage = 0;
        this.setState({serverPage: serverPage})
    }

    setExecBuscar(execBuscar) {
        this.setState({execBuscar: execBuscar})
    }

    setQueryid(queryid) {
        this.setState({queryid: queryid})
    }

    navegateTo(url) {
        window.location.href = url;
    }

    iniciarBusca() {
        this.setExecBuscar(false);
        this.setError(undefined);
        this.setProcessando(true);
        this.setBuscando(true);
        this.setRows([]);
    }

     
    componentDidMount() {
         
        if (this.props.open !== undefined && this.props.open === false) return;
        this.setServerPage(0);
        this.setExecBuscar(false);
        // this.setExecBuscar(true);
    }
  
    componentWillUnmount() {
         
    }

    componentDidUpdate(prevProps, prevState) {

        let buscar = false;
        let pagging = false;

        if (this.props.open !== undefined) {
            if (this.props.open === false) return;
            if (this.props.open !== prevProps.open) {
                buscar = true;
            }
        }

        if (buscar === true) {buscar = true;}
        else if (this.state.execBuscar === undefined) {buscar = true; }
        else if (this.state.execBuscar === true) {return;}

        else if (!lodash.isEqual(prevState.objBusca,this.state.objBusca)) {buscar = true; }

        else if (prevState.serverPage !== this.state.serverPage)  {pagging = true;}

        if (buscar === true || pagging === true) {

            if (this.state.buscando === true) {
                return;
            }

        }

        if (buscar === true) {
            if (this.state.serverPage === 0) this.setExecBuscar(true);
            else this.setServerPage(0);
        }
        else if (pagging === true) {
            this.setExecBuscar(true);
        }

        
    }

   
}

export default ListaBase;