export const relatorioTitleCSS = {
  fontSize: 24,
  fontWeight: 400,
  fontStyle: 'italic',
  color: '#777',
};

export const mainTitleCSS = {
  fontSize: 20,
  fontWeight: 400,
  fontStyle: 'italic',
  color: '#DDD',
};

/*
justify-content: center;
*/

export const bgRed = { backgroundColor: '#e61e28', color: '#EEE' };

export const bgBlue = { backgroundColor: '#1f1f85', color: '#EEE' };

export const tituloBarra = {
  ...bgRed,
  fontSize: '1.4rem',
  fontWeight: 700,
  padding: 1,
  paddingLeft: 2.5,
  margin: 1,
  marginTop: 2,
  borderRadius: '16px',
};

export const containerBarChart = { ...bgBlue, width: '400px', borderRadius: '8px', minHeight: '400px' };

export const tituloBarChart = {
  borderBottom: '4px solid #FFF',
  padding: 1,
  textAlign: 'center',
};

export const contentBarChar = {
  padding: 2,
};

// https://developers.google.com/chart/interactive/docs/gallery/piechart?hl=pt-br
export const optionsPie = {
  backgroundColor: '#1f1f85',
  color: '#EEE',
  title: 'Faxia de Ticket Médio',
  titleTextStyle: { color: '#EEE' },
  legend: { position: 'right', textStyle: { fontSize: 14, color: '#EEE' } },
  // chart: {
  //   title: "Faxia de Ticket Médio",
  //   legend: {position: 'top', textStyle: {fontSize: 14}},
  //   legendx: {position: 'left',textStyle: {color: '#EEE'}}
  // },
};

/*
hAxis: {
    textStyle:{color: '#FFF'}
}
*/

export const optionsBar = {
  backgroundColor: '#1f1f85',
  titlePosition: 'out',
  bar: { groupWidth: 40 },
  titleTextStyle: { color: '#EEE', fontSize: 16 },
  color: '#EEE',
  title: '',
  chartArea: { left: 100, top: 5, width: '90%', height: '100%' },
  vAxis: {
    textPosition: 'out',
    textStyle: { color: '#EEE', fontSize: 12 },
    gridlines: {
      color: 'transparent',
    },
  },
  hAxis: {
    textPosition: 'none',
    textStyle: { color: '#EEE', fontSize: 12 },
    gridlines: {
      color: 'transparent',
    },
  },
  colors: ['e61e28'],
  legend: { position: 'none' },
  axisTitlesPosition: 'in',
  annotations: {
    textStyle: {
      color: '#EEE',
    },
  },
};

export const optionsColumn = {
  backgroundColor: '#1f1f85',
  titlePosition: 'out',
  bar: { groupWidth: 60 },
  titleTextStyle: { color: '#EEE', fontSize: 16 },
  color: '#EEE',
  title: '',
  chartArea: { left: 8, top: 5, width: '95%', height: '90%' },
  vAxis: {
    textPosition: 'none',
    textStyle: { color: '#EEE', fontSize: 12 },
    gridlines: {
      color: 'transparent',
    },
  },
  hAxis: {
    textPosition: 'out',
    textStyle: { color: '#EEE', fontSize: 12 },
    gridlines: {
      color: 'transparent',
    },
  },
  colors: ['e61e28'],
  legend: { position: 'none' },
  axisTitlesPosition: 'in',
  annotations: {
    textStyle: {
      color: '#EEE',
    },
  },
};
