import * as React from 'react';
import { Box, Button, Link, Paper, Tab, Tabs } from '@mui/material';
import TsButton from '../../formulario/TsButton';
import { TsTextField } from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import contratoHttp from '../../util/http/contrato-http';
import relatorioHttp from '../../util/http/relatorio-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';
import { listaSituacoes, listaEntidadeTipo } from './contrato-lista'; 
import {getProjetoStorage} from '../../util/auth';
import ContratoHist from './contrato-hist';
import ContratoSigner from './contrato-signer';
import ProspectDetail from './prospect-detail';
import TsDialog from '../../formulario/TsDialog'; 
import { httpProxy } from '../../util/http/proxy-http';
import { timeout } from '../../util/Util';

let carregandoLista = false;
const propsPaper = {
    square: false, sx: {width: '100%', padding: 2, marginBottom: 2}, elevation: 2
}

const ContratoDetail = (props) => {

    const projAtual = getProjetoStorage();
    const setDetail = props.setDetail;
    const detail = props.detail;
    const [processando, setProcessando] = React.useState(false);
    const [contratoData, setContratoData] = React.useState({});
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [listaEntidade, setListaEntidade] =  React.useState([{ text: '... Carregando', value: '' }]);
    const [listaSofthouse, setListaSofthouse] = React.useState([]);
    const [listaDistribuidor, setListaDistribuidor] = React.useState([]);
    const [listaRede, setListaRede] = React.useState([]);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [detailProspect, setDetailProspect] = React.useState({open: false, id: 0, disabled: true});

    const LimpaCampos = () => {
        setContratoData({
            id: 0,
            dataInicio: '',
            dataFinal: '',
            descricao: '',
            contratoStatusId: '1',
            usuario: 'egoncalez',
            EntidadeTipo: '',
            entidadeId: 0,
            entidadeNome: '',
            cnpj: '',
            softhousePaiId: '0',
            distribuidorPaiId: '0',
            statusChanged: false,
        });
    }

    const Obter = async () => {
        if (processando) {
            return;
        }
        LimpaCampos();
        setProcessando(true);
        setError(undefined);
        
        try {
   
            const {data} = await contratoHttp.get(detail.id);
            if (data.sucesso === true && data.dado) {
                const dados = data.dado;

                if (dados.redePaiId === null || !dados.redePaiId) {
                    dados.redePaiId = '';
                }

                dados.statusChanged = false;
                const prospectEmpresaId = dados.prospectEmpresaId && dados.prospectEmpresaId !== null  
                    ? dados.prospectEmpresaId 
                    : 0;

                setDetailProspect({...detailProspect, id: prospectEmpresaId});

                setContratoData(dados); 
            }
            else {
                setError("Nenhum registro encontrado");
            }
        } catch (error) {
            setError(error);
        } 
        setProcessando(false);
        setUrl(contratoHttp.getUrl());
    };

    const changeEntidadeTipo = async (valor) => {
        setProcessando(true);
        try {
            setContratoData({...contratoData, entidadeTipo: valor});
            const lista = [];
            let listaNome = '';
            if (valor === 'REDE') {
                listaNome = 'rede';
            }
            else if (valor === 'DISTRIBUIDOR') {
                listaNome = 'distribuidor';
            }
            else if (valor === 'SOFTHOUSE') {
                listaNome = 'softhouse';
            }
            else if (valor === 'INDUSTRIA') {
                listaNome = 'industria';
            }

            if (listaNome) {
                const {data} = await relatorioHttp.obterLista(listaNome, projAtual.id);
                lista.push({text: '...Selecione', value: ''});
                data.forEach(item => {
                    lista.push({text: item.text, value: Number(item.value) + ''});
                });
                
            }
            else {
                lista.push({text: '... Carregando', value: ''});
            }
            setProcessando(false);
            setListaEntidade(lista);
        } catch (error) {
            setProcessando(false);
            setUrl(relatorioHttp.getUrl());
            setError(error);
        }
    }

    const carregarRede = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('rede', projAtual.id);
            const lista = [{text: '...Selecione', value: ''}, {text: 'Não se aplica', value: '0'}];
            data.forEach(item => {
                lista.push({text: item.text, value: Number(item.value) + ''});
            });
            setListaRede(lista);
            
        } catch (error) {
            console.error('erro ao carregar rede');
            console.error(error);
        }
        carregandoLista = false;
    }

    const carregarSofthouse = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('softhouse', projAtual.id);
            const lista = [{text: '...Selecione', value: ''}, {text: 'Não se aplica', value: '0'}];
            data.forEach(item => {
                lista.push({text: item.text, value: Number(item.value) + ''});
            });
            setListaSofthouse(lista);
            
        } catch (error) {
            console.error('erro ao carregar softhouse');
            console.error(error);
        }
        carregandoLista = false;
    }

    const carregarDistribuidor = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('distribuidor', projAtual.id);
            const lista = [{text: '...Selecione', value: ''}, {text: 'Não se aplica', value: '0'}];
            data.forEach(item => {
                lista.push({text: item.text, value: Number(item.value) + ''});
            });
            setListaDistribuidor(lista);
            
        } catch (error) {
            console.error('erro ao carregar distribuidor');
            console.error(error);
        }
        carregandoLista = false;
    }

    const Refresh = async () => {
        if (processando) {
            return;
        }
        setProcessando(true);
        setError(undefined);

        try {
            const {data} = await contratoHttp.refresh(detail.id,  {...contratoData, redePaiId: Number(contratoData.redePaiId)});
            
            if (data.sucesso === true) {
                ShowAlert.info('Refresh atualizado com sucesso, aguarde agendamento');
            }
            else {
                ShowAlert.error("Erro ao atualizar registro");
                // setError(retorno);
            }
            
        } catch (error) {
            setError(error);
        }

        setProcessando(false);
    }

    const Assinar = async () => {
        // setContratoData({...contratoData, contratoStatusId: e.target.value})}
        setContratoData({...contratoData, statusChanged: true, contratoStatusId: '2'});
    }

    const Ativar = async () => {
        if (!contratoData.redePaiId) {
            ShowAlert.error('Selecione uma rede Pai');
            return;
        }
        setContratoData({...contratoData, statusChanged: true, contratoStatusId: '9'});
    }

    const SyncRede = async () => {
        if (processando) {
            return;
        }
        setProcessando(true);
        setError(undefined);

        try {

            const {status, data} = await contratoHttp.syncRede(detail.id, contratoData);
            
            
            if (data.sucesso === true) {
                setProcessando(false);
                ShowAlert.info(data.mensagem);
            }
            else {
                ShowAlert.error("Erro ao sincronizar rede");
                // setError(retorno);
            }
            
        } catch (error) {
            setError(error);
        }

        setProcessando(false);
    }

    const visualizarDoc = async (docId) => {
        if (processando) {
            return;
        }
        setProcessando(true);
        setError(undefined);
        httpProxy.setDefaultProxy();
        try {
            const ret = await httpProxy.zapsignDoc(docId);
            if (ret.status === 200 && ret.data?.sucesso === true) {
                const doc = ret.data.data;
                const signed_file = doc?.signed_file;
                if (typeof(signed_file) === 'string' && signed_file.indexOf('https') === 0) {
                    window.open(signed_file, '_blank');
                }
                else {
                    console.log('retorno doc:', doc);
                    ShowAlert.info('Url retornada não é valida');
                }
            }
            else {
                console.log('retorno doc:', ret);
                ShowAlert.error(`retorno zapsign inválido`);
            }
            
        } catch (error) {
            setError(error);
        }
        setProcessando(false);
    }    

    const Atualizar = async () => {
        if (processando) {
            return;
        }

        if (!contratoData.redePaiId) {
            ShowAlert.error('Selecione uma rede PAI');
            return;
        }

        setProcessando(true);
        setError(undefined);

        try {
            
            const {data} = detail.id === 0 ? 
                await contratoHttp.create({...contratoData, redePaiId: Number(contratoData.redePaiId)}) :
                await contratoHttp.update(detail.id,  {...contratoData, redePaiId: Number(contratoData.redePaiId)});
            
            if (data.sucesso === true) {
                ShowAlert.info(data.mensagem);
                await timeout(2000);
                setProcessando(false);
                setDetail({open: false, id: 0, tipo: 'contrato', pageTitle: "Lista de Contratos"});
            }
            else {
                ShowAlert.error("Erro ao atualizar registro");
                // setError(retorno);
            }
            
        } catch (error) {
            setError(error);
        }

        setProcessando(false);

    }
 

    React.useEffect(() => {

        if (detail.open === true) {
            if (detail.id) {
                Obter();
            }
            else {
                LimpaCampos();
            }
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]);


    React.useEffect(() => {

        if (contratoData.statusChanged === true) {
            setContratoData({...contratoData, statusChanged: false});
            Atualizar();
        }
        else if (contratoData?.id !== undefined && contratoData.tipoEmpresa && carregandoLista === false) {
            if (contratoData.tipoEmpresa === 'ESTABELECIMENTO' && listaRede.length === 0) {
                carregandoLista = true;
                carregarRede();
            }
            else if (contratoData.tipoEmpresa === 'REDE' && listaSofthouse.length === 0) {
                carregandoLista = true;
                carregarSofthouse();
                carregarDistribuidor();
            }
        }

        
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contratoData]);

    /*
     id: 0,
    dataInicio: '',
    dataFinal: '',
    descricao: '',
    contratoStatusId: '1',
    usuario: 'egoncalez',
    EntidadeTipo: '',
    entidadeId: 0,
    entidadeNome: ''
    */

    return (<>
    <Paper {...propsPaper} >
        <Box sx={{display: "flex"}}>
            {contratoData.id === 0 ? 
            <>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                    <SelectCampo
                        id='entidadeTipo'
                        label='Entidade Tipo'
                        name='entidadeTipo'
                        disabled={false}
                        options={listaEntidadeTipo}
                        value={contratoData.entidadeTipo || ''}
                        onChange={(e) => {changeEntidadeTipo(e.target.value)}}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                <SelectCampo
                        id='entidadeId'
                        label='Entidade Nome'
                        name='entidadeId'
                        disabled={false}
                        options={listaEntidade}
                        value={contratoData.entidadeId || '0'}
                        onChange={(e) => {setContratoData({...contratoData, entidadeId: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>

                </Box>
            
            </>
            : 
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="entidadeNome"
                    label="entidadeNome" 
                    fullWidth
                    value={contratoData.entidadeNome || ''}
                    disabled={true}
                />
            </Box>
            }
            <Box sx={{flex: "0 0 auto", width: "200px", paddingRight: 2}}>
                <TsTextField
                    name="cnpj"
                    label="CNPJ" 
                    fullWidth
                    value={contratoData.cnpj || ''}
                    disabled={false}
                    onChange={(e) => {setContratoData({...contratoData, cnpj: e.target.value})}}
                />
            </Box>
            
            <Box sx={{flex: "0 0 auto", width: "300px", paddingRight: 2}}>
                <SelectCampo
                    id='contratoStatusId'
                    label='situacao'
                    name='contratoStatusId'
                    disabled={true}
                    options={listaSituacoes}
                    value={contratoData.contratoStatusId + '' || '0'}
                    onChange={(e) => {setContratoData({...contratoData, contratoStatusId: e.target.value})}}
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="descricao"
                    label="descricao"
                    fullWidth
                    value={contratoData.descricao || ''}
                    disabled={false}
                    onChange={(e) => {setContratoData({...contratoData, descricao: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '250px', paddingRight: 2}}>
            <TsTextField
                    name="templateId"
                    label="Template zapsign"
                    fullWidth
                    value={contratoData.templateId + '' || ''}
                    disabled={true}
                />
            </Box>
            
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
            <TsTextField
                    name="documentTokenId"
                    label="DocumentId zapsign"
                    fullWidth
                    value={contratoData.documentTokenId + '' || ''}
                    disabled={true}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '250px', paddingTop: 2, paddingRight: 2}}>
                {contratoData.documentTokenId && <Link 
                component={Button} 
                onClick={(e) => visualizarDoc(contratoData.documentTokenId)}  
                >Visualizar Contrato</Link>}
                 
            </Box>
            <Box sx={{flex: "0 0 auto", width: '250px', paddingRight: 2}}>
            <TsTextField
                    name="documentStatus"
                    label="Status zapsign"
                    fullWidth
                    value={contratoData.documentStatus + '' || ''}
                    disabled={true}
                />
            </Box>
        </Box>

        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="dataInicio"
                    label="dataInicio"
                    fullWidth
                    value={contratoData.dataInicio || ''}
                    disabled={false}
                    onChange={(e) => {setContratoData({...contratoData, dataInicio: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="dataFinal"
                    label="dataFinal"
                    fullWidth
                    value={contratoData.dataFinal || ''}
                    disabled={false}
                    onChange={(e) => {setContratoData({...contratoData, dataFinal: e.target.value})}}
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            

                {contratoData.tipoEmpresa === 'ESTABELECIMENTO' && 
                <>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <SelectCampo
                    id='redeId'
                    label='Rede PAI'
                    name='redeId'
                    disabled={false}
                    options={listaRede}
                    value={contratoData.redePaiId + '' || ''}
                    onChange={(e) => {setContratoData({...contratoData, redePaiId: e.target.value})}}
                />
                </Box>
                <Box sx={{flex: "0 0 auto", width: "300px", paddingRight: 2}}>
                    <TsTextField
                        name="nomeFranqueadora"
                        label="Franqueadora"
                        fullWidth
                        value={contratoData.nomeFranqueadora || ''}
                        disabled={true}
                    />
                </Box>
                </>
                }
                {contratoData.tipoEmpresa === 'REDE' && 
                <>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <SelectCampo
                    id='softhouseId'
                    label='Softhouse PAI'
                    name='softhouseId'
                    disabled={false}
                    options={listaSofthouse}
                    value={contratoData.softhousePaiId + '' || ''}
                    onChange={(e) => {setContratoData({...contratoData, softhousePaiId: e.target.value})}}
                />
                </Box>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <SelectCampo
                        id='distribuidorId'
                        label='Distribuidor PAI'
                        name='distribuidorId'
                        disabled={false}
                        options={listaDistribuidor}
                        value={contratoData.distribuidorPaiId + '' || ''}
                        onChange={(e) => {setContratoData({...contratoData, distribuidorPaiId: e.target.value})}}
                    />
                </Box>
                </>
                }
            
        </Box>
        <form>
        <Box sx={{display: "flex"}}>
            {Number(contratoData.contratoStatusId) === 1 && <Box sx={{flex: "0 0 auto"}}>
                <TsButton
                    disabled={false}
                    onClick={(e) => Assinar()}
                    color='alert'
                >Enviar zapsign</TsButton>
            </Box>}
            {Number(contratoData.contratoStatusId) === 4 && <Box sx={{flex: "0 0 auto"}}>
                <TsButton
                    disabled={false}
                    color='alert'
                    onClick={(e) => Ativar()}
                >Ativar</TsButton>
            </Box>}
            {Number(contratoData.contratoStatusId) >= 2 && Number(contratoData.contratoStatusId) <= 9 &&
            <Box sx={{flex: "0 0 auto"}}>
                <TsButton
                    disabled={false}
                    color='error'
                    onClick={(e) => SyncRede()}
                >Sincronizar Rede</TsButton>
            </Box>}
                        
            {(Number(contratoData.contratoStatusId) === 3 || contratoData.contratoStatusId === 94) && <Box sx={{flex: "0 0  auto"}}>
                <TsButton
                    disabled={false}
                    onClick={(e) => Refresh()}
                    color='alert'
                    >Sincronizar zapsign</TsButton>
            
            </Box>}
            {Number(detailProspect.id) > 0 && <Box sx={{flex: "0 0  auto"}}>
                <TsButton
                    disabled={false}
                    onClick={(e) => setDetailProspect({...detailProspect, open: true})}
                    >Visualizar prospect</TsButton>
            
            </Box>}
            <Box sx={{flex: "1 1 auto"}}></Box>
            <Box sx={{flex: "0 0  auto"}}>
                <TsButton
                    disabled={processando}
                    onClick={(e) => Atualizar()}
                >Enviar</TsButton>
            </Box>
            <Box sx={{flex: "0 0 auto"}}>
                <TsButton
                    onClick={(e) => setDetail({open: false, id: 0, tipo: 'contrato', pageTitle: "Lista de contratos"})}
                    color='secondary'
                >Voltar</TsButton>
            </Box>
        </Box>
        </form>
    
    <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} aria-label="Opções da campanha">
            <Tab label="Signer" id="contrato-tab-0" aria-labelledby={`contrato-tabpanel-0`} />
            <Tab label="Historico" id="contrato-tab-1" aria-labelledby={`contrato-tabpanel-1`} />
    </Tabs>
    <ContratoSigner open={tabIndex === 0} tipo='contrato' contratoId={detail.id} setError={setError} />
    <ContratoHist open={tabIndex === 1} contratoId={detail.id} setError={setError} />
    </Paper>
    <TsDialog 
        onClose={() => setDetailProspect({...detailProspect, open: false})} 
        id="dialog-detalhe-prospect" 
        open={detailProspect.open} 
        title='Detalhes do prospect' 
        minWidth={800}
        sx={{backgroundColor: '#ECECEC'}}
    >
        {detailProspect.open && <ProspectDetail setDetail={(value) => void(0)}  detail={detailProspect}></ProspectDetail>}
    </TsDialog>
        
    
   <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
    <TsBackDrop processando={processando} />
    </>)
}

export default ContratoDetail;
 
