import * as React from 'react';
import {Box} from "@mui/material";
 
import Cupons from './qrcode-cupons';
 

const QrCodeAdmPage = (props) => {
     
    return (
    <Box>
        <Cupons />
    </Box>
    )
}

export default QrCodeAdmPage;