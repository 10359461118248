import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class DistribuidorHttpResorce extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.url = resource;
    this.http = http;
    this.resource = resource;
  }

  // [HttpPut]
  // [Route("{campanhaId}/oferta/{ofertaId}/produto")]
  listRedes(distribuidorId, projetoId) {
    this.url = `/${this.resource}/${distribuidorId}/rede?projetoId=${projetoId} - GET`;
    return this.http.get(`${this.resource}/${distribuidorId}/rede?projetoId=${projetoId}`, this.config);
  }

  listDistribuidores(redeId, projetoId) {
    this.url = `/${this.resource}/rede/${redeId}/listar?projetoId=${projetoId} - GET`;
    return this.http.get(`${this.resource}/rede/${redeId}/listar?projetoId=${projetoId}`, this.config);
  }

  obterRede(distribuidorId, redeId, projetoId) {
    this.url = `/${this.resource}/${distribuidorId}/rede/${redeId}?projetoId=${projetoId} - GET`;
    return this.http.get(`${this.resource}/${distribuidorId}/rede/${redeId}?projetoId=${projetoId}`, this.config);
  }

  atualizarRede(data, redeId) {
    // const redeId = data.redeId;
    const distribuidorId = data.distribuidorId;

    if (redeId) {
      this.url = `/${this.resource}/${distribuidorId}/rede/${redeId} - PUT`;
      return this.http.put(`${this.resource}/${distribuidorId}/rede/${redeId}`, data, this.config);
    } else {
      this.url = `/${this.resource}/${distribuidorId}/rede - POST`;
      return this.http.post(`${this.resource}/${distribuidorId}/rede`, data, this.config);
    }
  }

  listProdutos(distribuidorId, redeId, query) {
    this.url = `/${this.resource}/${distribuidorId}/rede/${redeId}/produto - POST`;
    return this.http.post(`${this.resource}/${distribuidorId}/rede/${redeId}/produto`, query, this.config);
  }

  executeMaint(distribuidorId, redeId, projetoId, tipo, valor, operacao) {
    this.url = `/${this.resource}/${distribuidorId}/rede/${redeId}/produto/maint?projetoId=${projetoId}&tipo=${
      tipo || 'NULL'
    }&valor=${valor || '0'}&operacao=${operacao || 'NULL'} - GET`;
    return this.http.get(
      `${this.resource}/${distribuidorId}/rede/${redeId}/produto/maint?projetoId=${projetoId}&tipo=${tipo || 'NULL'}&valor=${
        valor || '0'
      }&operacao=${operacao || 'NULL'}`,
      this.config
    );
  }

  // const {data} = await distribuidorHttp.executeMaint(distribuidorId, redeId, projAtual.id, tipo, valor, operacao);
}

const distribuidorHttp = new DistribuidorHttpResorce(httpTsApi, 'distribuidor', baseUrl);

export default distribuidorHttp;
