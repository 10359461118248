import * as React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { Page } from '../Page';
import { Form } from './Form';
import TableSegs from '../segmentacao/Table';
// import TableProdutos from '../produto/table-produto-campanha';
import TableProdutoCampanha from "../produto/table-produto-campanha";
import TableClass from '../segmentacao/table-class'
import { subTitleCSS } from '../../style';

 
const CampanhaForm = (props) => {
    const { id } = useParams();
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabPanel = (event, newValue) => {
        setTabIndex(newValue);
    };

    const campanhaId = parseInt(id || '') || 0;

    return (
        <Page title={!id ? 'Criar campanha' : 'Detalhes campanha'}>

            <Tabs value={tabIndex} onChange={handleTabPanel} aria-label="Opções da campanha">
                <Tab label="Geral" id="campanha-tab-0" aria-labelledby={`campanha-tabpanel-0`} />
                <Tab label="Produtos" id="campanha-tab-1" aria-labelledby={`campanha-tabpanel-1`} />
                <Tab label="Segmentação" id="campanha-tab-2" aria-labelledby={`campanha-tabpanel-2`} />
                <Tab label="Classificação" id="campanha-tab-3" aria-labelledby={`campanha-tabpanel-3`} />
            </Tabs>

            <Box
                hidden={tabIndex !== 0}
                marginTop={2}
                id="campanha-tabpanel-0"
                aria-labelledby={`campanha-tab-0`}
                role="tabpanel"
            >
                {tabIndex === 0 &&
                    <>
                        <Typography sx={subTitleCSS}>Dados gerais da campanha</Typography>
                        <Form />
                    </>}
            </Box>
            <Box
                hidden={tabIndex !== 1}
                marginTop={2}
                id="campanha-tabpanel-1"
                aria-labelledby={`campanha-tab-1`}
                role="tabpanel"
            >
                {tabIndex === 1 &&
                    <>
                        <Typography sx={subTitleCSS}>Ofertas e produtos da campanha</Typography>
                        <TableProdutoCampanha
                            campanhaId={campanhaId}
                            ofertaId={0}
                            enableAdicionar={true}
                            enableEditar={false}
                            refresh={false}
                        ></TableProdutoCampanha>
                    </>
                }
            </Box>
            <Box
                hidden={tabIndex !== 2}
                marginTop={2}
                id="campanha-tabpanel-2"
                aria-labelledby={`campanha-tab-2`}
                role="tabpanel"
            >
                {tabIndex === 2 &&
                    <>
                        <Typography sx={subTitleCSS}>Segmentação da campanha</Typography>
                        <TableSegs campanhaId={campanhaId}></TableSegs>
                    </>
                }
            </Box>
            <Box
                hidden={tabIndex !== 3}
                marginTop={2}
                id="campanha-tabpanel-3"
                aria-labelledby={`campanha-tab-3`}
                role="tabpanel"
            >
                {tabIndex === 3 &&
                    <>
                        <Typography sx={subTitleCSS}>Classificação da campanha</Typography>
                        <TableClass campanhaId={campanhaId}></TableClass>
                    </>
                }
            </Box>
        </Page>
    );
};

export default CampanhaForm;
