import MUILink from '@mui/material/Link';
import { Popover, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { BadgeNo, BadgeYes } from '../../formulario/Badge';

/* http */
import campanhaHttp from '../../util/http/campanha-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import { HOMEDIR } from '../../util/auth';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';

const DetalhesRede = (props) => {

     
    const value = props.value === null || !props.value ? '' : props.value;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handlePopoverClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);

    return (<div>
         <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {value.length > 15 ? `${value.substr(0, 15)} ...`: value }
        </Typography>
          <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: 'none' }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{value}</Typography>
        </Popover> 
      </div>)
 
}

class Table extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;

        this.columns = [
            {
                name: "campanhaChaveId",
                label: "Código",
              format: ((value) => (<MUILink component={Link} to={`${HOMEDIR}/campanha/${value}/editar`}>{value}</MUILink>)) 
            },
            {
                name: "projetoId",
                label: "Projeto"
            },
            {
                name: "titulo",
                label: "Titulo"
            },
            {
                name: "inicio",
                label: "Inicio"
            },
            {
                name: "final",
                label: "Final"
            },
            {
                name: "status",
                label: "Status",
               format: (value) => { return this.showStatus(value) } // out_of_time 
            },
            {
                name: "flgClassificacao",
                label: "Classificação",
               format: (value) => { return value === true ? <BadgeYes label="Sim" /> : <BadgeNo label="Não" /> }
            },
            {
                name: "filtro",
                label: "Filtro"
            },
            {
                name: "redePrincipal",
                label: "Rede",
                format: ((value) => (<DetalhesRede value={value}/>)) 
            }
        ];
    }

    showStatus = (value) => {
        if (value === "ativo") {
            return (<BadgeYes color='alert' label="Ativo" />); // value === "ativo" ?  <BadgeYes label="Ativo" />: <BadgeNo label="Inativo" />
        }
        else if (value === "out_of_time") {
            return (<BadgeNo color='alert' label="Expirado" />);
        }
        else {
            return (<BadgeNo color='alert' label="Inativo" />);
        }
    }

    Buscar = () => {

        this.iniciarBusca();
    
        const query = { page: this.state.serverPage, nrecPage: this.serverPageSize, ativo: this.state.objBusca.ativo, projetoId: parseInt(this.projeto.id), busca: this.state.objBusca.busca };

        campanhaHttp.busca(query)
            .then(({ data }) => {
                this.setRows(data.dados);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(campanhaHttp.getUrl());
            });


    }

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
         
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
    }

    render() {
        return (
            <div>
             <>
                <TsInputBusca
                    name="input-busca"
                    value={this.state.objBusca.busca}
                    showAtivo={true}
                    showBusca={true}
                    submitSearch={(texto, ativo) => {
                        this.setObjBusca({...this.state.objBusca, ativo: ativo, busca: texto});
                    }}
                ></TsInputBusca> 
                </> 
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                    /> 
                {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
                <TsBackDrop processando={this.state.processando} />
                
            </div>
        );
    }

}

export default Table;
 