import { FormLabel, Grid, Link, Box, Tabs, Tab } from '@mui/material';
import * as React from 'react';
import { labelCSS, preWrapCSS } from '../../style';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ofertaHttp from '../../util/http/oferta-http';
import TextForm from '../../formulario/TextForm';
import TsButton from '../../formulario/TsButton';
import { OfertaCampos, validarOferta } from '../../dominios/CampanhaOferta';
import { HOMEDIR } from '../../util/auth';
import { TipoPontoForm } from './TipoPontoForm';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { exemploProgressivo } from '../../util/Util';
import SelectForm from '../../formulario/SelectForm';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';
const boxProps = {
    borderColor: '#B9B9B9',
    border: 1
};

/// _type_ PnGeralProps = {
///     ofertaId,
///     campanhaId 
/// };

export const TabPanelGeral = (props) => {
    const ofertaId = props.ofertaId;
    const campanhaId = props.campanhaId;

    const [form, setForm] = useState({});
    const [processando, setProcessando] = useState(false);
    const [error, setError] = useState(undefined);
    const [url, setUrl] = useState("");

    const navigate = useNavigate();

    const [openTipoPonto, setOpenTipoPonto] = useState(false);
    const [pontoTipoId, setPontoTipoId] = useState(0);
    const [formulaTotal, setFormulaTotal] = useState("");
    const [formulaUnitario, setFormulaUnitario] = useState("");
    const [pontoTipo, setPontoTipo] = useState("");

    const [validating, setValidating] = useState(false);

    const obterPontoTipo = (id) => {
        setOpenTipoPonto(false);

        
        if (id === undefined || isNaN(id)) {
            return;
        }

        setPontoTipoId(id);
        setProcessando(true);
        setError(undefined);
        setFormulaTotal(" ... aguarde ");
        setFormulaUnitario(" ... aguarde ");
        setPontoTipo(" ... aguarde ");
        ofertaHttp.listaTipoPonto()
            .then((retorno) => {
                const lista = retorno.data;
                lista.forEach(it => {
                    if (it.id === id) {
                        setFormulaTotal(it.formulaTotal);
                        setFormulaUnitario(it.formulaUnitario);
                        setPontoTipo(it.descricao);
                    }
                });

            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setProcessando(false);
                setUrl(ofertaHttp.getUrl());
            });
    }

    useEffect(() => {

        if (!ofertaId) {
            return;
        }
        setProcessando(true);
        ofertaHttp
            .get(ofertaId)
            .then(({ data }) => {

                const c = data;

                obterPontoTipo(c.pontoTipoId);

                updateData(c);

            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setProcessando(false);
                setUrl(ofertaHttp.getUrl());
            })

    }, [ofertaId, campanhaId]);

    const updateData = (data) => {

        if (data.cashbackTipoId && data.cashback) {
            // tipo cashback
            data.ofertaTabIndex = 2;
            data.desconto = 0;
            data.descontoProgressivo = "";
        }
        else if (data.descontoProgressivo) {
            // tipo desconto progressivo
            data.ofertaTabIndex = 1;
            data.cashbackTipoId = undefined;
            data.cashback = 0;
            data.desconto = 0;
        }
        else {
            // tipo desconto fixo
            data.ofertaTabIndex = 0;
            data.cashbackTipoId = undefined;
            data.cashback = 0;
            data.descontoProgressivo = "";
        }
        console.log('form', data);
        setForm(data);
    }

    const onSubmit = (event) => {

        let versaoId = "";

        form.versaoId = versaoId;
        form.pontoTipoId = pontoTipoId.toString();
        form.limiteTipoId = undefined;
        // limiteTipoId

        if (form.ofertaTabIndex === 0) {
            // desconto normal
            // form.descontoTipoId = 0;
            // form.desconto = 0;
            form.descontoProgressivo = "";
            form.cashback = undefined;
            form.cashbackTipoId = undefined;

        }
        else if (form.ofertaTabIndex === 1) {
            // descontoProgressivo
            form.descontoTipoId = 0;
            form.desconto = 0;
            // form.descontoProgressivo = "";
            form.cashback = undefined;
            form.cashbackTipoId = undefined;
        }
        else if (form.ofertaTabIndex === 2) {
            // cashback 
            form.descontoTipoId = 0;
            form.desconto = 0;
            form.descontoProgressivo = "";
            // form.cashback = undefined;
            // form.cashbackTipoId = undefined;
        }
        else {
            ShowAlert.error("Tipo de oferta não foi selecionado");
            return;
        }

        setValidating(true);

        const msg = validarOferta(form);

        if (msg) {
            ShowAlert.error(msg);
            return;
        }
        setValidating(false);
        setProcessando(true);

        const http = ofertaId === 0
            ? ofertaHttp.create(form)
            : ofertaHttp.update(ofertaId, form);

        http.then((response) => {
            ShowAlert.info('Oferta salva com sucesso!');
            setTimeout(() => {
                navigate(`${HOMEDIR}/campanha/${campanhaId}/editar`);
            }, 100);

        }).catch((error) => {
            setError(error);
        })
            .finally(() => {
                setProcessando(false);
                setUrl(ofertaHttp.getUrl());
            });

    }

    const handleTab = (newValue) => {
        setForm({ ...form, ofertaTabIndex: newValue });
    }

    return (
        <Box marginTop={2} >
            <form>
                <TextForm
                    campo={OfertaCampos.canalVendaId}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}
                />
                <Box padding={1} sx={{ background: "#eee", textAlign: "center" }}>
                    PONTUAÇÃO
                </Box>


                <Box {...boxProps}
                    borderRadius={4}
                    padding={2}
                    marginBottom={2}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={1}>
                            <Link component="button" onClick={(e) => { e.preventDefault(); setOpenTipoPonto(true); }}><EventNoteIcon /></Link>
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel sx={labelCSS}> Tipo Ponto:</FormLabel> <br />{pontoTipo}
                        </Grid>
                        <Grid item xs={6}>
                            <TextForm
                                campo={OfertaCampos.ponto}
                                formData={form}
                                setFormData={(value) => setForm(value)}
                                validating={validating}
                                fixed={2}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormLabel sx={labelCSS}> Formula Ponto Total:</FormLabel> <br />{formulaTotal}
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel sx={labelCSS}> Formula Ponto Unitario: </FormLabel> <br />{formulaUnitario}
                        </Grid>
                    </Grid>
                </Box>
                <Tabs
                    value={form.ofertaTabIndex || 0}
                    orientation="horizontal"
                    onChange={(e, newValue) => handleTab(newValue)}
                    centered
                    sx={{ background: "#eee" }}
                >
                    <Tab label="Desconto básico" id="oferta-tab-0" ></Tab>
                    <Tab label="Desconto progressivo" id="oferta-tab-1" ></Tab>
                    <Tab label="Cashback" id="oferta-tab-2" ></Tab>
                </Tabs>
                {form.ofertaTabIndex === 0 &&
                    <Grid container spacing={3} sx={{ minHeight: 260, paddingTop: 3 }}>
                        <Grid item xs={4}>
                            <SelectForm
                                campo={OfertaCampos.descontoTipoId}
                                formData={form}
                                setFormData={(value) => setForm({...value, descontoTipoId: value.descontoTipoId, desconto: 0, valorDescZicard: 0})} 
                                validating={validating}
                            />
                            {/* {console.log('form',form); console.log('value', value); setForm(value)} */}
                        </Grid>
                        <Grid item xs={4}>
                            <TextForm
                                campo={OfertaCampos.desconto}
                                formData={form}
                                setFormData={(value) => setForm(value)}
                                validating={validating}
                                fixed={2}

                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextForm
                                campo={OfertaCampos.valorDescZicard}
                                formData={form}
                                setFormData={(value) => setForm(value)}
                                validating={validating}
                                fixed={2}

                            />
                        </Grid>
                    </Grid>}
                {form.ofertaTabIndex === 1 &&
                    <>
                        <TextForm
                            campo={OfertaCampos.descontoProgressivo}
                            formData={form}
                            setFormData={(value) => setForm(value)}
                            validating={validating}
                            multiline={true}
                            minRows={4}
                        />
                        <div><div>Exemplo</div>
                            <Box sx={{ ...preWrapCSS, fontSize: 12 }} >{exemploProgressivo}</Box>
                        </div>
                    </>
                }
                {form.ofertaTabIndex === 2 &&
                    <Grid container spacing={3} sx={{ minHeight: 260, paddingTop: 3 }}>
                        <Grid item xs={6}>
                            <SelectForm
                                campo={OfertaCampos.cashbackTipoId}
                                formData={form}
                                setFormData={(value) => setForm(value)}
                                validating={validating}
                            />
                        </Grid>
                        <Grid item xs={6}><TextForm
                            campo={OfertaCampos.cashback}
                            formData={form}
                            setFormData={(value) => setForm(value)}
                            validating={validating}
                            fixed={2}
                        />
                        </Grid>
                    </Grid>
                }

                <Box dir="rtl">
                    <TsButton
                        onClick={(e) => navigate(`${HOMEDIR}/campanha/${campanhaId}/editar`)}
                        color='secondary'
                    >Voltar
                    </TsButton>
                    <TsButton
                        onClick={(e) => onSubmit(e)}
                    >Salvar oferta {ofertaId}</TsButton>
                </Box>

            </form>
            <TipoPontoForm idInicial={pontoTipoId} open={openTipoPonto} onClose={(valor) => obterPontoTipo(valor)} />
            <TsBackDrop processando={processando} />
            <ShowError error={error} mensagemPadrao={"Falha ao processar request"} url={url} />
        </Box>
    );
};