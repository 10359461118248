import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { TabPanelGeral } from "./TabPanelGeral";
import { useParams } from 'react-router-dom';
import TableProdutoCampanha from '../produto/table-produto-campanha';
import { subTitleCSS } from '../../style';

export const Form = (props) => {


    // /campanha/:campanhaId/oferta/:ofertaId/editar
    const { campanhaId, ofertaId } = useParams();

    const ofertaNum = parseInt(ofertaId || '');
    const campanhaNum = parseInt(campanhaId || '');

    return (
        <React.Fragment>
            <Typography sx={subTitleCSS}>
                Detalhes da Oferta: {ofertaId} ; Campanha: {campanhaId}
            </Typography>
            <TabPanelGeral
                ofertaId={ofertaNum}
                campanhaId={campanhaNum}
            />

            <Box marginTop={2} >
                <Typography sx={subTitleCSS}>
                    Produtos na oferta
                </Typography>


                <TableProdutoCampanha
                    ofertaId={ofertaNum}
                    campanhaId={campanhaNum}
                    enableAdicionar={true}
                    enableEditar={true}
                    refresh={false}
                ></TableProdutoCampanha>

            </Box>
        </React.Fragment>
    );
};