/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material';
import {
  Table,
  TableFooter,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Select,
  FormControl,
  MenuItem,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// props {
//  rows
//  columns
// }

const StyledTableCell = styled(TableCell)(({ theme, fontSize }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  }
  ,
  [`&.${tableCellClasses.body}`]: {
    fontSize: fontSize,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
// };

const TsDataCell = (props) => {
  let child = props.value;
  
  if (props.column.format && (props.value !== undefined && props.value !== null)) {
    child = props.column.format(props.value);
  }

  return <StyledTableCell fontSize={props.fontSize}>{child}</StyledTableCell>;
};

const TsDataGrid = (props) => {
  const serverPage = (props.serverPage && !isNaN(props.serverPage)) ? props.serverPage : 0;
  const serverPagesize = (props.serverPagesize && !isNaN(props.serverPagesize)) ? props.serverPagesize : 10;
  const showPagging = props.showPagging === undefined ? true: props.showPagging;
  const initialPageSize = props.pageSize === undefined ? 10: props.pageSize;
  const fontSize = props.fontSize === undefined ? '12': props.fontSize;
  const [offset, setOffset] = React.useState(serverPage * serverPagesize);

  const queryid = props.queryid;
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(showPagging ? initialPageSize: serverPagesize);
  const [nrecs, setNrecs] = React.useState(rows.length);
  const [from, setFrom] = React.useState(1);
  const [to, setTo] = React.useState(5);
  const [oldServerpage, setOldServerpage] = React.useState(0);

  const onChangePage = props.onChangePage;

  const handleChangePage = (newPage) => {
    if (newPage < 0) {
      onChangePage(serverPage - 1);
      return;
    }

    
    if (newPage * pageSize >= nrecs) {


      // console.log(`nrecs: ${nrecs} , serverPage: ${serverPage}, serverPagesize: ${serverPagesize}`);
      if (nrecs < serverPagesize) {
        if (serverPage > 0) {
          onChangePage(0);
        }
        else  {
          const np = 0;
          setPage(np);
          setFrom(offset + 1 + np * pageSize);
          setTo(offset + pageSize * (np + 1));
        }
        return;
      }
      else  {
        onChangePage(serverPage + 1);
        return;
      }
      
    }

    setPage(newPage);
    setFrom(offset + 1 + newPage * pageSize);
    setTo(offset + pageSize * (newPage + 1));
  };

  const handleChangeRowsPerPage = (event) => {
    const ps = Number(event.target.value, 10);
    const pg = 0;
    onChangePage(0);
    const offset_new = 0;
    setPageSize(ps);
    setPage(pg);
    setFrom(offset_new + 1 + pg * ps);
    setTo(offset_new + ps * (pg + 1));
  };

  React.useEffect(() => {

    const cols = [];

    if (props.columns && props.columns.length > 0) {
      
      props.columns.forEach(col => {
        if (col.show === undefined || col.show === true)  {
          cols.push(col);
        }
      });
      setColumns(cols);
    

      if (props.rows && props.rows.length > 0) {
        
        const rows = [];
        props.rows.forEach(row => {
          const data = {};
          cols.forEach(col => {
            const name = col.name;
            if (name in row) {
              const value = row[name];
              // console.log(`... TsDataGrid loading data ->  name: ${name} value: ${value}`); 
              if (typeof(value) === 'string' &&  value.indexOf("_") === 0) {
                data[name] = value.replace("_", '');
              }
              else {
                data[name] = value;
              }
            }
            else {
              console.log(`tsdatagrid - chave nao encontrada ${name}`);
            }
          });
          
          rows.push(data);
        });
        
        const pg = 0;
        setPage(pg);

        setFrom(offset + 1 + pg * pageSize);
        setTo(offset + pageSize * (pg + 1));
        

        setRows(rows);
        setNrecs(rows.length);
        
      }
      else  {
        setRows([]);
        setNrecs(0);
      }


    }

    

  }, [props.rows, props.columns]);

  React.useEffect(() => {
    let newPage = 0;
    let newOffset =  serverPage * serverPagesize;
    setOffset(newOffset);
    if (oldServerpage > serverPage) {
      newPage = serverPagesize / pageSize;
      newPage = newPage - 1;
    }

    // console.log(`useEffect , serverPage: ${serverPage} , serverPageSize: ${serverPagesize} , oldServerpage: ${oldServerpage} `);

    setPage(newPage);
    setFrom(newOffset + 1 + newPage * pageSize);
    setTo(newOffset + pageSize * (newPage + 1));

    setOldServerpage(serverPage);
  }, [serverPage, serverPagesize, offset]);

  React.useEffect(() => {
    let newPage = 0;
    setPage(newPage);
    setFrom(offset + 1 + newPage * pageSize);
    setTo(offset + pageSize * (newPage + 1));
    setOldServerpage(0);

  }, [queryid]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((col, colIndex) => (
                <StyledTableCell fontSize={fontSize} key={`head-${colIndex}`}>{col.label}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * pageSize, page * pageSize + pageSize).map((row, rowIndex) => (
              <StyledTableRow fontSize={fontSize} key={`row-${rowIndex}`}>
                {columns.map((col, colIndex) => (
                  <TsDataCell fontSize={fontSize} key={`${col.name}-${rowIndex}-${colIndex}`} value={row[col.name]} column={col} row={row} />
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
          { showPagging === true ?        
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columns.length || 99}>
                <Box fontSize={fontSize} fontWeight={400} sx={{ display: 'flex' }}>
                  <Box sx={{ flex: '1 1 100%' }}>
                    serverPage: {serverPage} serverPagesize: {serverPagesize} nrecs: {nrecs}
                  </Box>
                  <Box lineHeight={2} sx={{ flex: '0 0 auto' }}>
                    registros por pagina
                  </Box>
                  <Box lineHeight={2} marginLeft={2} marginRight={3} sx={{ flex: '0 0 auto' }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                      <Select
                        labelId="pagesize-label"
                        id="pagesize-id"
                        value={pageSize}
                        onChange={handleChangeRowsPerPage}
                        label="pageSize"
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box lineHeight={2} sx={{ flex: '0 0 auto' }}>
                    {from} - {to}
                  </Box>
                  <Box marginLeft={2} sx={{ flex: '0 0 auto' }}>
                    <IconButton onClick={() => handleChangePage(page - 1)}>
                      <ArrowLeftIcon />
                    </IconButton>
                  </Box>
                  <Box marginLeft={2} sx={{ flex: '0 0 auto' }}>
                    <IconButton onClick={() => handleChangePage(page + 1)}>
                      <ArrowRightIcon />
                    </IconButton>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter> : null}
        </Table>
      </TableContainer>
    </>
  );
};

// ActionsComponent={TablePaginationActions}

export default TsDataGrid;

/*

*/
