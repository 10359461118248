import { Box, Link, Tooltip } from '@mui/material';
import * as React from 'react';

import { httpProxy } from '../../util/http/proxy-http';
import relatorioHttp from '../../util/http/relatorio-http';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import {getProjetoStorage} from '../../util/auth';
import {random} from 'lodash';
import SelectCampo from '../../formulario/SelectCampo';

// <TsDataGridx />
const  TableProduto = (props) => {

    const serverPageSize = 50;
    const projAtual = getProjetoStorage();

    const [serverPage, setServerPage] = React.useState(0);
    const [objBusca, setObjBusca] = React.useState({busca: ''});
    const [rows, setRows] = React.useState([]);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [processando, setProcessando] = React.useState(false);
    const [queryid, setQueryid] = React.useState('');
    const [redeId, setRedeId] = React.useState('');
    const [lstRedes, setLstRedes] = React.useState([]);

    const columns = [
        {
            name: "codigoProduto",
            label: "Código",
            format: (value) => { return <Link component="button" onClick={(e) => selecionarCodigo(value)} >{value}</Link>}
        },
        {
            name: "produtoNome",
            label: "Nome"
        },
        {
            name: "campanhaId",
            label: "Campanha",
            format: (value) => { return <Link component="button" onClick={(e) => setObjBusca({...objBusca, busca: value})} >{value}</Link>}
        },
        {
            name: "rede",
            label: "rede",
            format: (value) => { return <Link component="button" onClick={(e) => setRedeId(value)} >{value}</Link>}
        },
        { name: "origem", label: "Origem"
        },
        {
            name: "desconto",
            label: "Desconto",
            format: (value) => {
                if (value) {
                    const obj = JSON.parse(value);
                    return <Tooltip title={obj.toolTip}>
                        <Box alignContent={"flex-end"}>{obj.valor}</Box>
                    </Tooltip>
                } else {
                    return "";
                }
            }
        },
        {
            name: "tipoDesconto",
            label: "Tipo"
        },
        {
            name: "codigoKit",
            label: "Kit"
        }
    ];

    const Buscar = async () => {
        setQueryid(random());
        setProcessando(true);
        setError(undefined);
        try {
        httpProxy.setDefaultProxy();
        const retorno = await httpProxy.buscaProduto(projAtual.id, redeId, objBusca.busca);

        if (retorno.sucesso === true && retorno.data !== undefined) {

            const prods = retorno.data.map((it) => {
                if (it.cashback) {
                    const cashback = (it.cashback) * 100;
                    it.tipoDesconto = "CASHBACK";
                    it.desconto = JSON.stringify({ valor: `${cashback.toFixed(2)} %`, toolTip: `${it.formulaCashback}` })
                }
                else if (it.descontoProgressivo) {
                    it.tipoDesconto = "PROGRESSIVO";
                    it.desconto = JSON.stringify({ valor: 'PROG', toolTip: `${it.descontoProgressivo}` })
                }
                else {
                    const desconto = it.desconto;
                    it.desconto = JSON.stringify({ valor: desconto.toFixed(2), toolTip: `DESCONTO: ${desconto.toFixed(2)} ` })
                }

                if (it.tipoDesconto === 'SEM_DESCONTO') {
                    it.tipoDesconto = '-';
                }

                return it;
            });
            setRows(prods);
        } else {
            setRows([]);
            //  mensagem: "falha ao obter produtos" });
        }

        } catch (error) {
            setError(error);
        }
    
        setProcessando(false);
        setUrl(httpProxy.getUrl());
        
    }

    const carregarRedes = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('rede', projAtual.id);
            const lista = [...data];
            if (lista.length > 0 && !redeId) {
                setRedeId(lista[0].value);
            }
            setLstRedes(lista);
        } catch (error) {
            setError(error);
        }
    }

    const selecionarCodigo = (value) => {

        if (!value) {
            return;
        }

        if (props.onSelect) {

            const prod = rows.find(p => p.codigoProduto === value);

            if (prod) {
                const preco = 100.00;
                props.onSelect(value, preco, prod.produtoNome);
            }

        }
        else {
            setObjBusca({...objBusca, busca: value}); 
        }

    };

    React.useEffect(() => {
        
        if (lstRedes.length === 0) {
            carregarRedes();
        }

        Buscar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objBusca, redeId]);

    
  
    return (
    <div>
        <TsInputBusca
            name="input-busca"
            value={objBusca.busca}
            showAtivo={false}
            showBusca={true}
            submitSearch={(texto, ativo) => { setObjBusca({...objBusca, busca: texto, ativo: ativo});}}
        >
            <Box sx={{flex: '0 0 auto', paddingRight: 2}}>
            <SelectCampo
                id="TsInputBusca-Rede"
                label="Rede"
                name="redeId"
                options={lstRedes}
                value={redeId || ''}
                onChange={(e) => setRedeId(e.target.value)}
                />
            </Box>

        </TsInputBusca>
        <TsDataGrid
            rows={rows}
            columns={columns}
            queryid={queryid}
            serverPage={serverPage}
            serverPagesize={serverPageSize}
            onChangePage={(value) => setServerPage(value)}
        />
        <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={processando} />
    </div>
    );
    
};

export default TableProduto;
