import * as React from 'react';
import { Box } from '@mui/material';
import TsDialog from '../../formulario/TsDialog';
import distribuidorHttp from '../../util/http/distribuidor-http';
import relatorioHttp from '../../util/http/relatorio-http';
import TsButton from '../../formulario/TsButton';
import { TsTextField } from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import {getProjetoStorage} from '../../util/auth';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';

const DistribuidorRedeDetail = (props) => {

    const projAtual = getProjetoStorage();
    const [dadosRegistro, setDadosRegistro] = React.useState({});
    const [listaRede, setListaRede] = React.useState([]);
    const {distribuidorId, redeId, processando, setProcessando} = props;
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState('');
    
    const limparCampos = () => {
        setDadosRegistro({
            redeId: redeId,
            distribuidorId: distribuidorId,
            nomeRede: '',
            categorias: '',
            dataAtivacao: '',
            ativo: 'true',

        });
    }

    const Buscar = async () => {
        
        try {
            limparCampos();
            if (redeId) {
                setProcessando(true);
                const {data} = await distribuidorHttp.obterRede(distribuidorId, redeId, projAtual.id);
                setProcessando(false);
                if (data.sucesso === true) {
                    const ativo = data.dado.ativo ? 'true' : 'false';
                    setDadosRegistro({...data.dado, ativo: ativo});
                }
                else {
                    props.onClose(false);
                    ShowAlert.error("registro não encontrado");
                }
            } else 
            {
                await carregarRedes();
            }
            
        } catch (error) {
            setProcessando(false);
            setUrl(distribuidorHttp.getUrl());
            setError(error);
        }
        
    }

    const carregarRedes = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('rede', projAtual.id);
            const lista = [{text: '...Selecione', value: ''}];
            data.forEach(item => {
                lista.push({text: item.text, value: Number(item.value) + ''});
            });
            setListaRede(lista);
            
        } catch (error) {
            console.error('erro ao carregar redes');
            console.error(error);
        }
    }

    const Atualizar = async () => {
        setProcessando(true);
        try {
            dadosRegistro.redeId = Number(dadosRegistro.redeId);
            dadosRegistro.projetoId = Number(projAtual.id);
            dadosRegistro.ativo = dadosRegistro.ativo === 'true' ? true : false;
            const {data} = await distribuidorHttp.atualizarRede(dadosRegistro, redeId);
            setProcessando(false);
            if (data.sucesso === true) {
                ShowAlert.info('dados atualizados');
                props.onClose();
            } else {
                ShowAlert.error(data.mensagem);
            }
        } catch (error) {
            setProcessando(false);
            setUrl(distribuidorHttp.getUrl());
            setError(error);
        }
    }

    React.useEffect(() => {
        if (props.open === true) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    return (
        <TsDialog onClose={(e) => props.onClose(false)} id="dialog-distrib-rede" open={props.open} title={props.titulo} minWidth={800}>
            
        {redeId === 0 ? 
            <>
                <Box><strong>Distribuidor #ID:</strong> {dadosRegistro.distribuidorId}</Box>
                <Box>
                    <SelectCampo
                        id='lst-rede'
                        label='Rede'
                        name='rede'
                        disabled={false}
                        options={listaRede}
                        value={dadosRegistro.redeId + '' || ''}
                        onChange={(e) => {setDadosRegistro({...dadosRegistro, redeId: Number(e.target.value)})}}
                    />
                </Box>
            </> : 
            <>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{flex: '1 1 auto'}}><strong>Distribuidor #ID:</strong> {dadosRegistro.distribuidorId}</Box>
                    <Box sx={{flex: '1 1 auto'}}><strong>Rede #ID:</strong> {dadosRegistro.redeId}</Box>
                </Box>
            </>
        }
        <Box sx={{display: 'flex'}}>
            <Box sx={{flex: '1 1 auto', padding: 2}}>
                <SelectCampo
                    id='lst-ativo'
                    label='Status'
                    name='ativo'
                    disabled={false}
                    options={[{ text: 'ATIVO', value: 'true' }, { text: 'INATIVO', value: 'false' }]}
                    value={dadosRegistro.ativo || 'true'}
                    onChange={(e) => {setDadosRegistro({...dadosRegistro, ativo: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: '1 1 auto', padding: 2}}>
                <TsTextField
                    name="dataAtivacao"
                    label="Ativação"
                    fullWidth
                    value={dadosRegistro.dataAtivacao || ''}
                    disabled={false}
                    onChange={(e) => {setDadosRegistro({...dadosRegistro, dataAtivacao: e.target.value})}}
                    />
            </Box>
        </Box>
        <Box dir="rtl">
            <TsButton
                disabled={processando}
                onClick={(e) => Atualizar()}
            >Salvar</TsButton>
            <TsButton
                onClick={(e) => props.onClose(false)}
                color='secondary'
            >Voltar</TsButton>
        </Box>
        
            <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        </TsDialog>
    )

}


export default DistribuidorRedeDetail;