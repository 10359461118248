import * as React from 'react';
import { Box, IconButton, Link, Paper, Tab, Tabs } from '@mui/material';
 
import CopyIcon from '@mui/icons-material/ContentCopy';
import TsButton from '../../formulario/TsButton';
import { TsTextField } from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import contratoHttp from '../../util/http/contrato-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';
import { listaProspectStatus, listaTipoEmpresa } from './contrato-lista'; 
import ProspectSigner from './prospect-signer';
import ProspectLocalidade from './prospect-localidade';
import ChaveBancaria from './chave-bancaria';

const propsPaper = {
    square: false, sx: {width: '100%', padding: 2, marginBottom: 2}, elevation: 2
}

const ProspectDetail = (props) => {

    
    const setDetail = props.setDetail;
    const detail = props.detail;
    const disabled = detail.disabled ? true: false;
    const [processando, setProcessando] = React.useState(false);
    const [contratoData, setContratoData] = React.useState({});
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [rows, setRows] = React.useState([]); // signers
    const [sites, setSites] = React.useState([]); // sites
    const [urlCadastroAjuste, setUrlCadastroAjuste] = React.useState("");
    const [showUrlAjuste, setShowUrlAjuste] = React.useState(false);
    const [tabIndex, setTabIndex] = React.useState(0);

    const LimpaCampos = () => {
        setContratoData({});
    }

    const Obter = async () => {
        if (processando) {
            return;
        }
        LimpaCampos();
        setProcessando(true);
        setError(undefined);
        
        try {
   
            const {data} = await contratoHttp.getProspect(detail.id);
            if (data?.id === detail.id) {
                const dados = data;
                dados.statusChanged = false;

                if (data.sites && data.sites !== null) {
                    const sites = JSON.parse(data.sites);
                    sites.forEach(item => {
                        item.endereco = `${item.endereco}, ${item.numero}`;
                        item.cidade = `${item.cidade}/${item.estado}`;
                        item.bairro = `${item.cep} ${item.bairro}`;
                    });
                
                    setSites(sites);
                }

                setRows(dados.signers);
                setContratoData(dados); 

                if (dados.prospectStatusId < 2) {

                    const cnpj = dados.cnpj
                    .replace(/[.\-/]/g, '');
                     
                    const token = dados.token;
                    let host = window.location.hostname;
                    if (host === 'localhost') host = 'localhost:3000';

                    const protocol = window.location.protocol;

                    const url = `${protocol}//${host}/cadastro/${cnpj}/${token}`;
                    setUrlCadastroAjuste(url);
                    setShowUrlAjuste(true);

                }
                else {
                    setUrlCadastroAjuste('');
                    setShowUrlAjuste(false);
                }

                console.log(dados);

                
            }
            else {
                setError("Nenhum registro encontrado");
            }
      
        } catch (error) {
            setError(error);
        } 
        setProcessando(false);
        setUrl(contratoHttp.getUrl());
    };

    const Atualizar = async () => {
        if (processando) {
            return;
        }
        setProcessando(true);
        setError(undefined);
        const contr = {...contratoData};

        try {

            const statusId = Number(contr.prospectStatusId);

            if (statusId === 1 && !contr.pendencia) {
                ShowAlert.error('Informar a pendência observada');
                setProcessando(false);
                return;
            }

            contr.prospectStatusId = statusId; ;
            
            const {data} = await contratoHttp.prospectUpdate(contratoData.id,  contr);
            
            if (data.sucesso === true) {
                setProcessando(false);
                ShowAlert.info('Prospect atualizado com sucesso!');
                if (statusId !== 1)
                    setDetail({open: false, id: 0, tipo: 'prospect', pageTitle: "Lista de Prospect"});
                else 
                    Obter();
            }
            else {
                ShowAlert.error(data.mensagem || "Erro ao atualizar registro");
            }
            
        } catch (error) {
            setError(error);
        }

        setProcessando(false);

    }
    
    const Aprovar = () => {
        setContratoData({...contratoData, statusChanged: true, prospectStatusId: '2'});
    }

    const Reprovar = () => {
        setContratoData({...contratoData, statusChanged: true, prospectStatusId: '90'});
    }

    const ClipCopy = (texto) => {
        navigator.clipboard.writeText(texto);
        ShowAlert.info("Url copiada com sucesso");
    }

    React.useEffect(() => {
        if (detail.open === true) {
            if (detail.id) {
                Obter();
            }
            else {
                LimpaCampos();
            }
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]);

    React.useEffect(() => {
        if (contratoData.statusChanged === true) {
            setContratoData({...contratoData, statusChanged: false});
            Atualizar();
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contratoData]);

    return (<>
    <Paper {...propsPaper} >
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                <SelectCampo
                    id='prospectStatusId'
                    label='situacao'
                    name='prospectStatusId'
                    disabled={true}
                    options={listaProspectStatus}
                    value={contratoData.prospectStatusId + '' || '0'}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                <SelectCampo
                    id='tipoEmpresaid'
                    label='Tipo Empresa'
                    name='tipoEmpresaId'
                    disabled={true}
                    options={listaTipoEmpresa}
                    value={contratoData.tipoEmpresaId + '' || '1'} 
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="nome_fantasia"
                    label="Nome fantasia"
                    fullWidth
                    value={contratoData.nome_fantasia || ''}
                    disabled={true}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", width: '250px', paddingRight: 2}}>
                <TsTextField
                    name="cnpj"
                    label="CNPJ"
                    fullWidth
                    value={contratoData.cnpj + '' || ''}
                    disabled={true}
                    />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "8 1 auto", paddingRight: 2}}>
                    {showUrlAjuste && !disabled && <>URL para ajustes:<br /> <Link href={urlCadastroAjuste} target='_blank' >{urlCadastroAjuste}</Link> <IconButton onClick={(e) => ClipCopy(urlCadastroAjuste)}><CopyIcon /></IconButton></>}
            </Box>
            <Box sx={{flex: "1 1 auto", width: '250px', paddingRight: 2}}>
                    Contrato Social <br />
                    {contratoData.contratoFile && contratoData.contratoFile !== null 
                    ? <><Link href={contratoData.contratoFile} target='_blank' >Visualizar</Link></>
                    : <>Não Enviado</>}
            </Box>
        </Box>
        {!disabled && 
        <>
        <Box sx={{ paddingRight: 2}}>
                <TsTextField
                    name="pendencia"
                    label="Pendências"
                    fullWidth
                    multiline={true}
                    rows={2}
                    value={contratoData.pendencia || ''}
                    disabled={false}
                    onChange={(e) => {setContratoData({...contratoData, pendencia: e.target.value})}}
                />
        </Box>
         <form>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "0 0 auto"}}>
                <TsButton
                    disabled={contratoData.prospectStatusId + '' !== '0' && contratoData.prospectStatusId + '' !== '1'}
                    color='error'
                    onClick={(e) => Aprovar()}
                >Aprovar</TsButton>
            </Box>
            <Box sx={{flex: "0 0 auto"}}>
                <TsButton
                    disabled={contratoData.prospectStatusId + '' !== '0' && contratoData.prospectStatusId + '' !== '1'}
                    color='error'
                    onClick={(e) => Reprovar()}
                >Reprovar</TsButton>
            </Box>
            <Box sx={{flex: "1 1 auto"}}></Box>
            <Box sx={{flex: "0 0  auto"}}>
                <TsButton
                    disabled={contratoData.prospectStatusId + '' !== '0' && contratoData.prospectStatusId + '' !== '1'}
                    onClick={(e) => {setContratoData({...contratoData, statusChanged: true, prospectStatusId: '1'});}}
                >Atualizar Pendência</TsButton>
            </Box>
            <Box sx={{flex: "0 0 auto"}}>
                <TsButton
                    onClick={(e) => setDetail({open: false, id: 0, tipo: 'prospect', pageTitle: "Lista de prospect"})}
                    color='secondary'
                >Voltar</TsButton>
            </Box>
        </Box>
        </form>
        </>}
    </Paper>
    <Box sx={{backgroundColor: '#FFF', margin: 1}}>
        <Tabs value={tabIndex} onChange={(e, i) => setTabIndex(i)} aria-label="Opções do Prospect">
            <Tab label="Geral" id="prosp-tab-0" aria-labelledby={`prosp-tabpanel-0`} />
            <Tab label="Localidades" id="prosp-tab-1" aria-labelledby={`prosp-tabpanel-1`} />
        </Tabs>
    </Box>
    <Paper {...propsPaper} >
        {tabIndex === 0 && 
        <>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "8 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="razao_social"
                        label="Razao Social"
                        fullWidth
                        value={contratoData.razao_social || ''}
                        disabled={true}
                        />
                </Box>
                <Box sx={{flex: "1 1 auto", width: '250px', paddingRight: 2}}>
                    <TsTextField
                        name="ie"
                        label="Inscrisao Estadual"
                        fullWidth
                        value={contratoData.ie || ''}
                        disabled={true}
                    />
                </Box>
                <Box sx={{flex: "1 1 auto", width: '250px', paddingRight: 2}}>
                    <TsTextField
                        name="nomeFranqueadora"
                        label="Franqueadora"
                        fullWidth
                        value={contratoData.nomeFranqueadora || ''}
                        disabled={true}
                    />
                </Box>
            </Box>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "8 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="endereco"
                        label="Endereço"
                        fullWidth
                        value={`${contratoData.logradouro} - CEP: ${contratoData.cep} - Bairro: ${contratoData.bairro} - Cidade: ${contratoData.cidade} - UF: ${contratoData.estado} `}
                        disabled={true}
                    />
                </Box>
                <Box sx={{flex: "1 1 auto", width: '350px', paddingRight: 2}}>
                    <ChaveBancaria
                        name="chaveBancaria"
                        disabled={true}
                        userLevel={1}
                        value={contratoData.chaveBancaria || ''}
                        onChange={(valor) => void(0)}
                    />
                </Box>
            </Box>
            <ProspectSigner open={true} rows={rows} />
        </>}
        {tabIndex === 1 && <><ProspectLocalidade open={true} rows={sites} /></>}

    </Paper>
   <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
    <TsBackDrop processando={processando} />
    </>)
}

export default ProspectDetail;
 
