// @flow 
import * as React from 'react';
import { Box, Button, Fab, Link } from '@mui/material';
import redeHttp from '../../util/http/rede-http';  
import relatorioHttp from '../../util/http/relatorio-http';
import DataGrid from '../../datagrid/data-grid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsButton from '../../formulario/TsButton';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';
import TsInputBusca from '../../formulario/TsInputBusca';
// import {toNumber, timeout} from '../../util/Util';
import {toNumber} from '../../util/Util';
import { getProjetoStorage } from '../../util/auth';  
import SelectCampo from '../../formulario/SelectCampo';
import EventNoteIcon from '@mui/icons-material/EventNote';
import {listaStatusEstabelecimento} from './rede-lista';
import { CSVLink } from "react-csv";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

let EstabelecimentoList_Processando = false;
let Resumo_Processando = false;
 
const EstabelecimentoList = (props) => {

    const {setDetail, listaSofthouse} = props;
 
    const projeto = getProjetoStorage();

    const [objBusca, setObjBusca] = React.useState({busca: '', redeId: '', status: '', serverPageSize: 500,  serverPage: 0 });
    
    const [rows, setRows] = React.useState([]);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const pageSize = props.pageSize || 25;
    const showPagging = true; 
    const [executing, setExeccuting] = React.useState(false);
    const [listaRede, setListaRede] = React.useState([]);
    const [resumo, setResumo] = React.useState({}); 
    const [queryResumo, setQueryResumo] = React.useState('');
    const downloadFileName = 'lista-estabelecimentos.csv';
    const csvLinkEl = React.createRef();

    const onResult = (result) => {
        console.log('onResult:', result);
        if (result === undefined || result === null)
            return;

        if (result.processando) {
            setRows([]);
            setExeccuting(true);
            return;
        }

        setExeccuting(false);
        

        if (result.sucesso === false) {
            if (result.error) {
                setError(result.error);
                setUrl(result.url);
                return;
            }
            ShowAlert.error(result.mensagem || 'Falha ao processar request'); 
        }

        const dados = result.dados;

        if (dados === undefined || !Array.isArray(dados) || dados.length === 0) 
            return;
        else {
            // dados.forEach(it => {});
            setRows(dados);
        }
    }

    /* 
        public long cnpj { get; set; }
        public string maskCnpj { get; set; }
        public string lojaId { get; set; }
        public string nome { get; set; }
        public string uf { get; set; }
        public string cidade { get; set; }
        public string bairro { get; set; }
        public string rede { get; set; }
        public string tipo { get; set; }
        public string terminais { get; set; }
    */

    const columns = [
        {name: "maskCnpj",label: "Cnpj"},
        {name: "tipo",    label: "Tipo"},
        {name: "lojaId",  label: "Loja"},
        {name: "nome",    label: "Nome"},
        {name: "cidade",  label: "Cidade"},
        {name: "bairro",  label: "Bairro"},
        {name: "rede",    label: "Rede"},
        {name: 'status',  label: 'status'},
        {name: 'data',    label: 'ùltimo acesso'},
        {name: "redeId",  label: "Detalhes",
            format: ((value) => (<Link component={Button} onClick={(e) => {setDetail({open: true, id: value, pageTitle: "Detalhes da rede"})}}><EventNoteIcon /></Link>))
        }
    ];

    const columnsCsv = [
        {name: 'maskCnpj',      key: 'maskCnpj',    show: true,  label: 'CNPJ'},
        {name: "tipo",          key: 'tipo',        show: true,  label: "Tipo"},
        {name: "lojaId",        key: 'lojaId',      show: true,  label: "Loja"},
        {name: "rede",          key: 'rede',        show: true,  label: "Rede"},
        {name: "nome",          key: 'nome',        show: true,  label: "Nome"},
        {name: "bairro",        key: 'bairro',      show: true,  label: "Bairro"},
        {name: "cidade",        key: 'cidade',      show: true,  label: "Cidade"},
        {name: "uf",            key: 'uf',          show: true,  label: "Estado"},
        {name:'status',         key: 'status',      show: true,  label: 'status'},
        {name:'data',           key: 'data',        show: true,  label: 'ùltimo acesso'},
    ];

    const carregarRedes = async () => {
        if (EstabelecimentoList_Processando)
            return;

        EstabelecimentoList_Processando = true;
        try {
            const {data} = await relatorioHttp.obterLista('rede', projeto.id);
            const lista = [{text: '...Selecione', value: ''}];
            data.forEach(item => {
                lista.push({text: item.text, value: Number(item.value) + ''});
            });
            setListaRede(lista);
            
        } catch (error) {
            console.error('erro ao carregar redes');
            console.error(error);
        }
        EstabelecimentoList_Processando = false;
    }

    const obterResumo = async () => {
        if (Resumo_Processando) return;
        Resumo_Processando = true;
        try {
            const softhouseId = objBusca.softhouseId ? objBusca.softhouseId + '' : '0';
            const redeId = objBusca.rede ? objBusca.rede + '': '0';
            const {status, data} = await redeHttp.obterResumo(projeto.id, softhouseId, redeId);
            if (status === 200)
                setResumo(data);
        } catch (error) {
            console.log('erro ao carregar resumo', error.mensagem || error.message);
        }
        Resumo_Processando = false;
    }

    const atualizarStatus = async () => {
        try {
            const {status, data} = await redeHttp.estabelecimentoUpdateStatus();
            if (status === 200)
                ShowAlert.info('status atualizado');
        } catch (error) {
            ShowAlert.error(error.mensagem || error.message);
            console.log('erro ao atualizar status', error.mensagem || error.message);
        }
    }

    const limpaMascara = (tempo) => {
        if (!tempo) return ''; 
        if (tempo.indexOf('/') > -1 && tempo.length > 8) {
            tempo = tempo.replace(/\//g, '').replace(/\./g, '').replace(/-/g, '');
        }
        return tempo;
    }
 
    const query = { 
            page: objBusca.serverPage, 
            nrecPage: objBusca.serverPageSize,
            tipo: "1",
            busca: limpaMascara(objBusca.busca),
            status: objBusca.status, 
            projetoId: toNumber(projeto.id), 
            redeId: toNumber(objBusca.rede),
            softhouseId: toNumber(objBusca.softhouseId),
        };

    const OnClickDownLoad = async () => {
        csvLinkEl.current.link.click(); 
    }

    React.useEffect(() => {
        const qResumo = JSON.stringify({rede: toNumber(objBusca.rede), softhouseId: toNumber(objBusca.softhouseId)});

        if (qResumo !== queryResumo) {
            setQueryResumo(qResumo);
            obterResumo();
        }

        if (listaRede.length === 0) {
            carregarRedes();
        }

    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objBusca]);

    return (
        <Box>
            <CSVLink
                headers={columnsCsv}
                filename={downloadFileName}
                data={rows}
                ref={csvLinkEl}
                separator={";"}
            />
            <TsInputBusca
                name="input-busca"
                value={objBusca.busca}
                showBusca={true}
                submitSearch={(texto, ativo) => {
                    setObjBusca(b => ({...b, busca: texto}));
                }}
            >
            <Box sx={{display: 'flex'}} >
            <Box sx={{flex: '0 0 auto', width: '200px', paddingRight: 2}}>
                    <SelectCampo
                        id="TsInputBusca-Status"
                        label="Status"
                        name="status"
                        options={listaStatusEstabelecimento}
                        value={objBusca.status || ''}
                        onChange={(e) => setObjBusca({ ...objBusca,  status: e.target.value})}
                        />
            </Box>
            <Box sx={{flex: '0 0 auto', width: '200px', paddingRight: 2}}>
                    <SelectCampo
                        id="TsInputBusca-Rede"
                        label="Rede"
                        name="rede"
                        options={listaRede || []}
                        value={objBusca.rede || ''}
                        onChange={(e) => setObjBusca({ ...objBusca,  rede: e.target.value})}
                        />
            </Box>
            <Box sx={{flex: '0 0 auto', width: '200px', paddingRight: 2}}>
                    <SelectCampo
                        id="TsInputBusca-Softhouse"
                        label="Softhouse"
                        name="softhouse"
                        options={listaSofthouse || []}
                        value={objBusca.softhouseId && objBusca.softhouseId !== null ?  objBusca.softhouseId : ''}
                        onChange={(e) => setObjBusca({ ...objBusca,  softhouseId: e.target.value})}
                        />
            </Box>
            <Box sx={{flex: "0 0 auto", paddingTop: 0.5, paddingLeft: 2}} ><Fab
                title="Download"
                color='alert'
                size="small"
                onClick={(e) => OnClickDownLoad()}
            >    
                    <CloudDownloadIcon />
                </Fab>
            </Box> 
            </Box>
            </TsInputBusca>
            <DataGrid
                columns={columns}
                rows={rows}
                pageSize={pageSize}
                showPagging={showPagging}
                query={query}
                onResult={(result) => onResult(result)}
                endPoint={{instancia: redeHttp, metodo: 'buscaEstabelecimento'}}
            />
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto'}}></Box>
                <Box sx={{flex: '0 0 auto'}}><TsButton onClick={(e) => atualizarStatus()}>Atualizar Status</TsButton></Box> 
            </Box>
            <Box sx={{padding: 2}}>*** RESUMO ***</Box>
            <Box sx={{padding: 2, borderTop: '1px solid #777'}}>total de Redes Ativas: {resumo.totalRedes}</Box>
            <Box sx={{padding: 2, borderTop: '1px solid #777'}}>
                 CNPJ sem configuração de Lojas
            </Box>
            <Box sx={{display: 'flex', padding: 2}}>
                <Box sx={{flex: '1 1 auto', width:'240px'}}>Estabelecimentos: {resumo.totalEstabelecimentos}</Box>
                <Box sx={{flex: '1 1 auto', width:'240px'}}>Online: {resumo.totalEstabelecimentosOnline}</Box>
                <Box sx={{flex: '1 1 auto', width:'240px'}}>Com transações: {resumo.totalEstabelecimentosTransacao}</Box>
                <Box sx={{flex: '1 1 auto', width:'240px'}}>Offline: {resumo.totalEstabelecimentosOffline}</Box>
            </Box>
            <Box sx={{padding: 2, borderTop: '1px solid #777'}}>
                 Lojas , CNPJ configurado como Loja
            </Box>
            <Box sx={{display: 'flex', padding: 2}}>
                <Box sx={{flex: '1 1 auto', width:'240px'}}>Lojas: {resumo.totalLocalidades}</Box>
                <Box sx={{flex: '1 1 auto', width:'240px'}}>Online: {resumo.totalLocalidadesOnline}</Box>
                <Box sx={{flex: '1 1 auto', width:'240px'}}>Com transações: {resumo.totalLocalidadesTransacao}</Box>
                <Box sx={{flex: '1 1 auto', width:'240px'}}>Offline: {resumo.totalLocalidadesOffline}</Box>
            </Box>
            <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
            <TsBackDrop processando={executing} />
        </Box>
    );
}

export default EstabelecimentoList;