import * as React from 'react';
import { Box, Button, Link } from '@mui/material';
import distribuidorHttp from '../../util/http/distribuidor-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';
import TsButton from '../../formulario/TsButton';
import { TsTextField } from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import { getProjetoStorage } from '../../util/auth';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ProductsIcon from '@mui/icons-material/ShoppingCart';
import TsDataGrid from '../../formulario/TsDataGrid';
import DistribuidorRedeDetail from './distribuidor-rede-detail';
import DistribuidorRedeProduto from './distribuidor-rede-produto';
import AddButtonEvent from '../../formulario/AddButtonEvent';
import ShowAlert from '../../util/ShowAlert';

let DistribuidorDetail_processando = false;

const DistribuidorDetail = (props) => {

    const setDetail = props.setDetail;
    const detail = props.detail;
    const [executing, setExeccuting] = React.useState(false);
    const [distribuidorData, setDistribuidorData] = React.useState({});
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [redeDetailOpen, setRedeDetailOpen] = React.useState(false);
    const [redeProductOpen, setRedeProductOpen] = React.useState(false);
    const [redeId, setRedeId] = React.useState(0);
    const [tituloDetail, setTituloDetail] = React.useState("Distribuidor - Rede");
    const projAtual = getProjetoStorage();

    const [serverPage, setServerPage] = React.useState(0);
    // const [serverPageSize, setServerPageSize] = React.useState(50);
    const serverPageSize = 50;
    const queryid = '' + Math.floor(Math.random() * 1000000000);

    const setProcessando = (value) => {
        DistribuidorDetail_processando = value;
        setExeccuting(value);
    }

    const [rows, setRows] = React.useState([]);

    const columns = [
        {
            name: "redeId",
            label: "Código",
            format: ((value) => (<Link component={Button} onClick={(e) => AbrirDetalheRede(value)}><EventNoteIcon /></Link>))
        },
        {
            name: "redeNome",
            label: "nome"
        },
        {
            name: "dataAtivacao",
            label: "Ativação"
        },
        {
            name: "ativo",
            label: "status",
            format: ((value) => (value ? <>Ativo</> : <>Inativo</>))
        },
        {
            name: "categorias",
            label: "categorias"
        },
        {
            name: "redeId",
            label: "produtos",
            format: ((value) => (<Link component={Button} onClick={(e) => AbrirDetalheProdutos(value)}><ProductsIcon /></Link>))
        }
    ];

    const AbrirDetalheProdutos = (id) => {
        setRedeId(id);
        let redeNome = '...SELECIONE'
        if (id !== 0) {
            const item = rows.find(r => r.redeId === id);
            if (item !== undefined) redeNome = item.redeNome;
        } 
        setTituloDetail(`${distribuidorData.nome} - REDE: ${redeNome}`);
        setRedeProductOpen(true);
    }

    const AbrirDetalheRede = (id) => {
        setRedeId(id);
        let redeNome = '...SELECIONE'
        if (id !== 0) {
            const item = rows.find(r => r.redeId === id);
            if (item !== undefined) redeNome = item.redeNome;
        } 
        setTituloDetail(`${distribuidorData.nome} - REDE: ${redeNome}`);
        setRedeDetailOpen(true);
    }

    const Obter = async () => {
        if (DistribuidorDetail_processando) {
            return;
        }
        LimpaCampos();
        
        setProcessando(true);
        setError(undefined);
        
        
        try {
   
            const {data} = await distribuidorHttp.get(detail.id);
            if (data.sucesso === true && data.dado) {
                const dados = data.dado;
                dados.ativo = dados.ativo ? 'true': 'false';

                setDistribuidorData(dados); 
                setTituloDetail(`${dados.nome} - Rede`);

                const redesRet = await distribuidorHttp.listRedes(detail.id, projAtual.id);

                if (redesRet.data.sucesso === true && redesRet.data.dados && redesRet.data.dados.length > 0) {
                    setRows(redesRet.data.dados);
                }
                else {
                    ShowAlert.info("Nenhuma rede encontrada");
                }

            }
            else {
                ShowAlert.info("Nenhum registro encontrado");
            }
        } catch (error) {
            setError(error);
        } 
        setProcessando(false);
        setUrl(distribuidorHttp.getUrl());
    };

    const LimpaCampos = () => {
        setDistribuidorData({
            id: 0,
            nome: '',
            dataAtivacao: '',
            ativo: 'true',
            comissaoFaturamento: ''
        });
        setRows([]);
    }

    const Atualizar = async () => {
        if (DistribuidorDetail_processando === true)
            return;

        setProcessando(true);

        try {

            const model = {
                    "id": distribuidorData.id,
                    "nome": distribuidorData.nome,
                    "dataAtivacao": distribuidorData.dataAtivacao,
                    "ativo": distribuidorData.ativo === 'false' ? false: true,
                    "comissaoFaturamento": Number(distribuidorData.comissaoFaturamento)
            }

            const {data} = model.id > 0 
                ? await distribuidorHttp.update(model.id, model)
                : await distribuidorHttp.create(model);
            
            setProcessando(false);
            if (data.sucesso === true) {
                ShowAlert.info('dados atualizados');
                if (distribuidorData.id === 0) {
                    setDetail({...detail, open: true, id: data.dado});
                }
                else {
                    Obter();
                }
            }
            else {
                ShowAlert.error(data.mensagem);
            }

        } catch (error) {
            setUrl(distribuidorHttp.getUrl());
            setProcessando(false);
            setError(error);
        }
    }

    React.useEffect(() => {
        if (detail.open === true && redeDetailOpen === false) {
            if (detail.id) {
                Obter();
            }
            else {
                LimpaCampos();
            }
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail, redeDetailOpen]);

    return (<>
        <Box>
            <Box>Detalhes de distribuidor</Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto', padding: 2}}>
                    <TsTextField
                        name="nome"
                        label="Nome"
                        fullWidth
                        value={distribuidorData.nome || ''}
                        disabled={false}
                        onChange={(e) => {setDistribuidorData({...distribuidorData, nome: e.target.value})}}
                        />
                </Box>
                <Box sx={{flex: '1 1 auto', padding: 2}}>
                    <TsTextField
                        name="dataAtivacao"
                        label="Ativacao"
                        fullWidth
                        value={distribuidorData.dataAtivacao || ''}
                        disabled={false}
                        onChange={(e) => {setDistribuidorData({...distribuidorData, dataAtivacao: e.target.value})}}
                        />
                </Box>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto', padding: 2}}>
                    <SelectCampo
                        id='lst-ativo'
                        label='Status'
                        name='ativo'
                        disabled={false}
                        options={[{ text: 'ATIVO', value: 'true' }, { text: 'INATIVO', value: 'false' }]}
                        value={distribuidorData.ativo || 'true'}
                        onChange={(e) => {setDistribuidorData({...distribuidorData, ativo: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: '1 1 auto', padding: 2}}>
                    <TsTextField
                        name="comissaoFaturamento"
                        label="comissao"
                        fullWidth
                        value={distribuidorData.comissaoFaturamento || ''}
                        disabled={false}
                        onChange={(e) => {setDistribuidorData({...distribuidorData, comissaoFaturamento: e.target.value})}}
                        />
                </Box>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto'}}>Lista das redes associadas</Box>
                <Box><AddButtonEvent title="Adicionar item" onClick={() => AbrirDetalheRede(0)} /></Box>
            </Box>
            
            <TsDataGrid
                    rows={rows}
                    columns={columns}
                    queryid={queryid}
                    serverPage={serverPage}
                    serverPagesize={serverPageSize}
                    onChangePage={(value) => setServerPage(value)}
                    />
            <Box dir="rtl">
                <TsButton
                    disabled={executing}
                    onClick={(e) => Atualizar()}
                >Salvar distribuidor</TsButton>
                <TsButton
                    onClick={(e) => setDetail({open: false, id: 0, pageTitle: "Lista de Distribuidores"})}
                    color='secondary'
                >Voltar</TsButton>
            </Box>
            <DistribuidorRedeDetail
                titulo={tituloDetail}
                onClose={(e) => setRedeDetailOpen(false)}
                open={redeDetailOpen}
                redeId={redeId}
                distribuidorId={detail.id}
                processando={executing}
                setProcessando={setProcessando}
            />

            <DistribuidorRedeProduto
                titulo={tituloDetail}
                onClose={(e) => setRedeProductOpen(false)}
                open={redeProductOpen}
                redeId={redeId}
                distribuidorId={detail.id}
                processando={executing}
                setProcessando={setProcessando}
            />
        
        </Box>
        <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={executing} />
        
        
    </>);
}

export default DistribuidorDetail;