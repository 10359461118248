import { validarForm } from './CampoBase';

export const CampanhaDefault = {
  campanhaChaveId: 0,
  versaoId: '',
  projetoId: 1033,
  campanhaTipoId: 0,
  campanhaGrupoId: 0,
  campanhaNome: '',
  dataInicio: '01/01/2020',
  dataFinal: '01/01/2021',
  msgCupom: '',
  flgGeraChave: false,
  qtdeMin: 0,
  flgBeneficiosMultiplos: false,
  url: '',
  ddd: '',
  telefone: '',
  precoSugerido: 0,
  produtosAprovados: false,
  ativo: true,
  uf: 'BR',
  flgClassificacao: false,
};

export const CampanhaCampos = {
  campanhaChaveId: { name: 'campanhaChaveId', label: 'Código', tipo: 'number', max: 9, min: 1 },
  versaoId: { name: 'versaoId', label: 'Versão', tipo: 'string', max: 32, min: 0 },
  projetoId: { name: 'projetoId', label: 'Projeto', tipo: 'number', max: 9, min: 1 },

  campanhaTipoId: {
    name: 'campanhaTipoId',
    label: 'Tipo',
    tipo: 'number',
    max: 9,
    min: 1,
    opcoes: [
      { valor: '0', label: 'Sem limite' },
      { valor: '1', label: 'Limite produto cliente' },
      { valor: '2', label: 'Limite cliente' },
    ],
  },

  campanhaGrupoId: { name: 'campanhaGrupoId', label: 'Grupo', tipo: 'number', max: 9, min: 1 },
  campanhaNome: { name: 'campanhaNome', label: 'Nome campanha', tipo: 'string', max: 50, min: 10 },
  dataInicio: { name: 'dataInicio', label: 'Início', tipo: 'string', max: 10, min: 10 },
  dataFinal: { name: 'dataFinal', label: 'Final', tipo: 'string', max: 10, min: 10 },
  msgCupom: { name: 'msgCupom', label: 'Mensagem cupom', tipo: 'string', max: 2000, min: 10 },
  flgGeraChave: { name: 'flgGeraChave', label: 'Gerar chave', tipo: 'boolean', max: 1, min: 0 },
  qtdeMin: { name: 'qtdeMin', label: 'Qtde Mínima', tipo: 'number', max: 14, min: 0 },
  flgBeneficiosMultiplos: { name: 'flgBeneficiosMultiplos', label: 'Tem beneficios mútiplos', tipo: 'boolean', max: 1, min: 1 },
  url: { name: 'url', label: 'URL campanha', tipo: 'string', max: 200, min: 0 },
  ddd: { name: 'ddd', label: 'DDD', tipo: 'string', max: 2, min: 0 },
  telefone: { name: 'telefone', label: 'Telefone', tipo: 'string', max: 16, min: 0 },
  precoSugerido: { name: 'precoSugerido', label: 'Preço base', tipo: 'number', max: 14, min: 0 },
  produtosAprovados: { name: 'produtosAprovados', label: 'Produtos aprovados', tipo: 'boolean', max: 1, min: 1 },
  ativo: { name: 'ativo', label: 'Ativo', tipo: 'boolean', max: 1, min: 1 },
  uf: { name: 'uf', label: 'UF', tipo: 'string', max: 2, min: 2 },
  flgClassificacao: { name: 'flgClassificacao', label: 'Classificação?', tipo: 'boolean', max: 2, min: 2 },
};

export const validarCampanha = (camp) => {
  return validarForm(camp, CampanhaCampos);
};
