import * as React from 'react';
// 
import { Box, Button, Link, Tab, Tabs, Typography } from '@mui/material';
import { TsTextField } from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import TsButton from '../../formulario/TsButton';
import usuarioHttp from '../../util/http/usuario-http';
import { timeout } from '../../util/Util';
import ShowAlert from '../../util/ShowAlert';
import {listaFiltro, listaTipo, permOptions} from './usuario-lista';
import { v4 as uuidv4 } from 'uuid';
import {TsTextMask} from '../../formulario/TsTextMask';
import {getUser} from '../../util/auth';

const boxProps = {
    borderColor: '#B9B9B9',
    border: 1
};


const UsuarioForm = (props) => {
    const [id, setId] = React.useState(props.id);
    const setProcessando = props.setProcessando;
    const closeModal = props.closeModal;
    const atual = getUser('admin');
    
    const [userData, setUserData] = React.useState({filtros: {}});
    const [senha, setSenha] = React.useState('');
    const [tabIndex, setTabIndex] = React.useState(0);
    const [showSenha, setShowSenha] = React.useState(false);
    
    const handleTabPanel = (event, newValue) => {
        setTabIndex(newValue);
      };

    const userPermit = (chave, permit) => {
        const item = permit.find(us => us.modulo === chave);
        if (!item) return 0;
        return item.permit;
    } 

    const ReadData = () => {
        if (id + '' === '0') {
            setUserData({
                login: '',
                tipo: 'powerbi',
                descricao: '', 
                perfilPowerBI: '', 
                filtros: {"projetoId": 5000}, 
                xapikey: '', 
                modAdmin: 0, 
                modCampanha: 0, 
                modParticipante: 0, 
                modProxy: 0,
                modFaturamento: 0, 
                modPowerbi: 1, 
                modHome: 1,
                modSimulador: 0,
                modRede: 0,
                modProduto: 0,
                usuarioPermit: '[]',
                filtroTipo: '',
                filtroRegistro: '',
                urlDashLogo: '',

            });
            return;
        }
        setProcessando(true);
        usuarioHttp
            .get(id)
            .then(({data}) => {
                
                const usuario = {...data.dado};
                const permit = JSON.parse(data.dado.usuarioPermit);

                usuario.modAdmin = userPermit("admin", permit);
                usuario.modCampanha = userPermit("campanha", permit);
                usuario.modSimulador = userPermit("simulador", permit);
                usuario.modHome = userPermit("home", permit);
                usuario.modProxy = userPermit("proxy", permit);
                usuario.modFaturamento = userPermit("faturamento", permit);
                usuario.modParticipante = userPermit("participante", permit);
                usuario.modPowerbi = userPermit("powerbi", permit);
                usuario.modHome = userPermit("home", permit);
                usuario.modSimulador = userPermit("simulador", permit);
                usuario.modProduto = userPermit("produto", permit);
                usuario.modRede = userPermit("rede", permit);

                usuario.filtroRegistro = usuario.filtroRegistro && usuario.filtroRegistro !== null ? usuario.filtroRegistro : 'xxxx';

                usuario.filtros = tryJson(usuario.filtros);

                setUserData(usuario);

                console.log("UserData", usuario);

            })
            .catch((error) => {
                ShowAlert.error(error.mensagem || error.message);
                console.log('ERROR', error);
                // this.setError(error);
            })
            .finally(() => {
                //this.setUrl(usuarioHttp.getUrl());
                setProcessando(false);
            });
            
    };

    const updatePermit = (permit, chave, valor) => 
    {
        let finded = false;
        permit.forEach(item => {
            if (item.modulo === chave) {
                item.permit = valor;
                finded = true;
            }
        });
        if (!finded) {
            permit.push({modulo: chave, permit: valor});
        }
        return permit;
    }

    const ChangePassword = async () => {
        let passw = senha + '';
        passw = passw.replace(/\s/g, '');
        if (!passw || passw.length < 8 ) {
            ShowAlert.error(`senha Invalida`);
            return;
        }
        const usuario = {
            id: id,
            login: userData.login,
            senha: passw
        };

        setProcessando(true);

        try {
            await usuarioHttp.update(id, usuario);
            ShowAlert.info("senha alterada com sucesso");
            await timeout(2000);
            setProcessando(false);
            closeModal();
        } catch (error) {
            ShowAlert.error("ERRO ao gravar dados");
            console.log("ERROR", error);
        }
        setProcessando(false);
    }

    const WriteData = async () => {

        const usuario = {...userData};

        if (!usuario.filtros || typeof(usuario.filtros) !== 'object') {
            ShowAlert.error("Filtros objeto inválido");
            return;
        }

        usuario.filtros = JSON.stringify(usuario.filtros);

        if (id === 0 && !usuario.login) {
            ShowAlert.error("Login inválido");
            return;
        }

        if (!usuario.tipo) {
            ShowAlert.error("Tipo usuário inválido");
            return;
        }

        if (usuario.tipo === 'api-pos') {
            if (!usuario.redeId) {
                ShowAlert.error("Usuários POS devem ter uma rede associada");
                return; 
            }
            usuario.filtroTipo = 'rede';
            usuario.filtroRegistro = usuario.redeId + '';
            usuario.filtros = JSON.stringify({redeId: Number(usuario.redeId)});
        }
        else if (usuario.filtroTipo === '') {
            ShowAlert.error("Selecione um filtro para esse usuario");
            return; 
        }
        else if (usuario.filtroTipo === 'rede') {
            if (!usuario.redeId) {
                ShowAlert.error("Usuários de REDE devem ter uma rede associada");
                return; 
            }
            usuario.filtroRegistro = usuario.redeId + '';
            
        }

        setProcessando(true);

        try {
        
            let permit = JSON.parse(usuario.usuarioPermit);
            permit = updatePermit(permit, "admin", usuario.modAdmin);
            permit = updatePermit(permit, "campanha", usuario.modCampanha);
            permit = updatePermit(permit, "produto", usuario.modCampanha);
            permit = updatePermit(permit, "simulador", usuario.modSimulador);
            permit = updatePermit(permit, "home", usuario.modHome);
            permit = updatePermit(permit, "proxy", usuario.modProxy);
            permit = updatePermit(permit, "faturamento", usuario.modFaturamento);
            permit = updatePermit(permit, "participante", usuario.modParticipante);
            permit = updatePermit(permit, "powerbi", usuario.modPowerbi);
            permit = updatePermit(permit, "rede", usuario.modRede);
            permit = updatePermit(permit, "produto", usuario.modProduto);

            usuario.usuarioPermit = JSON.stringify(permit);

            if (id + '' === '0') {
                usuario.id = 0;
                usuario.senha = uuidv4() + ''; 
                usuario.senha = usuario.senha.replace(/0/, '@');
                usuario.senha = usuario.senha.replace(/1/, 'l');
                usuario.senha = usuario.senha.replace(/2/, 'z');
                usuario.senha = usuario.senha.replace(/3/, 'e');
                usuario.senha = usuario.senha.replace(/4/, 'M');
                usuario.senha = usuario.senha.replace(/5/, 'S');
                usuario.senha = usuario.senha.replace(/-/, '%');
                usuario.senha = usuario.senha.substr(0, 8);
            }
            else if (id + '' !== usuario.id + '') {
                ShowAlert.error('Falha interna ao atualizar usuario');
                setProcessando(false);
                return;
            }
            else {
                // evita trocar senha por essa função. temos troca de senha em outra função
                usuario.id = Number(id);
                usuario.senha = "";
            }

            const {status, data} = await usuarioHttp.update(id, usuario);
            console.log(`status: ${status} - data:`, data);
            if (id === 0 && data.sucesso === true) {
                ShowAlert.info("Usuário incluido com sucesso");
                await timeout(1000);
                setId(data.dado); 
                setProcessando(false);
                return;
            } 
            ShowAlert.info("Usuário atualizado com sucesso");
            await timeout(2000);
            setProcessando(false);
            closeModal();

        } catch (error) {
            ShowAlert.error("ERRO ao gravar dados");
            console.log("ERROR", error);  
        }

        setProcessando(false);
    
    }

    const changeRede = (valor) => {
        const filtros = {...userData.filtros};
        filtros.redeId = Number(valor);
        setUserData({...userData, redeId: valor, filtros});
    }

    const changeFiltroTipo = (valor) => {
        // console.log(`changeFiltroTipo: [${valor}] userData:`, userData);
        if (valor === '') {
            setUserData({...userData, filtroTipo: valor});
            return;
        }
        let redeId = userData.redeId;
        const filtros = {...userData.filtros};
        let filtroRegistro = userData.filtroRegistro;
        if (valor !== userData.filtroTipo) {
            delete filtros.redeId;
            delete filtros.industriaId;
            delete filtros.softhouseId;
            delete filtros.cnpj;
            filtroRegistro = '';

            if (valor === 'admin') {
                delete filtros.projetoId;
                filtroRegistro = '0';
            }
            else {
                filtros.projetoId = 5000;
            }
        }

        if (valor === 'rede' || valor === 'cnpj') {
            if (!redeId || redeId === null) redeId = '';
            filtros.redeId = Number(redeId);
        }
        else {
            redeId = '';
        }

        setUserData({...userData, filtroTipo: valor, filtroRegistro, filtros, redeId})
    }

    const changeFiltroRegistro = (valor) => {
        const tipo = userData.filtroTipo;
        const filtros = userData.filtros;
        let redeId = userData.redeId;
        if (tipo === 'rede') {
            filtros.redeId = Number(valor);
            redeId = valor;
        }
        else if (tipo === 'cnpj') {
            filtros.cnpj = Number(valor);
            filtros.redeId = Number(redeId);
        }
        else if (tipo === 'softhouse') {
            filtros.softhouseId = Number(valor);
        }
        else if (tipo === 'projeto') {
            filtros.projetoId = Number(valor);
        }
        else if (tipo === 'industria') {
            filtros.industriaId = Number(valor);
        }

        setUserData({...userData, filtroRegistro: valor, filtros, redeId})
    }

    const tryJson = (valor) => {
        if (!valor || valor === null) return {};
        try {
            const obj = JSON.parse(valor);
            if (obj && obj !== null) return obj;
            else return {};
        } catch (error) {
            return {};
        }
    }

    React.useEffect(() => {
        ReadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (<>
         
                            <Box {...boxProps}
                                borderRadius={4}
                                padding={0.5}
                                paddingRight={2}
                            >
                                <Box sx={{display: 'flex'}}>
                                    <Box sx={{flex: '1 1 auto', paddingLeft: 0.5}}><TsTextField
                                     name="login"
                                     label="Login"
                                     fullWidth
                                     value={userData.login || ''}
                                     disabled={false}
                                     onChange={(e) => {setUserData({...userData, login: e.target.value})}}
                                    /></Box>
                                    <Box sx={{flex: '0 0 auto', width: '200px', paddingLeft: 0.5}}>
                                        <SelectCampo
                                            id='selTipo'
                                            label='Tipo'
                                            name='tipo'
                                            disabled={false}
                                            options={[{value: '', text: '... selecionar'}, ...listaTipo]}
                                            value={userData.tipo || ''}
                                            onChange={(e) => {setUserData({...userData, tipo: e.target.value})}}
                                        />
                                    </Box>
                                </Box>

                                <Box sx={{display: 'flex'}}>
                                    <Box sx={{flex: '1 1 auto', paddingLeft: 0.5}}>
                                        <TsTextField
                                            name="descricao"
                                            label="Descrição"
                                            fullWidth
                                            value={userData.descricao || ''}
                                            disabled={false}
                                            onChange={(e) => {setUserData({...userData, descricao: e.target.value})}}
                                        />
                                    </Box>
                                    {userData.tipo !== 'api-pos' && <Box sx={{flex: '0 0 auto', width: '200px', paddingLeft: 0.5}}>
                                        <TsTextField
                                            name="perfilPowerBI"
                                            label="perfilPowerBI"
                                            fullWidth
                                            value={userData.perfilPowerBI || ''}
                                            disabled={false}
                                            onChange={(e) => {setUserData({...userData, perfilPowerBI: e.target.value})}}
                                        />
                                    </Box>}
                                </Box>
                                <Box sx={{display: 'flex'}}>
                                    {userData.tipo === 'api-pos' && 
                                    <Box sx={{flex: '1 1 auto', paddingLeft: 0.5}}>
                                        <TsTextField
                                        name="xapikey"
                                        label="X-Api-Key"
                                        fullWidth
                                        value={userData.xapikey || ''}
                                        disabled={false}
                                        onChange={(e) => {setUserData({...userData, xapikey: e.target.value})}}
                                        />
                                    </Box> }
                                    {userData.tipo !== 'api-pos' && <>
                                    <Box sx={{flex: '0 0 auto', width: '200px', paddingLeft: 0.5}}>
                                        <SelectCampo
                                            id='selFiltro'
                                            label='Filtro - Tipo'
                                            name='filtroTipo'
                                            disabled={false}
                                            options={[{value: '', text: '... selecionar'}, ...listaFiltro]}
                                            value={userData.filtroTipo || ''}
                                            onChange={(e) => changeFiltroTipo(e.target.value)}
                                        />
                                    </Box> 
                                    <Box sx={{flex: '1 1 auto', paddingLeft: 0.5}}>
                                        <TsTextMask
                                            name="filtroRegistro"
                                            label="Filtro - Valor"
                                            fullWidth
                                            mask="00000000000000"
                                            inputProps={{ maxLength: 18 }}
                                            value={userData.filtroRegistro || ''} 
                                            disabled={false}
                                            onChangeValue={(v) => changeFiltroRegistro(v)}
                                        /> 
                                    </Box>
                                    
                                    </> }
                                    <Box sx={{flex: '0 0 auto', width: '200px', paddingLeft: 0.5}}>
                                        <TsTextMask
                                            name="redeId"
                                            label="Rede"
                                            fullWidth
                                            mask="00000"
                                            inputProps={{ maxLength: 5 }}
                                            value={userData.redeId || ''}
                                            disabled={userData.filtroTipo === 'cnpj' ? false: true}
                                            onChangeValue={(v) => changeRede(v)}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ paddingLeft: 0.5}}>
                                    <TsTextField
                                    name="filtros"
                                    label="Filtros"
                                    fullWidth
                                    value={JSON.stringify(userData.filtros) || ''}
                                    disabled={atual.login === 'egoncalez' ? false: true}
                                    />
                                </Box>
                                {userData.tipo !== 'api-pos' && <Box sx={{flex: '1 1 auto', paddingLeft: 0.5}}>
                                    <TsTextField
                                    name="urlDashLogo"
                                    label="Logotipo"
                                    fullWidth
                                    value={userData.urlDashLogo || ''}
                                    disabled={false}
                                    onChange={(e) => {setUserData({...userData, urlDashLogo: e.target.value})}}
                                    />
                                </Box> }
                                
                                <Tabs value={tabIndex} onChange={handleTabPanel} aria-label="Opções Formulario">
                                    <Tab label="Permissões" id="user-tab-0" aria-labelledby={`user-tabpanel-0`} />
                                    <Tab label="Senha" id="user-tab-1" aria-labelledby={`user-tabpanel-1`} /> 
                                </Tabs>

                                {tabIndex === 0 && <>
                                <Box sx={{display: 'flex'}}>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModHome'
                                            label='Home'
                                            name='modHome'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modHome + ''}
                                            onChange={(e) => {setUserData({...userData, modHome: e.target.value})}}
                                        />
                                    </Box>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModPowerbi'
                                            label='Dashboard'
                                            name='modPowerbi'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modPowerbi + ''}
                                            onChange={(e) => {setUserData({...userData, modPowerbi: e.target.value})}}
                                        />
                                    </Box>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModFaturamento'
                                            label='Faturamento'
                                            name='modFaturamento'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modFaturamento + ''}
                                            onChange={(e) => {setUserData({...userData, modFaturamento: e.target.value})}}
                                        />
                                    </Box>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModSimulador'
                                            label='Simulador'
                                            name='modSimulador'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modSimulador + ''}
                                            onChange={(e) => {setUserData({...userData, modSimulador: e.target.value})}}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{display: 'flex'}}>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>

                                    </Box>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModRede'
                                            label='Rede'
                                            name='modRede'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modRede + ''}
                                            onChange={(e) => {setUserData({...userData, modRede: e.target.value})}}
                                        />
                                    </Box>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModProduto'
                                            label='Produto'
                                            name='modProduto'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modProduto + ''}
                                            onChange={(e) => {setUserData({...userData, modProduto: e.target.value})}}
                                        />
                                    </Box>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        
                                    </Box>
                                </Box>

                                <Box sx={{display: 'flex'}}>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModAdmin'
                                            label='Admin'
                                            name='modAdmin'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modAdmin + ''}
                                            onChange={(e) => {setUserData({...userData, modAdmin: e.target.value})}}
                                        />
                                    </Box>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModCampanha'
                                            label='Campanha'
                                            name='modCampanha'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modCampanha + ''}
                                            onChange={(e) => {setUserData({...userData, modCampanha: e.target.value})}}
                                        />
                                    </Box>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModParticipante'
                                            label='Participante'
                                            name='modParticipante'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modParticipante + ''}
                                            onChange={(e) => {setUserData({...userData, modParticipante: e.target.value})}}
                                        />
                                    </Box>
                                    <Box sx={{flex: '1 0 auto', width: '186px', paddingLeft: 1}}>
                                        <SelectCampo
                                            id='selModProxy'
                                            label='Proxy'
                                            name='modProxy'
                                            disabled={false}
                                            options={permOptions}
                                            value={userData.modProxy + ''}
                                            onChange={(e) => {setUserData({...userData, modProxy: e.target.value})}}
                                        />
                                    </Box>

                                </Box>
                                

                                <Box dir="rtl">
                                    <TsButton
                                        onClick={(e) => WriteData()}
                                    >Enviar</TsButton>
                                    <TsButton
                                        onClick={(e) => closeModal()}
                                        color='secondary'
                                    >Cancelar</TsButton>
                                </Box>

                                </> }

                                {tabIndex === 1 && <>

                                <Box sx={{paddingLeft: 2, textAlign: 'center'}}>
                                    <Typography >
                                    A senha de cada usuário é particular, o painel permite a troca. Evite divulgar senhas, <br />
                                    Para novos usuarios passe a <Link component={Button} onClick={(e) => setShowSenha(!showSenha)} >senha inicial</Link>  em particular! <br />
                                     {showSenha && userData.senha  ? userData.senha : "********"}
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex'}}>
                                    <Box sx={{flex: '1 0 auto', paddingLeft: 2}}>
                                        <TsTextField
                                                name="senha"
                                                label="Nova Senha"
                                                fullWidth
                                                value={senha || ''}
                                                disabled={false}
                                                onChange={(e) => {setSenha(e.target.value)}}
                                        />
                                    </Box>
                                    <Box sx={{flex: '0 0 auto', paddingLeft: 2}}>
                                        <TsButton
                                            onClick={(e) => ChangePassword()}
                                        >Enviar nova senha</TsButton>
                                    </Box>
                                </Box>

                                </>}

                                {  /* {
                                ** "modHome": 4,
                                *** "modProduto": 4,
                                *** "modProxy": 4,
                                *** "modParticipante": 4,
                                *** "modPowerbi": 4,
                                "redeId": 7,
                                *** "perfilPowerBI": "RSL_Todos",
                                "filtros": "[]",
                                "senha": null,
                                ** "id": 1,
                                ** "login": "egoncalez",
                                ** "descricao": "Edison Goncalez - Zicard",
                                ** "tipo": "backoffice",
                                ** "modAdmin": 4,
                                ** "modCampanha": 4,
                                ** "modSimulador": 4,
                                ** "modFaturamento": 4
                            } */}
                                 
                                
                                
                            </Box>
                       
    </>);
}

export default UsuarioForm;