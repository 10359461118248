import * as React from 'react';
import { IconButton, Menu as MuiMenu, MenuItem, FormControl, InputLabel, Select, Box, Link, Button } from '@mui/material';
import { HOMEDIR, setUser, usuarioNull, getProjetoStorage, setProjetoStorage, getUser } from '../util/auth';
import PersonIcon from '@mui/icons-material/Person';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import projetoHttp from '../util/http/projeto-http';
import ShowAlert from '../util/ShowAlert';
import { parseISO } from 'date-fns';


const MenuUsuario = (props) => {

    const setOpenPerfil = props.setOpenPerfil;
    const [projetos, setProjetos] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [atual, setAtual] = React.useState(getUser('home'));
    const [showSelect, setShowSelect] = React.useState(false);
    const projAtual = getProjetoStorage();
    
    const open = Boolean(anchorEl);

    const handleOpen = (event) => setAnchorEl(event.currentTarget);

    const handleClose = (e) => {
        setAnchorEl(null);
      };

    const logout = () => {
        setUser(usuarioNull);
        setTimeout(() => {
          window.location.href = `${HOMEDIR}/`;
        }, 500);
      };

      const obterMinutosExpiracao = () => {
        if (atual.id === 0 || !atual.expires) return '0';
    
        const agora = new Date();
        const expires = parseISO(atual.expires);
        const delta = (expires.valueOf() - agora.valueOf()) / (1000 * 60);
        return delta.toFixed(0);
      };

      const obterProjetos = () => {
        if (atual.id !== 0 && atual.role !== 'powerbi') {
          projetoHttp
          .busca({ page: 0, nrecPage: 100, ativo: true })
          .then(({ data }) => {
            const lista = data.dados;
            if (lista && lista.length > 0) {
              const projs = [];
              lista.forEach((it) => projs.push({ id: it.id, nome: it.projetoNome }));
              setProjetos(projs);
            }
          })
          .catch((error) => {
            ShowAlert.error('Erro ao listar projetos');
          })
          .finally(() => {});
        }
        
      };

     

    React.useEffect(() => {

        if (projetos.length < 2) {
            obterProjetos();
        }

        const usuario = getUser('home');
        if (usuario.id !== atual.id) {
          setAtual(usuario);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 

    return (
    <>
    <Box sx={{display: 'flex', flex: '0 0 auto'}}>
        <IconButton
            edge="start"
            color="inherit"
            sx={{ marginLeft: '10px', marginRight: '10px'}}
            aria-label="abrir menu usuario"
            aria-controls="menu-usuario"
            aria-haspopup="true"
            onClick={handleOpen}
        >
            <PersonIcon
            />
        </IconButton>
        <MuiMenu
            id="menu-usuario"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <MenuItem onClick={(e) => setOpenPerfil(true)}>{atual.login}</MenuItem>
            <MenuItem>expira em {obterMinutosExpiracao()} Minutos</MenuItem>
            <MenuItem onClick={logout}>LOGOUT</MenuItem>
            {projetos.length > 1 && 
            <MenuItem>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="sel-projeto-label">Projeto</InputLabel>
                <Select
                  sx={{ borderBottom: '1px solid #DDD' }}
                  id="sel-projeto"
                  labelId="sel-projeto-label"
                  name="Projeto"
                  fullWidth
                  value={projAtual.id}
                  onChange={(e, c) => {
                    const sel = projetos.find((p) => p.id === e.target.value);
                    if (sel) {
                      setProjetoStorage(sel.id, sel.nome); 
                      setTimeout(() => {
                        window.location.href = `${HOMEDIR}/`;
                      }, 500);
                    }
                  }}
                >
                  <MenuItem value="">... Selecione</MenuItem>
                  {projetos.map((it, i) => {
                    return (
                      <MenuItem key={i} value={it.id}>
                        {it.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </MenuItem>}
        </MuiMenu>
    </Box>
    </>
    );
}

export default MenuUsuario;

/*
<Button color="inherit" onClick={() => logout()}>
    Logout
</Button>
Logado como: {atual.login} , expira em {obterMinutosExpiracao()} Minutos
*/

