// @flow
import * as React from 'react';
import { OutlinedInput } from '@mui/material';
import {processMask} from '../util/Util';

 
export const TsInputMask = (props) => {
  const p = {...props };
  p.fullWidth = props.fullWidth === undefined ? true : props.fullWidth;
  p.variant = props.variant || 'outlined';
  // p.margin = props.margin || 'normal';
  p.mask = props.mask || '000000000000000';
  const onChangeValue = p.onChangeValue;
  delete p.onChangeValue;

  const handleValueChange = (event) => {
    event.preventDefault();
    let str = event.target.value; // The new value entered by the user
    const result = processMask(str, p.mask);

    onChangeValue(result);
  };

  return <OutlinedInput type="text" value={p.value} onChange={handleValueChange} {...p} />;
 
  
};

 
