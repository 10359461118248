import * as React from 'react';
import {TsTextField} from '../../formulario/TsTextField';
import {Box} from '@mui/material';
import TsButton from '../../formulario/TsButton';
import ShowError from '../../formulario/ShowError';
import { monitorCSS } from '../../style';
import httpQrcode from '../../util/http/qrcode-http';
import { v4 as uuidv4 } from 'uuid';
import ShowAlert from '../../util/ShowAlert';

const QrcodeAnalyzer = (props) => {

    const {  htmlCode, chaveAcesso, cupomid, token } = props;
    const [dataText, setDataText] = React.useState(htmlCode);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState('');
    
    const makeCupomData = () => {
        const temp = {};
        temp.chave = chaveAcesso;
        temp.uuid = uuidv4();
        temp.cnpj = chaveAcesso.substring(6,6 + 14);
        temp.serie = chaveAcesso.substring(22,22 + 3);
        temp.numero = chaveAcesso.substring(25, 25 + 9);
        temp.modelo = chaveAcesso.substring(20, 20 + 2);
        temp.status = 'processando';
        temp.nota_valida = false;
        return temp;
        // xx -> cUf (0, 2)
        //   xxxx -> AAMM (2, 4)
        //       xxxxxxxxxxxxxx -> cnpj (6, 14)
        //                     xx -> modelo (20, 2)
        //                       xxx -> serie (22, 3)
        //                          xxxxxxxxx -> nf num (25, 9)
        // 00000000001111111111222222222233333333334444
        // 01234567890123456789012345678901234567890123
        // 35250100772675000159590012135971233587146926
        // Grupo	Quantidade de caracteres
        // cUF 	- estado de localização do emitente			    02 35
        // AAMM 	- ano e mês da emissão						04 2501
        // CNPJ 	- CNPJ do emitente							14 00772675000159
        // mod 	- modelo do documento fiscal				    02 59
        // Série 	- série do documento fiscal					03 001
        // nNF-e 	- número da NF-e/NFC-e						09 213597123
        // tpEmis 	- forma de emissão da NF-e/NFC-e			01 3
        // cNF 	- código numérico da chave de acesso		    08 58714692
        // cDV 	- dígito verificador da chave de acesso 	    01 6
    }

    const [cupomData, setCupomData] = React.useState(makeCupomData());

    const convertHtmlToJson = () => {
        const result = cupomData;
        let str = dataText.replace(/(?:\r\n|\r|\n)/g, ' ');
        
        str = str.replace(/\t/g, ' ');
        while(str.indexOf('  ') >= 0) {
            str = str.replace(/ {2}/g, ' ');
        }
        str = str.replace(/> </g, '><');

        str = str.replace(/<table/g, '\n<table');
        str = str.replace(/<div/g, '\n<div');
        str = str.replace(/<tr/g, '\n<tr');
        str = str.replace(/<td/g, '\n<td');
        str = str.replace(/<th/g, '\n<th');

        setDataText(str);
      
        let re = undefined;
        let tbGeral = '';
        let tbProdutos = '';

        let arr = [];
        

        // <legend class="TituloCampo">Dados do CF-e</legend>
        re = /(<table .*?<legend class="TituloCampo">Dados do CF-e<\/legend>.*?<\/table>).*$/gms;
        arr = re.exec(str);
        if (arr !== null) {
            tbGeral = arr[1];
        }
        

        re = /<legend class="TituloCampo">Produtos e Serviços<\/legend>.*?(<table .*?<\/table>).*$/gms;
        arr = re.exec(str);
        if (arr !== null) {
            tbProdutos = arr[1];
        }

        if (tbGeral === '' || tbProdutos === '') {
            result.status = 'invalido';
            result.errorMessage = 'Table geral ou produtos não encontrado no HML';
            setCupomData(result);
            return;
        }

  
        setDataText(str);
         
        result.emitente = {};
        result.consumidor = {};
        result.produtos = [];

        // <span id="conteudo_lblChaveAcesso" class="ValorCampo">CFe35250116699609000130590011424282126297213594</span>
        re = /<span id="conteudo_lblChaveAcesso" class="ValorCampo">CFe([0-9]{44})<\/span>.*$/gms;
        const chaveAcessoHml = buscarDado(re,tbGeral);

        if (chaveAcessoHml !== chaveAcesso) { 
            result.status = 'invalido';
            result.errorMessage = 'chaveAcesso invalida para o HTML';
            setCupomData(result);
            return;
        }

        // <span id="conteudo_lblEmitenteCnpj" class="ValorCampo">00.772.675/0001-59</span>
        re = /<span id="conteudo_lblEmitenteCnpj" .*?>(.*?)<\/span>.*$/gms;
        result.emitente.cnpj = buscarDado(re, tbGeral);
        
        if (result.emitente.cnpj === '') {
            result.status = 'invalido';
            result.errorMessage = 'Emitente nao encontrado';
            setCupomData(result); 
            return;
        }
        
        // <span id="conteudo_lblEmitenteNome" class="ValorCampo">COMERCIAL HORTIFRUTI DE COTIA LTDA</span>
        re = /<span id="conteudo_lblEmitenteNome" .*?>(.*?)<\/span>.*$/gms;
        result.emitente.razao_social = buscarDado(re, tbGeral);

        // <span id="conteudo_lblEmitenteNomeFantasia" class="ValorCampo">TERRAIZ SACOLAO</span>
        re = /<span id="conteudo_lblEmitenteNomeFantasia" .*?>(.*?)<\/span>.*$/gms;
        result.emitente.nome_fantasia = buscarDado(re, tbGeral);

        // <span id="conteudo_lblEmitenteInscricaoEstatual" class="ValorCampo">278.056.274.118</span>
        re = /<span id="conteudo_lblEmitenteInscricaoEstatual" .*?>(.*?)<\/span>.*$/gms;
        result.emitente.ie = buscarDado(re, tbGeral);

        // <span id="conteudo_lblEmitenteUf" class="ValorCampo">35</span>
        re = /<span id="conteudo_lblEmitenteUf" .*?>(.*?)<\/span>.*$/gms;
        result.emitente.uf = buscarDado(re, tbGeral);

        // <span id="conteudo_lblDestinatarioCnpj" class="ValorCampo">Não Informado</span> (cpf do consumidor)
        re = /<span id="conteudo_lblDestinatarioCnpj" .*?>(.*?)<\/span>.*$/gms;
        result.consumidor.cpf = buscarDado(re, tbGeral);

        // <span id="conteudo_lblDestinatarioNome" class="ValorCampo">XXX</span> ( nome do consumidor )
        re = /<span id="conteudo_lblDestinatarioNome" .*?>(.*?)<\/span>.*$/gms;
        result.consumidor.nome = buscarDado(re, tbGeral);

        // <span id="conteudo_lblDataHora" class="ValorCampo">06/01/2025 13:57:03</span>
        // <span id="conteudo_lblDataEmissao" class="ValorCampo">2025-01-03 15:19:39</span>
        re = /<span id="conteudo_lblDataEmissao" .*?>(.*?)<\/span>.*$/gms;
        result.data_emissao = buscarDado(re, tbGeral);

        re = /(<tr.*?>.*?<\/tr>)/gms;

        const arrTrProdutos = tbProdutos.matchAll(re);

        let nota_valida = false;

    
        for (const trProduto of arrTrProdutos) {
       

            // <span id="conteudo_grvProdutosServicos_lblProdutoServicoNum_0" class="ValorCampo">1</span>
            let reItem = /<span id="conteudo_grvProdutosServicos_lblProdutoServicoNum_([0-9]{1,3})" .*?>(.*?)<\/span>.*$/gms;
            arr = reItem.exec(trProduto);

            if (arr === null) {
                // result.mensagens.push(`NOT FOUND, item nao encontrado na tr index: ${prodIndex}`);
            } else {
                let item = arr[2];
                // result.mensagens.push(`OK, item encontrado na tr index: ${prodIndex} item: ${item}`);
                let codigoProduto = '';
                let nomeProduto = '';
                let qtde = '';
                let valor = '';
                let ean = '';
                let unidade = '';
                // Num|Desc|Qtd|Unit|CodigoProduto|Gtin|ValorBruto

                reItem = /<span id="conteudo_grvProdutosServicos_lblProdutoServicoDesc_([0-9]{1,3})" .*?>(.*?)<\/span>.*$/gms;
                arr = reItem.exec(trProduto);
                if (arr !== null) nomeProduto = arr[2];

                reItem = /<span id="conteudo_grvProdutosServicos_lblProdutoServicoQtd_([0-9]{1,3})" .*?>(.*?)<\/span>.*$/gms;
                arr = reItem.exec(trProduto);
                if (arr !== null) {
                    qtde = arr[2].replace(/\./g, '');
                    qtde = qtde.replace(/,/g, '.');
                }

                reItem = /<span id="conteudo_grvProdutosServicos_lblProdutoServicoUnit_([0-9]{1,3})" .*?">(.*?)<\/span>.*$/gms;
                arr = reItem.exec(trProduto);
                if (arr !== null) unidade = arr[2];

                reItem = /<span id="conteudo_grvProdutosServicos_lblProdutoServicoCodigoProduto_([0-9]{1,3})" .*?>(.*?)<\/span>.*$/gms;
                arr = reItem.exec(trProduto);
                if (arr !== null) codigoProduto = arr[2];

                reItem = /<span id="conteudo_grvProdutosServicos_lblProdutoServicoGtin_([0-9]{1,3})" .*?>(.*?)<\/span>.*$/gms;
                arr = reItem.exec(trProduto);
                if (arr !== null) ean = arr[2];

                reItem = /<span id="conteudo_grvProdutosServicos_lblProdutoServicoValorBruto_([0-9]{1,3})" .*?">(.*?)<\/span>.*$/gms;
                arr = reItem.exec(trProduto);
                if (arr !== null) {
                    valor = arr[2].replace(/\./g, '');
                    valor = valor.replace(/,/g, '.');

                } 

                result.produtos.push({item, codigo: codigoProduto, nome: nomeProduto, quantidade: qtde, unidade, gtin: ean, total: valor});
                nota_valida = true;

                
            }
 
        }

        result.nota_valida = nota_valida;
        result.status = nota_valida ? 'concluido' : 'error';
        

     
        setCupomData(result);
        return;
 
        
 
    }

    const buscarDado = (re, data) => {

        const arr = re.exec(data);

        if (arr === undefined || arr === null) return '';

        return arr[1];
        
        
    }

    const processarCupom = async () => {
        if (cupomData.status !== 'concluido') {
            ShowAlert.error(`analise do HMTL deve ser concluida`);
            return;
        }
        try {
            const retUpdate = await httpQrcode.processarCupom(token, cupomData);
            if (retUpdate.status === 200) {
                ShowAlert.info('callback processado com sucesso');
            }
            else {
                ShowAlert.error(`callback, httperror: ${retUpdate.status}`);
            }
        } catch (error) {
            setUrl(httpQrcode.getUrl());
            setError(error);
        }
    }
    
    return (
            <>
            <TsTextField 
                    name="htmlCode"
                    label="htmlCode" 
                    fullWidth
                    multiline={true}
                    minRows={6}
                    value={dataText}
                    onChange={(e) => setDataText(e.target.value) }
            />
            <Box>
                <TsButton variant="contained" onClick={(e) => convertHtmlToJson() } >Converter</TsButton>
                <TsButton variant="contained" disabled={cupomData.status !== 'concluido'} onClick={(e) => processarCupom()} >Processar</TsButton>
            </Box>
            <Box sx={monitorCSS}>{JSON.stringify(cupomData, null, 2)}</Box>
            <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
            </>
 
    );
};

export default QrcodeAnalyzer;