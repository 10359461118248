// @flow
import * as React from 'react';
import { TsInputMask } from './TsInputMask';
import {FormControl, InputLabel} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

 
export const TsTextMask = (props) => {
  const p = { ...props };
  p.fullWidth = props.fullWidth === undefined ? true : props.fullWidth;
  // p.variant = props.variant || 'outlined';
  // p.margin = props.margin || 'normal';
  p.id = props.id || uuidv4();


  const sx = p.sx ? p.sx : {margin: 1};
  delete p.sx;
 
  return (
  <FormControl fullWidth variant="outlined" sx={sx}  >
    <InputLabel htmlFor={p.id}>{p.label}</InputLabel>
    <TsInputMask {...p}  />
  </FormControl>
  );
};
