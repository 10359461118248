import * as React from 'react';
import backlogHttp from '../../util/http/backlog-http';
import {Link, Button, Box} from '@mui/material';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import SelectCampo from '../../formulario/SelectCampo';
import ShowError from '../../formulario/ShowError';
import EventNoteIcon from '@mui/icons-material/EventNote';
import listaSituacoes from './situacoes';

const BackLogList = (props) => {
    const setDetail = props.setDetail;
    const detail = props.detail;
    const [rows, setRows] = React.useState([]);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [processando, setProcessando] = React.useState(false); 
    const [serverPage, setServerPage] = React.useState(0);
    const serverPageSize = 50;
    const [objBusca, setObjBusca] = React.useState({busca: '', situacao: 'ALL'});
    const [queryid, setQueryid] = React.useState("");

    const columns = [
        {
            name: "id",
            label: "Código",
            format: ((value) => (<Link component={Button} onClick={(e) => {setDetail({open: true, id: value, pageTitle: "Detalhes do backlog"})}}><EventNoteIcon /></Link>))
        },
        {
            name: "dataInclusao",
            label: "Inclusão"
        },
        {
            name: "percentual",
            label: "percentual"
        },
        {
            name: "titulo",
            label: "titulo"
        },
        {
            name: "situacao",
            label: "situacao"
        }
    ];

    const Buscar = async () => {
        if (processando) {
            return;
        }
        setQueryid("");
        setProcessando(true);
        
        try {
            setRows([]);
            let situacao = objBusca.situacao || '';
            if (situacao === 'ALL') situacao = '';
            console.log(` buscando busca: ${objBusca.busca} , situacao: ${situacao} `);
            const query = { page: serverPage, nrecPage: serverPageSize, ativo: true, busca: objBusca.busca, situacao: situacao };
            const {data} = await backlogHttp.busca(query);
            if (data.sucesso === true && data.dados && data.dados.length > 0) {
                setRows(data.dados);
            }
            else {
                setError("Nenhum registro encontrado");
            }
        } catch (error) {
            setError(error);
        } 
        setProcessando(false);
        setUrl(backlogHttp.getUrl());
    }

    React.useEffect(() => {
        if (detail.open === false) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    React.useEffect(() => {
        if (detail.open === false) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objBusca]);

    return (
    <div>
        <>
            <TsInputBusca
                name="input-busca"
                value={objBusca.busca}
                showBusca={true}
                showAtivo={false}
                showAdd={true}
                submitSearch={(texto, ativo) => { setObjBusca({...objBusca, busca: texto}); }}
                onClickAdd={(e) => {setDetail({open: true, id: 0, pageTitle: "Novo backlog"})}}
            >
                <Box sx={{flex: '0 0 auto', paddingRight: 2}}>
                    <SelectCampo
                        id="TsInputBusca-Situacao"
                        label="Situação"
                        name="situacao"
                        options={[{ text: 'TODOS', value: 'ALL' }, ...listaSituacoes]}
                        value={objBusca.situacao || ''}
                        onChange={(e) => setObjBusca({ ...objBusca,  situacao: e.target.value})}
                        />
                </Box>
            </TsInputBusca>
        </>
        <TsDataGrid
                rows={rows}
                columns={columns}
                queryid={queryid}
                serverPage={serverPage}
                serverPagesize={serverPageSize}
                onChangePage={(value) => setServerPage(value)}
                />
        {error !== undefined && <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />}
        <TsBackDrop processando={processando} />
    </div>)
}

export default BackLogList;