import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class OfertaHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
  }

  // /classificacao/{campanhaId}/{perfil}
  deleteClassificacao(campanhaId, classificacaoNome) {
    this.url = `/${this.resource} - DELETE`;
    return this.http.delete(`${this.resource}/${campanhaId}/${classificacaoNome}`, this.Config());
  }
}

const classificacaoHttp = new OfertaHttpResource(httpTsApi, 'classificacao');

export default classificacaoHttp;
