 import { styled, TableHead, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const ThTableCell = styled(TableCell)(({ theme, fontSize }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    }
    ,
    [`&.${tableCellClasses.body}`]: {
      fontSize: fontSize,
    },
  }));

const Header = (props) => {
    const {columns} = props;
    return (<TableHead>
      <TableRow>
        {columns.map((col, colIndex) => (
            <ThTableCell key={`head-${colIndex}`}>{col.label}</ThTableCell>
        ))}
      </TableRow>
    </TableHead>);
  }

export default Header;