export const listaSituacoesBusca = [
  { text: 'Todos pendentes', value: '0' },
  { text: 'Aguardando assinatura', value: '3' },
  { text: 'Assinado, ag. ativação', value: '4' },
  { text: 'Ativo', value: '9' },
  { text: 'Todos inativos', value: '90' },
];
export const listaSituacoes = [
  { text: 'Incluido', value: '1' },
  { text: 'Aguardando Zapsign', value: '2' },
  { text: 'Aguardando Assinatura', value: '3' },
  { text: 'Assinado, ag. ativação', value: '4' },
  { text: 'Ativo', value: '9' },
  { text: 'Vencido', value: '90' },
  { text: 'Inativo', value: '91' },
  { text: 'Cancelado', value: '92' },
  { text: 'Finalidado', value: '93' },
  { text: 'Erro zapsign', value: '94' },
];

export const listaEntidadeTipo = [
  { text: '... SELECIONE', value: '' },
  { text: 'REDE', value: 'REDE' },
  { text: 'INDUSTRIA', value: 'INDUSTRIA' },
  { text: 'DISTRIBUIDOR', value: 'DISTRIBUIDOR' },
  { text: 'SOFTHOUSE', value: 'SOFTHOUSE' },
];

export const listaSignerStatus = [
  { value: '0', text: 'Contrato em espera' },
  { value: '1', text: 'Aguardando zapsign' },
  { value: '2', text: 'Aguardando assinatura' },
  { value: '3', text: 'Contrato suspenso' },
  { value: '4', text: 'Contrato assinado' },
];

export const listaTipoEmpresa = [
  { value: '1', text: 'Varejista simples' },
  { value: '2', text: 'Varejista rede' },
  { value: '3', text: 'Distribuidor' },
  { value: '4', text: 'Automação' },
];

export const listaContratoSignerTipo = [
  { value: '1', text: 'Representante legal' },
  { value: '2', text: 'Fornecedor' },
  { value: '3', text: 'Testemunha' },
  { value: '4', text: 'Sócio' },
];

export const listaProspectStatusBusca = [
  { value: '-1', text: 'Todos Pendentes' },
  { value: '0', text: 'Incluido' },
  { value: '1', text: 'Com pendências' },
  { value: '2', text: 'Aprovado' },
  { value: '90', text: 'Reprovado' },
];

export const listaProspectStatus = [
  { value: '0', text: 'Incluido' },
  { value: '1', text: 'Pendências' },
  { value: '2', text: 'Aprovado' },
  { value: '90', text: 'Reprovado' },
];
