export const formProdutoDefault = {
  id: 0,
  projetoId: 0,
  produtoCodigo: '',
  produtoNome: 'produto não selecionado',
  precoRef: '',
  campanhaChaveId: 0,
  ofertaItemId: 0,
  codigoKit: '',
  qtdeKit: '',
  alterado: false,
};

export const formCampanhaDefault = {
  campanhaChaveId: 0,
  projetoId: 0,
  campanhaNome: '',
  dataInicio: '',
  dataFinal: '',
  redeId: 0,
  uf: '',
  flagClassificacao: false,
  classificacoes: [],
  alterado: false,
};

export const formOfertaDefault = {
  ofertaItemId: 0,
  campanhaChaveId: 0,
  desconto: '',
  valorDescZicard: '',
  descontoTipoId: 1,
  descontoProgressivo: '',
  cashbackTipoId: 0,
  cashback: '',
  alterado: false,
  ofertaTabIndex: 0,
};
