import { HOMEDIR } from '../util/auth';
import Dashboard from '../pages/Dashboard';
import CampanhaListar from '../pages/campanha/CampanhaListar';
import CampanhaForm from '../pages/campanha/CampanhaForm';
import ProdutoBuscar from '../pages/produto/produto-buscar';
import ParticipanteListar from '../pages/participante/ParticipanteListar';
import LimiteListar from '../pages/limite/LimiteListar';
import LimiteForm from '../pages/limite/LimiteForm';
import OfertaForm from '../pages/oferta/OfertaForm';
import Simulador from '../pages/simulador/index';
import Transacao from '../pages/relatorio/Transacao';
import ProdutoDesconto from '../pages/produto/ProdutoDesconto';
import ProxyStatus from '../pages/proxy/proxy-status';
import ProxyProduto from '../pages/proxy/proxy-produto';
import ProxyCampanha from '../pages/proxy/proxy-campanha';
import ProxyConfig from '../pages/proxy/proxy-config';
import TransacaoPend from '../pages/relatorio/TransacaoPend';
import DashPowerbi from '../pages/relatorio/dash-powerbi';
import DashPage from '../pages/dashes/dash-page';
import Cadastro from '../pages/home/cadastro';
import Faturamento from '../pages/relatorio/faturamento';
import UsuarioListar from '../pages/usuario/usuario-listar';
import BackLogPage from '../pages/backlog/backlog-page';
import DistribuidorPage from '../pages/distribuidor/distribuidor-page';
import DescontoControlePage from '../pages/descontocontrole/dc-page';
import RedePage from '../pages/rede/rede-page';
import SofthousePage from '../pages/rede/softhouse-page';
import QrcodePage from '../pages/qrcode/qrcode-page';
import QrCodeAdmPage from '../pages/qrcode-adm/qrcode-page';
import ContratoPage from '../pages/faturamento/contrato-page';

const routes = [
  {
    name: 'cadastro',
    label: 'Cadastro estabelecimento',
    path: `${HOMEDIR}/cadastro`,
    element: Cadastro,
    exact: true,
    modulo: 'cadastro',
    permit: 0,
    menuOrder: 0,
    menuTitle: 'Cadastro Estabelecimento',
    mode: 'home',
  },
  {
    name: 'cadastro',
    label: 'Cadastro estabelecimento',
    path: `${HOMEDIR}/cadastro/:tipo`,
    element: Cadastro,
    exact: true,
    modulo: 'cadastro',
    permit: 0,
    menuOrder: 0,
    menuTitle: 'Cadastro Estabelecimento',
    mode: 'home',
  },
  {
    name: 'cadastro-ajuste',
    label: 'Cadastro estabelecimento',
    path: `${HOMEDIR}/cadastro/:cnpj/:token`,
    element: Cadastro,
    exact: true,
    modulo: 'cadastro',
    permit: 0,
    menuOrder: 0,
    menuTitle: 'Cadastro Estabelecimento',
    mode: 'home',
  },
  {
    name: 'dashboard',
    label: 'Nave de comando Dclube',
    path: `${HOMEDIR}/dashboard`,
    element: DashPage, // DashPowerbi
    exact: true,
    modulo: 'powerbi',
    permit: 1,
    menuOrder: 1,
    menuTitle: 'Nave de comando Dclube',
    mode: 'painel',
  },
  {
    name: 'dashpowerbi',
    label: 'Nave de comando Dclube',
    path: `${HOMEDIR}/dashpowerbi`,
    element: DashPowerbi, // DashPowerbi
    exact: true,
    modulo: 'powerbi',
    permit: 1,
    menuOrder: 0,
    menuTitle: '',
    mode: 'painel',
  },
  {
    name: 'home',
    label: 'Home',
    path: `${HOMEDIR}/`,
    element: Dashboard,
    exact: true,
    modulo: 'home',
    permit: 1,
    menuOrder: 1,
    menuTitle: 'Home',
    mode: '',
  },
  {
    name: 'rede.listar',
    label: 'Redes',
    path: `${HOMEDIR}/rede`,
    element: RedePage,
    exact: true,
    modulo: 'rede',
    permit: 1,
    menuOrder: 2,
    menuTitle: 'Redes',
    mode: 'painel',
  },
  {
    name: 'softhouse.listar',
    label: 'Softhouses',
    path: `${HOMEDIR}/softhouses`,
    element: SofthousePage,
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 2,
    menuTitle: 'Softhouses',
    mode: 'painel',
  },
  {
    name: 'limitador.listar',
    label: 'Limitadores',
    path: `${HOMEDIR}/limitador`,
    element: LimiteListar,
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 3,
    menuTitle: 'Limitadores',
    mode: 'painel',
  },
  {
    name: 'limitador.criar',
    label: 'Criar limitador',
    path: `${HOMEDIR}/limitador/criar`,
    element: LimiteForm,
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 0,
    menuTitle: '',
    mode: 'painel',
  },
  {
    name: 'limitador.editar',
    label: 'Editar limitador',
    path: `${HOMEDIR}/limitador/:id/editar`,
    element: LimiteForm,
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 0,
    menuTitle: '',
    mode: 'painel',
  },
  {
    name: 'simulador',
    label: 'Simulador',
    path: `${HOMEDIR}/simulador`,
    element: Simulador,
    exact: true,
    modulo: 'simulador',
    permit: 2,
    menuOrder: 7,
    menuTitle: 'Simulador',
    mode: '',
  },
  {
    name: 'campanha.listar',
    label: 'Listar campanhas',
    path: `${HOMEDIR}/campanha`,
    element: CampanhaListar,
    exact: true,
    modulo: 'campanha',
    permit: 4,
    menuOrder: 4,
    menuTitle: 'Campanhas',
    mode: 'painel',
  },
  {
    name: 'campanha.criar',
    label: 'Criar campanha',
    path: `${HOMEDIR}/campanha/criar`,
    element: CampanhaForm,
    exact: true,
    modulo: 'campanha',
    permit: 4,
    menuOrder: 0,
    menuTitle: '',
    mode: 'painel',
  },
  {
    name: 'campanha.editar',
    label: 'Editar campanha',
    path: `${HOMEDIR}/campanha/:id/editar`,
    element: CampanhaForm,
    exact: true,
    modulo: 'campanha',
    permit: 4,
    menuOrder: 0,
    menuTitle: '',
    mode: 'painel',
  },
  {
    name: 'oferta.editar',
    label: 'Editar oferta',
    path: `${HOMEDIR}/campanha/:campanhaId/editar/oferta/:ofertaId/editar`,
    element: OfertaForm,
    exact: true,
    modulo: 'campanha',
    permit: 4,
    menuOrder: 0,
    menuTitle: '',
    mode: 'painel',
  },
  {
    name: 'produto.listar',
    label: 'Listar produtos',
    path: `${HOMEDIR}/produto`,
    element: ProdutoBuscar,
    exact: true,
    modulo: 'powerbi',
    permit: 1,
    menuOrder: 5,
    menuTitle: 'Produtos',
    mode: 'painel',
  },
  {
    name: 'participante.listar',
    label: 'Listar participantes',
    path: `${HOMEDIR}/participante`,
    element: ParticipanteListar,
    exact: true,
    modulo: 'participante',
    permit: 4,
    menuOrder: 6,
    menuTitle: 'Participantes',
    mode: 'painel',
  },
  {
    name: 'produto.desconto',
    label: 'Produtos com desconto',
    path: `${HOMEDIR}/produto-desconto`,
    element: ProdutoDesconto,
    exact: true,
    modulo: 'campanha',
    permit: 4,
    menuOrder: 4,
    menuTitle: 'Gestão descontos',
    mode: 'painel',
  },
  {
    name: 'usuario.listar',
    label: 'Usuários',
    path: `${HOMEDIR}/usuario`,
    element: UsuarioListar,
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 2,
    menuTitle: 'Usuários',
    mode: 'painel',
  },
  {
    name: 'transacao.listar',
    label: 'Listar transações',
    path: `${HOMEDIR}/transacao`,
    element: Transacao,
    exact: true,
    modulo: 'powerbi',
    permit: 2,
    menuOrder: 8,
    menuTitle: 'Transações',
    mode: 'painel',
  },
  {
    name: 'transacao.pendentes',
    label: 'Listar transações pendentes',
    path: `${HOMEDIR}/transacao-pend`,
    element: TransacaoPend, // TransacaoPend
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 8,
    menuTitle: 'Transações Pendentes',
    mode: 'painel',
  },
  {
    name: 'proxy.status',
    label: 'Proxy status',
    path: `${HOMEDIR}/proxy`,
    element: ProxyStatus,
    exact: true,
    modulo: 'proxy',
    permit: 4,
    menuOrder: 9,
    menuTitle: 'Proxy Status',
    mode: 'proxy',
  },
  {
    name: 'proxy.produto',
    label: 'Proxy produto',
    path: `${HOMEDIR}/proxy-produto`,
    element: ProxyProduto,
    exact: true,
    modulo: 'proxy',
    permit: 4,
    menuOrder: 10,
    menuTitle: 'Proxy Produto',
    mode: 'proxy',
  },
  {
    name: 'proxy.campanha',
    label: 'Proxy Campanha',
    path: `${HOMEDIR}/proxy-campanha`,
    element: ProxyCampanha,
    exact: true,
    modulo: 'proxy',
    permit: 4,
    menuOrder: 11,
    menuTitle: 'Proxy Campanha',
    mode: 'proxy',
  },
  {
    name: 'proxy.config',
    label: 'Proxy Config',
    path: `${HOMEDIR}/proxy-config`,
    element: ProxyConfig,
    exact: true,
    modulo: 'proxy',
    permit: 4,
    menuOrder: 12,
    menuTitle: 'Proxy Config',
    mode: 'proxy',
  },
  {
    name: 'faturamento.sumario',
    label: 'Faturamento',
    path: `${HOMEDIR}/faturamento`,
    element: Faturamento,
    exact: true,
    modulo: 'faturamento',
    permit: 1,
    menuOrder: 12,
    menuTitle: 'Faturamento',
    mode: 'painel',
  },
  {
    name: 'contrato.list',
    label: 'Contratos',
    path: `${HOMEDIR}/contrato`,
    element: ContratoPage,
    exact: true,
    modulo: 'faturamento',
    permit: 3,
    menuOrder: 12,
    menuTitle: 'Contratos',
    mode: 'painel',
  },
  {
    name: 'backlog.lista',
    label: 'Backlog',
    path: `${HOMEDIR}/backlog`,
    element: BackLogPage,
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 13,
    menuTitle: 'Backlog',
    mode: 'painel',
  },
  {
    name: 'distribuidor.lista',
    label: 'Distribuidor',
    path: `${HOMEDIR}/distribuidor`,
    element: DistribuidorPage,
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 14,
    menuTitle: 'Distribuidor',
    mode: 'painel',
  },
  {
    name: 'dclist.lista',
    label: 'Desconto controle',
    path: `${HOMEDIR}/desccontrole`,
    element: DescontoControlePage,
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 13,
    menuTitle: 'Desconto Controle',
    mode: 'painel',
  },
  {
    name: 'qrcode.adm',
    label: 'Qrcode',
    path: `${HOMEDIR}/qrcode-adm`,
    element: QrCodeAdmPage,
    exact: true,
    modulo: 'admin',
    permit: 4,
    menuOrder: 1,
    menuTitle: 'QrCode',
    mode: 'painel',
  },
  {
    name: 'qrcode.home',
    label: 'Qrcode',
    path: `${HOMEDIR}/qrcode`,
    element: QrcodePage,
    exact: true,
    modulo: 'home',
    permit: 4,
    menuOrder: 1,
    menuTitle: 'QrCode',
    mode: 'qrcode',
  },
  // QrCodeAdmPage
];

export default routes;
