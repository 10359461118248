import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

export class TransacaoHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.url = baseUrl;
    this.http = http;
    this.resource = resource;
  }

  buscaPendentes(filtro, tipo) {
    if (tipo === 'pendentes') {
      this.url = `/${this.resource}/pendente - POST`;
      return this.http.post(`${this.resource}/pendente`, filtro, this.Config());
    } else {
      this.url = `/${this.resource}/busca - POST`;
      return this.http.post(`${this.resource}/busca`, filtro, this.Config());
    }
  }

  resetReplicacao(transacaoId, flgReplicado) {
    this.url = `/${this.resource}/flgReplicado?transacaoRecebidaId=${transacaoId}&flgReplicado=${flgReplicado} - PUT`;
    return this.http.put(
      `${this.resource}/flgReplicado?transacaoRecebidaId=${transacaoId}&flgReplicado=${flgReplicado}`,
      null,
      this.Config()
    );
  }
}

const transacaoHttp = new TransacaoHttpResource(httpTsApi, 'transacao');

export default transacaoHttp;
