import * as React from 'react';
import descontocontroleHttp from '../../util/http/descontocontrole-http';
import {Link, Button, Box} from '@mui/material';
import TsButton from '../../formulario/TsButton';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
//import SelectCampo from '../../formulario/SelectCampo';
import ShowError from '../../formulario/ShowError';
import EventNoteIcon from '@mui/icons-material/EventNote';
import {ResetProdutos, ExecValidacao} from "./operacoes";
//import listaSituacoes from './situacoes';

let DescontoControleList_processando = false;

const DescontoControleList = (props) => {
    const setDetail = props.setDetail;
    const detail = props.detail;
    const [rows, setRows] = React.useState([]);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    // const [processando, setProcessando] = React.useState(false); 
    const [serverPage, setServerPage] = React.useState(0);
    const serverPageSize = 50;
    // const [serverPageSize, setServerPageSize] = React.useState(50);
    const [objBusca, setObjBusca] = React.useState({busca: '', situacao: 'ALL'});
    const [queryid, setQueryid] = React.useState("");
    const [executing, setExeccuting] = React.useState(false);

    const getProcessando = () => {
        return DescontoControleList_processando;
    }

    const setProcessando = (value) => {
        DescontoControleList_processando = value;
        setExeccuting(value);
    }

    const columns = [
        {
            name: "id",
            label: "#",
            format: ((value) => (<Link component={Button} onClick={(e) => {
                setDetail(d => ({...d , open: true, id: value, pageTitle: "Detalhes Desconto Controle"}));
            }
            }><EventNoteIcon /></Link>))
        },
        {
            name: "industria",
            label: "industria"
        },
        {
            name: "marca",
            label: "marca"
        },
        {
            name: "categoria",
            label: "categoria"
        },
        {
            name: "valorLimite",
            label: "Limite"
        },
        {
            name: "valorUtilizado",
            label: "Utilizado"
        }
    ];

    const Buscar = async () => {
        if (getProcessando()) {
            return;
        }
        setQueryid("");
        setProcessando(true);
        
        try {
            setRows([]);
            const selectOptions = detail.listas === undefined ? true: false;
             
            const query = { page: serverPage, nrecPage: serverPageSize, ativo: true, selectOptions, busca: "" };
            const {data} = await descontocontroleHttp.busca(query);
            if (data.sucesso === true && data.dados && data.dados.length > 0) {
                if (data.extendData)
                {
                    const listas = JSON.parse(data.extendData);
                    setDetail(d => ({...d, listas}));
                }
                setRows(data.dados);
            }
            else {
                setError("Nenhum registro encontrado");
            }
        } catch (error) {
            setUrl(descontocontroleHttp.getUrl());
            setError(error);
        } 
        setProcessando(false);
        
    }

    React.useEffect(() => {
        if (detail.open === false) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // React.useEffect(() => {
    //     if (detail.open === false) {
    //         Buscar();
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [objBusca]);

    return (
    <div>
        <>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "0 0 auto", padding: 2}}>
                <TsButton
                    disabled={executing}
                    onClick={(e) => ResetProdutos(0, setUrl, setError)}
                >reset produtos</TsButton>
                <TsButton
                    disabled={executing}
                    onClick={(e) => ExecValidacao(setUrl, setError)}
                >executar validação</TsButton>
                </Box>
                <Box sx={{flex: "1 1 auto", padding: 2}}>
                </Box>
            </Box>
            <TsInputBusca
                name="input-busca"
                value={objBusca.busca}
                showBusca={true}
                showAtivo={false}
                showAdd={true}
                submitSearch={(texto, ativo) => { setObjBusca(b => ({...b, busca: texto, ativo: ativo})); }}
                onClickAdd={(e) => {setDetail(d => ({...d, open: true, id: 0, pageTitle: "Novo Desconto Controle"}))}}
            >
            </TsInputBusca>
        </>
        <TsDataGrid
                rows={rows}
                columns={columns}
                queryid={queryid}
                serverPage={serverPage}
                serverPagesize={serverPageSize}
                onChangePage={(value) => setServerPage(value)}
                />
        <ShowError error={error} url={url} mensagemPadrao="Retorno Request" />
        <TsBackDrop processando={executing} />
    </div>)
}

export default DescontoControleList;