import * as React from 'react';
import { Box } from '@mui/material';

import { format, parseISO } from 'date-fns'; 

/* http */
import transacaoHttp from '../../util/http/transacao-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop'; 
import ShowError from '../../formulario/ShowError';  
import ListaBase from '../ListaBase';
import {formatNumber} from '../../util/Util';

const selectorCSS = {
    cursor: 'pointer',
  };


 
class UltimasTransacoes extends ListaBase {

    constructor(props) {
      super(props);
      this.serverPageSize = 50;
  
      this.state.open = false;
      this.state.detalhes = '';
  
      this.columns = [
        {
          name: 'cnpj',
          label: 'CNPJ',
          format: (value) => {
            return (
              <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cnpj: "' + value + '"'})} >
                {value}
              </span>
            );
          },
        },
        {
          name: 'clienteId',
          label: 'ClienteID',
          format: (value) => {
            return (
              <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cpf: "' + value + '"'})}>
                {value}
              </span>
            );
          },
        },
        {
          name: 'dataTransacao',
          label: 'DATA',
          format: (value) => {
            return <span style={selectorCSS}>{format(parseISO(value), 'dd/MM HH:mm:ss')}</span>;
          },
        },
        { name: 'rede', label: 'Rede' },
        { name: 'nomeProduto', label: 'Produto', format: (value) => (<>{value.substring(0, 50)}</>) },
        { name: 'categoria', label: 'Categoria'},
        { name: 'marca', label: 'Marca'},
        { name: 'qtde', label: 'Qtde' },
        { name: 'valorUnitario', label: 'Valor',
          format: (value) => (<>{formatNumber(value)}</>)
        },
        { name: 'desconto', label: 'Desconto',
          format: (value) => (<>{formatNumber(value)}</>)
        },
        /* { name: 'statusId', label: 'st' }, */
      ];
    }

    showRede(redeId) {
      if (redeId === 2100) return 'Thalia';
      else if (redeId === 2000) return 'Emporium';
      else return redeId + '';
    }
  
    Buscar () {
      this.iniciarBusca();
  
      const query = { page: this.state.serverPage, nrecPage: this.serverPageSize, ativo: true, statusId: 2, busca: this.state.objBusca.busca };
  
      transacaoHttp
        .busca(query)
        .then(({ data }) => {
          this.setRows(data.dados);
        })
        .catch((error) => {
          this.setError(error);
        })
        .finally(() => {
          this.setProcessando(false);
          this.setBuscando(false);
          this.setUrl(transacaoHttp.getUrl());
        });
    };
  
    componentDidMount() {
      super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
        
    }
  
    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
    }
  
    render() {
  
      return (
        <>
          <Box>
            <TsDataGrid
              rows={this.state.rows}
              columns={this.columns}
              queryid={this.state.queryid}
              pageSize={25}
              serverPage={this.state.serverPage}
              serverPagesize={this.serverPageSize}
              onChangePage={(value) => this.setServerPage(value)}
              fontSize={'8pt'}
            />
            {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
          </Box>
          <TsBackDrop processando={this.state.processando} />
        </>
      );
  
    }
  
  }
  
  export default UltimasTransacoes;
  
  