// @flow 
import * as React from 'react';
import { Page } from '../Page';
import { Box } from '@mui/material';
import TableCampanha from './table-campanha';


const ProxyCampanha = (props) => {
    return (
        <Page title="Listar campanhas proxy">
            <Box>
                <TableCampanha></TableCampanha>
            </Box>

        </Page>
    );
};

export default ProxyCampanha;
