let lastmsg = '';
let lasttime = 0;

class ShowAlert {
  #snackBar = {
    enqueueSnackbar: () => {},
    closeSnackbar: () => {},
  };

  setSnackBar(enqueueSnackbar, closeSnackbar) {
    this.#snackBar.enqueueSnackbar = enqueueSnackbar;
    this.#snackBar.closeSnackbar = closeSnackbar;
  }

  success(msg, options = {}) {
    return this.toast(msg, { ...options, variant: 'success', autoHideDuration: 3000 });
  }
  warning(msg, options = {}) {
    return this.toast(msg, { ...options, variant: 'warning', autoHideDuration: 3000 });
  }
  info(msg, options = {}) {
    return this.toast(msg, { ...options, variant: 'info', autoHideDuration: 3000 });
  }

  error(msg, options = {}) {
    // persist: true
    return this.toast(msg, { ...options, variant: 'error', autoHideDuration: 9000 });
  }
  toast(msg, options = {}) {
    const agora = new Date().getTime();
    if (agora - lasttime < 3000 && lastmsg === msg) {
      // lasttime = agora;
      console.log('ShowAlert - repeticao mensagem', msg);
      return;
    } else if (agora - lasttime < 500) {
      console.log('ShowAlert - short time', msg);
      return;
    }

    lasttime = agora;
    lastmsg = msg;

    const finalOptions = {
      variant: 'default',
      ...options,
    };

    return this.#snackBar.enqueueSnackbar(msg, { ...finalOptions });
  }
  closeSnackbar(key) {
    this.#snackBar.closeSnackbar(key);
  }
}

export default new ShowAlert();
