import * as React from 'react';
import {Box} from "@mui/material";
import { usuarioNull } from '../../util/auth-qrcode';
import TsButton from '../../formulario/TsButton';
import Cupons from './cupons';

const QrCode_Home = (props) => {
    const atual = props.atual;
    const setUser = props.setUser; 
     
    const logout = () => {
        setUser(usuarioNull);
    }

   

    return (
    <Box>
        <Box sx={{display: 'flex'}}>
            <Box sx={{flex: '1 1 auto'}}>{atual.login}</Box>
            <Box sx={{flex: "1 0 auto"}} dir="rtl" >
              <TsButton onClick={(e) => logout()}>Logout</TsButton>
            </Box>
        </Box>
 
        <Cupons atual={atual} />
    </Box>
    )
}

export default QrCode_Home;