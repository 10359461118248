import * as React from 'react';
import { Box, Fab, Typography, Tabs, Tab } from '@mui/material';
import { CSVLink } from "react-csv";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DetalhesLista from './fat-trancacoes';
import {showDate} from './fat-util';
import {DetalhesSoma} from './fat-util'; 
import {formatNumber, formatEan, formatCnpj, valorText} from '../../util/Util';
import { httpProxy } from '../../util/http/proxy-http';
import ShowAlert from '../../util/ShowAlert';

function FatDetail(props) {

    const rows = props.rows;
    const fechamentoId = props.fechamentoId;
    const tipo = props.tipo;
    const id = props.id;
    const visible = props.visible;
    const onClose = props.onClose;

    const columnsDetalhes = [
        { name: 'dataTransacao',     key: 'dataTransacao',     show: true,  label: 'data'},
        { name: 'nsu',               key: 'nsu',               show: false, label: 'nsu'},
        { name: 'cnpj',              key: 'cnpj',              show: false, label: 'cnpj'},
        { name: "cupom",             key: "cupom",             show: true,  label: "cupom"},
        { name: "rede",              key: "rede",              show: true,  label: "rede"},
        { name: "industria",         key: "industria",         show: true,  label: "industria"},
        { name: "distribuidor",      key: "distribuidor",      show: true,  label: "distribuidor"},
        { name: "softhouse",         key: "softhouse",         show: true,  label: "softhouse"},
        { name: "ean",               key: "ean",               show: true,  label: "ean"},
        { name: "produto",           key: "produto",           show: false, label: "produto"},
        { name: "quantidadeVendida", key: "quantidadeVendida", show: true,  label: "qtde"},
        { name: "precoTotal",        key: "precoTotal",        show: true,  label: "total"},
        { name: "descontoTotal",     key: "descontoTotal",     show: true,  label: "desconto" },
        { name: "valDescZicard",     key: "valDescZicard",     show: false,  label: "zicard" }
    ];

    const [tabIndex, setTabIndex] = React.useState(0); 
    const csvLinkEl = React.createRef();
    const [downloadFileName, setDownloadFileName] = React.useState('dclube-relatorio.csv');
    const [desconto, setDesconto] = React.useState(0.00);
    const [total, setTotal] = React.useState(0.00);
    const [zicard, setZicard] = React.useState(0.00);
    const [periodo, setPeriodo] = React.useState("");
    const [fechadoEm, setFechadoEm] = React.useState("");


    const OnClickDownLoad = async () => {
        if (tabIndex === 0) {
            try {
                const {status, data} = await httpProxy.obterFechamento(tipo, id, fechamentoId);
                console.log("data", data);
                if (status !== -1) {
                    var newWindow = window.open('about:blank','_blank');
                    newWindow.document.open();
                    newWindow.document.write(data);
                    newWindow.document.close();
                    // newWindow.history.pushState({"html":data,"pageTitle": "Relatorio fechamento"},"", httpProxy.getUrl());
                } else {
                    ShowAlert.error(data);
                }
                

            } catch (error) {
                ShowAlert.error(error.message);
            }
        }
        else {
            csvLinkEl.current.link.click();
        } 
    }  

    React.useEffect(() => {

        if (fechamentoId && visible) 
        {
            setDownloadFileName(`dclube-relatorio-${fechamentoId}.csv`);
            let total = 0.00;
            let desconto = 0.00;
            let zicard = 0.00;
            // 202301  ano, mês, dia
            let fromDate = new Date(parseInt(fechamentoId.substring(0, 4)), parseInt(fechamentoId.substring(4, 6)) - 1, 1);
            // console.log("fromDate", fromDate);
            let toDate = new Date(fromDate.setMonth(fromDate.getMonth()+1));
            toDate = new Date(toDate.getTime() - (1000*60*60*24));

            if (rows && rows.length > 0)
            {

            
                rows.forEach(item => {
                    if (typeof(item.precoTotal) === 'string') {
                        item.precoTotal = parseFloat(item.precoTotal);
                    }
                    if (typeof(item.descontoTotal) === 'string') {
                        item.descontoTotal = parseFloat(item.descontoTotal);
                    }
                    if (typeof(item.valDescZicard) === 'string') {
                        item.valDescZicard = parseFloat(item.valDescZicard);
                    }

                    total += item.precoTotal;
                    desconto += item.descontoTotal;
                    zicard += item.valDescZicard;

                   const dtTransacao = new Date(item.dataTransacao);
                   if (dtTransacao < fromDate) fromDate = dtTransacao;
                   if (dtTransacao > toDate) toDate = dtTransacao
                    item.dataTransacao = showDate(item.dataTransacao);
                    item.precoTotal = formatNumber(item.precoTotal);
                    item.desconto = item.descontoTotal;
                    item.descontoTotal = formatNumber(item.descontoTotal);
                    item.valDescZicard = formatNumber(item.valDescZicard);
                    item.cnpj = formatCnpj(item.cnpj);
                    item.ean = formatEan(item.ean);
                })

            }
           
            setPeriodo(`${showDate(fromDate, 'dd/MM/yyyy')} - ${showDate(toDate, 'dd/MM/yyyy')}`);
            toDate = new Date(toDate.getTime() + (1000*60*60*24*3));
            setFechadoEm(`${showDate(toDate, 'dd/MM/yyyy')}`)
            // periodo = "";
            // fechadoEm = "";
        
            setTotal(total);
            setDesconto(desconto);
            setZicard(zicard);
        }
         

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, fechamentoId]);

  

    

    return (
    <>
        <CSVLink
            headers={columnsDetalhes}
            filename={downloadFileName}
            data={rows}
            ref={csvLinkEl}
            separator={";"}
        />
        <Box sx={{display: "flex", paddingBottom: 2}}>
            <Box sx={{flex: "0 0 auto", paddingTop: 0.5, paddingLeft: 0.5}}>
                <Fab
                    title="Fechar"
                    color='alert'
                    size="small"
                    onClick={(e) => onClose()}
                > 
                    <ArrowBackIcon />
                </Fab>
            </Box>
            <Box sx={{paddingLeft: 2, paddingTop: 1.5}}>DETALHES</Box>
            <Box sx={{paddingLeft: 5, paddingTop: 1.5}}></Box>
            <Box sx={{padding: 2, margin: 1, backgroundColor: "#e61e28"}}>
                <Box sx={{color: "#ddd"}}>Valor Total: <Typography component={"span"} sx={valorText}>R$ {total.toFixed(2)}</Typography></Box>
            </Box>
            <Box sx={{padding: 2, margin: 1, backgroundColor: "#e61e28"}}>
                <Box sx={{color: "#ddd"}} >Desconto Total: <Typography component={"span"} sx={valorText}>R$ {desconto.toFixed(2)}</Typography></Box>
            </Box>
            {!props.flagRede &&
            <Box sx={{padding: 2, margin: 1, backgroundColor: "#e61e28"}}>
                <Box sx={{color: "#ddd"}} >Desconto Zicard: <Typography component={"span"} sx={valorText}>R$ {zicard.toFixed(2)}</Typography></Box>
            </Box>}
            <Box sx={{paddingLeft: 5, paddingTop: 1.5, flex: "1 8 auto"}}>
             
                <Tabs value={tabIndex} onChange={(event, newValue) => {setTabIndex(newValue)}} aria-label="Tipo de Relatório">
                    <Tab label="Sumário" id="detail-tab-0" aria-labelledby={`detail-tabpanel-0`} />
                    <Tab label="Transações" id="detail-tab-1" aria-labelledby={`detail-tabpanel-1`} />
                </Tabs>
            

            </Box>
            <Box sx={{flex: "0 0 auto", paddingTop: 0.5, paddingRight: 5}} ><Fab
                title="Download"
                color='alert'
                size="small"
                onClick={(e) => OnClickDownLoad()}
            > 
                 
                    <CloudDownloadIcon />
                </Fab>
            </Box>
        </Box>
        <Box>
            {tabIndex === 0 && <DetalhesSoma id="detail-tabpanel-0" rows={rows} periodo={periodo} fechadoEm={fechadoEm} />}
            {tabIndex === 1 && <DetalhesLista rows={rows} columns={columnsDetalhes} id="detail-tabpanel-1" />}
        </Box>
    </>
    );

    
}

export default FatDetail;