// @flow 
import { Box } from '@mui/material';
import * as React from 'react';
import { Page } from '../Page';
import Table from './Table';

const ParticipanteListar = () => {
    return (
        <Page title="Listar participantes">
            <Box>
                <Table></Table>
            </Box>

        </Page>
    );
};

export default ParticipanteListar;