/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { httpAutorizadorV4 } from '../../util/http/autorizador-http';
import { getUser, getProjetoStorage } from '../../util/auth';
import { getProxy } from '../../util/auth';
import { format } from 'date-fns';
import TsDialog from '../../formulario/TsDialog';
import { Box } from '@mui/material';
import { TsTextField } from '../../formulario/TsTextField';
import TsButton from '../../formulario/TsButton';

const ServiceApi = (props) => {
  const { pedido, setPedido, showInfo, operacao, executeId, debug } = props;

  const [openDebug, setOpenDebug] = React.useState(false);
  const [request_url, setRequest_url] = React.useState('');
  const [request_body, setRequest_body] = React.useState('');
  const [request_name, setRequest_name] = React.useState('');

  const proxy = getProxy();

  const usuario = getUser('simulador');
  const projeto = getProjetoStorage();

  const consultarDesconto = () => {
    showInfo('cls', '');
    if (!pedido.itens || pedido.itens.length === 0) {
      showInfo('pop-error', 'Nenhum item incluido');
      return;
    }

    showInfo('debug', ' ... consultarDesconto');

    const nCnpj = obterCnpjNum(pedido.cnpj);

    const itens = pedido.itens.map((it, index) => {
      return {
        tipoProduto: 0,
        codigoProduto: it.codigo,
        precoUnitario: it.valorUtitario,
        qtdeVendida: it.qtde,
      };
    });

    /// prepara body

    const nsu = new Date().getTime();

    const body = {
      idTerminal: 'SIMULADOR',
      cnpj: nCnpj,
      nsuTef: nsu,
      numSessao: pedido.numSessao,
      projetosAtivos: [{ idProjeto: parseInt(projeto.id) }],
      itensCupom: itens,
    };
    /// end prepara boby

    const url = `consultar_desconto`;
    if (debug) {
      setRequest_name(url);
      setRequest_url(`${proxy.url}/${url}`);
      setRequest_body(JSON.stringify(body, null, 4));
      setOpenDebug(true);
    } else {
      enviarRequest(pedido.token, url, `${proxy.url}/${url}`, body);
    }
  };

  const retorno_consultarDesconto = (ret) => {
    if (ret !== undefined && ret.sucesso === true) {
      const retDebug = ret;
      try {
        if (retDebug.data && retDebug.data.itensDesconto) {
          retDebug.data.itensDesconto = retDebug.data.itensDesconto.map((it) => {
            // mudar para try ...
            // if (it.mensagemItem) {
            //  const obj = Buffer.from(it.mensagemItem, 'base64').toString('ascii');
            //   it.mensagemDecoded = JSON.parse(obj);
            // }
            return it;
          });
        }
      } catch (error) {
        console.warn(' ... Erro em decode', error);
      }

      if (ret.data !== undefined && ret.data.codigoResposta === '00') {
        if (ret.data.itensDesconto !== undefined && ret.data.itensDesconto.length > 0) {
          for (let i = 0; i < ret.data.itensDesconto.length; i++) {
            const item = ret.data.itensDesconto[i];
            atualizaItemRetorno(i, item.codigoProduto, item.descUnitario, item.qtdeAprovada, item.idCampanha, item.mensagemItem);
          }
        }
        setPedido((pedido) => ({ ...pedido, cestaValida: true }));
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const atualizaItemRetorno = (index, codigo, desconto, qtdeAprovada, campanhaId, mensagemItem) => {
    let tempItem = pedido.itens;
    console.log(
      `index: ${index}, codigo: ${codigo}, desconto: ${desconto}, qtdeAprovada: ${qtdeAprovada}, campanhaId: ${campanhaId}, mensagemItem: ${mensagemItem}`
    );

    if (index < tempItem.length && tempItem[index].codigo === codigo) {
      tempItem[index].campanhaId = campanhaId;
      tempItem[index].desconto = desconto;
      tempItem[index].qtdeAprovada = qtdeAprovada;
      tempItem[index].mensagemItem = mensagemItem;
    }
    setPedido({ ...pedido, itens: tempItem });
    // pedido.itens = tempItem;
  };

  const consultarIdentificacao = () => {
    showInfo('cls', '');
    showInfo('debug', ' ... consultarIdentificacao');
    if (!pedido.token) {
      showInfo('pop-error', 'O PDV não está aberto');
      return;
    }
    if (!pedido.cpf) {
      showInfo('pop-error', 'CPF não foi selecionado');
      return;
    }

    const numSessao = new Date().getTime();
    setPedido((pedido) => ({ ...pedido, numSessao: numSessao }));

    /// prepara body
    const nsu = new Date().getTime();
    let cpf = pedido.cpf.replaceAll('.', '');
    cpf = cpf.replaceAll('-', '');

    const nCnpj = obterCnpjNum(pedido.cnpj);

    const body = {
      idTerminal: 'SIMULADOR',
      cnpj: nCnpj,
      nsuReq: nsu,
      numSessao: numSessao,
      canalVenda: '02',
      credenciais: [
        {
          identificacao: cpf,
          idProjeto: parseInt(projeto.id),
          tipoCaptura: 1,
        },
      ],
    };

    /// end body
    const url = 'consultar_identificacao';
    if (debug) {
      setRequest_name(url);
      setRequest_url(`${proxy.url}/${url}`);
      setRequest_body(JSON.stringify(body, null, 4));
      setOpenDebug(true);
    } else {
      enviarRequest(pedido.token, url, `${proxy.url}/${url}`, body);
    }
  };

  const retorno_consultarIdentificacao = (ret) => {
    if (ret.sucesso === true) {
      const statusCadastro = ret.data.StatusCredenciais[0].idStatus;

      if (statusCadastro !== 1) {
        showInfo('debug', 'CPF não cadastrado');
        showInfo('processando', false);
        return;
      }
      const nome = `${ret.data.StatusCredenciais[0].nome} ${ret.data.StatusCredenciais[0].sobrenome}`;
      setPedido((pedido) => ({ ...pedido, nome: nome, sessaoAberta: true }));
      showInfo('processando', false);
    }
  };

  const gerarToken = async () => {
    showInfo('cls', '');
    showInfo('debug', ' ... gerarToken');
    const nCnpj = obterCnpjNum(pedido.cnpj);

    const body = { login: usuario?.login, senha: usuario?.senha, cnpj: nCnpj, terminalId: 'SIMULADOR' };
    const url = 'entrar';

    if (debug) {
      setRequest_name(url);
      setRequest_url(`${proxy.url}/${url}`);
      setRequest_body(JSON.stringify(body, null, 4));
      setOpenDebug(true);
    } else {
      enviarRequest('', url, `${proxy.url}/${url}`, body);
    }
  };

  const retorno_entrar = (ret) => {
    showInfo('debug', `RETORNO:\n ${JSON.stringify(ret, null, 4)}`);

    if (ret.sucesso === false || !ret.data) {
      return;
    }

    if (ret.data.token === undefined || ret.data.token.length === 0) {
      return;
    }

    setPedido((pedido) => ({ ...pedido, token: ret.data.token }));
  };

  const InserirIdentificacao = () => {
    showInfo('cls', '');
    showInfo('debug', ' ... InserirIdentificacao');

    if (!pedido.token) {
      showInfo('debug', ' ... SESSÃO NÃO INICIADA');
      return;
    }

    /// prepara body
    let cpf = pedido.cpf;
    let nome = pedido.nome;

    const nsu = new Date().getTime();

    cpf = cpf.replaceAll('.', '');
    cpf = cpf.replaceAll('-', '');
    const nCpf = parseFloat(cpf);

    const nCnpj = obterCnpjNum(pedido.cnpj);

    const arrNome = nome.split(' ');
    const dataNascimento = '1980-01-01';

    if (arrNome.length < 2) {
      arrNome.push(' ');
    }

    const sobrenome = arrNome.pop();
    nome = arrNome.join(' ');

    const body = {
      cnpj: nCnpj,
      idTerminal: 'SIMULADOR',
      nsuReq: nsu,
      cpf: nCpf,
      nome: arrNome[0],
      sobrenome: sobrenome,
      dataNascimento: dataNascimento,
      idProjeto: parseInt(projeto.id),
    };

    /// final prepara body

    const url = 'inserir_identificacao';
    if (debug) {
      setRequest_name(url);
      setRequest_url(`${proxy.url}/${url}`);
      setRequest_body(JSON.stringify(body, null, 4));
      setOpenDebug(true);
    } else {
      enviarRequest(pedido.token, url, `${proxy.url}/${url}`, body);
    }
  };

  const retorno_InserirIdentificacao = (ret) => {
    if (ret.sucesso === true) {
      showInfo('debug', ' ... CADASTRO INSERIDO COM SUCESSO');
    }
    return;
  };

  const EnviarPedido = (registrarVenda) => {
    showInfo('cls', '');
    showInfo('debug', ' ... EnviarPedido');

    if (pedido.pedidoFinalizado) {
      showInfo('pop-error', 'ESSE PEDIDO JÁ FOI FINALIZADO');
      return;
    }

    if (!pedido.token) {
      showInfo('pop-error', 'SESSÃO NÃO INICIADA');
      return;
    }

    if (typeof pedido.cnpj === 'string') {
      if (!pedido.cnpj || pedido.cnpj.length < 6) {
        showInfo('pop-error', 'CNPJ inválido');
        return;
      }
    } else {
      if (pedido.cnpj === 0 || isNaN(pedido.cnpj)) {
        showInfo('pop-error', 'CNPJ inválido');
        return;
      }
    }

    if (!registrarVenda) {
      if (!pedido.cpf || pedido.cpf.length < 11) {
        showInfo('pop-error', 'CPF inválido');
        return;
      }
    }

    if (!pedido.itens || pedido.itens.length < 1) {
      showInfo('pop-error', 'Nenhum item informado');
      return;
    }

    /// prepara body

    let numSessao = pedido.numSessao;
    let numCupom = pedido.numSessao.toString();
    const d = new Date();
    const nsu = d.getTime();
    const dataHora = format(d, "yyyy-MM-dd'T'HH:mm:ss");

    const nCnpj = obterCnpjNum(pedido.cnpj);

    const itens = pedido.itens.map((it) => {
      return {
        idCampanha: it.campanhaId,
        tipoProduto: 0,
        codigoProduto: it.codigo,
        precoUnitario: it.valorUtitario,
        descUnitario: it.desconto,
        descTotal: it.desconto * it.qtdeAprovada,
        qtdeVendida: it.qtde,
        pontoUnitario: 0,
        pontoTotal: 0,
        mensagemItem: it.mensagemItem,
      };
    });

    let descontoFinanceiro = 0;
    itens.forEach((element) => {
      descontoFinanceiro += element.descTotal;
    });

    const body = {
      cnpj: nCnpj,
      idTerminal: 'SIMULADOR',
      nsuReq: nsu,
      numSessao: numSessao,
      numCupom: numCupom,
      codigoFinalizacao: pedido.codigoFinalizacao,
      descontoFinanceiro: descontoFinanceiro,
      dataHora: dataHora,
      itensDescontos: itens,
      formaPagamento: '01', // 01 - dinheiro , 02 - debito , 03 - credito
      valorCashback: 0,
    };

    /// final prepara body
    const url = registrarVenda ? 'registrar_venda' : `registrar_descontos`;
    if (debug) {
      setRequest_name(url);
      setRequest_url(`${proxy.url}/${url}`);
      setRequest_body(JSON.stringify(body, null, 4));
      setOpenDebug(true);
    } else {
      enviarRequest(pedido.token, url, `${proxy.url}/${url}`, body);
    }
  };

  const retorno_EnviarPedido = (ret) => {
    if (ret.sucesso === true) {
      setPedido((pedido) => ({ ...pedido, pedidoFinalizado: true, nsuTransacao: ret.data.nsuHost }));
    }
  };

  const ConfirmarPedido = () => {
    /// prepara body
    showInfo('cls', '');
    showInfo('debug', ' ... ConfirmarPedido');
    const d = new Date();
    const nsu = d.getTime();

    const nCnpj = obterCnpjNum(pedido.cnpj);

    const body = {
      cnpj: nCnpj,
      idTerminal: 'SIMULADOR',
      nsuReq: nsu,
      numSessao: pedido.numSessao,
      numCupom: pedido.numSessao.toString(),
    };

    /// final prepara body
    const url = `confirmar_descontos`;
    if (debug) {
      setRequest_name(url);
      setRequest_url(`${proxy.url}/${url}`);
      setRequest_body(JSON.stringify(body, null, 4));
      setOpenDebug(true);
    } else {
      enviarRequest(pedido.token, url, `${proxy.url}/${url}`, body);
    }
  };

  const retorno_ConfirmarPedido = (ret) => {
    if (ret.sucesso === true) {
      setPedido((pedido) => ({ ...pedido, pedidoFinalizado: true, nsuTransacao: ret.data.nsuHost }));
    }
  };

  const AtualizarStatus = async () => {
    showInfo('cls', '');
    showInfo('debug', ' ... AtualizarStatus');

    if (!pedido.nsuTransacao) {
      showInfo('pop-error', 'nsuTransacao não encontrado');
      return;
    }

    const aprovar = pedido.aprovar.toUpperCase() !== 'TRUE' ? false : true;

    /// pepara body
    const nsuReq = new Date().getTime();

    const body = {
      nsuReq,
      nsuTransacao: pedido.nsuTransacao,
      aprovar,
    };

    /// final prepara body

    const url = 'atualizar_status_transacao';
    if (debug) {
      setRequest_name(url);
      setRequest_url(`${proxy.url}/${url}`);
      setRequest_body(JSON.stringify(body, null, 4));
      setOpenDebug(true);
    } else {
      enviarRequest(pedido.token, url, `${proxy.url}/${url}`, body);
    }
  };

  const processarRequest = () => {
    const url = request_url;
    const body = JSON.parse(request_body);
    const name = request_name;

    enviarRequest(pedido.token, name, url, body);
    setRequest_name('');
    setRequest_url('');
    setRequest_body({});
    setOpenDebug(false);
  };

  const enviarRequest = async (token, name, url, body) => {
    showInfo('processando', true);
    let ret = undefined;
    try {
      showInfo('debug', `REQUEST:\n ${JSON.stringify(body, null, 4)}`);

      ret = await httpAutorizadorV4.post_request(token, name, url, body);
      showInfo('processando', false);

      if (ret) {
        showInfo('debug', `##################################################`);
        showInfo('debug', `RESPONSE:\n ${JSON.stringify({ sucesso: ret.sucesso, status: ret.status, data: ret.data }, null, 4)}`);
      } else {
        showInfo('debug', `########## ERROR: NO RESPONSE FROM REQUEST ###########`);
      }
    } catch (error) {
      showInfo('processando', false);
      showInfo('debug', `... EXECPTION ao processar request: ${error.message}`);
      console.log(error);
      return { sucesso: false, mensagem: error.message };
    }

    if (ret && ret.sucesso === true) {
      if (name === 'consultar_desconto') {
        // `consultar_desconto`
        retorno_consultarDesconto(ret);
      } else if (name === 'consultar_identificacao') {
        retorno_consultarIdentificacao(ret);
      } else if (name === 'inserir_identificacao') {
        retorno_InserirIdentificacao(ret);
      } else if (name === 'confirmar_descontos') {
        retorno_ConfirmarPedido(ret);
      } else if (name === 'registrar_venda' || name === 'registrar_descontos') {
        retorno_EnviarPedido(ret);
      } else if (name === 'entrar') {
        retorno_entrar(ret);
      }
    }
  };

  React.useEffect(() => {
    if (executeId === undefined || operacao === undefined || operacao === 'continue') return;

    console.log('ServiceApi - operacao:' + operacao);

    if (operacao === 'consultar_desconto') consultarDesconto();
    else if (operacao === 'consultar_identificacao') consultarIdentificacao();
    else if (operacao === 'entrar') gerarToken();
    else if (operacao === 'inserir_identificacao') InserirIdentificacao();
    else if (operacao === 'registrar_descontos') EnviarPedido(false);
    else if (operacao === 'confirmar_descontos') ConfirmarPedido();
    else if (operacao === 'atualizar_status_transacao') AtualizarStatus();
    else if (operacao === 'registrar_venda') EnviarPedido(true);
  }, [executeId]);

  const obterCnpjNum = (cnpj) => {
    let nCnpj = cnpj;
    if (typeof cnpj === 'string') {
      nCnpj = parseFloat(pedido.cnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''));
    }
    return nCnpj;
  };

  return (
    <>
      <TsDialog id="dialog-debug-mode" onClose={() => void 0} open={openDebug} title="debug request" minWidth={650}>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: '1 1 auto' }}>END POINT: {request_name}</Box>
            <Box sx={{ flex: '1 1 auto' }}>
              <TsButton color="primary" sx={{ display: 'inline-flex' }} onClick={(e) => processarRequest()}>
                Enviar
              </TsButton>
            </Box>
          </Box>
          <Box>
            <TsTextField
              name="body"
              label="body"
              fullWidth
              multiline={true}
              minRows={4}
              value={request_body || ''}
              disabled={false}
              onChange={(e) => {
                setRequest_body(e.target.value);
              }}
            />
          </Box>
          <Box>
            <TsTextField
              name="url"
              label="url"
              fullWidth
              multiline={false}
              value={request_url || ''}
              disabled={false}
              onChange={(e) => {
                setRequest_url(e.target.value);
              }}
            />
          </Box>
        </Box>
      </TsDialog>
    </>
  );
};

export default ServiceApi;
