import * as React from 'react';
import { Box } from '@mui/material';
import TsButton from '../../formulario/TsButton';
import { TsTextField } from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import descontocontroleHttp from '../../util/http/descontocontrole-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';
import {ResetProdutos} from "./operacoes";
import {getProjetoStorage} from '../../util/auth';
import ShowAlert from '../../util/ShowAlert';

let DescontoControleForm_processando = false;

const DescontoControleForm = (props) => {

    const setDetail = props.setDetail;
    const detail = props.detail;

    const projAtual = getProjetoStorage();

    const listaIndustrias = [{text: 'Selecionar', value: ''}, {text: 'Todas', value: '0'}, ...detail.listas.industrias];
    const listaMarcas = [{text: 'Selecionar', value: ''}, {text: 'Todas', value: '0'}, ...detail.listas.marcas];
    const listaCategorias = [{text: 'Selecionar', value: ''}, {text: 'Todas', value: '0'}, ...detail.listas.categorias];
 
    // const [processando, setProcessando] = React.useState(false);
    const [descontoControle, setDescontoControle] = React.useState({});
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [executing, setExeccuting] = React.useState(false);

    const setProcessando = (value) => {
        DescontoControleForm_processando = value;
        setExeccuting(value);
    }

    const getProcessando = () => {
        return DescontoControleForm_processando;
    }

    /*
        id 
        industriaId 
        marcaId 
        categoriaId 
        ativo 
        dataInclusao 
        dataAlteracao 
        dataInicio 
        dataFinal 
        valorLimite 
        valorUtilizado 
        descontoFechamentoId 
        dataFechamento 
    */

    const LimpaCampos = () => {
        setDescontoControle({
            id: 0,
            industriaId: 0, 
            marcaId: 0,
            categoriaId: 0, 
            ativo: true,
            dataInicio: '',
            dataFinal: '',
            valorLimite: 0.00,
            projetoId: Number(projAtual.id)
        });
    }

    const Obter = async () => {
        
        if (getProcessando()) {
            return;
        }
        
        setProcessando(true);
        LimpaCampos();
        setError(undefined);
        
        try {
   
            const {data} = await descontocontroleHttp.get(detail.id);
            if (data.sucesso === true && data.dado) {
                const dados = data.dado;

                setDescontoControle(dados); 
            }
            else {
                setError("Nenhum registro encontrado");
            }
        } catch (error) {
            setUrl(descontocontroleHttp.getUrl());
            setError(error);
        } 
        setProcessando(false);
        
    };

    const Atualizar = async () => {
        if (getProcessando()) {
            return;
        }
        setProcessando(true);
        setError(undefined);

        try {
            const {data} = detail.id === 0 ? 
                await descontocontroleHttp.create(descontoControle) :
                await descontocontroleHttp.update(detail.id,  descontoControle);
                setProcessando(false);
            
            if (data.sucesso === true) {
                ShowAlert.info("Registro atualizado");
                setDetail(d => ({...d, open: false, id: 0, pageTitle: "Lista Desconto Controle"}));
            }
            else {
                console.log("ERROR on return", data);
                ShowAlert.error("Erro ao atualizar registro");
                // setError(retorno);
            }
            
        } catch (error) {
            setError(error);
        }

        setProcessando(false);

    }

    React.useEffect(() => {
        if (detail.open === true) {
            if (detail.id) {
                Obter();
            }
            else {
                LimpaCampos();
            }
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    return (<>
    <form>

        <SelectCampo
            id='industriaId'
            label='industria'
            name='industriaId'
            disabled={false}  
            options={listaIndustrias}
            value={descontoControle.industriaId + ''}
            onChange={(e) => {setDescontoControle({...descontoControle, industriaId: e.target.value})}}
        />
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <SelectCampo
                    id='marcaId'
                    label='marca'
                    name='marcaId'
                    disabled={false}
                    options={listaMarcas}
                    value={descontoControle.marcaId + ''}
                    onChange={(e) => {setDescontoControle({...descontoControle, marcaId: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <SelectCampo
                    id='categoriaId'
                    label='categoria'
                    name='categoriaId'
                    disabled={false}
                    options={listaCategorias}
                    value={descontoControle.categoriaId + ''}
                    onChange={(e) => {setDescontoControle({...descontoControle, categoriaId: e.target.value})}}
                />
            </Box>
        </Box>
        <TsTextField
            name="descricao"
            label="descrição"
            fullWidth
            value={descontoControle.descricao || ''}
            disabled={false}
            onChange={(e) => {setDescontoControle({...descontoControle, descricao: e.target.value})}}
        />
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", padding: 2}}>
                <TsTextField
                    name="dataInicio"
                    label="dataInicio"
                    fullWidth
                    value={descontoControle.dataInicio || ''}
                    disabled={false}
                    onChange={(e) => {setDescontoControle({...descontoControle, dataInicio: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", padding: 2}}>
                <TsTextField
                    name="dataFinal"
                    label="dataFinal"
                    fullWidth
                    value={descontoControle.dataFinal || ''}
                    disabled={false}
                    onChange={(e) => {setDescontoControle({...descontoControle, dataFinal: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", padding: 2}}>
                <TsTextField
                    name="valorLimite"
                    label="valorLimite"
                    fullWidth
                    value={descontoControle.valorLimite || ''}
                    disabled={false}
                    onChange={(e) => {setDescontoControle({...descontoControle, valorLimite: e.target.value})}}
                />
            </Box>
        </Box>
        <Box>dataAlerta: {descontoControle.dataAlerta}, valorUtilizado: {descontoControle.valorUtilizado} <br /> 
        Produtos Total: {descontoControle.nroProdutosTotal} , Produtos Ativos desconto: {descontoControle.nroProdutosAtivoDesconto}
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "0 0 auto", padding: 2}}>
            <TsButton
                disabled={executing}
                onClick={(e) => ResetProdutos(detail.id, setUrl, setError)}
            >Reset produtos</TsButton>
            </Box>
            <Box sx={{flex: "1 1 auto", padding: 2}}>
            </Box>
            <Box sx={{flex: "0 0 auto", padding: 2}}>
            <TsButton
                disabled={executing}
                onClick={(e) => Atualizar()}
            >Salvar</TsButton>
            </Box>
            <Box sx={{flex: "0 0 auto", padding: 2}}>
            <TsButton
                onClick={(e) => setDetail(d => ({...d, open: false, id: 0, pageTitle: "Lista Desconto Controle"}))}
                color='secondary'
            >Voltar</TsButton>
            </Box>
        </Box>
    </form>
   <ShowError error={error} url={url} mensagemPadrao="Retorno Request" />
    <TsBackDrop processando={executing} />
    </>)
}

export default DescontoControleForm;
