/* eslint-disable react-hooks/exhaustive-deps */
// @flow 
import * as React from 'react';
import { Page } from '../Page';
import { httpProxy } from '../../util/http/proxy-http';
import { Box, Grid } from '@mui/material';
import { monitorCSS } from '../../style'
import TsButton from '../../formulario/TsButton';
import { TsTextField } from '../../formulario/TsTextField';
import TsBackDrop from '../../formulario/TsBackDrop';
import { getProxy } from '../../util/auth';
import SelectCampo from '../../formulario/SelectCampo';
import ShowAlert from '../../util/ShowAlert';


const ProxyStatus = (props) => {

    const [status, setStatus] = React.useState("");
    const [proxyToken, setProxyToken] = React.useState("");
    const [serverId, setServerId] = React.useState("");
    const [cnpj, setCnpj] = React.useState(0);
    const [rede, setRede] = React.useState(0);
    const [enableEdit, setEnableEdit] = React.useState(false);
    const [processando, setProcessando] = React.useState(false);
    const [showEdit, setShowEdit] = React.useState(false);

    const prx = JSON.parse(localStorage.getItem('LISTA_PROXY'));

    const [proxy, setProxy] = React.useState(getProxy());

    const obterStatus = () => {
        setShowEdit(false);
        if (!proxy.monitor) {
            return obterStatusFromLocalStorage(true);
        }

        setProcessando(true);
        setStatus(" ... obter status, aguarde");
        httpProxy.consultarStatus()
            .then((retorno) => {
                setProcessando(false);
                setStatus(JSON.stringify(retorno, null, 4));

                if (retorno.sucesso === true && retorno.data?.config) {
                    setShowEdit(true);
                    setEnableEdit(false);
                    if (retorno.data.config.SERVERID) {
                        setServerId(retorno.data.config.SERVERID);
                    }
                    if (retorno.data.config.TOKEN) {
                        setProxyToken(retorno.data.config.TOKEN);
                    }
                    if (retorno.data.config.REDE) {
                        setRede(retorno.data.config.REDE);
                    }
                    if (retorno.data.config.CNPJ) {
                        setCnpj(retorno.data.config.CNPJ);
                    }
                }
                else {
                    return obterStatusFromLocalStorage(false);
                }

            })
            .catch((error) => {
                setProcessando(false);
                setStatus(error.message || error);
            });
    }

    const obterStatusFromLocalStorage = (showData) => {
        setServerId(proxy.nome);
        setProxyToken("Informação Indisponivel");

        const server = prx.find(p => p.nome === proxy.nome);
        if (showData) setStatus(JSON.stringify({ ...server, origem: "LOCAL-STORAGE" }, null, 4));
        if (server) {
            setRede(Number(server.rede));
            setCnpj(server.cnpj);
        }
        return;
    }

    const enableProxy = () => {
        if (!proxy.monitor) {
            ShowAlert.error('PROXY selecionado é inválido para essa operação');
            return;
        }
        setProcessando(true);
        setStatus(" ... enable proxy, aguarde");
        httpProxy.enableProxy()
            .then((retorno) => { obterStatus(); setProcessando(false); })
            .catch((error) => { setStatus(JSON.stringify(error, null, 4)); setProcessando(false); });
    }

    const disableProxy = () => {
        if (!proxy.monitor) {
            ShowAlert.error('PROXY selecionado é inválido para essa operação');
            return;
        }
        setProcessando(true);
        setStatus(" ... disabel proxy, aguarde");
        httpProxy.disableProxy()
            .then((retorno) => { setProcessando(false); obterStatus(); })
            .catch((error) => { setProcessando(false); setStatus(JSON.stringify(error, null, 4)) });
    }

    const syncProxy = () => {
        if (!proxy.monitor) {
            ShowAlert.error('PROXY selecionado é inválido para essa operação');
            return;
        }
        setProcessando(true);
        setStatus(" ... sync proxy, aguarde");
        httpProxy.syncProxy()
            .then((retorno) => {
                setProcessando(false);
                ShowAlert.error('Sincronização encerrada');
                setStatus(JSON.stringify(retorno.data, null, 4));
            })
            .catch((error) => {
                setProcessando(false);
                ShowAlert.error(error.message);
                setStatus(JSON.stringify(error, null, 4))
            });
    }

    const atualizarConfig = () => {

        if (!proxy.monitor) {
            ShowAlert.error('PROXY selecionado é inválido para essa operação');
            return;
        }

        setProcessando(true);
        httpProxy.updateConfig(serverId, proxyToken, cnpj, rede)
            .then((retorno) => {
                setProcessando(false);
                ShowAlert.error('Atualização encerrada');
                setStatus(JSON.stringify(retorno.data, null, 4));
                setEnableEdit(false);
            })
            .catch((error) => {
                setProcessando(false);
                ShowAlert.error(error.message);
                setStatus(JSON.stringify(error, null, 4));
            });


    };

    React.useEffect(() => {
        obterStatus();
    }, [proxy]);

    return (
        <Page title="Proxy">
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <TsButton color="inherit" onClick={(e) => { e.preventDefault(); obterStatus(); }}>STATUS</TsButton>
                </Grid>
                <Grid item xs={2}>
                    <TsButton color="inherit" onClick={(e) => { e.preventDefault(); enableProxy(); }}>ENABLE</TsButton>
                </Grid>
                <Grid item xs={2}>
                    <TsButton color="inherit" onClick={(e) => { e.preventDefault(); disableProxy(); }}>DISABLE</TsButton>
                </Grid>
                <Grid item xs={3}>
                    <TsButton color="inherit" onClick={(e) => { e.preventDefault(); syncProxy(); }}>SYNC</TsButton>
                </Grid>
                <Grid item xs={3}>
                    <SelectCampo
                        name="proxy"
                        id='lst-proxy'
                        label='PROXY-URL'
                        value={proxy.nome}
                        onChange={(e) => {
                            const valor = e.target.value;
                            localStorage.setItem("chaveProxy", valor);
                            const novo = prx.find(p => p.nome === valor);
                            if (novo) {
                                setProxy(novo);
                            }

                        }}
                        options={prx.map(p => { return { value: p.nome, text: p.nome } })}
                    />
                </Grid>
            </Grid>
            <form>
                <div><label>PROXY URL (MONITOR):</label><strong> {proxy.monitor}</strong></div>
                <TsTextField
                    name="serverId"
                    label="Server ID"
                    fullWidth
                    value={serverId}
                    disabled={!enableEdit}
                    onChange={(e) => { setServerId(e.target.value) }}
                />
                <TsTextField
                    name="proxyToken"
                    label="Proxy Token"
                    fullWidth
                    value={proxyToken}
                    disabled={!enableEdit}
                    onChange={(e) => { setProxyToken(e.target.value) }}
                />
                <TsTextField
                    name="cnpj"
                    label="CNPJ"
                    fullWidth
                    value={cnpj}
                    disabled={!enableEdit}
                    onChange={(e) => { setCnpj(parseFloat(e.target.value)) }}
                />
                <TsTextField
                    name="rede"
                    label="Rede ID"
                    fullWidth
                    value={rede}
                    disabled={!enableEdit}
                    onChange={(e) => { setRede(parseInt(e.target.value)) }}
                />
                {showEdit && <Box dir="rtl">
                    <TsButton
                        disabled={!enableEdit}
                        onClick={(e) => setEnableEdit(false)}
                        color='secondary'
                    >Cancelar</TsButton>
                    <TsButton
                        disabled={!enableEdit}
                        onClick={(e) => atualizarConfig()}
                    >Enviar</TsButton>
                    <TsButton
                        disabled={enableEdit}
                        onClick={(e) => setEnableEdit(true)}
                    >Alterar</TsButton>

                </Box>}
            </form>
            <Box sx={monitorCSS}>{status}</Box>
            <TsBackDrop processando={processando} />
        </Page>
    );
};

export default ProxyStatus;