import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class ParticipanteClassificacaoHttp extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.http = http;
    this.resource = resource;
    this.url = resource;
  }

  busca(filtro) {
    const cpf = filtro.cpf;
    this.url = `/${this.resource}/${cpf}/classificacao/busca - POST`;

    return this.http.post(`${this.resource}/${cpf}/classificacao/busca`, filtro, this.Config());
  }

  updateClassificacao(cpf, projetoId, dados) {
    // {cpf}/projeto/{projetoId}/classificacao/
    this.url = `/${this.resource}/${cpf}/projeto/${projetoId}/classificacao - PUT`;

    return this.http.put(`${this.resource}/${cpf}/projeto/${projetoId}/classificacao`, dados, this.Config());
  }

  deleteClassificacao(cpf, projetoId, perfil) {
    this.url = `/${this.resource}/${cpf}/projeto/${projetoId}/classificacao/${perfil} - DELETE`;
    return this.http.delete(`${this.resource}/${cpf}/projeto/${projetoId}/classificacao/${perfil}`, this.Config());
  }
}

const participanteClassificacaoHttp = new ParticipanteClassificacaoHttp(httpTsApi, 'participante');

export default participanteClassificacaoHttp;
