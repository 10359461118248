import * as React from 'react';
import { Box, IconButton } from '@mui/material';
import TsDialog from '../../formulario/TsDialog';
import distribuidorHttp from '../../util/http/distribuidor-http';
import relatorioHttp from '../../util/http/relatorio-http';
import TsButton from '../../formulario/TsButton';
import SelectCampo from '../../formulario/SelectCampo';
import {getProjetoStorage} from '../../util/auth';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';
import TsDataGrid from '../../formulario/TsDataGrid';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';

const DistribuidorRedeProduto = (props) => {

    const projAtual = getProjetoStorage();
    const [rows, setRows] = React.useState([]); 
    const {distribuidorId, redeId, processando, setProcessando} = props;
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState('');
    const [openOptions, setOpenOptions] = React.useState(false);
    const [categoriaId, setCategoriaId] = React.useState('');
    const [listaCategoria, setListaCategoria] = React.useState([]);
    // listaMarca marcaId setMarcaId
    const [marcaId, setMarcaId] = React.useState('');
    const [listaMarca, setListaMarca] = React.useState([]);
     
    const [serverPage, setServerPage] = React.useState(0);
    const serverPageSize = 50;
    const [objBusca, setObjBusca] = React.useState({busca: ''});
    const queryid = Math.random();
    

    const columns = [
        {name: "distribuidorNome",label: "distribuidor"},
        {name: "redeNome",label: "rede"},
        {name: "ean",label: "ean"},
        {name: "produtoNome",label: "produto"},
    ];

    const Buscar = async () => {
        if (processando === true) return;
        setRows([]);
        setProcessando(true);
        
        try {
            const query = { page: serverPage, nrecPage: serverPageSize, ativo: true, busca: objBusca.busca, projetoId: Number(projAtual.id) };
            const {data} = await distribuidorHttp.listProdutos(distribuidorId, redeId, query);
            setProcessando(false);
            if (data.sucesso === true) {
                setRows(data.dados);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
            
        } catch (error) {
            setProcessando(false);
            setUrl(distribuidorHttp.getUrl());
            setError(error);
        }
        
    }

    const carregarCategorias = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('categoria', undefined);
            const lista = [
                {text: '...Selecione', value: ''},
                ...data
            ];
            setListaCategoria(lista);
            
        } catch (error) {
            console.error('erro ao carregar categorias');
            console.error(error);
        }
    }

    // 'categoria', categoriaId, 'add'
    const executeMaint = async (tipo, valor, operacao) => {
        console.log(`executeMaint - tipo: ${tipo}, valor: ${valor}, operacao: ${operacao}`);
        setProcessando(true);
        try {
            
            const {data} = await distribuidorHttp.executeMaint(distribuidorId, redeId, projAtual.id, tipo, valor, operacao);
            setProcessando(false);
            if (data.sucesso === true) {
                ShowAlert.info(data.mensagem);
                setOpenOptions(false);
                Buscar();
            } else {
                ShowAlert.error(data.mensagem);
            }
        } catch (error) {
            setProcessando(false);
            setUrl(distribuidorHttp.getUrl());
            setError(error);
        }

    }

    const carregarMarcas = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('marca', undefined);
            const lista = [
                {text: '...Selecione', value: ''},
                ...data
            ];
            setListaMarca(lista);
            
        } catch (error) {
            console.error('erro ao carregar marcas');
            console.error(error);
        }
    }

    React.useEffect(() => {
        if (props.open === true) {
            setObjBusca({busca: ''});
            Buscar();
        }
        if (listaCategoria.length === 0) {
            carregarCategorias();
        }
        if (listaMarca.length === 0) {
            carregarMarcas();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    return (
        <TsDialog onClose={(e) => props.onClose(false)} id="dialog-distrib-rede" open={props.open} title={props.titulo} minWidth={800}>
        <Box sx={{display: 'flex'}}>
            <Box sx={{flex: '1 1 auto'}}>LISTA PRODUTOS</Box>
            <Box sx={{flex: '1 1 auto'}}>
                {openOptions ? 
                    <IconButton color="inherit" style={{ fontSize: 20 }} onClick={(e) => {setOpenOptions(false); }}><UpIcon /></IconButton> : 
                    <IconButton color="inherit" style={{ fontSize: 20 }} onClick={(e) => {setOpenOptions(true);}}><DownIcon /></IconButton>
                } Opções de manutenção  
            </Box>
        </Box>
        {openOptions && 
        <Box>
            <Box>
                <TsButton
                    disabled={processando}
                    onClick={(e) => void(0)}
                >Adicionar</TsButton>
                <TsButton
                    disabled={processando}
                    onClick={(e) => executeMaint('all', '0', 'add')}
                >Todos da Rede</TsButton>
                
                <TsButton
                    onClick={(e) => props.onClose(false)}
                    color='secondary'
                >Voltar</TsButton>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto', padding: 2}}>
                    <SelectCampo
                            id='lst-categoria'
                            label='Categoria'
                            name='categoria'
                            disabled={false}
                            options={listaCategoria}
                            value={categoriaId || ''}
                            onChange={(e) => setCategoriaId(e.target.value) }
                    />
                </Box>
                <Box   sx={{padding: 2}}>
                    <TsButton
                        disabled={processando}
                        onClick={(e) => executeMaint('categoria', categoriaId, 'add')}
                    >adicionar</TsButton>
                </Box>
                <Box   sx={{padding: 2}}>
                    <TsButton
                        disabled={processando}
                        color='alert'
                        onClick={(e) => executeMaint('categoria', categoriaId, 'delete')}
                    >remover</TsButton>
                </Box>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto', padding: 2}}>
                    <SelectCampo 
                            id='lst-marca'
                            label='Marca'
                            name='marca'
                            disabled={false}
                            options={listaMarca}
                            value={marcaId || ''}
                            onChange={(e) => setMarcaId(e.target.value) }
                    />
                </Box>
                <Box sx={{padding: 2}}>
                    <TsButton
                        disabled={processando}
                        onClick={(e) => executeMaint('marca', marcaId, 'add')}
                    >adicionar</TsButton>
                </Box>
                <Box sx={{padding: 2}}>
                    <TsButton
                        disabled={processando}
                        color='alert'
                        onClick={(e) => executeMaint('marca', marcaId, 'delete')}
                    >remover</TsButton>
                </Box>
            </Box>
            
        </Box>
        }
            <TsDataGrid
                    rows={rows}
                    columns={columns}
                    queryid={queryid}
                    serverPage={serverPage}
                    serverPagesize={serverPageSize}
                    onChangePage={(value) => setServerPage(value)}
                    />
            <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        </TsDialog>
    )

}

export default DistribuidorRedeProduto;