import { Box} from '@mui/material';
import * as React from 'react';
import { Page } from '../Page';
import DescontoControleList from './dc-list';
import DescontoControleForm from './dc-form';

const DescontoControlePage = () => {
    const [detail, setDetail] = React.useState({open: false, id: 0, pageTitle: "Desconto controle lista"}); 
    
    return (
        <Page title={detail.pageTitle}>
            <Box>
                {detail.open ? 
                <DescontoControleForm setDetail={setDetail}  detail={detail}></DescontoControleForm> : 
                <DescontoControleList setDetail={setDetail} detail={detail}></DescontoControleList>}
            </Box>
        </Page>
    );
};

export default DescontoControlePage;
