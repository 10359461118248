import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class CampanhaHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.url = resource;
    this.http = http;
    this.resource = resource;
  }

  // [HttpPut]
  // [Route("{campanhaId}/oferta/{ofertaId}/produto")]
  addProduto(campanhaId, ofertaId, dados) {
    this.url = `/${this.resource}/${campanhaId}/oferta/${ofertaId}/produto - PUT`;
    return this.http.put(`${this.resource}/${campanhaId}/oferta/${ofertaId}/produto`, dados, this.config);
  }

  nova_busca(filtro) {
    this.url = `/campanha-busca - POST`;
    return this.http.post(`campanha-busca`, filtro, this.Config());
  }
}

const campanhaHttp = new CampanhaHttpResource(httpTsApi, 'campanha');

export default campanhaHttp;
