import * as React from 'react';
import { Box, Button, Link } from '@mui/material';
import distribuidorHttp from '../../util/http/distribuidor-http';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ProductsIcon from '@mui/icons-material/ShoppingCart';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsButton from '../../formulario/TsButton';
import DistribuidorRedeDetail from '../distribuidor/distribuidor-rede-detail';
import DistribuidorRedeProduto from '../distribuidor/distribuidor-rede-produto';
import ShowAlert from '../../util/ShowAlert';

const RedeDistribuidor = (props) => {

    const {detail, setDetail, projAtual, setError, setUrl, redeNome, processando, setProcessando} = props;
    const [rows, setRows] = React.useState([]);
    const [serverPage, setServerPage] = React.useState(0);
    const serverPageSize = 50;
    const queryid = '' + Math.floor(Math.random() * 1000000000);
    const [distribuidorId, setDistribuidorId] = React.useState(0);
    const [tituloDetail, setTituloDetail] = React.useState("Rede - Distribuidor");
    const [redeDetailOpen, setRedeDetailOpen] = React.useState(false);
    const [redeProductOpen, setRedeProductOpen] = React.useState(false);

   

    const AbrirDetalheDistribuidor = (id) => {
        setDistribuidorId(id);
        let distribuidorNome = '...SELECIONE'
        if (id !== 0) {
            const item = rows.find(r => r.distribuidorId === id);
            if (item !== undefined) distribuidorNome = item.distribuidorNome;
        } 
        setTituloDetail(`${redeNome} - REDE: ${distribuidorNome}`);
        setRedeDetailOpen(true);
    }

    const AbrirDetalheProdutos = (id) => {
        setDistribuidorId(id);
        let distribuidorNome = '...SELECIONE'
        if (id !== 0) {
            const item = rows.find(r => r.distribuidorId === id);
            if (item !== undefined) distribuidorNome = item.distribuidorNome;
        } 
        setTituloDetail(`${redeNome} - DISTRIBUIDOR: ${distribuidorNome}`);
        setRedeProductOpen(true);
    }

    const columns = [
        {
            name: "distribuidorId",
            label: "Código",
            format: ((value) => (<Link component={Button} onClick={(e) => AbrirDetalheDistribuidor(value)}><EventNoteIcon /></Link>))
        },
        {
            name: "distribuidorNome",
            label: "nome"
        },
        {
            name: "dataAtivacao",
            label: "Ativação"
        },
        {
            name: "ativo",
            label: "status",
            format: ((value) => (value ? <>Ativo</> : <>Inativo</>))
        },
        {
            name: "categorias",
            label: "categorias"
        },
        {
            name: "distribuidorId",
            label: "produtos",
            format: ((value) => (<Link component={Button} onClick={(e) => AbrirDetalheProdutos(value)}><ProductsIcon /></Link>))
        }
    ];

    const Buscar = async () => {
        if (processando) {
            return;
        }
        setProcessando(true);
        setError(undefined);
        
        
        try {

            const distribuidorRet = await distribuidorHttp.listDistribuidores(detail.id, projAtual.id);

            if (distribuidorRet.data.sucesso === true && distribuidorRet.data.dados && distribuidorRet.data.dados.length > 0) {
                setRows(distribuidorRet.data.dados);
            }
            else {
                ShowAlert.info("Nenhuma rede encontrada");
            }

             
        } catch (error) {
            setError(error);
        } 
        setProcessando(false);
        setUrl(distribuidorHttp.getUrl());
    };

    React.useEffect(() => {
        if (detail.open === true && redeDetailOpen === false) {
            Buscar();
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail, redeDetailOpen]);


    return (<>
         <TsDataGrid
            rows={rows}
            columns={columns}
            queryid={queryid}
            serverPage={serverPage}
            serverPagesize={serverPageSize}
            onChangePage={(value) => setServerPage(value)}
        />
        <Box dir="rtl">
            <TsButton
                onClick={(e) => setDetail({open: false, id: 0, pageTitle: "Lista de redes"})}
                color='secondary'
            >Voltar</TsButton>
        </Box>
        <DistribuidorRedeDetail
                titulo={tituloDetail}
                onClose={(e) => setRedeDetailOpen(false)}
                open={redeDetailOpen}
                redeId={detail.id}
                distribuidorId={distribuidorId}
                processando={processando}
                setProcessando={setProcessando}
            />

            <DistribuidorRedeProduto
                titulo={tituloDetail}
                onClose={(e) => setRedeProductOpen(false)}
                open={redeProductOpen}
                redeId={detail.id}
                distribuidorId={distribuidorId}
                processando={processando}
                setProcessando={setProcessando}
            />
    
    </>);

}


export default RedeDistribuidor;