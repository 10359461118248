import * as React from 'react';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
// import TsButton from '../../formulario/TsButton';
import BankIcon from '@mui/icons-material/AccountBalance';
import TsDialog from '../../formulario/TsDialog';
import SelectCampo from '../../formulario/SelectCampo';
import {TsTextField} from '../../formulario/TsTextField';
import TsButton from '../../formulario/TsButton';
import ShowAlert from '../../util/ShowAlert';

const listaChaveBancariaTipo = [
    {value: '', text: '...Selecione'},
    {value: 'PIX', text: 'PIX'},
    {value: 'CONTA', text: 'CONTA'},
];

const listaPixChave = [
    {value: '', text: '...Selecione'},
    {value: 'CPF', text: 'CPF'},
    {value: 'CNPJ', text: 'CNPJ'},
    {value: 'EMAIL', text: 'EMAIL'},
];

const ChaveBancaria = (props) => {

    const {id, name, disabled, value, onChange, userLevel} = props;

    const [open, setOpen] = React.useState(false);
    const [chave, setChave] = React.useState({tipo: '', valor: 'NÃO ESPECIFICADO', mask: '000.000.000-00'});
     
    const atualizar = () => {
        let obj = {};
        let msgErr = '';
        const reCpf = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/g; // 123.123.123-11
        const reCnpj = /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/g; // 02.123.123/0001-00
        const reEmail = /^[0-9a-zA-Z\-.]{1,100}@[0-9a-zA-Z\-.]{3,150}$/g;
        const reBanco = /^[0-9]{3,4}$/g;
        const reAgConta = /^[0-9-]{4,50}\/[0-9]{3,50}-[0-9]{1}$/g;
        if (chave.tipo === 'PIX') {
            if (chave.chave === 'CPF' && !reCpf.test(chave.valor)) msgErr = 'Valor CPF inválido';
            else if (chave.chave === 'EMAIL' && !reEmail.test(chave.valor)) msgErr = 'Valor e-mail inválido';
            else if (chave.chave === 'CNPJ' && !reCnpj.test(chave.valor)) msgErr = 'Valor CNPJ inválido';
            if (msgErr) {
                ShowAlert.error(msgErr);
                return;
            }
            obj = {tipo: chave.tipo, chave: chave.chave, valor: chave.valor, mask: chave.mask};
        }
        else {
            if (!reBanco.test(chave.banco)) msgErr = 'Valor BANCO inválido';
            if (!reAgConta.test(chave.agconta)) msgErr = 'Valor Agencia/Conta , formato inválido';
            if (msgErr) {
                ShowAlert.error(msgErr);
                return;
            }

            obj = {tipo: chave.tipo, banco: chave.banco, agconta: chave.agconta, mask: chave.mask};
        }

        setOpen(false);
        setChave(obj);
        ShowAlert.error('Atualize o cadatro antes de sair');
        onChange(JSON.stringify(obj));
    }

    const changeTipo = (valor) => {
        if (valor === 'PIX') {
            
            const pixTipo = chave.chave ? chave.chave : 'CPF';
            const pixValor = chave.chave && chave.valor ? chave.valor : '';
            let mask = '';
            if (pixTipo === 'CPF') mask = '000.000.000-00';
            else if (pixTipo === 'CNPJ') mask = '00.000.000/0000-00';
            else mask = 'usuario@meudominio.com';

            setChave({ ...chave,  tipo: valor, chave: pixTipo, valor: pixValor, mask: mask});

        }
        else {
            setChave({ ...chave,  tipo: valor, mask: '0000-0/000000-0'});
        }
        
    }

    const changeChave = (valor) => {
        let mask = '';
        if (valor === 'CPF') mask = '000.000.000-00';
        else if (valor === 'CNPJ') mask = '00.000.000/0000-00';
        else mask = 'usuario@meudominio.com';

        setChave({ ...chave, chave: valor, valor: '',  mask: mask});
    }

    React.useEffect(() => {
        if (value) {
            
            try {
                const obj = JSON.parse(value);
                if (obj && obj.tipo) {
                    setChave(obj);
                }
            } catch (error) {
                setChave({tipo: 'PIX', valor: value})
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
 

    return (
    <>
        <FormControl fullWidth variant="outlined" sx={{margin: 1}} >
            <InputLabel htmlFor="input-chave-bancaria">
            Chave bancária
            </InputLabel>
            <OutlinedInput
            label='Chave bancária'
            id={id || 'input-chave-bancaria'}
            name={name || 'chaveBancaria'}
            value={chave.tipo === 'CONTA' ? 
                `BANCO [${chave.banco || ''}] AG/CONTA: ${chave.agconta || ''}` :
                `${chave.tipo ? '(' + chave.tipo + '):' : ''} ${chave.valor || ''}`
                 
            }
            disabled={true}
            startAdornment={
                <InputAdornment position="start">
                    <IconButton disabled={disabled || false} onClick={(e) => setOpen(!open)} ><BankIcon /></IconButton>
                </InputAdornment>
            }
        />
      </FormControl>
      <TsDialog sx={{backgroundColor: '#E7E7E7'}} 
        onClose={() => setOpen(false)} 
        id="dialog-chave-bancaria" 
        open={open} 
        title='Editar chave bancária' minWidth={400} 
        >
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: '1 1 auto', paddingLeft: 2}}>
                    <SelectCampo
                        id="chave-bancaria-tipo"
                        label="Tipo"
                        name="chave-bancaria-tipo"
                        options={listaChaveBancariaTipo}
                        disabled={userLevel > 2 ? false: true}
                        value={chave.tipo || ''}
                        onChange={(e) => changeTipo(e.target.value)}
                    />
                </Box>
                <Box sx={{flex: '1 1 auto', paddingLeft: 2}}>
                    {chave.tipo === 'PIX' ? 
                    <SelectCampo
                        id="pix-chave"
                        label="Tipo Chave"
                        name="pix-chave"
                        options={listaPixChave}
                        disabled={userLevel > 2 ? false: true}
                        value={chave.chave || ''}
                        onChange={(e) => changeChave(e.target.value)}
                    />
                    :
                    <TsTextField
                        name="conta-banco"
                        label="Banco [xxx]"
                        inputProps={{ maxLength: 4 }}
                        fullWidth
                        disabled={userLevel > 2 ? false: true}
                        value={chave.banco || ''}
                        onChange={(e) => {setChave({...chave, banco: e.target.value})}}
                    />}
                </Box>
            </Box>
            <Box sx={{paddingLeft: 3, paddingTop: 2, fontSize: '11pt', color: '#AA7777'}}>Utilize a mascara: {chave.mask}</Box>
                {chave.tipo === 'PIX' ? 
                <Box sx={{paddingLeft: 2}}>
                    <TsTextField
                        name="pix-valor"
                        label="Valor Chave"
                        fullWidth
                        inputProps={{ maxLength: 250 }}
                        disabled={userLevel > 2 ? false: true}
                        value={chave.valor || ''}
                        onChange={(e) => {setChave({...chave, valor: e.target.value})}}
                    />
                </Box> 
                : 
                <Box sx={{paddingLeft: 2}}>
                    <TsTextField
                        name="agencia-conta"
                        label="Agência/Conta"
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        disabled={userLevel > 2 ? false: true}
                        value={chave.agconta || ''}
                        onChange={(e) => {setChave({...chave, agconta: e.target.value})}}
                    />
                </Box>
                }
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}></Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                    {userLevel > 2 && <TsButton
                        onClick={(e) => atualizar()}
                        
                    >Modificar valor</TsButton> }
                </Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                    <TsButton
                        onClick={(e) => setOpen(false)}
                        color='secondary'
                    >Voltar</TsButton>
                </Box>
            </Box>
       </TsDialog>
    </>

    );

 
 /*
    return (
    <Box>
    <FormControl 
        variant="outlined" 
        fullWidth
    >
        <InputLabel htmlFor="chave-bancaria-valor">Chave bancária</InputLabel>
        <OutlinedInput
          id="chave-bancaria-valor"
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                edge="end"
              >
                <BankIcon></BankIcon>
              </IconButton>
            </InputAdornment>
          }
          disabled={true}
        />
    </FormControl>
    </Box>
      );
 */

    /*
    return (
 
        <Box sx={{position: 'relative'}}>
        <TsTextField
            name={name}
            label="Chave bancária"
            fullWidth
            disabled={true}
            value={value || ''}
        />
            <Fab
                title="Editar chave bancária"
                name={name}
                disabled={disabled || false}
                color='alert'
                size="small"
                onClick={(e) => void(0)}
                sx={{marginLeft: 2, position: 'absolute', left: '0', top: '16px'}}
            > 
                <BankIcon></BankIcon>
            </Fab>
        </Box>);
    */
}

export default ChaveBancaria;