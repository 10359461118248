import * as React from 'react';
import { Box } from '@mui/material';
import { monitorCSS } from '../../style';


const titleCSS = {
    background: '#000099',
    color: '#ccc',
    padding: '10px',
    textAlign: 'center',
    margin: '4px',
  };

const ShowInfo = (props) => {

    const {pedido, debugMsg} = props;


    return (<>
    <Box paddingTop={2}>
        <Box sx={titleCSS}>DEBUG, MENSAGENS E ÚLTIMO REQUEST</Box>
        <Box>
        PROXY URL: {pedido.proxy.url} | REDE DO PROXY: {pedido.rede}
        </Box>
        <Box sx={monitorCSS}>
            {debugMsg.map((value, index) => {
                return <div key={index}>{value}</div>;
            })}
        </Box>
    </Box>
    </>);

}

export default ShowInfo;