import React from "react";
import { PageFull } from '../Page-full';

import TsBigNumber from '../../formulario/TsBigNumber';
import DashCadastro from './dash-cadastro';
import DashProdutos from './dash-produtos'
import DashRede from './dash-rede';
import DashResumo from "./dash-resumo";
import DashTransacao from './dash-transacao';
import ProdutoBuscar from '../produto/produto-buscar'; 
import {Box, Fade, IconButton, Typography} from "@mui/material";
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';
import relatorioHttp from '../../util/http/relatorio-http';
import MiniMenu from '../relatorio/mini-menu';
import SailingIcon from '@mui/icons-material/Sailing';
import { timeout } from '../../util/Util';
import { monitorCSS } from '../../style';
import ArrowIcon from '@mui/icons-material/ArrowDropDown';
import { bgRed, relatorioTitleCSS } from "./dash-style";
import ShowAlert from "../../util/ShowAlert";
import SelectCampo from '../../formulario/SelectCampo';

import {convertArray} from './dash-util'
import TsDialog from "../../formulario/TsDialog";
import {getProjetoStorage, getUser} from "../../util/auth";
import TsButton from "../../formulario/TsButton";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import 'dayjs/locale/pt-br';

import ProdutoBuscarDialog from '../proxy/table-produto';


const DashPage_Processando = {
    main: false,
    resumo: false,
    produto: false,
    rede: false,
    cadastro: false,
    listPages: false,
    transacao: false,
};

let DashPage_Select_Processando = false;

const BasicDateCalendar = (props) => {
    const {label, value, onChange} = props; 
    //  label="Controlled picker"
    // value={value}
    // onChange={(newValue) => setValue(newValue)}
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <DemoContainer components={['DatePicker']}>
            <DatePicker minDate={dayjs('2024-01-01')} maxDate={dayjs()} label={label} value={dayjs(value)} onChange={onChange} />
        </DemoContainer>
        </LocalizationProvider>
    );
  }
 
const DashPage = (props) => {

    const setOpenPerfil = props.setOpenPerfil;

    const atual = getUser('powerbi');
    // atual.urlDashLogo
    const [pages, setPages] = React.useState([]);
    const [pageSelected, setPageSelected] = React.useState(-1);
    const [titulo, setTitulo] = React.useState('Carregando relatórios');
    const [executing, setExecuting] = React.useState(false);
    const [url, setUrl] = React.useState('');
    const [error, setError] = React.useState(undefined);
    const [splash, setSplash] = React.useState(true);
    const projAtual = getProjetoStorage();
    const redeId = atual.rede || '0007';

    const [busca, setBusca] = React.useState({index: 0});
    const [buscaIndex, setBuscaIndex] = React.useState(0); 
    
    const [homeData, setHomeData] = React.useState({});
    const [resumoData, setResumoData] = React.useState(undefined);
    const [prodData, setProdData] = React.useState(undefined);
    const [redeData, setRedeData] = React.useState(undefined);
    const [cadastroData, setCadastroData] = React.useState(undefined);
    const [transacaoData, setTransacaoData] = React.useState(undefined);
    const [buscaSelect, setBuscaSelect] = React.useState({
      'marca':  {name: 'marca', text: 'Todas',open: false, id: 0}, 
      'rede':  {name: 'rede', text: 'Todas',open: false, id: 0}, 
      'canal':  {name: 'canal', text: 'Todos',open: false, id: 0}, 
      'ean':  {name: 'ean', text: 'Todos',open: false, id: 0, valor: ''}, 
      'periodo':  {name: 'periodo', text: '', id: 0,open: false, inicio: '', final: ''}
    });
    const [listaSelect, setListaSelect] = React.useState({name: '', lista: []});
    // 

    const setProcessando = (value, modulo) => {

        DashPage_Processando[modulo] = value;

        if (value === false) {

            setExecuting(false);

        }
        else {
            setExecuting(true);
        }
    }

    const waitSplash = async () => {
        setSplash(true);
        await timeout(3000);
        setSplash(false);
    }

    const listPages = async ()  => {
        if (DashPage_Processando.listPages === true) 
            return;

        setProcessando(true, 'listPages');
 
        try { 
            const modulo =  'powerbi-prod';
            const { data } = await relatorioHttp.obterPainelItem(modulo); 
            selectPage(0);
            setTitulo(data[0].titulo);
            setPages(data);
            setBusca({...busca, index: busca.index + 1});
            
        } catch (error) {
            setError(error);
            setUrl(relatorioHttp.getUrl());
        }
        setProcessando(false, 'listPages');
    }

    const selectPage = (index) => {
        if (pageSelected !== index && index >= 0) {
            setPageSelected(index);
            window.scrollTo(0, 0);
        }
    };

    const MakeQuery = () => {
        const query = {...busca};

        query.redeId = buscaSelect['rede'].id;
        query.marcaId = buscaSelect['marca'].id;
        query.canalId = buscaSelect['canal'].id;
        query.inicio = buscaSelect['periodo'].inicio;
        query.final = buscaSelect['periodo'].final;
        query.ean = buscaSelect['ean'].valor;
        return query;
    }

    const BuscarMain = async () => {
        
        if (DashPage_Processando.main === true) 
            return;

        const query = MakeQuery();

        setProcessando(true, 'main');
        setHomeData({});

        setBuscaIndex(busca.index);
        BuscaComponente(query);
        try {
            const {status, data} = await relatorioHttp.obterDashData('home', query);
            if (status === 200 && data.sucesso === true) {
                setHomeData(JSON.parse(data.dado));
            }
            else {
                ShowAlert.error(data.mensagem);
            }
        } catch (error) {
            setError(error);
        }
        
        setProcessando(false, 'main');
        
    }

    const BuscaComponente = async (query) => {
        if (query === undefined) {
            query = MakeQuery();
        }
        if (pageSelected === 0) {
            BuscarResumo(query);
        }
        else if (pageSelected === 1) {
            BuscarProduto(query);
        }
        else if (pageSelected === 2) {
            BuscarRede(query);
        }
        else if (pageSelected === 3) {
            BuscarCadastro(query);
        }
        else if (pageSelected === 4) {
            BuscarTransacoes(query);
        }
    }

    const BuscarResumo = async (query) => {
        
        if (DashPage_Processando.resumo === true)
            return;

        setProcessando(true, 'resumo');
        setResumoData(undefined);

        try {

            const {status, data} = await relatorioHttp.obterDashData('resumo', query);
            if (status === 200 && data.sucesso === true) {
                const obj = JSON.parse(data.dado);
                obj.faixaTicketMedio = convertArray(obj.faixaTicketMedio);
                setResumoData(obj);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
            
        } catch (error) {
            setError(error);
        }

        setProcessando(false, 'resumo');
    } 

    const BuscarProduto = async (query) => {

        if (DashPage_Processando.produto === true)
            return;

        setProcessando(true, 'produto');
        setProdData(undefined)
        
        try {

            const {status, data} = await relatorioHttp.obterDashData('produto', query);
            if (status === 200 && data.sucesso === true) {
                const obj = JSON.parse(data.dado);
                obj.marca_qtdeVendas = convertArray(obj.marca_qtdeVendas);
                obj.marca_valorVendas = convertArray(obj.marca_valorVendas);
                obj.marca_descontoVendas = convertArray(obj.marca_descontoVendas);
                obj.prod_qtdeVendas = convertArray(obj.prod_qtdeVendas);
                obj.prod_valorVendas = convertArray(obj.prod_valorVendas);
                obj.prod_descontoVendas = convertArray(obj.prod_descontoVendas);
                
                setProdData(obj);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
            
        } catch (error) {
            setError(error);
        }

        setProcessando(false, 'produto');
    } 

    const BuscarRede = async (query) => {
        
        if (DashPage_Processando.rede === true)
            return;

        setProcessando(true, 'rede');
        setRedeData(undefined);
        
        try {
            const {status, data} = await relatorioHttp.obterDashData('rede', query);
            if (status === 200 && data.sucesso === true) {
                const obj = JSON.parse(data.dado);
                obj.canal_qtdeVendas = convertArray(obj.canal_qtdeVendas);
                obj.canal_valorVendas = convertArray(obj.canal_valorVendas);
                obj.canal_descontoVendas = convertArray(obj.canal_descontoVendas); 

                obj.rede_qtdeVendas = convertArray(obj.rede_qtdeVendas);
                obj.rede_valorVendas = convertArray(obj.rede_valorVendas);
                obj.rede_descontoVendas = convertArray(obj.rede_descontoVendas); 

                obj.cat_qtdeVendas = convertArray(obj.cat_qtdeVendas);
                obj.cat_valorVendas = convertArray(obj.cat_valorVendas);
                obj.cat_descontoVendas = convertArray(obj.cat_descontoVendas); 
                
                setRedeData(obj);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
            
        } catch (error) {
            setError(error);
        }

        setProcessando(false, 'rede');
    }

    const BuscarCadastro = async (query) => {
        
        if (DashPage_Processando.cadastro === true)
            return;

        setProcessando(true, 'cadastro');
        setCadastroData(undefined);
        
        try {
                
            const {status, data} = await relatorioHttp.obterDashData('cadastro', query);
            if (status === 200 && data.sucesso === true) {
                const obj = JSON.parse(data.dado);
                obj.cadastros = convertArray(obj.cadastros); 
                
                setCadastroData(obj);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
            
        } catch (error) {
            setError(error);
        }
        
        setProcessando(false, 'cadastro');
    }

    const BuscarTransacoes = async (query) => {
        // setTransacaoData
        if (DashPage_Processando.transacao === true)
            return;

        setTransacaoData(undefined);
        setProcessando(true, 'transacao');
        
        try {   
            const {status, data} = await relatorioHttp.obterDashData('transacao', query);
            if (status === 200 && data.sucesso === true) {
                const obj = JSON.parse(data.dado);
                setTransacaoData(obj);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
            
        } catch (error) {
            setError(error);
        }
        
        setProcessando(false, 'transacao');
    }

    const FillListaSelect = async (item) => {
        if (DashPage_Select_Processando)
            return;

        DashPage_Select_Processando = true;
        const name = item.name;
        let ret = undefined;
        
        try {
            if (name === 'rede')
                ret = await relatorioHttp.obterLista('rede', projAtual.id);
            else if (name === 'marca')
                ret = await relatorioHttp.obterLista('marca', projAtual.id);
            else if (name === 'canal')
                ret = await relatorioHttp.obterLista('canal', projAtual.id);
            else {
                DashPage_Select_Processando = false;
                return;
            }

            if (ret.status === 200) {
                setListaSelect({name , lista: [{value: '0', text: 'Todas'}, ...ret.data]});
            }
        } catch (error) {
            ShowAlert.error(error.mensagem || error.message);
        }
        DashPage_Select_Processando = false;
    }

    React.useEffect(() => {
        if (pages.length === 0) {
            listPages();
            waitSplash();
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

    React.useEffect(() => {
        if (busca.index > buscaIndex) {
            BuscarMain();
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [busca]);

    React.useEffect(() => {
        if (pages && pages.length > 0) {
            const page = pages[pageSelected];
            BuscaComponente();
            setTitulo(page.titulo);
        }
            
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [pageSelected]);

    React.useEffect(() => {
       
        const arr = Object.values(buscaSelect);
        const item = arr.find((b) => b.open === true);
        if (item === undefined) {
            return;
        }
        else {
            if (listaSelect.name !== item.name) {
                setListaSelect({name: '', lista: []});
                FillListaSelect(item);
            }
        }
         
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buscaSelect]);

    React.useEffect(() => {
        const item = buscaSelect['periodo'];
        if (item.inicio === '') {
            let agora = dayjs();
            const final = agora.format('YYYY-MM-DD');

            agora = agora.add(-6, 'month');
 
            const inicio = agora.format('YYYY-MM-DD'); 
            setBuscaSelect({...buscaSelect, 'periodo': {...item, text: `${inicio.substring(2, 10)} até ${final.substring(2, 10)}`, inicio, final}});
            setBusca({inicio, final});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const DashSelect = (props) => {
        const {name} = props;

        if (name === undefined) return (<Box></Box>);

        const item = buscaSelect[name];
        if (!item || item.open === undefined) {
            console.error(`DashSelect error: name: ${name} , item:`, item);
            return (<Box>ERROR</Box>);
        }
        return (<>
        <Box sx={{textAlign: 'center'}}>{item.text}
            <IconButton 
                onClick={(e) => { 
                    if (name === 'ean')
                        setBuscaSelect({...buscaSelect, [name]: {...item, text: 'Todos', valor: '', open: true}});
                    else 
                        setBuscaSelect({...buscaSelect, [name]: {...item, open: true}});
                }}
            ><ArrowIcon /></IconButton>
        </Box>
        
        {item.open === true && 
        <TsDialog fullWidth maxWidth="lg" onClose={() => setBuscaSelect({...buscaSelect, [name]: {...item, open: false}})} id={`dialog-dashselect-${name}`} open={item.open} title={`SELECT ${name}`}>
            <Box sx={{minHeight: '200px'}}>
            {(item.name === 'marca' || item.name === 'rede' || item.name === 'canal') && <SelectCampo
                    id={`select-${name}`}
                    label={name}
                    name={`select-${name}`}
                    options={listaSelect.lista}
                    value={item.id + '' || ''}
                    onChange={(e) => {
                        const text = listaSelect.lista.find(l => l.value === e.target.value)?.text;
                        setBuscaSelect({ ...buscaSelect,  [name]: {...item, text, open: false, id: Number(e.target.value)}});
                        setBusca({...busca, index: busca.index + 1});
                    }}
                />
            }
            {(item.name === 'ean') && 
            <Box>
                <ProdutoBuscarDialog
                    onSelect={(value, preco, nome) => {
                        setBuscaSelect({ ...buscaSelect,  [name]: {...item, text: value, open: false, valor: value}});
                        setBusca({...busca, index: busca.index + 1}); 
                    }}
                    redeId={redeId}
                    projetoId={5000}
                />    
            </Box>}
            {(item.name === 'periodo') && 
                <>
                <Box>
                    <BasicDateCalendar  
                        label='Data inicial' 
                        name={`data-inicial-${name}`} 
                        id={`data-inicial-${name}`} 
                        value={item.inicio || ''}
                        onChange={(valor) => {
                            const inicio = valor.format('YYYY-MM-DD');
                            const final = buscaSelect['periodo'].final;
                            setBuscaSelect({...buscaSelect, 'periodo': {...item, text: `${inicio.substring(2, 10)} até ${final.substring(2, 10)}`, inicio}});
                        }}
                    />
                </Box>
                <Box>
                    <BasicDateCalendar  
                        label='Data Final' 
                        name={`data-final-${name}`} 
                        id={`data-inicial-${name}`} 
                        value={item.final || ''}
                        onChange={(valor) => {
                            const inicio = buscaSelect['periodo'].inicio;
                            const final = valor.format('YYYY-MM-DD');
                            setBuscaSelect({...buscaSelect, 'periodo': {...item, text: `${inicio.substring(2, 10)} até ${final.substring(2, 10)}`, final}});
                        }}
                    />
                </Box>
            </>
            }
                <Box sx={{padding: 1}} dir="rtl">
                    <TsButton
                        onClick={(e) => {
                            setBuscaSelect({ ...buscaSelect,  [name]: {...item, open: false}});
                            setBusca({...busca, index: busca.index + 1});
                        }}
                    >Enviar</TsButton>
                    <TsButton
                        onClick={(e) => setBuscaSelect({...buscaSelect, [name]: {...item, open: false}})}
                        color='secondary'
                    >Cancelar</TsButton>
                </Box>
            </Box>
        </TsDialog>}
        </>);
    }


    const RelatorioLocal = () => {
        const titleStyle = {
            fontSize: '16pt', color: '#FFF', backgroundColor: '#E61414', borderRadius: '10px', padding: 0.5, paddingLeft: 2, margin: 2 
        };
        const page = pageSelected >= 0 ? pages[pageSelected] : {mensagem: 'nenhuma pagina selecionada', menuIcone: 'na'};
    

        if (page.menuIcone === 'home') {
            return (<DashResumo resumoData={resumoData} executing={executing} login={atual.login} open={page.menuIcone === 'home' ? true: false} />);
        }
        else if (page.menuIcone === 'fabricante') {
            return (<DashProdutos prodData={prodData} executing={executing} login={atual.login} open={page.menuIcone === 'fabricante' ? true: false} />);
        }
        else if (page.menuIcone === 'rede') {
            return (<DashRede redeData={redeData} executing={executing} login={atual.login} open={page.menuIcone === 'rede'? true: false} />);
        }
        else if (page.menuIcone === 'cadastro') {
            return (<DashCadastro cadastroData={cadastroData} executing={executing} login={atual.login} open={page.menuIcone === 'cadastro' ? true: false} />);
        }
        else if (page.menuIcone === 'transacao') {
            return (<DashTransacao transacaoData={transacaoData} executing={executing} login={atual.login} open={page.menuIcone === 'transacao' ? true: false} />);
        }
        else if (page.menuIcone === 'desconto') {
            return (
                <div>
                    <Box sx={titleStyle}>Descontos vigentes</Box>
                    {page.menuIcone === 'desconto' &&  <ProdutoBuscar fullScreen={true} pageSize={100} showPagging={false}></ProdutoBuscar>}
                </div>        
            );
        }
        else {
            return(<Box sx={monitorCSS}>{JSON.stringify(page)}</Box>)
        }

    };

    return (
    <PageFull title="">
        <MiniMenu 
            itens={pages?.map((p, index) => ({menuIcone: p.menuIcone, menuLabel: p.menuLabel, index}))} 
            selected={pageSelected} 
            show={!splash} 
            setOpenPerfil={(e) => setOpenPerfil(true)}
            onClick={(index) => selectPage(index) } 
        />
        <div className='dash-titulo'><SailingIcon fontSize='large' sx={{color: 'white', marginRight: 4}} /><span className='dash-titulo-text'> Nave de comando Dclube</span></div>
        
    <Box sx={{flex: '1 8 auto', position: 'relative', marginLeft: 9}}>
        <div className='dash-container'>
        <Box className='dash-header'>
            <Box sx={{flex: '1 8 auto', marginLeft: 4, paddingTop: 5, color: '#555'}}>
                <span className='dash-titulo-text'>{titulo} {(splash) && ' ... Aguarde, processando'}</span>
            </Box>
            <Box sx={{flex: '0 0 auto', padding: 0.5, paddingRight: 2}}>
                <img src={atual.urlDashLogo} alt='parceiro' className='dash-logo' />
            </Box>
        </Box>
        <Box className='dash-content bgDash'>
        <Fade in={splash} timeout={{appear: 0, exit: 1500}} id='div-fade' unmountOnExit={true} >
                <div className='splash'><img id='img-fade' src='./splash.png' alt='' className='splash-img' /></div>
        </Fade>
            {!splash && <>
            <Box className='dash-flex-container' >
                <Box className='dash-flex-item-4'><TsBigNumber sx={{borderRadius: '8px 0 0 8px'}} bgcolor='blue' labelPos='bottom' label='Clientes Compradores' >{homeData.clientesCompradores || '-'}</TsBigNumber></Box>
                <Box className='dash-flex-item-4'><TsBigNumber bgcolor='blue' labelPos='bottom' label='Transações'>{homeData.nTransacoes || '-'}</TsBigNumber></Box>
                <Box className='dash-flex-item-4'><TsBigNumber bgcolor='blue' labelPos='bottom' label='Itens'>{homeData.nItens || '-'}</TsBigNumber></Box>
                <Box className='dash-flex-item-4'><TsBigNumber sx={{borderRadius: '0 8px 8px 0'}} bgcolor='blue' labelPos='bottom' label='Valor Total Bruto das Transações'>{homeData.valorTotalTransacoes || '-'}</TsBigNumber></Box>
            </Box>
            { pageSelected >= 0 && pages[pageSelected] && pages[pageSelected].showBusca === true  && 
            <>
            <Box className='dash-flex-container' sx={{padding: 1, margin: 1}}>
                <Box className='dash-flex-item-5'>
                    <Box sx={{...bgRed,textAlign: 'center'}}>Marca</Box>
                    <DashSelect name='marca' text={buscaSelect['marca'].text} ></DashSelect>
                </Box>
                <Box className='dash-flex-item-5'>
                    <Box sx={{...bgRed, textAlign: 'center'}}>Rede</Box>
                    <DashSelect name='rede' text={buscaSelect['rede'].text} ></DashSelect>
                </Box>
                <Box className='dash-flex-item-5'>
                    <Box sx={{...bgRed, textAlign: 'center'}}>Canal</Box>
                    <DashSelect name='canal' text={buscaSelect['canal'].text} ></DashSelect>
                </Box>
                <Box className='dash-flex-item-5'>
                    <Box sx={{...bgRed, textAlign: 'center'}}>Produto (EAN)</Box>
                    <DashSelect name='ean' text={buscaSelect['ean'].text} ></DashSelect>
                </Box>
                <Box className='dash-flex-item-5'>
                    <Box sx={{...bgRed, textAlign: 'center'}}>Periodo</Box>
                    <DashSelect name='periodo' text={`${buscaSelect['periodo'].inicio} até ${buscaSelect['periodo'].final}`} ></DashSelect>
                </Box>
            </Box>
            </>
            }
            <RelatorioLocal />
            </>}
        </Box>
        </div>
    </Box>

    <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={executing} />
    </PageFull>);

}

export default DashPage;