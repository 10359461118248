// @flow 
import * as React from 'react';
import MUILink from '@mui/material/Link';
import { Link, Navigate } from 'react-router-dom';
import { Page } from '../Page';
import TsDataGrid from '../../formulario/TsDataGrid';
import { HOMEDIR, getProjetoStorage } from '../../util/auth';
import limiteHttp from '../../util/http/limite-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';
import EventNoteIcon from '@mui/icons-material/EventNote';

// <TsDataGridx />
class LimiteListar extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;
        this.state.redirect = '';
        this.projeto = getProjetoStorage();

        this.columns = [
            {
                name: "limitadorItemid",
                label: "ID",
                format: ((value) => (value && <MUILink component={Link} to={`${HOMEDIR}/limitador/${value}/editar`}><EventNoteIcon /></MUILink>))
            },
            {
                name: "projetoId",
                label: "Projeto"
            },
            {
                name: "chaveLimitador",
                label: "Chave"
            },
            {
                name: "descricao",
                label: "Descrição"
            },
            {
                name: "intervaloMinutos",
                label: "Intervalo",
                format: ((value) => this.obterPeriodo(value))
            },
            {
                name: "limitadorItemid",
                label: "Valor",
                format: ((value) => this.obterValor(value))
            },
            {
                name: "ativo",
                label: "Ativo",
                format: ((value) => (value ? <>Ativo</> : <>Inativo</>))
            }
        ];
    }

    setRedirect(redirect) {
        this.setState({redirect});
    }

    Buscar = () => {

        this.iniciarBusca();
        const query = { page: 0, nrecPage: 10, projetoId: Number(this.projeto.id)};
        if (this.state.objBusca.ativo !== undefined) {
            query.ativo = this.state.objBusca.ativo;
        }
        
        limiteHttp.busca(query)
            .then(({ data }) => {
                this.setRows(data.dados);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setUrl(limiteHttp.getUrl());
                this.setBuscando(false);
                this.setProcessando(false);
            });
    };

    open() {
        // React.useEffect(() => {
        //     buscarLimites();
        // }, []);
    }

    obterPeriodo = (value) => {
        if (value < 60) {
            return (<div>{value.toFixed(2)} minutos</div>);
        }
        value = value / 60;
        if (value < 24) {
            return (<div>{value.toFixed(2)} horas</div>);
        }
        value = value / 24;
        return (<div>{value.toFixed(2)} dias</div>);
    }

    obterValor = (value) => {

        const item = this.state.rows.find(l => l.limitadorItemid === value);
        let valor = "N/A";

        if (item) {
            if (item.nItensTotalPeriodo > 0) {
                valor = `ITEM: ${item.nItensTotalPeriodo}`;
            }
            else if (item.qtdeEanPeriodo > 0) {
                valor = `EAN: ${item.qtdeEanPeriodo}`;
            }
            else if (item.nCompraPeriodo > 0) {
                valor = `COMPRA: ${item.nCompraPeriodo}`;
            }

        }

        return (<div>{valor}</div>);
    }

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
    }

    render()  {
        return (
            <Page title="Listar Limites">
                <>
                    <TsInputBusca
                        name="input-busca"
                        value={this.state.busca}
                        showBusca={true}
                        showAdd={true}
                        showAtivo={true}
                        onClickAdd={(e) => this.setRedirect(`${HOMEDIR}/limitador/criar`) }
                        submitSearch={(texto, ativo) => {
                            this.setObjBusca({...this.state.objBusca, ativo: ativo});
                        }}
                    ></TsInputBusca>
                </>
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                />
                {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
                <TsBackDrop processando={this.state.processando} />
                {this.state.redirect ? <Navigate to='this.state.redirect' replace={true} />: null}
            </Page>
        );
    }
};

export default LimiteListar;