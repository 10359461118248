import * as React from 'react';
import { Link, Box } from '@mui/material';
import { Page } from '../Page'; 
import UsuarioForm from './usuario-form';

/* http */
import usuarioHttp from '../../util/http/usuario-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsDialog from '../../formulario/TsDialog';
import TsInputBusca from '../../formulario/TsInputBusca';
import SelectCampo from '../../formulario/SelectCampo';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LoginIcon from '@mui/icons-material/Login';
import { HOMEDIR, setUser, usuarioNull } from '../../util/auth'; 
import {listaTipo, listaFiltro} from './usuario-lista';

class Table extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;
        this.state.objBusca = {busca: '', tipo: 'all', filtroTipo: '', ativo: true};

        this.state.open = false;
        this.state.login = '';
        this.state.id = 0;
        this.columns = [
            {
                name: "id",
                label: "id",
                format: ((value) => (<Link component="button" onClick={() => this.openDetail(value)}>
                <EventNoteIcon />
                </Link>))
            },
            {
                name: "login",
                label: "Login"
            },
            {
                name: "descricao",
                label: "Descricao"
            },
            {
                name: "tipo",
                label: "Tipo"
            },
            {
                name: "modAdmin",
                label: "Admin"
            },
            {
                name: "modHome",
                label: "Home"
            },
            {
                name: "modPowerbi",
                label: "Powerbi"
            },
            {
                name: "filtroTipo",
                label: "Filtro"
            },
            {
                name: "filtroRegistro",
                label: "Valor"
            },
            {
                name: "login",
                label: "login-as",
                format: ((value) => <Link component="button" onClick={() => this.loginAs(value)}><LoginIcon /></Link>)
            }
        ];
    }

    setLogin(login) {
        this.setState({login});
    }

    setId(id) {
        this.setState({id});
    }

    setOpen(open) {
        this.setState({open});
    }

    closeModal() {
        this.setOpen(false);
        this.Buscar();
    }

    loginAs(login) {
        this.setProcessando(true);
        localStorage.setItem("login-as", login);
        setUser(usuarioNull);
        setTimeout(() => {
            window.location.href = `${HOMEDIR}/`;
        }, 1000);
        // const { navigate } = this.props;
        // navigate(`${HOMEDIR}/`);
        
    }

    Buscar = () => {
        this.iniciarBusca();
        let tipo = this.state.objBusca.tipo ? this.state.objBusca.tipo : '';
        if (tipo === 'all') tipo = '';
        const filtroTipo = this.state.objBusca.filtroTipo;
        let busca = this.state.objBusca.busca;
        let filtroRegistro = Number(busca);
        if (isNaN(filtroRegistro) === true) filtroRegistro = 0;

        if (filtroTipo &&  filtroRegistro > 0) {
            busca = '';
        }

        const query = { page: this.state.serverPage, 
                    nrecPage: this.serverPageSize, 
                    projetoId: parseInt(this.projeto.id), 
                    ativo: true, 
                    tipo: tipo, 
                    filtroTipo: filtroTipo,
                    filtroRegistro: filtroRegistro === 0 ? '': filtroRegistro + '',
                    busca: busca };

        usuarioHttp.busca(query)
            .then(({ data }) => {
                this.setRows(data.dados);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(usuarioHttp.getUrl());
            });
    }

    openDetail = (value) => {

        if (value + '' === '0') {
            this.setLogin('NOVO USUARIO');
            this.setId(0);
            this.setOpen(true);
            return;
        }
        
        const item = this.state.rows.find(r => r.id === value);

        if (!item) {
            window.alert("ID INVALIDO");
            return;
        }

        this.setLogin(item.login);
        this.setId(item.id);
        this.setOpen(true);
         

    };

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
    }

    load() {
        // React.useEffect(() => {
        //     Buscar();
        // }, [objetoBusca]);
    }
    
    render() {

        return (
            <div>
                <TsInputBusca
                    name="input-busca"
                    value={this.state.objBusca.busca}
                    showAtivo={false}
                    showBusca={true}
                    showAdd={true}
                    submitSearch={(texto, ativo) => { this.setObjBusca({...this.state.objBusca, ativo: ativo, busca: texto}); }}
                    onClickAdd={(e) => this.openDetail(0)}
                >
                    <Box sx={{flex: '0 0 auto', width: '300px', display: 'flex'}}>
                        <Box sx={{flex: '1 1 auto', width: '150px', paddingRight: 2}}>
                            <SelectCampo
                                id="TsInputBusca-Tipo"
                                label="Tipo"
                                name="usuarioTipo"
                                options={[{text: 'Todos', value: ''}, ...listaTipo]}
                                value={this.state.objBusca.tipo || ''}
                                onChange={(e) => this.setObjBusca({ ...this.state.objBusca,  tipo: e.target.value})}
                                />
                        </Box>
                        <Box sx={{flex: '1 1 auto', width: '150px', paddingRight: 2}}>
                            <SelectCampo
                                id="TsInputBusca-Filtro"
                                label="Filtro"
                                name="usuarioFiltro"
                                options={[{text: 'Todos', value: ''}, ...listaFiltro]}
                                value={this.state.objBusca.filtroTipo || ''}
                                onChange={(e) => this.setObjBusca({ ...this.state.objBusca,  filtroTipo: e.target.value})}
                                />
                        </Box>
                    </Box>
                </TsInputBusca>
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                />
                {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
                <TsBackDrop processando={this.state.processando} />
                <TsDialog onClose={() => this.setOpen(false)} id="dialog-detalhe-log" open={this.state.open} title={`Detalhes LOGIN: ${this.state.login}`} minWidth={800} >
                    {this.state.open && <UsuarioForm login={this.state.login} id={this.state.id} open={this.state.open} closeModal={() => this.closeModal()} setProcessando={(value) => this.setProcessando(value)} />}
                </TsDialog>
            </div>
        );
    }
};


const UsuarioListar = () => {
    return (
        <Page title="Listar usuarios">
            <Box>
                <Table></Table>
            </Box>

        </Page>
    );
};

export default UsuarioListar;

// export default UsuarioListar;