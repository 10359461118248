
import * as React from 'react';
import { Box, Fab, Autocomplete, TextField } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

/* http */
import participanteClassificacaoHttp from '../../util/http/participante-classificacao';
import classificacaoHttp from '../../util/http/classificacao-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
// import { HOMEDIR } from '../../util/auth';
// import TsDataGridBusca from '../../formulario/TsDataGridBusca';
import TsButton from '../../formulario/TsButton';
import ShowError from '../../formulario/ShowError';
import TsInputBusca from '../../formulario/TsInputBusca';
import ListaBase from '../ListaBase';
import ShowAlert from '../../util/ShowAlert';

// _type_ Props = {
//     cpf,
//     open 
// };

// <TsDataGridx />
export class ParticipanteDetalhe extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;

        this.state.adicionar = false;
        this.state.listaLabel = [];
        this.state.perfil = {id: '', label: ''};
        this.state.objBusca = {busca: '', ativo: true, cpf: props.cpf}

        this.columns = [
            {
                name: "id",
                label: "CPF",
                format: (value) => (<div>{this.state.objBusca.cpf}</div>)
    
            },
            {
                name: "classificacaoNome",
                label: "perfil"
            },
            {
                name: "classificacaoNome",
                label: "x",
                format: (value) => { return <Fab color='alert' size="small" aria-label="delete" onClick={(e) => this.deleteItem(value)}><DeleteForeverIcon /></Fab> }
            }
        ];
    }

    setAdicionar(adicionar) {
        this.setState({adicionar});
    }

    setListaLabel(listaLabel) {
        this.setState({listaLabel});
    }

    setPerfil(perfil) {
        this.setState({perfil});
    }

 
        // const [adicionar, setAdicionar] = React.useState(false);
        // const [listaLabel, setListaLabel] = React.useState([]);
        // const [perfil, setPerfil] = React.useState({ id: "", label: "" });

    deleteItem = (value) => {
        this.setError(undefined);
        this.setProcessando(true);
        participanteClassificacaoHttp
            .deleteClassificacao(this.props.cpf, Number(this.projeto.id), value)
            .then((retorno) => {
                ShowAlert.info('Deletado com sucesso com sucesso');
                this.setAdicionar(false);
                this.Buscar();
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setUrl(participanteClassificacaoHttp.getUrl());
            });

            this.setAdicionar(false);
    }

    AdicionarClassificacao = () => {
        this.setPerfil({ id: "", label: "" });


        if (this.state.listaLabel.length === 0) {
            this.setProcessando(true);
            this.setAdicionar(false);
            this.setError(undefined);
            this.setProcessando(true);

            classificacaoHttp.list()
                .then((retorno) => {
                    const tmp = retorno.data.dados;
                    const ls = [];
                    tmp.forEach((it) => ls.push({ id: it.id.toString(), label: it.classificacaoNome }));
                    this.setListaLabel(ls);
                    this.setAdicionar(true);
                })
                .catch((error) => {
                    this.setError(error);
                })
                .finally(() => {
                    this.setProcessando(false);
                    this.setUrl(classificacaoHttp.getUrl());
                });
        }
        else {
            this.setAdicionar(true);
        }


    };

    AdicionarSubmit = () => {
        this.setError(undefined);
        this.setProcessando(true);

        participanteClassificacaoHttp
            .updateClassificacao(this.props.cpf, Number(this.projeto.id), [{ id: this.state.perfil.id, classificacaoNome: this.state.perfil.label }])
            .then((retorno) => {
                ShowAlert.info('Atualizado com sucesso');
                this.Buscar();
                this.setAdicionar(false);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setUrl(participanteClassificacaoHttp.getUrl());
            });

        this.setAdicionar(false);
    };

    Buscar = () => {
        this.iniciarBusca();

        const query = { page: this.state.serverPage, nrecPage: this.serverPageSize, ativo: this.state.objBusca.ativo, cpf: this.state.objBusca.cpf, projetoId: this.projeto.id };

        participanteClassificacaoHttp.busca(query)
            .then(({ data }) => {
                this.setRows(data.dados);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(participanteClassificacaoHttp.getUrl());
            });
    }

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);

        if (prevProps.cpf !== this.props.cpf) {
            this.state.objBusca = {busca: '', ativo: true, cpf: this.props.cpf}
        }

        if (this.state.execBuscar === true) this.Buscar();
    }

    load() {
        // React.useEffect(() => {
        //     if (open && cpf)
        //         Buscar();
        // }, [open, cpf]);
    }

    render() {

        return (
            <React.Fragment>
                {this.props.open &&
                    <>
                        {this.state.adicionar &&
                            <form>
                                <Box sx={{ display: "flex" }} padding={4}>
                                    <Autocomplete
                                        freeSolo
                                        id="combo-box-perfil"
                                        options={this.state.listaLabel}
                                        value={this.state.perfil}
                                        onChange={(event, value) => {
                                            if (value !== null) {
                                                this.setPerfil(value);
                                            }
                                        }}
                                        sx={{ flex: "8 1 auto", width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Perfil" value={this.state.perfil.label}
                                            onChange={(e) => this.setPerfil({ id: "0", label: e.target.value })}
                                        />}
                                    />
                                    <Box dir="rtl" paddingTop={4}>
                                        <TsButton
                                            onClick={(e) => this.AdicionarSubmit()}
                                        >Enviar</TsButton>
                                        <TsButton
                                            onClick={(e) => this.setAdicionar(false)}
                                            color='secondary'
                                        >Cancelar</TsButton>
                                    </Box>
                                </Box>
                            </form>
                        }
                        <div>
                            <Box marginTop="2">
                                <Box>Nome: <strong> {this.props.nome}</strong></Box>
                                <Box>Email: <strong> {this.props.email} </strong></Box>
                                <TsInputBusca
                                        name="input-busca" 
                                        showAtivo={false}
                                        showBusca={false}
                                        showAdd={true}
                                        onClickAdd={(e) => this.AdicionarClassificacao()}
                                    ></TsInputBusca>
                                <TsDataGrid
                                    rows={this.state.rows}
                                    columns={this.columns}
                                    queryid={this.state.queryid}
                                    serverPage={this.state.serverPage}
                                    serverPagesize={this.serverPageSize}
                                    onChangePage={(value) => this.setServerPage(value)}
                                />  
                            </Box>
                        </div>
                    </>
                }

                    {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
                    <TsBackDrop processando={this.state.processando} />
            </React.Fragment>

        );
    }
};