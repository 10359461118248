import React from "react";

import {Box} from '@mui/material';
import { tituloBarra, containerBarChart, tituloBarChart, contentBarChar, optionsColumn } from "./dash-style";
import { monitorCSS } from "../../style";
import { Chart } from "react-google-charts";

// Evolução Cadastral
const DashCadastro = (props) => {

    const {cadastroData, executing} = props;

    return (<Box>
            {cadastroData && <>
            <Box sx={tituloBarra}>Evolução cadastral últimos 12 meses</Box>
            <Box sx={{...containerBarChart, width: 'auto', height: '570px', marginLeft: 1, marginRight: 1}}>
                <Box sx={tituloBarChart}>Quantidade de cadastros por mês</Box>
                <Box sx={contentBarChar}>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height={500}
                        data={cadastroData.cadastros}
                        options={{...optionsColumn, title: ""}}
                        legendToggle
                        />
                </Box>
            </Box>
            </>}
            {cadastroData === undefined 
            ? <Box sx={{minHeight: '300px'}}>...Processando</Box> 
            : <>{props.login === 'egoncalez' && <Box sx={{...monitorCSS, marginTop: 2}}>{JSON.stringify(cadastroData, null, 2)}</Box>}</>}
            </Box>);

}

export default DashCadastro;