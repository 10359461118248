import * as React from 'react';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import CopyIcon from '@mui/icons-material/ContentCopy';
import ShowAlert from '../util/ShowAlert';

export const TsTextField = (props) => {

  const copy = props.disabled && props.copy ? {endAdornment: 
    <InputAdornment position="end">
        <IconButton sx={{padding: 0}}  onClick={(e) => ClipCopy(props.value)} ><CopyIcon sx={{fontSize: '1.2rem'}} /></IconButton>
    </InputAdornment>
  } : null;

  const p = {...props};
  const fullWidth = props.fullWidth === undefined ? true : props.fullWidth;
  const variant = props.variant || 'outlined';
  const margin = props.margin || 'normal';
  const sx = props.sx || {margin: 1};

  delete p.fullWidth;
  delete p.variant;
  delete p.margin;
  delete p.sx;

  const ClipCopy = (texto) => {
    navigator.clipboard.writeText(texto);
    ShowAlert.info("Valor copiado com sucesso");
  }

  if (p.disabled) {
    return (
      
      <FormControl onDoubleClick={(e) => ClipCopy(p.value)} fullWidth variant={variant} sx={{...sx, cursor: 'pointer'}}  >
        <InputLabel htmlFor={p.id}>{p.label}</InputLabel>
        <OutlinedInput 
          label={p.label} 
          fullWidth={fullWidth} 
          disabled={true} 
          value={p.value} 
          {...copy}
        ></OutlinedInput>
      </FormControl>
      
      );
  }
  else {
    return <TextField fullWidth={fullWidth} variant={variant} margin={margin} sx={sx} {...p}  />;
  }

  
}
 
/*
export const TsTextField = (props) => {
  
  const [p, setP] = React.useState({ ...props });
  const [copy, setCopy] = React.useState(null);

  const ClipCopy = (texto) => {
    navigator.clipboard.writeText(texto);
    ShowAlert.info("Valor copiado com sucesso");
  }

  React.useEffect(() => {

    const tmp = {...props};
    tmp.fullWidth = props.fullWidth === undefined ? true : props.fullWidth;
    tmp.variant = props.variant || 'outlined';
    tmp.margin = props.margin || 'normal';
    tmp.defaultValue = props.value;

    if (tmp.sx === undefined) {
      tmp.sx = {margin: 1};
    } 

    if (tmp.disabled === true) {
      if (!tmp.value || tmp.value === null || tmp.value === 'undefined' || tmp.value === 'null') {
        tmp.value = p.label;
      }
      if (tmp.copy) {
        setCopy({endAdornment: 
          <InputAdornment position="end">
              <IconButton sx={{padding: 0}}  onClick={(e) => ClipCopy(tmp.value)} ><CopyIcon sx={{fontSize: '1.2rem'}} /></IconButton>
          </InputAdornment>
        });
      }
      else {
        setCopy(null);
      }
    }
    setP(tmp);
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [props]);

  if (p.disabled) {
    return (
      
      <FormControl onDoubleClick={(e) => ClipCopy(p.value)} fullWidth variant="outlined" sx={{...p.sx, cursor: 'pointer'}}  >
        <InputLabel htmlFor={p.id}>{p.label}</InputLabel>
        <OutlinedInput 
          label={p.label} 
          fullWidth={p.fullWidth} 
          disabled={true} 
          defaultValue={p.value} 
          {...copy}
        ></OutlinedInput>
      </FormControl>
      
      );
  }
  else {
    return <TextField {...p}  />;
  }

  
 
  
};
*/
