import { Box, Button, Link, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router';
import React from "react";
import { PagePublic } from '../Page';
import {TsTextField} from '../../formulario/TsTextField';
import ShowError from '../../formulario/ShowError';
import TsBackDrop from '../../formulario/TsBackDrop';
import CadastroSigner from './cadastro-signer';
import CadastroSites from './cadastro-sites';
import ShowAlert from '../../util/ShowAlert';
import contratoHttp from '../../util/http/contrato-http';
import { mainTitleCSS } from '../../style';
import ChaveBancaria from '../faturamento/chave-bancaria';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { listaTipoEmpresa } from './cadastro-lista';
import {HOMEDIR} from '../../util/auth';
import {TsTextMask} from '../../formulario/TsTextMask';
import getCep from '../../formulario/cep';

const propsPaper = {
    square: false, sx: {width: '100%', padding: 2, marginBottom: 2}, elevation: 2
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const cadastroDataNull = {
    id: 0, 
    prospectStatusId: 0, // incluido
    razao_social: '', 
    cnpj: '', 
    nome_fantasia: '', 
    ie: '', 
    endereco: '', 
    numero: '',
    cep: '',
    bairro: '',
    cidade: '',
    uf: '', 
    nomeFranqueadora: '',
    signers: [],
    sites: []
};
// tipoEmpresaId	descricao
// 1	varejista simples
// 2	varejista rede
// 3	distribuidor
// 4	automação
// 5	estabelecimento

let Cadastro_Processando = false;

const Cadastro = (props) => {

    const { tipo, cnpj, token } = useParams();

    const [cadastroData, setCadastroData] = React.useState(cadastroDataNull);

    const [executing, setExecuting] = React.useState(false); 
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] =  React.useState('');
    const [signers, setSigners] = React.useState([]);
    const [sites, setSites] = React.useState([]);
    const [complete, setComplete] =  React.useState(false); 
    const [contratoFile, setContratoFile] = React.useState({sucesso: true, mensagem: 'arquivo [pdf,doc,docx,png,jpg,jpeg]'});
    const [tabIndex, setTabIndex] = React.useState(0);
    const [tipoEmpresa, setTipoEmpresa] = React.useState({value: '5', text: 'Estabelecimento', path: 'cadastro', pageTitle: 'Cadastro Estabelecimento'});
    const [buttonText, setButtonText] = React.useState('Próximo');

    const setProcessando = (value) => {
        setExecuting(value);
        Cadastro_Processando = value;
    }

    // arrFileType = ['.doc', '.docx', '.pdf']
    // pasta = 'contratos-dev';
    // cadastroData.contratoFile = data.dado.link;
    const uploadFile = async (file, pasta) => {
        
        try {
            
            const {status, data} = await contratoHttp.enviarArquivo(pasta, file);
            console.log(`arquivo upload status: ${status} , data:`, data);
            if (status === 200 && data.sucesso === true) {
                /*{
                    "link": "https://zicard-accentiv.s3.amazonaws.com/dclube/contratos-dev/template001_06122024182427.docx",
                    "nomeArquivo": "template001_06122024182427.docx",
                    "nomeOriginal": "template001.docx"
                } */
                return {sucesso: true, mensagem: 'ok', link: data.dado.link};
                
            } else {
                ShowAlert.error("Falha ao enviar arquivo");
                return {sucesso: false, mensagem: "Falha ao enviar arquivo"}; 
            }    
            
        } catch (error) {
            setError(error);
            return {sucesso: false, mensagem: "Falha ao enviar arquivo - (EXCEPTION)"}; 
        }
    }

    const ChangeTab = async (tab) => {

        if (tab === 2 && cadastroData.cnpj === '') {
            setTabIndex(0);
            ShowAlert.error("Preencha o CNPJ");
            return;
        }

        setTabIndex(tab);

        if (tab === 0 || tab === 1)
            setButtonText('Próximo');
        else 
            setButtonText('Enviar');
    }

    const validarDados = async (event) => {

        if (event !== undefined)
            event.preventDefault();

        if (Cadastro_Processando === true) return;
        setProcessando(true);
        let msgErr = '';

        const cadData = {...cadastroData};

            if (!validateString(cadData.razao_social, 4, 'string')) msgErr = 'Razão social inválido';
            else if (!validateString(cadData.nome_fantasia, 4, 'string')) msgErr = 'Nome fantasia inválido';
            else if (!validateString(cadData.cnpj, 14, 'number')) msgErr = 'CNPJ inválido'; // 00000000000122
            else if (!validateString(cadData.ie, 4, 'string')) msgErr = 'Inscrição estadual inválido, preencha ISENTO caso necessário';
            else if (!validateString(cadData.endereco, 4, 'string')) msgErr = 'Endereço inválido';
            else if (!validateString(cadData.numero, 1, 'string')) msgErr = 'Numero inválido';
            else if (!validateString(cadData.cep, 8, 'number')) msgErr = 'CEP inválido'; // 01001001
            else if (!validateString(cadData.bairro, 2, 'string')) msgErr = 'Bairro inválido';
            else if (!validateString(cadData.cidade, 2, 'string')) msgErr = 'Cidade inválida';
            else if (!validateString(cadData.uf, 2, 'string')) msgErr = 'Estado inválido';
            else if (!validateString(cadData.chaveBancaria, 5, 'string')) msgErr = 'Chave bancária invalida';

            if (msgErr) {
                ShowAlert.error(msgErr);
                setTabIndex(0);
                setButtonText('Próximo');
                setProcessando(false);
                return;
            }

            if (tabIndex === 0) {
                setTabIndex(1);
                setButtonText('Próximo');
                setProcessando(false);
                return;
            }

            // validacao signatarios.
            
            if (signers.length === 0) {
                msgErr = 'É necessario pelo menos um REPRESENTANTE';
            }
            else if (signers.find(s => s.contratoSignerTipoId + '' === '1') === undefined) {
                msgErr = 'É necessario pelo menos um REPRESENTANTE';
            }
            else if ((cadData.tipoEmpresaId === 2 || cadData.tipoEmpresaId === 3) && signers.find(s => s.contratoSignerTipoId === 3) === undefined) {
                msgErr = 'É necessario pelo menos uma TESTEMUNHA';
            } 
            else {
                let nRepresentanteLegal = 0;
                for (let i = 0; i < signers.length; i++) {
                    const item = signers[i];
                    if (item.contratoSignerTipoId + '' === '1') nRepresentanteLegal++;

                    if (!validateString(item.nome, 4, 'string')) msgErr = 'Nome signatário inválido';
                    else if (!validateString(item.email, 4, 'email')) msgErr = `Email signatário inválido [${item.email}]`;
                    else if (!validateString(item.cpf, 11, 'number')) msgErr = `CPF signatário inválido [${item.cpf}]`; // 00583325882
                    else if (!validateString(item.fone, 8, 'number')) msgErr = `Celular signatário inválido [${item.cpf}]`; // 00583325882
                }
                if (nRepresentanteLegal === 0) msgErr = 'Indicar um Sócio representante';
                else if (nRepresentanteLegal > 1) msgErr = 'Indicar somente um Sócio representante';
            }

            if (msgErr) {
                ShowAlert.error(msgErr);
                setTabIndex(1);
                setButtonText('Próximo');
                setProcessando(false);
                return;
            }

            if (tabIndex === 1) {
                setTabIndex(2);
                setButtonText('Enviar');
                setProcessando(false);
                return;
            }

             // validacao sites (localidades).

            if (sites.length === 0) {
                msgErr = 'É necessario cadastrar pelo menos uma localidade';
            }
            else {
                sites.forEach(site => {
                    const lojaId = Number(site.lojaId);
                    if (isNaN(lojaId)) msgErr = 'Identificação da Loja inválida';
                    else if (!validateString(site.endereco, 4, 'string')) msgErr = 'Endereço da Loja inválido';
                    else if (!validateString(site.cidade, 3, 'string')) msgErr = 'Cidade da Loja inválido';
                    else if (!validateString(site.estado, 2, 'string')) msgErr = 'Estado da Loja inválido';
                });
            }

            if (msgErr) {
                ShowAlert.error(msgErr);
                // setTabIndex(2);
                // setButtonText('Enviar');
                setProcessando(false);
                return;
            }

            await Atualizar(cadData);

    }
    
    const Atualizar = async (cadData) => {

        cadData.logradouro = `${cadData.endereco}, ${cadData.numero}`;
        cadData.estado = cadData.uf;
        cadData.signers = signers;
        cadData.tipoEmpresaId = Number(tipoEmpresa.value);
        cadData.sites = JSON.stringify(sites);

        try {
             const {status, data} = await contratoHttp.enviarProspect(cadData);
             if (status === 200) {
                setComplete(true);
                ShowAlert.info("Dados enviados com sucesso");
             }
             else {
                ShowAlert.error(data.mensagem);
             }
        } catch (error) {
            if (error.mensagem) {
                ShowAlert.error(error.mensagem);
            }
            else {
                setComplete(true);
                ShowAlert.error('Falha ao cadastrar parceiro. contate o suporte.');
            }
            setUrl('');
        }
        setProcessando(false);
    }

    const validateString = (str, minLen, tipo) => {
        if (str === undefined || str === null) return false;
        if (typeof(str) !== 'string') return false;
        if (str.length < minLen) return false;
        if (tipo === 'number') {
            const s = str.replace(/\./g, '').replace(/-/g, '').replace(/,/g, '').replace(/\//g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '');
            if (s.length < minLen) return false;
            if (isNaN(Number(s))) return false;
        }
        return true;
    } 

    const buscarCadastro = async () => {

        if (Cadastro_Processando === true) return;
        
        const cnpj = cadastroData.cnpj;
        const token = cadastroData.token;

        if (!cnpj || !token) {
            ShowAlert.error("Cnpj ou token inválidos");
            return;
        }
        setProcessando(true);

        try {
            
            const {status, data} = await contratoHttp.continueProspect(cnpj, token);
            if (status === 200) {
                const p = data.logradouro.indexOf(',');
                const endereco = data.logradouro.substring(0, p);
                const numero = data.logradouro.substring(p + 2);
                const lista = data.signers;
                let lstSites = data.sites && data.sites !== null ? JSON.parse(data.sites) : [];
                const tipoEmpresaId = data.tipoEmpresaId + '';
                const item = listaTipoEmpresa.find(el => el.value === tipoEmpresaId);
                if (item !== undefined) {
                    setTipoEmpresa(item);
                } 

                let contratoFile = data.contratoFile;
                let estabelecimentosFile = data.estabelecimentosFile;

                if (contratoFile === null) contratoFile = '';
                if (estabelecimentosFile === null) estabelecimentosFile = '';

                contratoFile = contratoFile + '';
                estabelecimentosFile = estabelecimentosFile + '';

    
                setCadastroData({...data, 
                    uf: data.estado, 
                    endereco:endereco, 
                    numero: numero,
                    contratoFile: contratoFile,
                    estabelecimentosFile: estabelecimentosFile
                });

                setSigners(lista);
                setSites(lstSites);
            }
            else {
                console.log('buscarCadastro - status', status);
                ShowAlert.info('veja retorno na console');
            }

            
            
        } catch (error) {
            setUrl(contratoHttp.getUrl());
            setError(error);
            if (error.mensagem) {
                ShowAlert.error(error.mensagem);
            }
            else {
                ShowAlert.error('Falha ao cadastrar parceiro. contate o suporte.');
            }
            
        }
        setProcessando(false);
    } 

    const contratoUpload = async (event) => {
        event.preventDefault();

        //  event.target.elements.fileInput.files[0];
        // const file = event.target.files[0];
        // const element = document.getElementById('contrato-file-input');
        // const file = element && element.files ? element.files[0] : undefined; 

        const file = event.target.files ? event.target.files[0]: undefined;

        if (file && file.size > 0) {
            
            const rx = /^.+\.(pdf|doc|docx|png|jpg|jpeg)$/gi;
            // .doc,.docx,.pdf,.png,.jpg,.jpeg
            if (!rx.test(file.name)) {
                setContratoFile({sucesso: false, mensagem: 'extensão inválida [pdf,doc,docx,png,jpg,jpeg]'});
                return;
            }
            else if (file.size > 100000000) {
                setContratoFile({sucesso: false, mensagem: 'Tamanho não suportado'});
                return;
            }

            setProcessando(true);
            try {
                const retUpload = await uploadFile(file, 'contratos-dev');
                if (retUpload.sucesso === false) {
                    setContratoFile({sucesso: false, mensagem: retUpload.mensagem});
                }
                else {
                    setCadastroData({...cadastroData, contratoFile: retUpload.link});
                    setContratoFile({sucesso: true, mensagem: file.name.substr(0, 30)});
                }   
                
            } catch (error) {
                setContratoFile({sucesso: false, mensagem: error.mensagem || error.message});
            }
            setProcessando(false);
            
        }
    }

    const processCep = async () => {
        let cep = cadastroData.cep;
        cep = cep.replace(/\./g, '').replace(/-/g, '');

        if (cep.length === 8) {
            const data = await getCep(cep);
            if (data.cep) {
                setCadastroData({...cadastroData, 
                    endereco: data.logradouro,
                    bairro: data.bairro,
                    cidade: data.localidade,
                    uf: data.uf,
                });
            }
        }
    }

    React.useEffect(() => {
        let redirect = true;
        if (tipo !== undefined) {
            const item = listaTipoEmpresa.find(el => el.path === tipo);
            if (item !== undefined) {
                redirect = false;
                setCadastroData(cadastroDataNull);
                setTipoEmpresa(item);
            } 
        }
        else if (cnpj !== undefined && token !== undefined) {
            redirect = false;
            setCadastroData({...cadastroDataNull, cnpj, token: token});
        }

        if (redirect) {
            window.location.href = `${HOMEDIR}/cadastro/estabelecimento`;
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipo, cnpj, token]);

    React.useEffect(() => {
        if (cadastroData.cnpj && cadastroData.token && cadastroData.id === 0) {
            buscarCadastro();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cadastroData]);

    React.useEffect(() => {
        
    }, [tipo]);

     
    return (<PagePublic sx={{backgroundColor: '#ECECEC'}}>
        {complete 
        ? 
        <>Obrigado pelo cadastro. aguarde contato de nosso departamento comercial</> 
        : 
        <> 
            <Box sx={{padding: 2}}>
                <Typography sx={mainTitleCSS}>{tipoEmpresa.pageTitle}</Typography>
            </Box>
            <Box  sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto', padding: 2}}>
                    <Tabs value={tabIndex} onChange={(e, value) => ChangeTab(value)} aria-label="Opções do cadastro">
                        <Tab label="Geral" id="cad-tab-0" aria-labelledby={`cad-tabpanel-0`} />
                        <Tab label="Signatários" id="cad-tab-1" aria-labelledby={`cad-tabpanel-1`} />
                        <Tab label="Localidades" id="cad-tab-2" aria-labelledby={`cad-tabpanel-2`} />
                    </Tabs>
                </Box>
                <Box sx={{flex: '0 0 auto', padding: 2}}>
                    <form onSubmit={(event) => validarDados(event)}>
                            <Button
                                variant="contained" 
                                color="primary"
                                type="submit"
                            >{buttonText}</Button>
                    </form>
                </Box>
            </Box>
            
                
                <Paper {...propsPaper} >
                    <Box sx={{display: "flex"}}>
                        <Box sx={{flex: "0 0 auto", width: '300px', paddingRight: 2}}>
                            <TsTextMask
                                name="cnpj"
                                label="CNPJ" 
                                fullWidth
                                mask="00.000.000/0000-00"
                                inputProps={{ maxLength: 18 }}
                                value={cadastroData.cnpj || ''} 
                                disabled={false}
                                onChangeValue={(v) => {setCadastroData({...cadastroData, cnpj: v})}}
                            />
                        </Box>
                        <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                        <TsTextField
                                name="nome_fantasia"
                                label="Nome fantasia"
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                value={cadastroData.nome_fantasia || ''}
                                disabled={false}
                                onChange={(e) => {setCadastroData({...cadastroData, nome_fantasia: e.target.value})}}
                            />
                        </Box>
                    </Box>
                </Paper>
                {tabIndex === 0 && 
                <Paper {...propsPaper} >
                    <Box sx={{display: "flex"}}>
                        <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                                <TsTextField
                                    name="razao_social"
                                    label="Razão Social" 
                                    fullWidth
                                    value={cadastroData.razao_social || ''} 
                                    inputProps={{ maxLength: 50 }}
                                    disabled={false}
                                    onChange={(e) => {setCadastroData({...cadastroData, razao_social: e.target.value})}}
                                />
                        </Box>
                        <Box sx={{flex: "0 0 auto", width: '250px', paddingRight: 2}}>
                            <TsTextField
                                    name="ie"
                                    label="Inscrição estadual"
                                    fullWidth
                                    inputProps={{ maxLength: 24 }}
                                    value={cadastroData.ie || ''}
                                    disabled={false}
                                    onChange={(e) => {setCadastroData({...cadastroData, ie: e.target.value})}}
                                />
                        </Box>
                        <Box sx={{flex: "0 0 auto", width: '250px', paddingRight: 2}}>
                            <TsTextField
                                    name="nomeFranqueadora"
                                    label="Franqueadora"
                                    fullWidth
                                    inputProps={{ maxLength: 24 }}
                                    value={cadastroData.nomeFranqueadora || ''}
                                    disabled={false}
                                    onChange={(e) => {setCadastroData({...cadastroData, nomeFranqueadora: e.target.value})}}
                                />
                        </Box> 
                    </Box>
                    <Box sx={{display: "flex"}}>
                        <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                            <TsTextField
                                name="logradouro"
                                label="Endereco"
                                fullWidth
                                inputProps={{ maxLength: 150 }}
                                value={cadastroData.endereco || ''}
                                disabled={false}
                                onChange={(e) => {setCadastroData({...cadastroData, endereco: e.target.value})}}
                            />
                        </Box>
                        <Box sx={{flex: "0 0 auto", width: '120px', paddingRight: 2}}>
                        <TsTextField
                                name="numero"
                                label="Número"
                                fullWidth
                                inputProps={{ maxLength: 16 }}
                                value={cadastroData.numero || ''}
                                disabled={false}
                                onChange={(e) => {setCadastroData({...cadastroData, numero: e.target.value})}}
                            />
                        </Box>
                        <Box sx={{flex: "0 0 auto", width: '180px', paddingRight: 2}}>
                        <TsTextField
                                name="complemento"
                                label="Complemento"
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                value={cadastroData.complemento || ''}
                                disabled={false}
                                onChange={(e) => {setCadastroData({...cadastroData, complemento: e.target.value})}}
                            />
                        </Box>                    
                    </Box>
                    <Box sx={{display: "flex"}}>
                        <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                            <TsTextField
                                name="bairro"
                                label="Bairro"
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                value={cadastroData.bairro || ''}
                                disabled={false}
                                onChange={(e) => {setCadastroData({...cadastroData, bairro: e.target.value})}}
                            />
                        </Box>
                        <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                        <TsTextField
                                name="cidade"
                                label="Cidade"
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                value={cadastroData.cidade || ''}
                                disabled={false}
                                onChange={(e) => {setCadastroData({...cadastroData, cidade: e.target.value})}}
                            />
                        </Box>
                        <Box sx={{flex: "0 0 auto", width: '120px', paddingRight: 2}}>
                        <TsTextField
                                name="Estado"
                                label="UF"
                                fullWidth
                                inputProps={{ maxLength: 2 }}
                                value={cadastroData.uf || ''}
                                disabled={false}
                                onChange={(e) => {setCadastroData({...cadastroData, uf: e.target.value})}}
                            />
                        </Box>
                        <Box sx={{flex: "0 0 auto", width: '180px', paddingRight: 2}}>
                            <TsTextMask
                                name="CEP"
                                label="CEP"
                                fullWidth
                                mask="00000-000"
                                inputProps={{ maxLength: 9 }}
                                value={cadastroData.cep || ''}
                                disabled={false}
                                onChangeValue={(v) => {setCadastroData({...cadastroData, cep: v})}}
                                onBlur={() => {
                                    processCep();
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{display: "flex"}}>
                        <Box sx={{flex: "1 1 auto", paddingRight: 2, minWidth: '200px', paddingTop: 1}}>
                            <Box>
                            <Button
                                component="label"
                                color={contratoFile.sucesso ? 'primary' : 'error'}
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                            >
                                Contrato social
                                <VisuallyHiddenInput
                                    type="file"
                                    id='contrato-file-input'
                                    name='contrato-file-input'
                                    accept='.doc,.docx,.pdf,.png,.jpg,.jpeg'
                                    onChange={(event) => contratoUpload(event)} 
                                />
                            </Button>
                            </Box>
                            <Box sx={{paddingTop: 1}}>
                                <Typography sx={{fontSize: '11pt', color: '#777'}}>{contratoFile.mensagem || ''}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{flex: "1 1 auto", paddingRight: 2, minWidth: '200px', paddingTop: 2}}> 
                            {cadastroData.contratoFile && 
                            <Link href={cadastroData.contratoFile + ''} target='_blank'  >download contrato</Link>  }
                        </Box>
                        <Box sx={{flex: "1 1 auto", minWidth: '200px', paddingRight: 2}}>
                            <ChaveBancaria
                                name="chaveBancaria"
                                disabled={false}
                                userLevel={3}
                                value={cadastroData.chaveBancaria || ''}
                                onChange={(valor) => {setCadastroData({...cadastroData, chaveBancaria: valor})}}
                            />
                        </Box>
                    </Box>
                    {cadastroData.pendencia &&  
                    <Box>
                        <strong>Pendências</strong>: <>{cadastroData.pendencia}</>
                    </Box>
                    }
                </Paper>}
                {tabIndex === 1 && 
                <Paper {...propsPaper} >
                    <Box sx={{padding: 2}}>
                        <Typography sx={mainTitleCSS}>Lista de signatários, inclua Sócios</Typography>
                    </Box>
                    <CadastroSigner 
                        open={true} 
                        setError={setError} 
                        signers={signers} 
                        setSigners={setSigners} 
                    />
                </Paper>}
                {tabIndex === 2 && 
                <Paper {...propsPaper} >
                    <Box sx={{padding: 2}}>
                        <Typography sx={mainTitleCSS}>Lista de localidades, lojas e estabelecimentos</Typography>
                    </Box>
                    <CadastroSites setProcessando={setProcessando} rows={sites} setRows={setSites} 
                        cadastroData={{
                            cnpj: cadastroData.cnpj, 
                            endereco: cadastroData.endereco, 
                            bairro: cadastroData.bairro,
                            numero: cadastroData.numero,
                            complemento: cadastroData.complemento,
                            cep: cadastroData.cep,    
                            cidade: cadastroData.cidade, 
                            uf: cadastroData.uf,
                        }} 
                    />
                </Paper>
                }
            
        </>
        }
       <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={executing} />
        </PagePublic>)
}

export default Cadastro;