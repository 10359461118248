import * as React from 'react';
import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

// type FilterChipsProps = {
//     onChange: (lista: string[]) => void,
//     items: string[]
// };

const TsFilterChips = (props) => {

    const [chipData, SetChipData] = React.useState(props.items);

    const handleDelete = (index) => {
        const temp = [...chipData];
        temp.splice(index, 1);
        SetChipData(temp);
        props.onChange(temp);
    };

    React.useEffect(() => {

        SetChipData(props.items);

    }, [props.items])

    return (
        <Paper
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 1,
            }}
            component="ul"
            elevation={0}
        >
            {chipData.map((label, index) => {
                return (
                    <ListItem key={index}>
                        <Chip
                            label={label}
                            onDelete={(e) => handleDelete(index)}
                        />
                    </ListItem>
                );
            })}
        </Paper>
    );
}

export default TsFilterChips;
