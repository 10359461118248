// @flow 
import { Box } from '@mui/material';
import * as React from 'react';
import { Page } from '../Page';
import Table from './Table';

const CampanhaListar = () => {
    return (
        <Page title="Listar campanhas - limite 200 registros">
            <Box>
                <Table showAdd={true} showBusca={true}></Table>
            </Box>
        </Page>
    );
};

export default CampanhaListar;