import { Box} from '@mui/material';
import * as React from 'react';
import { Page } from '../Page';
import BackLogList from './backlog-list';
import BackLogDetail from './backlog-detail';

const BackLogPage = () => {
    const [detail, setDetail] = React.useState({open: false, id: 0, pageTitle: "Lista de backlogs"}); 
    
    return (
        <Page title={detail.pageTitle}>
            <Box>
                {detail.open ? 
                <BackLogDetail setDetail={setDetail}  detail={detail}></BackLogDetail> : 
                <BackLogList setDetail={setDetail} detail={detail}></BackLogList>}
            </Box>
        </Page>
    );
};

export default BackLogPage;
