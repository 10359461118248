/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
  Box,
  Table,
  TableBody,
} from '@mui/material'; 
import {monitorCSS} from '../style';
import Header from './header';
import DataRow from './data-row';
import Pagging from './pagging';

let DataGrid_processando = false; 

const DataGrid = (props, ref) => {
 

  const {query, endPoint, onResult} = props;


  const decodeColumns = (pCols) => {
    if (pCols === undefined || pCols.length === 0) return [];

    const cols = [];

    if (props.columns && props.columns.length > 0) {
      
      props.columns.forEach(col => {
        if (col.show === undefined || col.show === true)  {
          cols.push(col);
        }
      });
      
    } 
    return cols;
  }

  const decodeRows = (pRows) => {
    const arr = [];
    if (!columns || columns.length === 0)
      return arr;

    if (pRows && pRows.length > 0) {
        
        pRows.forEach((row, ix) => {
          const data = {};
          data.index = ix;
          columns.forEach(col => {
            const name = col.name;
            if (name in row) {
              const value = row[name]; 
              if (typeof(value) === 'string' &&  value.indexOf("_") === 0) {
                data[name] = value.replace("_", '');
              }
              else {
                data[name] = value;
              }
            }
            else {
              console.log(`tsdatagrid - chave nao encontrada ${name}`);
            }
          });
          
          arr.push(data);
        });
    }
    return arr;
  }

  const [componentEnable, setComponentEnable] = React.useState(false);
  const [queryId, setQueryId] = React.useState('');
  const serverPagesize = query?.serverPagesize ? query.serverPagesize : 200;
  const showPagging = props.showPagging === undefined ? true: props.showPagging;
  const initialPageSize = props.pageSize === undefined ? 10: props.pageSize;
  const fontSize = props.fontSize === undefined ? '12': props.fontSize;

  // locais
  const [serverPage, setServerPage] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(showPagging ? initialPageSize: serverPagesize);
  const [nrecs, setNrecs] = React.useState(0);

  const offset = serverPage * serverPagesize;  
  const from = offset + 1 + page * pageSize;
  const to = offset + pageSize * (page + 1);

  const onChangePage = (value) => {
    let newPage = value;
    if (newPage < 0) {
      if (serverPage > 0)
        setServerPage(serverPage - 1);
      newPage = 0;
    }
    else {

      if (newPage * pageSize >= nrecs) {
     
        if (nrecs < serverPagesize) {
          newPage = 0;
          setServerPage(0);
        }
        else  {
          newPage = serverPage + 1;
        }
      }

    }
    setPage(newPage);
  };

  const onChangeRowsPerPage = (value) => {
    const ps = Number(value, 10);
    const pg = 0;
    setServerPage(0);
    setPageSize(ps);
    setPage(pg);
  };
 
  const Buscar = async () => {

    if (endPoint === undefined || typeof(query) !== 'object') {
      return;
    }

    if (DataGrid_processando)
      return;

    DataGrid_processando = true;
    onResult({processando: true});
    setPage(0);
    try {
      
      // endPoint={{instancia: produtoHttp, metodo: 'busca'}}
      // endPoint={produtoHttp}
      const {status, data} = endPoint.instancia !== undefined ?
         await endPoint.instancia[endPoint.metodo](query) :
         await endPoint.busca(query);

      if (status !== 200) {
        console.error(`Datagrid buscar, http-status: ${status} , data:`, data);
        onResult({sucesso: false, mensagem: data?.mensagem || `http-status ${status}, falha ao processar request`});
      }
      else {
        let arr = [];
        const result = {sucesso: true, mensagem: 'ok'};
        if (Array.isArray(data)) {
          arr = data;
          result.dados = arr;
        } 
        else if (Array.isArray(data.dados)) {
          result.sucesso = data.sucesso;
          result.mensagem = data.mensagem;
          result.extendData = data.extendData;
          result.dados = data.dados;
        }
        else {
          result.sucesso = false;
          result.mensagem = 'retorno desconhecido, veja console';
          console.log(data);
        }
        onResult(result); 
      }

    } catch (error) {
      onResult({sucesso: false, mensagem: error.mensagem || 'EXCEPTION: ' + error.message});
      if (!error.mensagem)
        console.error('datagrid error:', error);
    }
    DataGrid_processando = false;

  }

  React.useEffect(() => {
    if (!componentEnable) {
      setComponentEnable(true);
    }
    else {

      if (endPoint === undefined || typeof(query) !== 'object') {
        return;
      }
      else {
        const q = JSON.stringify(query);
        if (q !== queryId) {
          setQueryId(q);
          Buscar();
        } 
      }
    }
  }, [query, componentEnable]);

  React.useEffect(() => {
    if (nrecs === 0 && props.rows?.length > 0) {
      setNrecs(props.rows.length);
      setPage(0);
    }
  }, [props.rows]);

  const columns = decodeColumns(props.columns);
  const rows = decodeRows(props.rows);

  return (<>
    <Table className='datagrid-table'>
      <Header columns={columns} />
      <TableBody>
        {rows.slice(page * pageSize, page * pageSize + pageSize).map((row, rowIndex) => (
              <DataRow fontSize={fontSize} key={`row-${rowIndex}`} columns={columns} row={row} rowIndex={rowIndex} />
        ))}
      </TableBody>
      { showPagging === true && 
        <Pagging 
          serverPage={serverPage} 
          serverPagesize={serverPagesize} 
          nrecs={nrecs} 
          pageSize={pageSize} 
          onChangeRowsPerPage={(value) => onChangeRowsPerPage(value)} 
          onChangePage={(value) => onChangePage(value)} 
          colSpan={columns.length || 99}
          fontSize={fontSize}
          from={from} 
          to={to} 
          page={page}
        />
      }
    </Table>
    {/* query && <Box sx={{...monitorCSS, marginTop: 4}}>pagging: <br /> {JSON.stringify({serverPage, serverPagesize, offset, nrecs, pageSize, from, to, page}, null, 2)}</Box> */}
    
  </>);

};
 
export default DataGrid;

 
