import * as React from 'react';
import {Box} from "@mui/material";
import { getUserQrCode, setUserQrCode } from '../../util/auth-qrcode';
import Login from './login';
import Home from './home';

const QrcodePage = (props) => {
    const [atual, setAtual] = React.useState(getUserQrCode());

    const setUser = (user) => {
        setUserQrCode(user);
        setAtual(user);
    }

    return (
    <Box sx={{display: 'flex', backgroundColor: '#EEE', marginTop: '-80px', paddingBottom: 8}}>  
        <Box sx={{flex: '1 1 auto'}}></Box>  
        <Box sx={{margin: 2, padding: 4, border: '1px solid #CCC', minHeight: '600px', width: '400px', backgroundColor: '#FFF'}}>
            {!atual.token 
            ? <Login setUser={setUser} /> 
            : <Home atual={atual} setUser={setUser} />
            }
        </Box>
        <Box sx={{flex: '1 1 auto'}}></Box>
    </Box>
    )

   
}

export default QrcodePage;
