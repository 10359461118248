/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import campanhaHttp from '../../util/http/campanha-http';
import { validarCampanha, CampanhaCampos, CampanhaDefault } from '../../dominios/Campanha';
import { HOMEDIR } from '../../util/auth';
import { toDateJson } from '../../util/Util';
import TextForm from '../../formulario/TextForm';
import CheckForm from '../../formulario/CheckForm';
import TsButton from '../../formulario/TsButton';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert'; 
import SelectCampo from '../../formulario/SelectCampo';

const listaFiltro = [
    { text: '...Selecione', value: '' },
    { text: 'Não se aplica', value: 'NA' },
    { text: 'Mercado', value: 'MERCADO' },
    { text: 'Perfumaria', value: 'PERFUMARIA' },
    { text: 'Pet-shop', value: 'PET-SHOP' },
    { text: 'Drogaria', value: 'DROGARIA' }, 
];

export const Form = (props) => {

    const [form, setForm] = useState(CampanhaDefault);
    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [validating, setValidating] = useState(false);
    const [error, setError] = useState(undefined);
    const [url, setUrl] = useState('');

    useEffect(() => {

        obterCampanha();

    }, [id]);

    const obterCampanha = () => {
        if (!id) {
            return;
        }
        setLoading(true);
        campanhaHttp
            .get(id)
            .then(({ data }) => {
                data.dataInicio = (data.dataInicio).substr(0, 10);
                data.dataFinal = (data.dataFinal).substr(0, 10);
                const c = data;
                localStorage.setItem("campanha", JSON.stringify({ campanhaChaveId: c.campanhaChaveId, campanhaNome: c.campanhaNome }));

                updateData(c);

            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const updateData = (data) => {
        setForm(data);
    }

    const onSubmit = async (event) =>  {
        // event.preventDefault();

        setValidating(true);
        const campanhaChaveId = id ? parseInt(id) : 0;

        let versaoId = "";

        form.versaoId = versaoId;

        form.ativo = (form.ativo + '') === "false" ? false : true;
        form.flgClassificacao = (form.flgClassificacao + '') === "true" ? true : false;

        form.dataInicio = (toDateJson(form.dataInicio)).substr(0, 10);
        form.dataFinal = (toDateJson(form.dataFinal)).substr(0, 10);

        const erroMensagem = validarCampanha(form);

        if (erroMensagem) {
            ShowAlert.error(erroMensagem);
            return false;
        }

        if (!form.filtro) {
            ShowAlert.error('Selecione um tipo de filtro');
            return false;
        }

        setValidating(false);

        setLoading(true);

        try {

            let response = undefined;

            if (campanhaChaveId === 0) {
                response = await campanhaHttp.create(form);
            }
            else {
                

                response = await campanhaHttp.update(campanhaChaveId, {...form});
            }

            if (response.status === 200) {
                ShowAlert.info('Campanha salva com sucesso!');

                setTimeout(() => {
                    (
                        id
                            ? obterCampanha()
                            : navigate(`${HOMEDIR}/campanha/${response.data.campanhaChaveId}}/editar`)
                    );
                }, 100);

            }
            else  {
                console.log('salvar campanha error:', response.data);
                ShowAlert.error('Falhou a salvar campanha!');
            }


            
        } catch (error) {
            console.log('salvar campanha exception:', error);
            setUrl(campanhaHttp.getUrl());
            setError(error);
        }

        setLoading(false);

    }

    return (
        <React.Fragment>
            
            <form>
                <TextForm
                    campo={CampanhaCampos.projetoId}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}
                    disabled={true}
                />

                <TextForm
                    campo={CampanhaCampos.campanhaNome}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}
                />

                <TextForm
                    campo={CampanhaCampos.dataInicio}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}
                />

                <TextForm
                    campo={CampanhaCampos.dataFinal}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}
                />

                <TextForm
                    campo={CampanhaCampos.msgCupom}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}
                />

                <CheckForm
                    campo={CampanhaCampos.ativo}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                />

                <CheckForm
                    campo={CampanhaCampos.flgClassificacao}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                />

                <TextForm
                    campo={CampanhaCampos.uf}
                    formData={form}
                    setFormData={(value) => setForm(value)}
                    validating={validating}
                />

                <SelectCampo
                    id="camp-filtro"
                    label="Filtro"
                    name="filtro"
                    options={listaFiltro}
                    value={form.filtro || ''}
                    onChange={(e) => setForm({ ...form,  filtro: e.target.value})}
                    />


                <Box dir="rtl">
                    <TsButton
                        disabled={loading}
                        onClick={(e) => onSubmit(e)}
                    >Salvar campanha [{form.campanhaChaveId}]</TsButton>
                    <TsButton
                        onClick={(e) => navigate(`${HOMEDIR}/campanha`)}
                        color='secondary'
                    >Voltar</TsButton>

                </Box>
            </form>
            <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" /> 
            <TsBackDrop processando={loading} />
        </React.Fragment>
    );

};