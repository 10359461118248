import React from "react";
import {Box} from '@mui/material';
import { Chart } from "react-google-charts";
import TsBigNumber from '../../formulario/TsBigNumber'
import ExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PeopleIcon from '@mui/icons-material/PeopleAlt';
import { bgBlue, optionsPie, tituloBarra } from "./dash-style";
import { monitorCSS } from "../../style";

// resumo transacao clientes.
const DashResumo = (props) => {

    const {resumoData, executing} = props;

    // return (<Box sx={monitorCSS} >{JSON.stringify(resumoData, null, 2)}</Box>);
 
    
    return (
        <Box>
            {resumoData && <>
            <Box sx={tituloBarra}><ExchangeIcon /> Transações </Box>
            <Box className='dash-flex-container'>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='Vendas no Período'>{resumoData.qtdeVendas}</TsBigNumber></Box>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='Valor Líquido Vendas'>{resumoData.valorTotalVendas}</TsBigNumber></Box>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='Desconto Total'>{resumoData.descontoTotal}</TsBigNumber></Box>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='Quantidade de Itens'>{resumoData.qtdeItens}</TsBigNumber></Box>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='Ticket Médio'>{resumoData.ticketMedio}</TsBigNumber></Box>
            </Box>
            <Box sx={tituloBarra}><PeopleIcon /> Clientes</Box>
            <Box className='dash-flex-container'>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='Base Clientes Dclube'>{resumoData.nTotalClientes}</TsBigNumber></Box>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='Clientes com compra'>{resumoData.nClienteCompra}</TsBigNumber></Box>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='% Conversão'>{resumoData.percentClienteConversao}</TsBigNumber></Box>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='Ticket Médio por Cliente'>{resumoData.ticketMedioCliente}</TsBigNumber></Box>
                <Box className='dash-flex-item-5 mh-2'><TsBigNumber sx={{borderRadius: '8px'}} bgcolor='blue' labelPos='top' label='Desconto Médio por Cliente'>{resumoData.descontoMedioCliente}</TsBigNumber></Box>
            </Box>
            <Box sx={tituloBarra}>Clientes por Faixa de Ticket Médio</Box>
            <Box sx={{...bgBlue, margin: 1, padding: 2}}>
                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="400px"
                    data={resumoData.faixaTicketMedio}
                    options={optionsPie}
                    legendToggle
                    />
            </Box>
            </>}
            {resumoData === undefined 
            ? <Box sx={{minHeight: '300px'}}>...Processando</Box> 
            : <>{props.login === 'egoncalez' && <Box sx={{...monitorCSS, marginTop: 2}}>{JSON.stringify(resumoData, null, 2)}</Box>}</>}
        </Box>
    );
    

}

export default DashResumo;