import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';
const querystring = require('querystring');

class ContratoHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
  }

  buscaProspects(filtro) {
    this.url = `/${this.resource}/prospect/busca - POST`;
    return this.http.post(`${this.resource}/prospect/busca`, filtro, this.Config());
  }

  getProspect(id) {
    this.url = `/${this.resource}/prospect/${id} - GET`;
    return this.http.get(`${this.resource}/prospect/${id}`, this.Config());
  }

  prospectUpdate(id, data) {
    this.url = `/${this.resource}/prospect/${id} - PUT`;
    return this.http.put(`${this.resource}/prospect/${id}`, data, this.Config());
  }

  getHist(contratoId) {
    this.url = `/${this.resource}/${contratoId} - GET`;
    return this.http.get(`${this.resource}/${contratoId}/hist`, this.Config());
  }

  getSigner(contratoId) {
    this.url = `/${this.resource}/${contratoId} - GET`;
    return this.http.get(`${this.resource}/${contratoId}/signer`, this.Config());
  }

  getCadastro(id, busca) {
    if (id === 0) {
      this.url = `/${this.resource}/cadastro/busca - post`;
      return this.http.post(`${this.resource}/cadastro/busca`, { busca }, this.Config());
    } else {
      this.url = `/${this.resource}/cadastro/${id} - get`;
      return this.http.get(`${this.resource}/cadastro/${id}`, this.Config());
    }
  }

  atualizarCad(signer, cadastro) {
    const data = { signer: signer, cadastro: cadastro };
    this.url = `/${this.resource}/cadastro - post`;
    return this.http.post(`${this.resource}/cadastro`, data, this.Config());
  }

  enviarProspect(data) {
    this.url = `/${this.resource}/prospect - post`;
    return this.http.post(`${this.resource}/prospect`, data, this.Config());
  }

  continueProspect(cnpj, token) {
    this.url = `/${this.resource}/prospect/continue - post`;
    const data = {
      cnpj: cnpj,
      token: token,
    };

    return this.http.post(`${this.resource}/prospect/continue`, data, this.Config());
  }

  enviarArquivo(pasta, arquivo) {
    this.url = `/upload/{pasta} - post`;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-projeto': '5000',
      },
    };
    const formData = new FormData();
    formData.append('arquivo', arquivo);

    return this.http.post(`upload/${pasta}`, formData, config);
  }

  refresh(id, dados) {
    this.url = `/${this.resource}/${id}/refresh - PUT`;
    return this.http.put(`${this.resource}/${id}/refresh`, dados, this.Config());
  }

  syncRede(id, dados) {
    this.url = `/${this.resource}/${id}/syncrede - PUT`;
    return this.http.put(`${this.resource}/${id}/syncrede`, dados, this.Config());
  }
}

const contratoHttp = new ContratoHttpResource(httpTsApi, 'contrato', baseUrl);

export default contratoHttp;
