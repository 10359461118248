import { responseException, responseHttp } from './util-http';
import axios from 'axios';
import { qrcodeUrl } from './index';

class HttpQrcode {
  // protected resource: string
  constructor() {
    this.url = '';
    this.baseURL = qrcodeUrl || 'https://proxy.trackingsales.com.br/ts4qrcode';
  }

  httpQrcodeApi = () => {
    return axios.create({
      baseURL: this.baseURL,
    });
  };

  async entrar(login, senha) {
    try {
      this.url = `${this.baseURL}/entrar - POST`;
      const ret = await this.httpQrcodeApi().post(`entrar`, { login, senha }, undefined);

      return responseHttp('entrar', ret, {});
    } catch (error) {
      return responseException('entrar', error, 'Falha interna - entrar', {});
    }
  }

  async obterImg(token, url) {
    try {
      const config = {
        headers: {
          'x-token': token,
        },
      };

      const ret = await this.httpQrcodeApi().get(`image/${url}`, config);
      return { status: ret.status, data: ret.data };
    } catch (error) {
      return { status: 500, data: `EXCEPTION: ${error.message}` };
    }
  }

  async listaCupom(token, cpf, flagImg, status, page) {
    try {
      const config = {
        headers: {
          'x-token': token,
        },
      };

      if (status === undefined) status = '1';
      if (cpf === '' || cpf === undefined) cpf = '0';

      this.url = `${this.baseURL}/cupom/${cpf}?status=${status}&flagImg=${flagImg}&page=${page}&pageSize=50 - GET`;
      const ret = await this.httpQrcodeApi().get(
        `cupom/${cpf}?status=${status}&flagImg=${flagImg}&page=${page}&pageSize=50`,
        config
      );

      return responseHttp('listaCupom', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('listaCupom', error, 'Falha interna - listaCupom', { token: token });
    }
  }

  async atualizarCupom(token, id, cpf, idStatus, mensagem, chaveAcesso, sefazUrl) {
    try {
      const config = {
        headers: {
          'x-token': token,
        },
      };

      this.url = `${this.baseURL}/cupom/${cpf}/${id} - PUT`;
      const ret = await this.httpQrcodeApi().put(`cupom/${cpf}/${id}`, { idStatus, mensagem, chaveAcesso, sefazUrl }, config);

      return responseHttp('atualizarCupom', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('atualizarCupom', error, 'Falha interna - obterCupom', { token: token });
    }
  }

  async obterCupom(token, id, cpf) {
    try {
      const config = {
        headers: {
          'x-token': token,
        },
      };

      this.url = `${this.baseURL}/cupom/${id}?adm=true - GET`;
      const ret = await this.httpQrcodeApi().get(`cupom/${cpf}/${id}?adm=true`, config);

      return responseHttp('obterCupom', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('obterCupom', error, 'Falha interna - obterCupom', { token: token });
    }
  }

  async sendEanStatus(token, statusId, ean, itemId, cpf) {
    try {
      const config = {
        headers: {
          'x-token': token,
        },
      };

      const data = { usuarioStatusId: statusId, ean: ean };

      this.url = `${this.baseURL}/cupom/${cpf}/item/${itemId}/update-user-status - POST`;
      const ret = await this.httpQrcodeApi().post(`cupom/${cpf}/item/${itemId}/update-user-status`, data, config);

      return responseHttp('sendEanStatus', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('sendEanStatus', error, 'Falha interna - sendEanStatus', { token: token });
    }
  }

  async sendQrcode(token, data) {
    try {
      const config = {
        headers: {
          'x-token': token,
        },
      };

      this.url = `${this.baseURL}/send - POST`;
      const ret = await this.httpQrcodeApi().post(`send`, data, config);

      return responseHttp('send', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('send', error, 'Falha interna - cupom', { token: token });
    }
  }

  async processarCupom(token, data) {
    try {
      const config = {
        headers: {
          'x-token': token,
        },
      };

      this.url = `${this.baseURL}/callback - POST`;
      const ret = await this.httpQrcodeApi().post(`callback`, data, config);

      return responseHttp('callback', ret, { token: this.proxy_token });
    } catch (error) {
      return responseException('callback', error, 'Falha interna - callback', { token: token });
    }
  }

  getUrl() {
    return this.url;
  }
}

const httpQrcode = new HttpQrcode();

export default httpQrcode;
