import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ProdutoDescontoEditar from '../produto/produto-desconto-editar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EventNoteIcon from '@mui/icons-material/EventNote';

/* http */
import produtoHttp from '../../util/http/produto-http';
import campanhaHttp from '../../util/http/campanha-http';
import gestaodescontoHttp from '../../util/http/gestaodesconto-http';

/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsInputBusca from '../../formulario/TsInputBusca';
import TsBackDrop from '../../formulario/TsBackDrop';
import { HOMEDIR } from '../../util/auth';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';
import ShowAlert from '../../util/ShowAlert';

// type PropsProdutos = {
//     ofertaId?: number,
//     campanhaId: number,
//     enableAdicionar: boolean,
//     enableEditar: boolean,
//     refresh: boolean
// };

class TableProdutoCampanha extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;

        this.state.openEdit = false;
        this.state.campanhaId = "";
        this.state.ofertaId = "";
        this.state.produtoDescontoId = "";
        this.state.refresh = Math.random();
        this.state.buscaAtual = { campanha: -1, oferta: -1 };
    
        this.columns = [
            {
                name: "campanhaId",
                label: "campanha"
            },
            {
                name: "produtoCodigo",
                label: "",
                format: ((value) => (<Link component="button" variant='inherit' onClick={(e) => this.editarProduto(value)}><EventNoteIcon /></Link>))
            },
            {
                name: "produtoCodigo",
                label: "EAN"
            },
            {
                name: "produtoNome",
                label: "PRODUTO",
                format: (value) => { return value && value.length > 30 ? value.substr(0, 26) + ' ...' : value}
            },
            {
                name: "descontoTxt",
                label: "DESCONTO"
            },
            {
                name: "descontoZicard",
                label: "Dclube"
            },
            {
                name: "codigoKit",
                label: "KIT"
            },
            {
                name: "ponto",
                label: "PONTO"
            },
            {
                name: "ofertaId",
                label: "OFERTA",
                format: ((value) => (<Link component={RouterLink} variant='inherit' to={`${HOMEDIR}/campanha/${props.campanhaId}/editar/oferta/${value}/editar`}>{value}</Link>))
            },
            {
                label: "x", name: "id",
                format: (value) => { return (<Link component="button" onClick={(e) => this.deleteItem(value)}><DeleteForeverIcon /></Link>) }
            }
        ];
    }

    setOpenEdit (openEdit) {
        this.setState({openEdit});
    }
    
    setCampanhaId (campanhaId) {
        this.setState({campanhaId});
    }
    
    setOfertaId (ofertaId) {
        this.setState({ofertaId});
    }
    
    setProdutoDescontoId (produtoDescontoId) {
        this.setState({produtoDescontoId});
    }
    
    setRefresh (refresh) {
        this.setState({refresh});
    }
 
    async Buscar () {
        

        const oferta = !this.props.ofertaId ? 0 : this.props.ofertaId;
        const campanha = !this.props.campanhaId ? 0 : this.props.campanhaId;

        if (campanha === 0)
            return;

        this.iniciarBusca();
        this.setOpenEdit(false);

        produtoHttp
            .produtoCampanha(campanha, oferta ? oferta : undefined)
            .then(({ data }) => {

                if (!data.sucesso || data.sucesso === false) {
                    this.setError(data);
                    return;
                }
                else {
                    if (data.dados === null || data.dados === undefined)
                        data.dados = [];

                    const lista = data.dados.map(it => {
                        if (it.cashback) {
                            it.descontoTxt = `CASH ${(it.cashback * 100).toFixed(0)} %`;
                        }
                        else if (it.descontoProgressivo) {
                            it.descontoTxt = "PROG";
                        }
                        else {
                            it.descontoTxt = it.desconto.toFixed(2);
                        }
                        
                        if (!it.descontoZicard || it.descontoZicard === null) it.descontoZicard = 0;
                        
                        it.descontoZicard = it.descontoZicard.toFixed(2);

                        return it;
                    });

                    this.setRows(lista);

                }
            })
            .catch((error) => {
                this.setError(error);

            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(produtoHttp.getUrl());
            });
    }

    deleteItem (Id) {
        this.setProcessando(true);
        const item = this.state.rows.find((el) => el.id === Number(Id));
        if (!item || !item.id || !item.campanhaId || !item.ofertaId) {
            ShowAlert.error("Falha ao obter dados do item");
            return;
        }
        
        gestaodescontoHttp.deleteProduto(item.id, item.campanhaId, item.ofertaId)
            .then((retorno) => {
                ShowAlert.error("Desconto deletado com sucesso");
                this.Buscar();
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setUrl(campanhaHttp.getUrl());
            });
    }

    editarProduto (codigo) {

        if (!codigo) {
            return;
        }
        const item = this.state.rows.find(pr => pr.produtoCodigo === codigo);

        this.setRefresh(Math.random());
        this.setCampanhaId(item.campanhaId + '');
        this.setOfertaId(item.ofertaId + '');
        this.setProdutoDescontoId(item.id + '');
        this.setOpenEdit(true);

    }

    handleAddProd () {

        this.setRefresh(Math.random());
        this.setCampanhaId(this.props.campanhaId + '');
        this.setOfertaId(this.props.ofertaId + '');
        this.setProdutoDescontoId('');
        this.setOpenEdit(true);
 
     }

     componentDidMount() {
        super.componentDidMount();
      }
    
      componentWillUnmount() {
          super.componentWillUnmount();
          
      }
    
      componentDidUpdate(prevProps, prevState) {
    
          super.componentDidUpdate(prevProps, prevState);
    
          if (this.state.execBuscar === true) { 
            this.Buscar();
            return; 
          }

          if (this.props.ofertaId !== prevProps.ofertaId || this.props.campanhaId !== prevProps.campanhaId) {
            this.setServerPage(0);
            this.setExecBuscar(true);
          }  
      }

    render() {
        return (
            <div>
                <TsInputBusca
                    name="input-busca"
                    value=''
                    showAtivo={false}
                    showBusca={false}
                    showAdd={true}
                    onClickAdd={(e) => this.handleAddProd()}
                ></TsInputBusca>
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                />
                {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
                <TsBackDrop processando={this.state.processando} />
               <ProdutoDescontoEditar
                    open={this.state.openEdit}
                    onClose={() => this.Buscar()}
                    campanhaId={this.state.campanhaId}
                    ofertaId={this.state.ofertaId}
                    default={{ final: '', inicio: '', rede: '', uf: '' }}
                    produtoDescontoId={this.state.produtoDescontoId}
                    refresh={this.state.refresh}
                />
            </div>
        );
    }

}

export default TableProdutoCampanha;

 

