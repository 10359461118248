import { httpAutV4Api, autV4ApiKey } from '.';

import axios from 'axios';
import { responseAutorizador, responseException, responseHttp } from './util-http';
import { getProxy } from '../auth';

export class HttpAutorizador {
  constructor() {
    this.resource = '';
    this.http = httpAutV4Api;
    this.apiKey = autV4ApiKey || 'QDrWeBz8vA36KDKjydce9aMtz26xTo7n9R3YulvB';
  }

  httpProxyApi = () => {
    const proxy = getProxy();
    return axios.create({
      baseURL: proxy.url,
    });
  };

  async get_request(token, requestName, url) {
    const request = { token: token, url: url };

    try {
      const config = { headers: { token, 'x-api-key': this.apiKey } };

      const ret = await this.httpProxyApi().get(request.url, config);

      return responseAutorizador(requestName, ret, request);
    } catch (error) {
      return responseException(requestName, error, `Falha Interna - ${requestName}`, request);
    }
  }

  async post_request(token, requestName, url, body) {
    const request = { body: body, token: token, url: url };

    try {
      const config = token ? { headers: { token, 'x-api-key': this.apiKey } } : { headers: { 'x-api-key': this.apiKey } };

      const ret = await this.httpProxyApi().post(request.url, request.body, config);

      if (requestName === 'entrar') {
        if (ret.status === 200) {
          return { sucesso: true, codigoResposta: '00', data: ret.data };
        } else {
          return { sucesso: false, codigoResposta: '41', data: undefined };
        }
      } else {
        return responseAutorizador(requestName, ret, request);
      }
    } catch (error) {
      return responseException(requestName, error, `Falha Interna - ${requestName}`, request);
    }
  }

  async entrarAsync(data) {
    const request = { body: data, token: '', url: 'entrar' };

    try {
      const config = { headers: { 'x-api-key': this.apiKey } };
      const ret = await this.httpProxyApi().post(request.url, request.body, config);
      return responseHttp('entrar', ret, request);

      // return responseHttp("entrar", ret);
    } catch (error) {
      return responseException('entrar', error, 'Falha Interna - entrar', request);
    }
  }

  // consultar_identificacao

  // CADASTRO DERMACLUB
  async obterTokenCadastro() {
    const HTTP = axios.create({
      baseURL: 'https://configuracaohml.trackingsales.com.br',
    });

    const query = {
      login: 'integracoes-loreal',
      senha: 'zut99jokry3pj9P+GSK70ypr3ByMr2oLJSuBJXqaHsSRjIgPH8tx895JjF9ac31T',
    };

    const request = { body: query, token: '', url: `/api/login/entrar` };

    try {
      const ret = await HTTP.post(request.url, request.body);
      return responseHttp('obterTokenCadastro', ret, request);
      // return response.data.result;
    } catch (error) {
      return responseException('obterTokenCadastro', error, 'Falha Interna - obterTokenCadastro', request);
    }

    /*
      
        obtenção do token:
        https://configuracaohml.trackingsales.com.br/api/login/entrar
        {"login" :"integracoes-loreal", "senha" :"zut99jokry3pj9P+GSK70ypr3ByMr2oLJSuBJXqaHsSRjIgPH8tx895JjF9ac31T"}

 
        */
  }

  // consultar_beneficios_extra
  async consultarBeneficiosExtra(token, cnpj) {
    const nsu = new Date().getTime();

    cnpj = cnpj + '';

    cnpj = cnpj.replaceAll('.', '');
    cnpj = cnpj.replaceAll('-', '');
    cnpj = cnpj.replaceAll('/', '');

    const nCnpj = parseFloat(cnpj);
    /* 
        
        {
            "cnpj": 61585865000151,
            "idTerminal": "TERM01",
            "nsuReq": 2021070422105960,
            "numSessao": "2021070422105960",
            "itensCupom": [
                {
                "tipoProduto": 0,
                "codigoProduto": "7899706175005",
                "precoUnitario": 89.9,
                "qtdeVendida": 1
                }
            ]
        }
        */

    const query = {
      idTerminal: 'SIMULADOR',
      cnpj: nCnpj,
      nsuReq: nsu,
      numSessao: nsu,
    };

    const request = { body: query, token: token, url: `${this.resource}/consultar_beneficios_extra` };

    try {
      const config = { headers: { token, 'x-api-key': this.apiKey } };

      const ret = await this.http.post(request.url, request.body, config);
      return responseAutorizador('consultarBeneficiosExtra', ret, request);
      // return response.data.result;
    } catch (error) {
      return responseException('consultarBeneficiosExtra', error, 'Falha Interna - consultarBeneficiosExtra', request);
    }
  }

  getUrl() {
    return 'autorizadorV4';
  }

  /// async confirmarDescontos(token, numSessao, numCupom, pedido) {

  /// }
}

export const httpAutorizadorV4 = new HttpAutorizador();

// https://api-autorizadorzeushml.trackingsales.com.br/swagger/ui/index

// https://api-autorizadorzeushml-v3.trackingsales.com.br/swagger/ui/index#/AutorizadorV3
