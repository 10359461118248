/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import * as React from 'react';
import { IconButton, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SelectCampo from './SelectCampo';
import { Box } from '@mui/system';
import AddButtonEvent from './AddButtonEvent';

const classesCSS = {
  searchIcon: {
    color: (theme) => theme.palette.text.secondary,
    marginTop: 1,
    marginRight: 1,
  },
  Icons: {
    flex: '0 0 auto',
    '&:hover': {
      color: (theme) => theme.palette.error.main,
    },
  },
  SelAtivo: {
    flex: '0 0 auto',
    marginRight: 4,
  },
};

// type Props = {
//   name: string;
//   submitSearch: (texto: string, ativo: boolean | undefined) => void;
//   showAtivo: boolean;
//   showBusca: boolean;
//   value?: string;
//   searchHits?: string[];
//   children?: any;
// };

const TsInputBusca = (props) => {
  const [valor, setValor] = React.useState('');
  const [ativo, setAtivo] = React.useState('true');
  const [ativoOptions, setAtivoOptions] = React.useState([]);
  const submitSearch = props.submitSearch ?? ((texto, ativo) => {});

  const getAtivoBoolean = () => {
    if (!ativo || ativo === 'todos') return undefined;
    else if (ativo === 'true') return true;
    else return false;
  };

  const handleSearch = (event) => {
    let val = '';
    if (event === undefined) {
      val = valor;
    } else {
      event.preventDefault();
      val = event.target.mui_datatable_search.value;
    }
    setValor(val);
    props.submitSearch(val, getAtivoBoolean());
  };

  const clearSearch = () => {
    setValor('');
    submitSearch('', getAtivoBoolean());
  };

  React.useEffect(() => {
    if (props.value) {
      setValor(props.value);
    }
  }, [props.value]);

  React.useEffect(() => {
    submitSearch(valor, getAtivoBoolean());
  }, [ativo]);

  React.useEffect(() => {
    // [{value: "", text: "Todos"}, {value: "true", text: "Ativo"}, {value: "false", text: "Inativo"}]
    if (props.showAtivo === true) {
      setAtivo('true');
      setAtivoOptions([
        { value: 'todos', text: 'Todos' },
        { value: 'true', text: 'Ativo' },
        { value: 'false', text: 'Inativo' },
      ]);
    } else {
      setAtivo('todos');
      setAtivoOptions([{ value: 'todos', text: 'Todos' }]);
    }
  }, [props.showAtivo]);

  return (
    <Box sx={{display: 'flex'}}>
      {props.showBusca ? 
      <>
        <form onSubmit={(e) => handleSearch(e)} style={{ flex: '8 1 auto', minWidth: '200px' }}>
          <Input
            autoFocus={true}
            sx={{ paddingTop: 4 }}
            name="mui_datatable_search"
            fullWidth={true}
            value={valor}
            onChange={(e) => setValor(e.target.value)}
          />
        </form>
        <IconButton sx={classesCSS.Icons} onClick={(e) => handleSearch(undefined)}>
          <SearchIcon sx={classesCSS.searchIcon} />
        </IconButton>
        <IconButton sx={classesCSS.Icons} onClick={(e) => clearSearch()}>
          <ClearIcon />
        </IconButton>
      </> : 
      <>
        <Box sx={{flex: '8 1 auto', minWidth: '200px'}}></Box>
      </>}
      {props.children !== undefined ? <Box sx={{ flex: '0 0 auto' }}>{props.children}</Box> : null}
      {props.showAtivo === true ? (
        <Box sx={classesCSS.SelAtivo}>
          <SelectCampo
            id="TsInputBusca-Ativo"
            label="Ativo"
            name="Ativo"
            options={ativoOptions}
            value={ativo}
            onChange={(e) => {
              setAtivo(e.target.value);
            }}
          />
        </Box>
      ) : null}
      { props.showAdd === true ? ( 
          <AddButtonEvent title="Adicionar item" onClick={props.onClickAdd} /> 
      ) : null }
    </Box>
  );
};

export default TsInputBusca;
