import { getProjetoStorage, getToken, HOMEDIR, setUser, usuarioNull } from '../auth';
import { ConvertError } from './error-convert';
// import { useHistory } from 'react-router-dom';

const projAtual = getProjetoStorage();

const errorInterceptor = (error) => {
  const httpError = ConvertError(error, 'Erro ao processar request');

  console.warn('------------------------');
  console.warn('errorInterceptor');
  console.warn(`HTTP-ERROR: ${httpError.mensagem}`);
  console.warn(`HTTP-ERROR - STATUS: (${httpError.retCod})`);
  console.warn('------------------------');

  switch (httpError.retCod) {
    case 499:
      setUser(usuarioNull);
      setTimeout(() => {
        window.location.href = `${HOMEDIR}/`;
      }, 100);

      break;

    default:
  }
  return Promise.reject(httpError);
};

export default class HttpResource {
  // protected uri: string;

  constructor(http, resource, uri) {
    this.http = http;
    this.Id = 0;
    this.resource = resource;
    this.uri = uri;
    this.url = uri;
    this.Id++;
    this.token = getToken();

    // this.uri = http.getUri(http.defaults);
    this.config = {
      headers: {
        Authorization: this.token,
        'x-projeto': projAtual.id,
      },
    };

    var obj = this.http.interceptors.response;

    if (!obj.handlers || obj.handlers.length === 0) {
      this.http.interceptors.response.use(undefined, (error) => errorInterceptor(error));
    }
  }

  Config = () => {
    // console.l og("HttpResource - Config()  , getToken()");
    this.token = getToken();
    this.config = {
      headers: {
        Authorization: this.token,
        'x-projeto': projAtual.id,
      },
    };
    return this.config;
  };

  list() {
    this.url = `/${this.resource} - GET`;
    return this.http.get(this.resource, this.Config());
  }

  busca(filtro) {
    this.url = `/${this.resource}/busca - POST`;
    if (!filtro.projetoId) {
      filtro.projetoId = Number(projAtual.id);
    }

    return this.http.post(`${this.resource}/busca`, filtro, this.Config());
  }

  get(id) {
    this.url = `/${this.resource}/${id} - GET`;
    return this.http.get(`${this.resource}/${id}`, this.Config());
  }

  create(dados) {
    this.url = `/${this.resource} - POST`;
    return this.http.post(`${this.resource}`, dados, this.Config());
  }

  update(id, dados) {
    this.url = `/${this.resource} - PUT`;
    return this.http.put(`${this.resource}/${id}`, dados, this.Config());
  }

  delete(id) {
    this.url = `/${this.resource} - DELETE`;
    return this.http.delete(`${this.resource}/${id}`, this.Config());
  }

  getUrl() {
    return `${this.uri}${this.url}`;
  }
}
