import {Box, Typography} from "@mui/material";
import {valorText, labelTextBotton, labelTextTop} from '../util/Util';

const TsBigNumber = (props) =>  {
    const bgcolor = props.bgcolor || 'red';
    const labelPos = props.labelPos || 'top';
    const sx = props.sx || {};
    const valor = props.value || props.children;

     const boxStyle = {
        ...sx,
        padding: sx.padding || 1, 
        margin: sx.margin || 0.5, 
        backgroundColor: bgcolor === 'red' ? "#e61e28" : "#1f1f85"
    };

    return (
        <Box sx={boxStyle}>
                { labelPos === 'top' &&  <Box sx={{textAlign: 'center'}}><Typography component={"span"} sx={labelTextTop}>{props.label || 'Label'}</Typography></Box> }
                <Box sx={{textAlign: 'center', minHeight: '50px'}}>
                    <Typography component={"span"} sx={valorText}>{valor}</Typography>
                </Box>
                { labelPos !== 'top' &&  <Box sx={{textAlign: 'center'}}><Typography component={"span"} sx={labelTextBotton}>{props.label || 'Label'}</Typography></Box> }
        </Box>
    );
}

export default TsBigNumber;