import * as React from 'react';
import { Box, Link } from '@mui/material';

import { format, parseISO } from 'date-fns';
import { Page } from '../Page';
import EventNoteIcon from '@mui/icons-material/EventNote';

/* http */
import transacaoHttp from '../../util/http/transacao-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';
import SelectCampo from '../../formulario/SelectCampo';
import {formatNumber} from '../../util/Util';

const selectorCSS = {
    cursor: "pointer"
};

// <TsDataGridx />
class TransacaoPend extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;
        
        this.state.open = false;
        this.state.tipo = 'pendentes';
        this.state.detalhes = '';
        this.tipoOptions = [
            { value: 'todos', text: 'Todos' },
            { value: 'pendentes', text: 'Pendentes' }
          ];

        this.columns = [
            {
                name: "id", label: "id"
            },
            {
                name: "dataTransacao", label: "DATA",
                format: (value) => { return <span style={selectorCSS}>{format(parseISO(value), 'dd/MM/yy HH:mm')}</span> }
            },
            {
                name: "cnpj", label: "CNPJ",
                format: (value) => { return <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cnpj: "' + value + '"'})}>{value}</span> }
            },
            {
                name: "cpf", label: "CPF",
                format: (value) => { return <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cpf: "' + value + '"'})}>{value}</span> }
            },
            {
                name: "codigoProduto", label: "codigoProduto",
                format: (value) => { return <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'produto: "' + value + '"'})}>{value}</span> }
            },
            { name: "desconto", label: "Desconto", 
                format: (value) => (<>{formatNumber(value)}</>)
             },
            { name: "flgReplicado", label: "Replicado",
                format: (value) => {return <span>{value ? 'SIM': 'NÃO'}</span>}
            },
            { name: "dclubeTransacaoId", label: "Dclube-ID" },
            { name: "dclubeMensagem", label: "Dclube-MSG" },
            {
                name: "transacaoRecebidaId", label: "Reset",
                format: ((value) => (<Link component="button" onClick={() => this.resetReplicacao(value)}><EventNoteIcon /></Link>))
            },
        ];
    }

    setTipo = (tipo) => {
        this.setState({tipo});
        this.setExecBuscar(true);
        this.setServerPage(0);
    }

    Buscar = () => {
        this.iniciarBusca();

        const query = { page: this.state.serverPage, nrecPage: this.serverPageSize, ativo: true, busca: this.state.objBusca.busca };

        transacaoHttp.buscaPendentes(query, this.state.tipo)
            .then(({ data }) => {
                this.setRows(data.dados);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(transacaoHttp.getUrl());
            });
    };

    resetReplicacao = (transacaoId) => {
        this.setError(undefined);
        this.setProcessando(true);
         
        transacaoHttp.resetReplicacao(transacaoId, false)
            .then(({ data }) => {
                this.Buscar();
            })
            .catch((error) => {
                this.setUrl(transacaoHttp.getUrl());
                this.setError(error);
                this.setProcessando(false);
            });

    };

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
    }

    render() {
    return (
        <Page title="Listar Transações Pendentes">
            <Box>
                 
                <Box>
                    <TsInputBusca
                        name="input-busca"
                        value={this.state.objBusca.busca}
                        showAtivo={false}
                        showBusca={true}
                        submitSearch={(texto, ativo) => { this.setObjBusca({...this.state.objBusca, ativo: ativo, busca: texto}); }}
                    >
                        <SelectCampo 
                            id="TsInputBusca-Tipo"
                            label="Tipo"
                            name="tipo"
                            options={this.tipoOptions}
                            value={this.state.tipo}
                            onChange={(e) => {
                              this.setTipo(e.target.value);
                            }}
                        />
                    </TsInputBusca>
                </Box>
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                />
                   
            </Box>
            {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
            <TsBackDrop processando={this.state.processando} />
        </Page>
    );
    }
};

export default TransacaoPend;