import { ConvertError } from './error-convert';

export const responseException = (modulo, error, mensagem, request) => {
  const retorno = ConvertError(error, mensagem);

  const ret = {
    sucesso: false,
    request: request,
    mensagem: retorno.mensagem ? retorno.mensagem : mensagem,
    codigoResposta: retorno.retCod ? retorno.retCod + '' : '',
    mensagemResposta: retorno.mensagem ? retorno.mensagem : '',
    status: retorno.retCod,
    data: retorno.data,
  };

  try {
    if (retorno.data) {
      if (retorno.data.mensagem) ret.mensagem = retorno.data.mensagem;
      if (retorno.data.codigoResposta) ret.codigoResposta = retorno.data.codigoResposta;
    }
  } catch (error) {}

  return ret;
};

export const responseAutorizador = (modulo, retorno, request) => {
  const ret = {
    sucesso: false,
    request: request,
    mensagem: '',
    codigoResposta: '',
    mensagemResposta: '',
    status: 599,
    data: undefined,
  };
  let codigo = '99';
  let statusText = '';

  ret.sucesso = true;
  ret.mensagem = 'SUCESSO';
  ret.status = retorno.status;
  ret.data = retorno.data;
  statusText = retorno.statusText;

  if (ret.status > 299 && ret.status < 200) {
    ret.sucesso = false;
    ret.mensagem = statusText ? statusText : ret.mensagem;
    codigo = ret.status.toString().substr(0, 2);
  } else {
    if (!ret.data || !ret.data.codigoResposta) {
      codigo = '99';
    } else {
      codigo = ret.data.codigoResposta;
    }
  }

  if (codigo.length !== 2) {
    codigo = '99';
  }

  ret.codigoResposta = codigo;
  ret.mensagemResposta = ret.mensagemResposta ? ret.mensagemResposta : statusText;

  if (ret.sucesso && ret.codigoResposta !== '00') {
    ret.sucesso = false;
    ret.mensagem = ret.mensagemResposta;
  }

  return ret;
};

export const responseHttp = (modulo, retorno, request) => {
  const ret = {
    sucesso: false,
    mensagem: '',
    request: request,
    codigoResposta: '',
    mensagemResposta: '',
    status: 599,
    data: undefined,
  };
  let codigo = '99';
  let statusText = '';

  ret.sucesso = true;
  ret.mensagem = 'SUCESSO';
  ret.status = retorno.status;
  ret.data = retorno.data;
  statusText = retorno.statusText;

  if (ret.status > 299 && ret.status < 200) {
    ret.sucesso = false;
    ret.mensagem = statusText ? statusText : ret.mensagem;
    codigo = ret.status.toString().substr(0, 2);
  } else {
    codigo = '00';
  }

  if (codigo.length !== 2) {
    codigo = '99';
  }

  ret.codigoResposta = codigo;
  ret.mensagemResposta = ret.mensagemResposta ? ret.mensagemResposta : statusText;

  if (ret.sucesso && ret.codigoResposta !== '00') {
    ret.sucesso = false;
    ret.mensagem = ret.mensagemResposta;
  }

  return ret;
};
