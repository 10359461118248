import * as React from 'react';
import contratoHttp from '../../util/http/contrato-http';
import relatorioHttp from '../../util/http/relatorio-http';
import {Link, Button, Box} from '@mui/material';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TsDialog from '../../formulario/TsDialog'; 
import {TsTextField} from '../../formulario/TsTextField';
import AddButtonEvent from '../../formulario/AddButtonEvent';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowAlert from '../../util/ShowAlert';
import TsButton from '../../formulario/TsButton';
import { listaSignerStatus, listaContratoSignerTipo } from './contrato-lista'; 
import SelectCampo from '../../formulario/SelectCampo';
import {getProjetoStorage} from '../../util/auth';

let ContratoSigner_Processando = false;
const cadastro_vazio = {id: 0, cpf: '', rg_num: '', rg_emissor: '', fone: '', email: '', nome: ''};


const ContratoSigner = (props) => {
    const {open, contratoId} = props;
    const signer_vazio = {id: 0, cadastroUsuarioId: 0, contratoId: contratoId, entidadeTipo: ''};
    const projAtual = getProjetoStorage();
    const serverPageSize = 50;
    const {serverPage, setServerPage} = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [executing, setExeccuting] = React.useState(false);
    const [detail, setDetail] = React.useState({open: false, id: 0, pageTitle: 'Lista de signatários'});
    const [signer, setSigner] = React.useState(signer_vazio);
    const [cadastro, setCadastro] = React.useState(cadastro_vazio);
    const [buscaCadastro, setBuscaCadastro] = React.useState({busca: ''});
    const [listaEntidade, setListaEntidade] = React.useState([]); 

    const columns = [
        {  name: "id", 
           label: "#",
           format: ((value) => (<Link component={Button} onClick={(e) => {setDetail({open: true, id: value, pageTitle: "Detalhes do signatário"})}}><EventNoteIcon /></Link>))
        },
        {name: "contratoSignerStatus", label: "Situação"},
        {name: "cadastroNome", label: "Nome"},
        {name: "cadastroEmail", label: "E-mail"},
        {name: "entidadeNome", label: "Entidade"},
        {name: "contratoSignerTipoId", label: "Tipo", format: ((value) => (<>{obterTipo(value)}</>))},
        {name: "nMsgEviada", label: "Mensagens"},
    ];

    const obterTipo = (id) => {
        const tipoId = id + '';
        for(let i = 0; i < listaContratoSignerTipo.length; i++) {
            if (tipoId === listaContratoSignerTipo[i].value)
                return listaContratoSignerTipo[i].text;
        }
        return 'Desconhecido';
    }

    const setProcessando = (value) => {
        ContratoSigner_Processando = value;
        setExeccuting(value);
    }

    const openAddItem = (e) => {
        setBuscaCadastro({busca: ''});
        setSigner(signer_vazio);
        setCadastro(cadastro_vazio);
        setDetail({open: true, id: 0, pageTitle: "Adicionar signatário"});
    }

    const Buscar = async () =>{

        if (ContratoSigner_Processando) {
            return;
        }
        setProcessando(true);
        setRows([]);

        try {
            const {status, data} = await contratoHttp.getSigner(contratoId);
            if (status === 200 && data.length > 0) {
                setRows(data);
            }
            else {
                ShowAlert.info("Nenhum registro encontrado");
            }
        } catch (error) {
            if (error.mensagem)
                ShowAlert.error(error.mensagem);
            else
                ShowAlert.error("Nenhum registro retornado");
             
        }
        setProcessando(false);


    }

    const BuscarCad = async () => {

        cadastro.id = Number(cadastro.id);
        signer.cadastroUsuarioId = Number(signer.cadastroUsuarioId);

        if (cadastro.id !== 0 && signer.cadastroUsuarioId === cadastro.id) {
            return;
        }
         
        let cadRetorno = cadastro_vazio;
        setProcessando(true);
        // setCadastro(cadRetorno);
        try {
            const {status, data} = await contratoHttp.getCadastro(signer.cadastroUsuarioId, buscaCadastro.busca); 
            if (status === 200) {
                data.id = Number(data.id);
                cadRetorno = data;
                setCadastro(data);
                if (signer.cadastroUsuarioId !== data.id) {
                    setSigner({...signer, cadastroUsuarioId: data.id});
                }
            }
        } catch (error) {
            setCadastro(cadastro_vazio);
            console.log('cadastro nao encontrado', error);
            setProcessando(false);
        }

        if (cadRetorno.id === 0) {
            if (buscaCadastro.busca.indexOf('@') > 0)
                setCadastro({...cadastro, email: buscaCadastro.busca});
            else 
                setCadastro({...cadastro, cpf: buscaCadastro.busca});
            ShowAlert.info("Preecha os dados para incluir o cadastro");
        }
        setProcessando(false);
    }

    const changeEntidadeTipo = async (valor) => {
        if (ContratoSigner_Processando) {
            return;
        }
        setProcessando(true);
        try {
            if (signer.entidadeTipo !== valor) {
                setSigner({...signer, entidadeTipo: valor});
            }
            
            const lista = [];
            let listaNome = '';
            if (valor === 'REDE') {
                listaNome = 'rede';
            }
            else if (valor === 'DISTRIBUIDOR') {
                listaNome = 'distribuidor';
            }
            else if (valor === 'SOFTHOUSE') {
                listaNome = 'softhouse';
            }
            else if (valor === 'INDUSTRIA') {
                listaNome = 'industria';
            }

            if (listaNome) {
                const {data} = await relatorioHttp.obterLista(listaNome, projAtual.id);
                lista.push({text: '...Selecione', value: ''});
                data.forEach(item => {
                    lista.push({text: item.text, value: Number(item.value) + ''});
                });
                
            }
            else {
                lista.push({text: '... Carregando', value: ''});
            }
            setProcessando(false);
            setListaEntidade(lista);
        } catch (error) {
            setProcessando(false); 
            // setError(error);
        }
    }

    const AtualizarCad = async () => {
        if (ContratoSigner_Processando) {
            return;
        }
        setProcessando(true);
        signer.id = Number(signer.id);
        signer.cadastroUsuarioId = Number(signer.cadastroUsuarioId);
        signer.entidadeId = Number(signer.entidadeId);
        signer.contratoSignerStatusId = Number(signer.contratoSignerStatusId);

        cadastro.id = Number(cadastro.id);

        try {
            const {status, data} = await contratoHttp.atualizarCad(signer, cadastro);
            ShowAlert.info("Cadastro atualizado");
        } catch (error) {
            console.log('AtualizarCad error:', error);
            if (error.mensagem)
                ShowAlert.error(error.mensagem);
            else
                ShowAlert.error("Erro ao atualizar cadastro");
        }
        setProcessando(false);
    }

    const ObterDetalhes = async () => {
        if (detail.id === 0) {
            setSigner(signer_vazio);
        }
        else {
            const item = rows.find(r => r.id === detail.id);
            if (item !== undefined) {
                console.log('signer encontrado', item);
                setSigner(item);
            }
        }
    }

    React.useEffect(() => {

        if (open === true) {
            if (detail.open === false)
                Buscar();
            else if (detail.open === true) {
                ObterDetalhes();
            }
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, detail]);

    React.useEffect(() => {
        if (signer.id !== 0 && cadastro.id !== signer.cadastroUsuarioId) {
            BuscarCad();
        }
        else if (buscaCadastro.busca) {
            BuscarCad();
        }

        changeEntidadeTipo(signer.entidadeTipo);
        
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signer, buscaCadastro]);


    if (open) return (<>
        <Box dir="rtl"><AddButtonEvent title="Adicionar item" onClick={openAddItem} /></Box>
        <TsDataGrid
            rows={rows}
            columns={columns}
            queryid={''}
            serverPage={serverPage}
            serverPagesize={serverPageSize}
            onChangePage={(value) => setServerPage(value)}
        />
        <TsDialog onClose={() => setDetail({open: false, id: 0, pageTitle: "Lista de signinatários"})} id="dialog-detalhe-signer" open={detail.open} title={detail.pageTitle} minWidth={800}>
          <Box sx={{minHeight: '300px'}}>
            
            { signer.id === 0 ?
            <>
            <Box>Adicionar signer, buscar por e-mail ou CPF</Box>
            <Box><TsInputBusca
                name="input-busca"
                value={setBuscaCadastro.busca || ''}
                showBusca={true}
                showAtivo={false}
                showAdd={false}
                submitSearch={(texto, ativo) => { setBuscaCadastro({...buscaCadastro, busca: texto}); }}
            ></TsInputBusca></Box>
            </> :
            <><Box>Editar signer</Box></>
            }
            
            <Box sx={{display: 'flex'}}>
            <Box sx={{flex: '1 1 auto', paddingRight: 2}}><TsTextField
                    name="cpf"
                    label="Cpf"
                    fullWidth
                    value={cadastro.cpf || ''}
                    disabled={false}
                    onChange={(e) => {setCadastro({...cadastro, cpf: e.target.value})}}
             /></Box>
             <Box sx={{flex: '1 1 auto', paddingRight: 2}}><TsTextField
                    name="nome"
                    label="Nome"
                    fullWidth
                    value={cadastro.nome || ''}
                    disabled={false}
                    onChange={(e) => {setCadastro({...cadastro, nome: e.target.value})}}
             /></Box>
            </Box>

            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 1 auto', paddingRight: 2}}>
                    <TsTextField
                        name="email"
                        label="E-mail"
                        fullWidth
                        value={signer.email || ''}
                        disabled={false}
                        onChange={(e) => {setSigner({...signer, email: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: '1 1 auto', paddingRight: 2}}>
                    <TsTextField
                        name="fone"
                        label="Fone"
                        fullWidth
                        value={cadastro.fone || ''}
                        disabled={false}
                        onChange={(e) => {setCadastro({...cadastro, fone: e.target.value})}}
                    />
                </Box>
            </Box>

            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '8 1 auto', paddingRight: 2}}>
                    <TsTextField
                        name="rg_num"
                        label="Numero rg"
                        fullWidth
                        value={cadastro.rg_num || ''}
                        disabled={false}
                        onChange={(e) => {setCadastro({...cadastro, rg_num: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: '1 0 auto', paddingRight: 2}}>
                    <TsTextField
                        name="rg_emissor"
                        label="Emissor rg"
                        fullWidth
                        value={cadastro.rg_emissor || ''}
                        disabled={false}
                        onChange={(e) => {setCadastro({...cadastro, rg_emissor: e.target.value})}}
                        />
                </Box>
                <Box sx={{flex: '1 0 auto', paddingRight: 2}}>
                    <SelectCampo
                            id='signerTipo'
                            label='Signer Tipo'
                            name='signerTipo'
                            disabled={false}
                            options={listaContratoSignerTipo}
                            value={signer.contratoSignerTipoId + '' || ''}
                            onChange={(e) => {setSigner({...signer, contratoSignerTipoId: Number(e.target.value)})}}
                        />
                </Box>
            </Box>

            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '8 1 auto', paddingRight: 2}}>
                    <TsTextField
                        name='sign_url'
                        label='URL assinatura'
                        value={signer.sign_url + '' || ''}
                        disabled={true}
                    />
                </Box>
            </Box>

             <Box sx={{display: 'flex'}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name='sign_status'
                        label='Status assinatura'
                        value={signer.sign_status + '' || ''}
                        disabled={true}
                    />
                </Box>
                <Box sx={{flex: '8 1 auto', paddingRight: 2}}>
                    <TsTextField
                        name='signed_at'
                        label='Data assinatura'
                        value={signer.signed_at + '' || ''}
                        disabled={true}
                    />
                </Box>
            </Box>
            <Box sx={{display:'flex'}} >
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <SelectCampo
                        id='status-usuario'
                        label='Status signer'
                        name='stauts-usuario'
                        disabled={false}
                        options={listaSignerStatus}
                        value={signer.contratoSignerStatusId || ''}
                        onChange={(e) => {setSigner({...signer, contratoSignerStatusId: Number(e.target.value)})}}
                    />
                </Box>
             <Box sx={{flex: "0 0 auto", paddingRight: 2}} >
                <TsButton
                    onClick={(e) => AtualizarCad()}
                >Enviar</TsButton>
            </Box>

            </Box>
            
          </Box>
        </TsDialog>
        <TsBackDrop processando={executing} />
        </>);
        else return null;
}

export default ContratoSigner;

