// @flow 
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';
import { Chip } from '@mui/material';
import * as React from 'react';
import { palette } from '../../theme';

const localTheme = createTheme ({
    palette: {
        primary: palette.success,
        secondary: palette.error
    }

});


export const BadgeYes = (props) => {
    return (
        <ThemeProvider theme={localTheme}>
            <Chip label={props.label} color='primary' />
        </ThemeProvider>
    );
};

export const BadgeNo = (props) => {
    return (
        <ThemeProvider theme={localTheme}>
            <Chip label={props.label} color='secondary' />
        </ThemeProvider>
    );
};

 
