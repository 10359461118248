import * as React from 'react';
import { Box, IconButton, Paper } from '@mui/material';
import { TsTextField } from '../../formulario/TsTextField'; 

import redeHttp from '../../util/http/rede-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError'; 
import TsButton from '../../formulario/TsButton';
import ShowAlert from '../../util/ShowAlert';
import SelectCampo from '../../formulario/SelectCampo';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import ChaveBancaria from '../faturamento/chave-bancaria';

let RedeAdicionar_Processando = false;

const listaCanalEstabelecimento = [
    {value:'1', text: 'ALIMENTAR'},
    {value:'2', text: 'FARMA'},
    {value:'3', text: 'PERFUMARIA'},
    {value:'4', text: 'PETSHOP '},
];

const listaFiltro = [
{ value: 'ALL' , text: 'Todos' }, 
{ value: 'MERCADO', text: 'Mercado' },
{ value: 'PERFUMARIA', text: 'Perfumaria' },
{ value: 'PET-SHOP', text: 'Pet-shop' },
{ value: 'DROGARIA', text: 'Drogaria' },
];

// PROC : PR_Rede_Create

const RedeAdicionar = (props) => {
    const {open, detail, setDetail, listaSofthouse} = props;

    const [redeDetail, setRedeDetail] = React.useState({id: 0});
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState('');
    const [executing, setExcecuting] = React.useState(false); 

    const setProcessando = (value) => {
        RedeAdicionar_Processando = value;
        setExcecuting(value);
    }


    const limpaForm = () => {
        setRedeDetail({
            id: 0,
            autoIncrement: true,
            redeId: 'AUTOINCREMENT',
            redeNome: '',
            filtro: 'ALL',
            matrizCnpj: '',
            matrizNome: '',
            matrizEndereco: '',
            matrizNumero: '',
            matrizComplemento: '',
            matrizBairro: '',
            matrizCep: '',
            matrizUf: '',
            canalEstabelecimentoId: '1',
            softhouseId: ''
        });
    }

    const changeAutoincrement = () => {
        if (redeDetail.autoIncrement) {
            setRedeDetail({...redeDetail, autoIncrement: false, redeId: ''});
        }
        else {
            setRedeDetail({...redeDetail, autoIncrement: true, redeId: 'autoincrement'});
        }
    }

    const submitForm = async () => {
        if (RedeAdicionar_Processando === true) return;
        setProcessando(true);
        const rede = {...redeDetail};
        let msgError = '';

        rede.canalEstabelecimentoId = Number(rede.canalEstabelecimentoId);
        rede.matrizCnpj = Number(rede.matrizCnpj.replace(/\./g, '').replace(/\//g, '').replace(/-/g, ''));

        if (isNaN(rede.matrizCnpj) || !rede.matrizCnpj) {
            mostraError('CNPJ da matriz inválido');
            return;
        }

        if (rede.autoIncrement) {
            rede.redeId = 0;
        }
        else {
            rede.redeId = Number(rede.redeId);
        }

        if (isNaN(rede.redeId)) {
            mostraError('ID da rede inválido');
            return;
        }

        rede.softhouseId = Number(rede.softhouseId);
        if (isNaN(rede.softhouseId)) rede.softhouseId = 0;

        if (!rede.matrizNome || rede.matrizNome.length < 5) msgError = 'Nome da matriz invalido';
        else if (!rede.matrizCep || rede.matrizCep.length < 8) msgError = 'CEP da matriz inválido';
        else if (!rede.matrizEndereco || rede.matrizEndereco.length < 5) msgError = 'Bairro da matriz inválido';
        else if (!rede.matrizBairro || rede.matrizBairro.length < 2) msgError = 'Bairro da matriz inválido';
        else if (!rede.matrizCidade || rede.matrizCidade.length < 3) msgError = 'Cidade da matriz inválido';
        else if (!rede.matrizUf || rede.matrizUf.length !== 2) msgError = 'Estado da matriz inválido';
        // else if (!rede.nomeAdmin || rede.nomeAdmin.length < 3) msgError = 'Nome administrador inválido';
        else if (!rede.emailAdmin || rede.emailAdmin.length < 3) msgError = 'E-mail administrador inválido';
        // else if (!rede.foneAdmin || rede.foneAdmin.length < 3) msgError = 'Celular administrador inválido';
        // else if (!rede.chaveBancaria || rede.chaveBancaria.length < 3) msgError = 'Chave bancária administrador inválida';
        else if (!rede.usuarioPos || rede.usuarioPos.length < 3) msgError = 'Usuário POS inválido';

        if (msgError) {
            mostraError(msgError);
            return;
        }
        
        try {
            const retorno = await redeHttp.create(rede);

            if (retorno.data.sucesso === false) {
                ShowAlert.info(retorno.data.mensagem);
            }
            else {
                ShowAlert.info('Rede adicionada com sucesso!');
            }

        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        }
        setProcessando(false);
    }

    const mostraError = (msg) => {
        ShowAlert.error(msg);
        setProcessando(false);
        return;
    }

    React.useEffect(() => {
        if (detail.open === true) {
            limpaForm();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail])

    return (<>
        <form>
        <Box>
        <Paper square={false} sx={{width: '100%', padding: 2, marginBottom: 2}} elevation={2} >
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="redeNome"
                    label="Nome Rede"
                    fullWidth
                    value={redeDetail.redeNome || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, redeNome: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '200px', paddingRight: 2}}>
                <SelectCampo
                    id="softhouseId"
                    label="Softhouse"
                    name="softhouseId"
                    options={listaSofthouse}
                    value={redeDetail.softhouseId || ''}
                    onChange={(e) => setRedeDetail({ ...redeDetail,  softhouseId: e.target.value})}
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "0 0 auto", width: '200px'}}>
                <TsTextField
                    name="redeId"
                    label="RedeId"
                    fullWidth
                    value={redeDetail.redeId || ''} 
                    disabled={redeDetail.autoIncrement}
                    onChange={(e) => {setRedeDetail({...redeDetail, redeId: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '40px', paddingRight: 2, paddingTop: 1.5}}>
                <IconButton onClick={(e) => changeAutoincrement()} >{redeDetail.autoIncrement ? <FlashOnIcon /> : <FlashOffIcon />}</IconButton>
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}></Box>
            <Box sx={{flex: "0 0 auto", width: '200px', paddingRight: 2}}>
                <SelectCampo
                    id="filtro"
                    label="Filtro produtos"
                    name="filtro"
                    options={listaFiltro}
                    value={redeDetail.filtro || ''}
                    onChange={(e) => setRedeDetail({ ...redeDetail,  filtro: e.target.value})}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '200px', paddingRight: 2}}>
                <SelectCampo
                    id="canalEstabelecimentoId"
                    label="Canal Estabelecimento"
                    name="canalEstabelecimentoId"
                    options={listaCanalEstabelecimento}
                    value={redeDetail.canalEstabelecimentoId || ''}
                    onChange={(e) => setRedeDetail({ ...redeDetail,  canalEstabelecimentoId: e.target.value})}
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="nomeAdmin"
                    label="Nome Administrador"
                    fullWidth
                    value={redeDetail.nomeAdmin || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, nomeAdmin: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="emailAdmin"
                    label="E-mail Administrador"
                    fullWidth
                    value={redeDetail.emailAdmin || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, emailAdmin: e.target.value})}}
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
        <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="foneAdmin"
                    label="Celular Administrador"
                    fullWidth
                    value={redeDetail.foneAdmin || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, foneAdmin: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <ChaveBancaria
                    name="chaveBancaria"
                    disabled={false}
                    userLevel={3}
                    value={redeDetail.chaveBancaria || ''}
                    onChange={(valor) => {setRedeDetail({...redeDetail, chaveBancaria: valor})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="usuarioPos"
                    label="Usuário POS"
                    fullWidth
                    value={redeDetail.usuarioPos || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, usuarioPos: e.target.value})}}
                />
            </Box>
        </Box>
        </Paper>

        <Paper square={false} sx={{width: '100%', padding: 2, marginBottom: 2}} elevation={2} >
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="cnpj"
                    label="CNPJ Matriz"
                    fullWidth
                    value={redeDetail.matrizCnpj || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, matrizCnpj: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="matrizNome"
                    label="Nome Matriz"
                    fullWidth
                    value={redeDetail.matrizNome || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, matrizNome: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '150px', paddingRight: 2}}>
                <TsTextField
                    name="matrizCep"
                    label="CEP"
                    fullWidth
                    value={redeDetail.matrizCep || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, matrizCep: e.target.value})}}
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="matrizEndereco"
                    label="Endereço"
                    fullWidth
                    value={redeDetail.matrizEndereco || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, matrizEndereco: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '150px', paddingRight: 2}}>
                <TsTextField
                    name="matrizNumero"
                    label="Número"
                    fullWidth
                    value={redeDetail.matrizNumero || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, matrizNumero: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '150px', paddingRight: 2}}>
                <TsTextField
                    name="matrizComplemento"
                    label="Complemento"
                    fullWidth
                    value={redeDetail.matrizComplemento || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, matrizComplemento: e.target.value})}}
                />
            </Box>
        </Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="matrizBairro"
                    label="Bairro"
                    fullWidth
                    value={redeDetail.matrizBairro || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, matrizBairro: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="matrizCidade"
                    label="Cidade"
                    fullWidth
                    value={redeDetail.matrizCidade || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, matrizCidade: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", width: '150px', paddingRight: 2}}>
                <TsTextField
                    name="matrizUf"
                    label="Estado"
                    fullWidth
                    value={redeDetail.matrizUf || ''} 
                    onChange={(e) => {setRedeDetail({...redeDetail, matrizUf: e.target.value})}}
                />
            </Box>
        </Box>
        </Paper>
        <Box sx={{display: "flex"}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}></Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                    <TsButton 
                        onClick={(e) => setDetail({...detail, open: false})}
                        color='secondary'
                    >Voltar</TsButton>
                </Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                    <TsButton onClick={(e) => submitForm()}
                    >Enviar</TsButton>
                </Box>
        </Box>
        </Box>
        </form>
        <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={executing} />
    </>);

}

export default RedeAdicionar;