import * as React from 'react';
import TsDataGrid from '../../formulario/TsDataGrid';
import redeHttp from '../../util/http/rede-http';
import {Box} from '@mui/material';
import TsButton from '../../formulario/TsButton';
import ShowAlert from '../../util/ShowAlert'; 

const RedeProdutoFiltro = (props) => {

    const {detail, setDetail, projAtual, setError, setUrl, redeNome, processando, setProcessando} = props;
    const [rows, setRows] = React.useState([]);
    const columns = [
        {
            name: "id",
            label: "#"
        },
        {
            name: "filtro",
            label: "filtro"
        },
        {
            name: "tipo",
            label: "tipo"
        },
        {
            name: "valor",
            label: "valor"
        }

    ];

 
    const ObterCategorias = async () => {

        if (processando)
            return;

        setProcessando(true);

        try {
            const {data} = await redeHttp.obterCategorias(detail.id);
            const lista = [];
            data.dados.forEach(filtro => {
                if (filtro.filtroItens && filtro.filtroItens.length > 0) {
                    filtro.filtroItens.forEach(item => {
                        lista.push({id: filtro.id, filtro: filtro.filtro, tipo: item.tipo, valor: item.valor});
                    });
                }
                else {
                    lista.push({id: filtro.id, filtro: filtro.filtro, tipo: 'N/A', valor: 'N/A'});
                }
                

            });
            setRows(lista);

            // data.dados.filtroItens

            // setRedeData(data.dado);
        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        }

        setProcessando(false);

    }

    const atualizarProdutos = async () => {
        if (processando)
            return;

        setProcessando(true);

        try {
            const {data} = await redeHttp.execProdutoUpdate(detail.id, projAtual.id);
            ShowAlert.info(data.mensagem);
        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        }

        setProcessando(false);
        ObterCategorias();
    }

    React.useEffect(() => {
        if (props.open === true) {
            ObterCategorias();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open])

    return (
    <Box>
        <TsDataGrid
                rows={rows}
                columns={columns}
                serverPage={0}
                serverPagesize={50}
                showAdd={false} 
                onChangePage={(value) => void(0) }
            />
        <Box dir="rtl">
            <TsButton
                disabled={processando}
                onClick={(e) => atualizarProdutos()}
            >Atualizar produtos</TsButton>
            <TsButton
                onClick={(e) => setDetail({open: false, id: 0, pageTitle: "Lista de redes"})}
                color='secondary'
            >Voltar</TsButton>

        </Box>
        
    </Box>
    )
}

export default RedeProdutoFiltro;