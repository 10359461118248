import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class LimiteHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.http = http;
    this.resource = resource;
    this.url = resource;
  }
}

const limiteHttp = new LimiteHttpResource(httpTsApi, 'limitador');

export default limiteHttp;
