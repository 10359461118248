export const toDateJson = (dt) => {
  if (dt === undefined) {
    return '';
  }

  const arr = dt.split('/');
  if (arr.length === 3) return `${arr[2]}-${arr[1]}-${arr[0]}T00:00:00`;
  return '';
};

export const exemploProgressivo = `[
    {"filtro": "qtde >= 3", "formula": "qtde * valor * 0.15"},
    {"filtro": "qtde >= 2", "formula": "qtde * valor * 0.12"},
    {"filtro": "qtde >= 1", "formula": "qtde * valor * 0.08"}
]`;

export const processMask = (str, mask) => {
  const re = /^[0-9]{1}$/g;
  let result = '';
  let iC = 0;
  let iM = 0;

  while (true) {
    if (iC >= str.length || iM >= mask.length) break;

    let c = str.charAt(iC);
    let m = mask.charAt(iM);

    if (m !== '0') {
      result = result + m;
      iM++;
      if (m === c) {
        iC++;
      }
    } else {
      re.lastIndex = 0;
      let test = re.test(c + '');

      if (c === undefined || c === '' || c === null || !test) {
        break;
      } else {
        iC++;
        iM++;
        result = result + c;
      }
    }
  }
  return result;
};

export const TxtDouble = (input) => {
  if (typeof input === 'number' && !isNaN(input)) {
    return input.toFixed(2);
  }
  if (!input || input === null) {
    return '';
  }

  if (typeof input === 'string') {
    try {
      let t = input;
      t = t.replace('.', '').replace(',', '');
      const n = parseFloat(t) / 100;
      return n.toFixed(2);
    } catch (error) {}
  }

  return input + '';
};

export const TxtInt = (input) => {
  if (typeof input === 'number' && !isNaN(input)) {
    return input + '';
  }
  if (!input || input === null) {
    return '';
  }
  return input;
};

export const TxtString = (input) => {
  if (!input || input === null) {
    return '';
  }

  if (typeof input === 'string') {
    return input + '';
  }

  return input;
};

export const formatNumber = (valor) => {
  let n = valor;
  if (typeof valor === 'string') {
    n = parseFloat(valor);
    if (n === 0) return '0,00';
    else if (isNaN(n)) return valor;
  }

  if (n || n === 0 || n === 0.0) {
    let v = n.toFixed(2);
    v = v.replace('.', ',');
    return v;
  } else {
    return valor;
  }
};

export const toNumber = (valor) => {
  if (typeof valor === 'number') {
    return isNaN(valor) ? undefined : valor;
  }

  const val = parseFloat(valor);

  if (typeof val === 'number') {
    return isNaN(val) ? undefined : val;
  }

  return undefined;
};

export const formatEan = (valor) => {
  // 007.891.268.400.113  == 007891268400113
  // 7891268400113
  valor = '' + valor;
  if (valor && valor.length >= 10) {
    valor = valor.padStart(15, '0');

    return (
      valor.substr(0, 3) +
      '.' +
      valor.substr(3, 3) +
      '.' +
      valor.substr(6, 3) +
      '.' +
      valor.substr(9, 3) +
      '.' +
      valor.substr(12, 3)
    );
  } else {
    return valor;
  }
};

export const formatCnpj = (valor) => {
  valor = '' + valor;
  if (valor && valor.length >= 10) {
    valor = valor.padStart(14, '0');
    // 00.000.000-0001/00  -- 00000000000100
    // 12 345 678 9012 34
    //                  12       .                 345      .                 678      /                 9012     -                 34
    return (
      valor.substr(0, 2) +
      '.' +
      valor.substr(2, 3) +
      '.' +
      valor.substr(5, 3) +
      '/' +
      valor.substr(8, 4) +
      '-' +
      valor.substr(12, 2)
    );
  } else {
    return valor;
  }
};

export const valorText = {
  fontSize: '1.8rem',
  fontWeight: 400,
  color: '#EEE',
};

export const labelTextBotton = {
  fontSize: '0.8rem',
  color: '#EEE',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  overflow: 'hidden',
};

export const labelTextTop = {
  fontSize: '0.8rem',
  color: '#EEE',
};

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
