import React from "react";

import {Box} from '@mui/material';
import { contentBarChar, tituloBarChart, tituloBarra, optionsBar } from "./dash-style";
import { Chart } from "react-google-charts";
import { monitorCSS } from "../../style";

const chartProps = {
    chartType: "BarChart",
    width: "100%",
    height: 360,
    legendToggle: true,
    options: {...optionsBar, title: ""},
}

 
// Detalhes por marca e produtos
const DashProdutos = (props) => {

    const {prodData, executing} = props;

    return (
        <Box>
            {prodData && <>
            <Box sx={tituloBarra}>Marca</Box>
            <Box className='dash-flex-container' sx={{margin: 1}}>
                <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                    <Box sx={tituloBarChart}>Quantidade de Vendas</Box>
                    <Box sx={contentBarChar}>
                        <Chart
                        {...chartProps}
                        data={prodData.marca_qtdeVendas} 
                        />
                    </Box>
                </Box>
                <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                    <Box sx={tituloBarChart}>Valor das Vendas (R$)</Box>
                    <Box sx={contentBarChar}>
                        <Chart
                        {...chartProps}
                        data={prodData.marca_valorVendas} 
                        />
                    </Box>
                </Box>
                <Box className='dash-flex-item-3 dash-bar-chart mh-2'>
                    <Box sx={tituloBarChart}>Desconto total das Vendas (R$)</Box>
                    <Box sx={contentBarChar}>
                        <Chart
                        {...chartProps}
                        data={prodData.marca_descontoVendas}
                        />
                    </Box>
                </Box> 
            </Box>
            <Box sx={tituloBarra}>Produto</Box>
            <Box className='dash-flex-container' sx={{margin: 1}}>
                <Box  className='dash-flex-item-3 dash-bar-chart mh-2'>
                    <Box sx={tituloBarChart}>Quantidade de Vendas</Box>
                    <Box sx={contentBarChar}>
                        <Chart
                        {...chartProps}
                        data={prodData.prod_qtdeVendas}
                        />
                    </Box>
                </Box>
                <Box  className='dash-flex-item-3 dash-bar-chart mh-2'>
                    <Box sx={tituloBarChart}>Valor das Vendas (R$)</Box>
                    <Box sx={contentBarChar}>
                        <Chart
                        {...chartProps}
                        data={prodData.prod_valorVendas}
                        />
                    </Box>
                </Box>
                <Box  className='dash-flex-item-3 dash-bar-chart mh-2'>
                    <Box sx={tituloBarChart}>Desconto total das Vendas (R$)</Box>
                    <Box sx={contentBarChar}>
                        <Chart
                        {...chartProps}
                        data={prodData.prod_descontoVendas}
                        />
                    </Box>
                </Box> 
            </Box>
            </>}
            {prodData === undefined 
            ? <Box sx={{minHeight: '300px'}}>...Processando</Box> 
            : <>{props.login === 'egoncalez' && <Box sx={{...monitorCSS, marginTop: 2}}>{JSON.stringify(prodData, null, 2)}</Box>}</>}
        </Box>
    );

}

export default DashProdutos;