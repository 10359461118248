import * as React from 'react';
import { Link } from '@mui/material';
import { ParticipanteDetalhe } from './ParticipanteDetalhe';

/* http */
import particiapnteHttp from '../../util/http/participante-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsDialog from '../../formulario/TsDialog';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';

// <TsDataGridx />
class Table extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;

        this.state.open = false;
        this.state.cpf = 0;
        this.state.nome = '';
        this.state.email = '';

        this.columns = [
            {
                name: "cpf",
                label: "CPF",
                format: ((value) => (<Link component="button" onClick={() => this.openDetail(value)}>{value}</Link>))
            },
            {
                name: "projetoId",
                label: "Projeto"
            },
            {
                name: "nome",
                label: "Nome"
            },
            {
                name: "sobrenome",
                label: "Sobrenome"
            },
    
            {
                name: "classificacao",
                label: "classificação"
            }
        ];
    }

    setCpf(cpf) {
        this.setState({cpf});
    }

    setNome(nome) {
        this.setState({nome});
    }

    setEmail(email) {
        this.setState({email});
    }

    setOpen(open) {
        this.setState({open});
    }

    Buscar = () => {
        this.iniciarBusca();

        const query = { page: this.state.serverPage, nrecPage: this.serverPageSize, projetoId: parseInt(this.projeto.id), ativo: true, busca: this.state.objBusca.busca };

        particiapnteHttp.busca(query)
            .then(({ data }) => {
                this.setRows(data.dados);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(particiapnteHttp.getUrl());
            });
    }

    openDetail = (value) => {
        const nCpf = parseFloat(value);

        if (!isNaN(nCpf)) {
            this.setCpf(nCpf);
            // console.log(this.state.rows);
            /*
            cadastroId: "e1a2848e1e82429b8646a5b4ae5cd4bd"
            classificacao: ""
            clienteStatus: "ativo"
            cpf: "62621419011"
            email: "teste.222@yopmail.com"
            id: "002da7203af94a43b19d265601040bd3"
            nome: "Thales"
            projetoId: 5000
            sobrenome: "Crisostomo"
            */
            const row = this.state.rows.find(r => r.cpf === value);
            if (row) {
                this.setNome(`${row.nome} ${row.sobrenome}`);
                this.setEmail(row.email);
            }
            else {
                this.setNome(`N/A`);
                this.setEmail(`N/A`);
            }
            
            this.setOpen(true);
        }

    };

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
    }

    load() {
        // React.useEffect(() => {
        //     Buscar();
        // }, [objetoBusca]);
    }
    
    render() {

        return (
            <div>
                <TsInputBusca
                    name="input-busca"
                    value={this.state.objBusca.busca}
                    showAtivo={true}
                    showBusca={true}
                    submitSearch={(texto, ativo) => { this.setObjBusca({...this.state.objBusca, ativo: ativo, busca: texto}); }}
                ></TsInputBusca>
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                />
                {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
                <TsBackDrop processando={this.state.processando} />
                <TsDialog onClose={() => this.setOpen(false)} id="dialog-detalhe-log" open={this.state.open} title={`Detalhes CPF: ${this.state.cpf}`} minWidth={800} >
                    {this.state.open && <ParticipanteDetalhe nome={this.state.nome} email={this.state.email} cpf={this.state.cpf} open={this.state.open} />}
                </TsDialog>
            </div>
        );
    }
};

export default Table;

