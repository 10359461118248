import * as React from 'react';
import { Page } from '../Page';
import { Form } from './Form';

const OfertaForm = (props) => {

    return (
        <Page title="Detalhes Oferta">
            <Form />
        </Page>
    );
};

export default OfertaForm;