import * as React from 'react';
import {TableHead, TableRow, styled} from '@mui/material';
import { Box, Typography, TableContainer, Table, TableBody } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { format } from 'date-fns';
import { mainTitleCSS } from '../../style';
import { formatNumber } from '../../util/Util';

let sumarioLines = [];


export const showDate = (valor, template) => {
    try {
        const date = new Date(valor);
        if (template === undefined) template = "dd/MM/yy HH:mm";
        return format(date, template);
    } catch (error) {
        return valor;
    }
    
}

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    },
// hide last border
    '&:last-child td, &:last-child th': {
    border: 0,
},
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

export const DetalhesTableTitle = (props) => {
    return (<TableHead>
        <TableRow>
            <StyledTableCell colSpan={2}>{props.title}</StyledTableCell>
        </TableRow>
    </TableHead>)
}

    
export const DetalhesAgregate = (props) => {
    // rows={rowsDetalhes}
    // rows=rowsDetalhes  tipo=categoria/marca/rede/produto
    /* 
            {
                "id": 75,
                "nsu": 13994,
                "dataTransacao": "2023-03-07T17:14:50",
                "cnpj": "05763950000191",
                "redeTsId": 2100,
                "rede": "Thailia",
                "cupom": "7494",
                "fechamentoId": "202302",
                "ean": "7891010518844",
                "produtoSkuId": 126,
                "produto": "Absorvente Externo Adapt Plus Noite e Dia Cobertura Suave Com Abas",
                "categoria": "Higiene e Saúde",
                "marca": "Sempre Livre",
                "industriaId": 1,
                "industria": "Johnson & Johnson",
                "ts4_clienteTransacaoId": 100,
                "quantidadeVendida": 1,
                "precoUnitario": 31.99,
                "precoTotal": 31.99,
                "descontoUnitario": 3.5,
                "descontoTotal": 3.5,
                "valDescZicard": 1.5
            }
        */
    const {rows, tipo} = props;
    const [items, setItems] = React.useState([]);

    const groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
        });
        return map;
    }

    const fillData = () => {
        let lista = {};
        const tmpItems = [];
        // categoria marca rede produto
        if (tipo === "marca") lista = groupBy(props.rows, item => item.marca);
        else if (tipo === "categoria") lista = groupBy(props.rows, item => item.categoria);
        else if (tipo === "rede") lista = groupBy(props.rows, item => item.rede);
        else if (tipo === "produto") lista = groupBy(props.rows, item => item.produto);

        if (lista.size) {
            let total = 0; 
            for (let [chave, item] of lista.entries()) {
                let desconto = 0;
                let texto = chave || '';
                texto = texto.length > 53 ? texto.substring(0, 50) + '...' : texto;
                item.forEach(el => { desconto += el.desconto;});
                if (isNaN(desconto)) break;
                total += desconto;
                tmpItems.push({chave: texto, desconto});
            }
            
            tmpItems.push({chave: 'TOTAL', desconto: total});
        }
        else {
            tmpItems.push({chave: 'TOTAL', desconto: 0.0});
        }
        setItems(tmpItems);
        // console.log("result group by", lista);
    }

    const appendLine = (linha) => {
        // const encoded = new TextEncoder("iso-8889-1",{ NONSTANDARD_allowLegacyEncoding: true }).encode(linha + "\r\n");
        const encoded = linha + "\r\n";
        sumarioLines.push(encoded);
    }

    
    // console.log(props.rows);
        
    // const grouped = groupBy(pets, pet => pet.type);

    React.useEffect(() => {
        fillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, tipo]);


    React.useEffect(() => {
        if (items && items.length > 0) {
            appendLine(`${props.title};valor`);
            items.forEach(it => {
                appendLine(`${it.chave};${formatNumber(it.desconto)}`);
            });
            appendLine("--------------------------------------------------------;-----------");
            appendLine("");
        }
    }, [items, props.title]);
        
 

    return (<TableBody>
        {items.map(it => (
            <StyledTableRow key={it.chave}>
                <StyledTableCell>{it.chave}</StyledTableCell>
                <StyledTableCell>R$ {formatNumber(it.desconto)}</StyledTableCell>
            </StyledTableRow>
        ))}
    </TableBody>)
}

export const DetalhesSoma = (props) => { 
    sumarioLines = [];
    sumarioLines.push(new Uint8Array([0xEF, 0xBB, 0xBF]));
    
    return (<>
        <Box><Typography sx={mainTitleCSS}>Controle de Rebate</Typography></Box>
        <Box>Periodo: {props.periodo}</Box>
        <Box>Fechamento em : {props.fechadoEm}</Box>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto"}}>
                <TableContainer sx={{margin: 2}} component={Box}>
                    <Table sx={{ width: 400 }} size="small" aria-label="por categoria">  
                        <DetalhesTableTitle title="Desconto por categoria" />
                        <DetalhesAgregate rows={props.rows} tipo="categoria" title="Desconto por categoria"></DetalhesAgregate> 
                    </Table>
                </TableContainer>
                <TableContainer sx={{margin: 2}} component={Box}>
                    <Table sx={{ width: 400 }} size="small" aria-label="por marca">
                        <DetalhesTableTitle title="Desconto por marca" />
                        <DetalhesAgregate rows={props.rows} tipo="marca" title="Desconto por marca"></DetalhesAgregate>
                    </Table>
                </TableContainer>
                <TableContainer sx={{margin: 2}} component={Box}>
                    <Table sx={{ width: 400 }} size="small" aria-label="por categoria">
                        <DetalhesTableTitle title="Desconto por rede" />
                        <DetalhesAgregate rows={props.rows} tipo="rede" title="Desconto por rede"></DetalhesAgregate>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{flex: "1 1 auto"}}>
                <TableContainer sx={{margin: 2}} component={Box}>
                    <Table sx={{ width: 600 }} size="small" aria-label="por produto">
                        <DetalhesTableTitle title="Desconto por produto" />
                        <DetalhesAgregate rows={props.rows} tipo="produto" title="Desconto por produto"></DetalhesAgregate>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
        </>)
    }
    