import {
    Box,
    IconButton,
    Select,
    FormControl,
    MenuItem
  } from '@mui/material'; 
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Pagging = (props) => {

    const {serverPage, serverPagesize, nrecs, pageSize, onChangeRowsPerPage, onChangePage, colSpan, fontSize, from, to, page} = props;
  
    return (<tfoot>
      <tr>
        <td colSpan={colSpan} >
        <Box fontSize={fontSize} fontWeight={400} sx={{ display: 'flex' }}>
                <Box sx={{ flex: '1 1 100%' }}>
                  serverPage: {serverPage} serverPagesize: {serverPagesize} nrecs: {nrecs}
                </Box>
                <Box lineHeight={2} sx={{ flex: '0 0 auto' }}>
                  registros por pagina
                </Box>
                <Box lineHeight={2} marginLeft={2} marginRight={3} sx={{ flex: '0 0 auto' }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                    <Select
                      labelId="pagesize-label"
                      id="pagesize-id"
                      value={pageSize}
                      onChange={(e) => onChangeRowsPerPage(e.target.value)}
                      label="pageSize"
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={200}>200</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box lineHeight={2} sx={{ flex: '0 0 auto' }}>
                  {from} - {to}
                </Box>
                <Box marginLeft={2} sx={{ flex: '0 0 auto' }}>
                  <IconButton onClick={() => onChangePage(page - 1)}>
                    <ArrowLeftIcon />
                  </IconButton>
                </Box>
                <Box marginLeft={2} sx={{ flex: '0 0 auto' }}>
                  <IconButton onClick={() => onChangePage(page + 1)}>
                    <ArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
  
        </td>
      </tr>
    </tfoot>);
  
  }
  export default Pagging;