// @flow 
import * as React from 'react';
import { Page } from '../Page';
import { Box } from '@mui/material';
import TableProduto from './table-produto';


const ProxyProduto = (props) => {
    return (
        <Page title="Listar produtos proxy">
            <Box>
                <TableProduto></TableProduto>
            </Box>

        </Page>
    );
};

export default ProxyProduto;