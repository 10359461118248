import * as React from 'react';
import { Box } from '@mui/material';
import TsButton from '../../formulario/TsButton';
import { TsTextField } from '../../formulario/TsTextField';
import SelectCampo from '../../formulario/SelectCampo';
import backlogHttp from '../../util/http/backlog-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError';
import { format, parseISO } from 'date-fns';
import { getUser } from '../../util/auth';
import ShowAlert from '../../util/ShowAlert';
import listaSituacoes from './situacoes';

const BackLogDetail = (props) => {

    const setDetail = props.setDetail;
    const detail = props.detail;
    const atual = getUser('admin'); 
    const [processando, setProcessando] = React.useState(false);
    const [backlogData, setBacklogData] = React.useState({});
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    
    /*
     Backlog backlog = new Backlog()
    {
        dataConclusao = dbItem.dataConclusao,
        dataInclusao = dbItem.dataInclusao,
        dataPrevisao = dbItem.dataPrevisao,
        descricao = dbItem.descricao,
        id = dbItem.id,
        percentual = dbItem.percentual,
        situacao = dbItem.situacao,
        titulo = dbItem.titulo,
        usuario = dbItem.usuario
    };

    List<BacklogHist> historio = dbItem.backlogHists.Select(h => new BacklogHist()
    {
       
    }).ToList();

    backlog.historico = historio;
    */

    const LimpaCampos = () => {
        setBacklogData({
            id: 0,
            dataConclusao: '',
            dataInclusao: '',
            dataPrevisao: '',
            descricao: '',
            percentual: 0,
            situacao: 'NEW',
            titulo: '',
            usuario: 'egoncalez',
            historico: []
            /*
                dataInclusao = h.dataInclusao,
                Id = h.Id,
                texto = h.texto,
                usuario = h.usuario
            */
        });
    }

    const Obter = async () => {
        if (processando) {
            return;
        }
        LimpaCampos();
        setProcessando(true);
        setError(undefined);
        
        try {
   
            const {data} = await backlogHttp.get(detail.id);
            if (data.sucesso === true && data.dado) {
                const dados = data.dado;
                dados.histUsuario = atual.login;
                dados.histTexto = "";

                setBacklogData(dados); 
            }
            else {
                setError("Nenhum registro encontrado");
            }
        } catch (error) {
            setError(error);
        } 
        setProcessando(false);
        setUrl(backlogHttp.getUrl());
    };

    const Atualizar = async () => {
        if (processando) {
            return;
        }
        setProcessando(true);
        setError(undefined);

        try {
            const {data} = detail.id === 0 ? 
                await backlogHttp.create(backlogData) :
                await backlogHttp.update(detail.id,  backlogData);
            
            if (data.sucesso === true) {
                setProcessando(false);
                setDetail({open: false, id: 0, pageTitle: "Lista de backlog"});
            }
            else {
                console.log("ERROR on return", data);
                ShowAlert.error("Erro ao atualizar registro");
                // setError(retorno);
            }
            
        } catch (error) {
            setError(error);
        }

        setProcessando(false);

    }

    React.useEffect(() => {
        if (detail.open === true) {
            if (detail.id) {
                Obter();
            }
            else {
                LimpaCampos();
            }
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]);

    return (<>
    <form>
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                <TsTextField
                    name="titulo"
                    label="titulo"
                    fullWidth
                    value={backlogData.titulo || ''}
                    disabled={false}
                    onChange={(e) => {setBacklogData({...backlogData, titulo: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                <SelectCampo
                    id='situacao'
                    label='situacao'
                    name='situacao'
                    disabled={false}
                    options={listaSituacoes}
                    value={backlogData.situacao || ''}
                    onChange={(e) => {setBacklogData({...backlogData, situacao: e.target.value})}}
                />
            </Box>
        </Box>
        <TsTextField
            name="descricao"
            label="descricao"
            fullWidth
            multiline={true}
            minRows={4}
            value={backlogData.descricao || ''}
            disabled={false}
            onChange={(e) => {setBacklogData({...backlogData, descricao: e.target.value})}}
        />

        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", padding: 2}}>
                <TsTextField
                    name="dataInclusao"
                    label="dataInclusao"
                    fullWidth
                    value={backlogData.dataInclusao || ''}
                    disabled={false}
                    onChange={(e) => {setBacklogData({...backlogData, dataInclusao: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", padding: 2}}>
                <TsTextField
                    name="dataConclusao"
                    label="dataConclusao"
                    fullWidth
                    value={backlogData.dataConclusao || ''}
                    disabled={false}
                    onChange={(e) => {setBacklogData({...backlogData, dataConclusao: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", padding: 2}}>
                <TsTextField
                    name="dataPrevisao"
                    label="dataPrevisao"
                    fullWidth
                    value={backlogData.dataPrevisao || ''}
                    disabled={false}
                    onChange={(e) => {setBacklogData({...backlogData, dataPrevisao: e.target.value})}}
                />
            </Box>
        </Box>


        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", padding: 2}}>
                <TsTextField
                    name="usuario"
                    label="usuario"
                    fullWidth
                    value={backlogData.usuario || ''}
                    disabled={false}
                    onChange={(e) => {setBacklogData({...backlogData, usuario: e.target.value})}}
                />
            </Box>
            <Box sx={{flex: "1 1 auto", padding: 2}}>
                <TsTextField
                    name="percentual"
                    label="percentual"
                    fullWidth
                    value={backlogData.percentual || ''}
                    disabled={false}
                    onChange={(e) => {setBacklogData({...backlogData, percentual: e.target.value})}}
                />
            </Box>
        </Box>
        <TsTextField
            name="histTexto"
            label="historico"
            fullWidth
            multiline={true}
            minRows={4}
            value={backlogData.histTexto || ''}
            disabled={false}
            onChange={(e) => {setBacklogData({...backlogData, histTexto: e.target.value})}}
        />
        <Box dir="rtl">
        
            <TsButton
                disabled={processando}
                onClick={(e) => Atualizar()}
            >Salvar backlog</TsButton>
            <TsButton
                onClick={(e) => setDetail({open: false, id: 0, pageTitle: "Lista de backlog"})}
                color='secondary'
            >Voltar</TsButton>

        </Box>
    </form>
    <div>
    {backlogData.historico && backlogData.historico.map((item) => {
        return (
            <Box key={item.id} sx={{display: "flex", padding: 1, margin: 1, border: '1px solid #777'}}>
                <Box sx={{flex: "0 0 auto", padding: 1}}>{format(parseISO(item.dataInclusao), 'dd/MM/yyyy')} : </Box>
                <Box sx={{flex: "0 0 auto", padding: 1}}>{item.usuario} : </Box>
                <Box sx={{flex: "1 1 auto", padding: 1}}>{item.texto}</Box>
            </Box>
            );
        })
    }
    </div>
   <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
    <TsBackDrop processando={processando} />
    </>)
}

export default BackLogDetail;
