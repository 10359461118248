import * as React from 'react';
import { Box } from '@mui/material';
import { TsTextField } from '../../formulario/TsTextField'; 

import redeHttp from '../../util/http/rede-http';
import TsBackDrop from '../../formulario/TsBackDrop';
import ShowError from '../../formulario/ShowError'; 
import TsButton from '../../formulario/TsButton';
import ShowAlert from '../../util/ShowAlert';
import ChaveBancaria from '../faturamento/chave-bancaria';
import SelectCampo from '../../formulario/SelectCampo';

let EstabelecimentoDetail_Processando = false;

const EstabelecimentoDetail = (props) => {

    const {detail, setDetail, userLevel} = props;

    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [executing, setExcecuting] = React.useState(false);
    const [estabelecimento, setEstabelecimento] = React.useState({cnpj: detail.cnpj, maskCnpj: detail.maskCnpj, tipo: detail.tipo, lojaId: detail.lojaId, redeId: detail.redeId});

    const setProcessando = (value) => {
        EstabelecimentoDetail_Processando = value;
        setExcecuting(value);
    }

    const Buscar = async () => {

        if (EstabelecimentoDetail_Processando === true || detail.cnpj === 0 || (detail.tipo === 'loja' && !detail.lojaId)) return;
 

        setProcessando(true);
        try {
            const retorno = await redeHttp.obterEstabelecimento(detail.cnpj, detail.tipo, detail.lojaId || "0");
            console.log('estabelecimento:', retorno.data);
            setEstabelecimento({...retorno.data} );
        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        }
        setProcessando(false);
    };

    const submitForm = async () => {
        if (EstabelecimentoDetail_Processando === true) return;
        const estab = {...estabelecimento};
        if (typeof(estab.comissaoFaturamento) === 'string')
            estab.comissaoFaturamento = Number(estab.comissaoFaturamento);
        
        if (isNaN(estab.comissaoFaturamento)) estab.comissaoFaturamento = 0.00;
        
        if (estab.tipo === 'loja') estab.comissaoFaturamento = 0.00;

        if (estab.comissaoFaturamento < 0 || estab.comissaoFaturamento > 0.01) {
                    ShowAlert.error(`Valor [${estab.comissaoFaturamento} - ${typeof(estab.comissaoFaturamento)}] da comissão inválida` );
                    return;
        }

        if (estab.cnpj === 0) {
            let numCnpj = Number(estab.maskCnpj.replace(/\./g, '').replace(/-/g, '').replace(/\//g, ''));
            if (!numCnpj || isNaN(numCnpj)) {
                ShowAlert.error("CNPJ Inválido");
                return;
            }
            estab.cnpj =numCnpj;
        }
        estab.redeId = Number(estab.redeId);
        if (estab.tipo === 'loja' && !estab.lojaId) {
            ShowAlert.error("Loja Inválida");
            return;
        }

        setProcessando(true);
        try {
            

            const retAtualizar = await redeHttp.estabelecimentoAtualizar(estab);

            if (retAtualizar.data.sucesso === false) {
                ShowAlert.info(retAtualizar.data.mensagem);
            }
            else {
                ShowAlert.info('Item atualizado com sucesso!');
                setDetail({...detail, open: false});
            }

        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        }
        setProcessando(false);
    };

    React.useEffect(() => {
        if (detail.open === true) {
            Buscar();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail])


    return(<>
        <form>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="cnpj"
                        label="CNPJ"
                        fullWidth
                        value={estabelecimento.maskCnpj || ''}
                        disabled={estabelecimento.cnpj + '' === '0' && userLevel > 2 ? false : true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, maskCnpj: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", width: "150px", paddingRight: 2}}>
                    <TsTextField
                        name="tipo"
                        label="Tipo"
                        fullWidth
                        value={estabelecimento.tipo + '' || ''}
                        disabled={true}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", width: "150px", paddingRight: 2}}>
                    <TsTextField
                        name="lojaId"
                        label="Loja (idPdv)"
                        fullWidth
                        value={estabelecimento.lojaId + '' || ''}
                        disabled={estabelecimento.tipo === 'loja' && userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, lojaId: e.target.value})}}
                    />
                </Box>
            </Box>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="terminais"
                        label="Terminais (separar por virgula)"
                        fullWidth
                        value={estabelecimento.terminais + '' || ''}
                        disabled={userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, terminais: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", width: "200px", paddingRight: 2}}>
                    <SelectCampo
                        id="estabelecimento-status"
                        label="Ativo"
                        name="ativo"
                        options={[{ text: 'Ativo', value: '1' },{ text: 'Inativo', value: '0' }]}
                        value={estabelecimento.ativo + '' || '1'}
                        onChange={(e) => setEstabelecimento({ ...estabelecimento,  ativo: e.target.value})}
                    />
                </Box>
            </Box>
            
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="nome"
                        label="Nome"
                        fullWidth
                        value={estabelecimento.nome || ''}
                        disabled={userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, nome: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", width: "200px", paddingRight: 2}}>
                    <TsTextField
                        name="cep"
                        label="Cep"
                        fullWidth
                        value={estabelecimento.cep || ''}
                        disabled={userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, cep: e.target.value})}}
                    />
                </Box>
            </Box>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="endereco"
                        label="Endereço"
                        fullWidth
                        value={estabelecimento.endereco || ''}
                        disabled={userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, endereco: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", width: "150px", paddingRight: 2}}>
                    <TsTextField
                        name="numero"
                        label="Número"
                        fullWidth
                        value={estabelecimento.numero || ''}
                        disabled={userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, numero: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", width: "200px", paddingRight: 2}}>
                    <TsTextField
                        name="complemento"
                        label="Complemento"
                        fullWidth
                        value={estabelecimento.complemento || ''}
                        disabled={userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, complemento: e.target.value})}}
                    />
                </Box>
            </Box>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="bairro"
                        label="Bairro"
                        fullWidth
                        value={estabelecimento.bairro || ''}
                        disabled={userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, bairro: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="cidade"
                        label="Cidade"
                        fullWidth
                        value={estabelecimento.cidade || ''}
                        disabled={userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, cidade: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", width: "200px", paddingRight: 2}}>
                    <TsTextField
                        name="uf"
                        label="Estado"
                        fullWidth
                        value={estabelecimento.uf || ''}
                        disabled={userLevel > 2 ? false: true}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, uf: e.target.value})}}
                    />
                </Box>
            </Box>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="nomeAdmin"
                        label="Nome Administrador"
                        fullWidth
                        disabled={userLevel > 2 ? false: true}
                        value={estabelecimento.nomeAdmin || ''}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, nomeAdmin: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="emailAdmin"
                        label="E-mail Administrador"
                        fullWidth
                        disabled={userLevel > 2 ? false: true}
                        value={estabelecimento.emailAdmin || ''}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, emailAdmin: e.target.value})}}
                    />
                </Box>
            </Box>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                    <TsTextField
                        name="foneAdmin"
                        label="Celular Administrador"
                        fullWidth
                        disabled={userLevel > 2 ? false: true}
                        value={estabelecimento.foneAdmin || ''}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, foneAdmin: e.target.value})}}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", width: '200px', paddingRight: 2}}>
                    <ChaveBancaria
                        name="chaveBancaria"
                        disabled={userLevel > 2 ? false: true}
                        userLevel={userLevel}
                        value={estabelecimento.chaveBancaria || ''}
                        onChange={(valor) => {setEstabelecimento({...estabelecimento, chaveBancaria: valor})}}
                    />
                </Box>
                <Box sx={{flex: "0 0 auto", width: '200px', paddingRight: 2}}>
                    <TsTextField
                        name="comissaoFaturamento"
                        label="Comissão"
                        fullWidth
                        disabled={userLevel > 2 ? false: true}
                        value={estabelecimento.comissaoFaturamento * 100 || '0'}
                        onChange={(e) => {setEstabelecimento({...estabelecimento, comissaoFaturamento: Number(e.target.value) / 100})}}
                    />
                </Box>
            </Box>
            <Box>(*) A comissão do estabelecimento nunca deve ser maior que a comissão da rede. ( máx 2% )</Box>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}></Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                    <TsButton 
                        onClick={(e) => setDetail({...detail, open: false})} 
                        color='secondary'
                    >Voltar</TsButton>
                </Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}}>
                    { userLevel > 2 &&
                    <TsButton onClick={(e) => submitForm()}
                    >Enviar</TsButton>
                    }
                </Box>
            </Box>
        </form>
    
        <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={executing} />
    </>);

}

export default EstabelecimentoDetail;