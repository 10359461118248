import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

export class FaturamentoHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.url = baseUrl;
    this.http = http;
    this.resource = resource;
  }

  sumario(fechamentoId) {
    this.url = `/${this.resource}/sumario/${fechamentoId} - GET`;
    return this.http.get(`${this.resource}/sumario/${fechamentoId}`, this.Config());
  }

  detalhe(fechamentoId, redeId, industriaId, softhouseId, distribuidorId, marca, categoria) {
    let url = `${this.resource}/detalhe/${fechamentoId}`;
    const parms = [];

    if (redeId) parms.push('redeId=' + redeId);
    if (industriaId) parms.push('industriaId=' + industriaId);
    if (softhouseId) parms.push('softhouseId=' + softhouseId);
    if (distribuidorId) parms.push('distribuidorId=' + distribuidorId);
    if (marca) parms.push('marca=' + encodeURI(marca));
    if (categoria) parms.push('categoria=' + encodeURI(categoria));

    if (parms.length > 0) {
      url = url + '?' + parms.join('&');
    }

    this.url = `/${url} - GET`;
    return this.http.get(url, this.Config());
  }

  executeFechamento(fechamentoId, projetoId) {
    this.url = `/${this.resource}/exec-fechamento/${projetoId}/${fechamentoId} - GET`;
    return this.http.get(`${this.resource}/exec-fechamento/${projetoId}/${fechamentoId}`, this.Config());
  }
  cancelFechamento(fechamentoId, projetoId) {
    this.url = `/${this.resource}/cancel-fechamento/${projetoId}/${fechamentoId} - GET`;
    return this.http.get(`${this.resource}/cancel-fechamento/${projetoId}/${fechamentoId}`, this.Config());
  }
}

const faturamentoHttp = new FaturamentoHttpResource(httpTsApi, 'faturamento');

export default faturamentoHttp;
