export const pedidoInicializado = {
  itens: [],
  rede: '',
  cnpj: '',
  cpf: '',
  nome: '',
  token: '',
  numSessao: 0,
  canal: 'PDV',
  sessaoAberta: false,
  cestaValida: false,
  tabIndex: 0,
  pedidoFinalizado: false,
  nsuTransacao: 0,
  codigoFinalizacao: '00',
  aprovar: 'false',
};
