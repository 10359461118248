// @flow 
import * as React from 'react';
import TsDataGrid from '../../formulario/TsDataGrid';
import classificacaoHttp from '../../util/http/classificacao-http'; 
import { Box, Fab } from '@mui/material';
import TsButton from '../../formulario/TsButton';
import TsBackDrop from '../../formulario/TsBackDrop';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ListaBase from '../ListaBase';
import ShowAlert from '../../util/ShowAlert';

// type Props = {
//     campanhaId: number;
// };

// <TsDataGridx />

class TableClass extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;

        this.state.listaLabel = [];
        this.state.adicionar = false;
        this.state.perfil = {id: '', label: ''};
        this.state.objBusca = {busca: '', campanhaId: this.props.campanhaId};

        this.columns = [
            {
                name: "campanhaChaveId",
                label: "Campanha"
                
            },
            {
                name: "classificacaoNome",
                label: "perfil"
            },
            {
                name: "classificacaoNome",
                label: "x",
                format: (value) => { return <Fab color='alert' size="small" aria-label="delete" onClick={(e) => this.deleteItem(value)}><DeleteForeverIcon /></Fab> }            
            }
        ];
    }

    setListaLabel(listaLabel) {
        this.setState({listaLabel});
    }

    setAdicionar(adicionar) {
        this.setState({adicionar});
    }

    setPerfil(perfil) {
        this.setState({perfil});
    }

    Buscar = () => {
        this.iniciarBusca();
        
        if (!this.props.campanhaId) {
            ShowAlert.error('A Campanha não foi especificada');
            return;
        }
 
        const query = {page: this.state.serverPage, nrecPage: this.serverPageSize, ativo: true, campanhaId: this.state.objBusca.campanhaId, busca: this.state.objBusca.busca};

        classificacaoHttp.busca(query)
        .then(({data}) => {
            this.setRows(data.dados);
        })
        .catch((error) => {
            this.setError(error);
        })
        .finally( () => {
            this.setProcessando(false);
            this.setBuscando(false);
            this.setUrl(classificacaoHttp.getUrl());
        }   
        );

        // setObjetoBusca({page: 0, nrecPage: 10, ativo: true, campanhaId: props.campanhaId});
    }

    AbrirFormulario = () => {
        this.setPerfil({id: "", label: ""});

        if (this.state.listaLabel.length === 0) {
            this.setProcessando(true);

            classificacaoHttp.list()
            .then((retorno) => {
                const tmp = retorno.data.dados;
                const ls = [];
                tmp.forEach((it) => ls.push({id: it.id.toString(), label: it.classificacaoNome}));
                this.setListaLabel(ls);
                this.setProcessando(false);
                this.setAdicionar(true);
            })
            .catch((error) => {
                ShowAlert.error('Erro ao processar request');
                 this.setProcessando(false);
            });
        }
        else {
            this.setAdicionar(true);
        }
 
        
    }

    AdicionarSubmit = () => {
        // processar 
        this.setProcessando(true);

        if (this.state.perfil.id === "" || this.state.perfil.label === "") {
            ShowAlert.error("Selecione ou digite um perfil");
            return;
        }

        const perfilId = parseInt(this.state.perfil.id);

        if (isNaN(perfilId)) {
            ShowAlert.error("PerfilId inválido");
             return;
        }

        const data = [{
            id: 0,
            campanhaChaveId: this.props.campanhaId,
            classificacaoId: parseInt(this.state.perfil.id),
            classificacaoNome: this.state.perfil.label
        }];

        classificacaoHttp.update(this.props.campanhaId, data)
        .then((retorno) => {
            this.setAdicionar(false);
            this.setQueryid(new Date().getDate() + '')
        })
        .catch((error) => {
            this.setError(error);
        })
        .finally(() => {
            this.setProcessando(false);
            this.setUrl(classificacaoHttp.getUrl())
        });
        
    }

    deleteItem = (value) => {
        this.setError(undefined);
        this.setProcessando(true);
        classificacaoHttp
            .deleteClassificacao(this.props.campanhaId, value)
            .then((retorno) => {
                ShowAlert.info('Deletado com sucesso com sucesso');
                this.setAdicionar(false);
                this.Buscar();
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setUrl(classificacaoHttp.getUrl());
            });

        this.setAdicionar(false);
    }

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);

        if (this.props.campanhaId !== prevProps.campanhaId) {
            this.setObjBusca({...this.state.objBusca, campanhaId: this.props.campanhaId});
        }

        if (this.state.execBuscar === true) this.Buscar();
    }
    
    render() {
        return (
            <>
            
            { this.state.adicionar && 
            <div>
                <form>
                    <Box sx={{display: "flex"}} padding={4}>
                        <Autocomplete
                            freeSolo
                            id="combo-box-perfil"
                            options={this.state.listaLabel}
                            value={this.state.perfil} 
                            onChange={(event, value) => {
                                if (value !== null) {
                                    this.setPerfil(value);
                                } 
                            }}
                            sx={{ flex: "8 1 auto", width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Perfil" value={this.state.perfil.label} 
                            onChange={(e) => this.setPerfil({id: "0", label: e.target.value})}  
                            />}
                        />
                    
                        <Box dir="rtl" sx={{flex: "1 0 auto"}}>
                            <TsButton
                                onClick={(e) => this.AdicionarSubmit()}
                            >Enviar</TsButton>
                            <TsButton 
                                onClick={(e) => this.setAdicionar(false)}
                                color='secondary'
                            >Cancelar</TsButton>
                        </Box>
                    </Box>    
                </form>
            </div>}
            <div>
                <Box>
                    <TsInputBusca
                        name="input-busca"
                        value={this.state.objBusca.busca}
                        showAtivo={false}
                        showAdd={true}
                        onClickAdd={(e) => this.AbrirFormulario()}
                        showBusca={true}
                        submitSearch={(texto, ativo) => {
                            this.setObjBusca({...this.state.objBusca, ativo: ativo, busca: texto});
                        }}
                    ></TsInputBusca>
                </Box>
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                />
            
            </div>
            <TsBackDrop processando={this.state.processando} />
            <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />
            </>
        );
    }
};

export default TableClass;