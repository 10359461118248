import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from '.';
import ShowAlert from '../util/ShowAlert';
import { useSnackbar } from 'notistack';

const AppRouter = (props) => {
  const setOpenPerfil = props.setOpenPerfil;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    ShowAlert.setSnackBar(enqueueSnackbar, closeSnackbar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {routes.map((route, key) => (
        <Route key={key} path={route.path} element={<route.element setOpenPerfil={(value) => setOpenPerfil(value)} />} />
      ))}
    </Routes>
  );
};

export default AppRouter;
