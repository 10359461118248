/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { subTitleCSS, monitorCSS } from '../style';
import ShowAlert from '../util/ShowAlert';


const ShowError = (props) => {
  const { error, url, mensagemPadrao, mode } = props;
  const [mensagem, setMensagem] = React.useState('');
  const [show, setShow] = React.useState(false);
  
  const mostrarErro = () => {

    if (mode === "debug") {
      setShow(true);
      if (typeof(error) === 'object') {
        setMensagem(JSON.stringify(error, null, 2));
      }
      else {
        setMensagem(error + '');
      }
      return;
    }

    let msg = mensagemPadrao; 

    if (!error) {
      return;
    }
    
    if (error.mensagem && (error.retCod === 404 || error.retCod === 400) ) {
      ShowAlert.error(error.mensagem);
      return;
    }

    if (error.mensagem) {
      msg = error.mensagem;
    } 
    else if (error.retCod === -1) {
      msg = 'Sem resposta do servidor';
    } 
    else if (error.retCod >= 500) {
      msg = 'Falha interna do servidor';
    } 
    else {
      msg = error.message;
    }

    if (!msg) {
      msg = mensagemPadrao;
    }

    setShow(true);
    const u = url ? url : 'undefined';

    msg = `
mensagem: ${msg}
url: ${u}
#######################################################################
${JSON.stringify(error, null, 2)}
#######################################################################
    `;
 
    setMensagem(msg);
  };

  React.useEffect(() => {
    if (error !== undefined) mostrarErro();
  }, [error]);

    return (
      <>
        {show && <>
        <Typography sx={subTitleCSS}>ERRO AO PROCESSAR REQUEST</Typography>
        <Box sx={monitorCSS}>{mensagem}</Box>
        </>}
      </>
    );
  
};

export default ShowError;
