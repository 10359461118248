export const listaContratoSignerTipo = [
  { value: '1', text: 'Representante legal' },
  { value: '2', text: 'Fornecedor' },
  { value: '3', text: 'Testemunha' },
  { value: '4', text: 'Sócio' },
];

export const listaTipoEmpresa = [
  { value: '1', text: 'Varejista simples', path: 'varejo', pageTitle: 'Cadastro Varejista' },
  { value: '2', text: 'Varejista rede', path: 'rede', pageTitle: 'Cadastro Rede' },
  { value: '3', text: 'Distribuidor', path: 'distribuidor', pageTitle: 'Cadastro Distribuidor' },
  { value: '4', text: 'Automação', path: 'automacao', pageTitle: 'Cadastro Automação' },
  { value: '5', text: 'Estabelecimento', path: 'estabelecimento', pageTitle: 'Cadastro Estabelecimento' },
];
