import * as React from 'react';
import { Box } from '@mui/material';
import { TsTextField } from '../../formulario/TsTextField';
import { preWrapCSS } from '../../style';
import { getProjetoStorage } from '../../util/auth';

const titleCSS = {
    background: "#000099",
    color: "#ccc",
    padding: "10px",
    textAlign: "center",
    margin: "4px"
};

 

const ConsultarIdentificacao = (props) => {

    const {pedido, setPedido} = props;
    const projeto = getProjetoStorage();

    return (<Box>

        {pedido.tabIndex === 2 && <Box sx={titleCSS}>ABRIR SESSÃO</Box>}
        {pedido.tabIndex === 5 && <Box sx={titleCSS}>CADASTRAR CPF</Box>}
        <TsTextField
            name="cpf"
            label="CPF"
            value={pedido.cpf}
            onChange={(e) => {
                const valor = e.target.value;
                setPedido(pedido => ({...pedido, cpf: valor}));
            }}
        />
        {pedido.tabIndex === 5 &&
            <TsTextField
                name="nome"
                label="Nome"
                value={pedido.nome}
                onChange={(e) => {
                    const valor = e.target.value;
                    setPedido(pedido => ({...pedido, nome: valor}));
                }}
            />}
        {pedido.tabIndex === 2 &&
            <Box paddingLeft={4} sx={{ ...preWrapCSS, fontSize: 12 }}>
                {pedido.sessaoAberta ?
                    "Sessão está aberta, pressione continuar" :
                    <>Json PARCIAL do request:<br />{JSON.stringify({ credenciais: [{ identificacao: pedido.cpf, idProjeto: parseInt(projeto.id), tipoCaptura: 1 }] }, null, 4)}</>
                }
            </Box>
        }
    </Box>);

}

export default ConsultarIdentificacao;