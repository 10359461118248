import * as React from 'react';

/* http */
import { httpProxy } from '../../util/http/proxy-http';
import relatorioHttp from '../../util/http/relatorio-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
// import { HOMEDIR } from '../../util/auth';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import {getProjetoStorage} from '../../util/auth';
import {Box, Link} from '@mui/material';
import SelectCampo from '../../formulario/SelectCampo';

let Prx_TableCampanha_processando = false;

 

const  TableCampanha = (props) => {

    const columns = [
        {
            name: "campanhaId",
            label: "#",
            format: (value) => { return <Link component="button" onClick={(e) => setObjBusca({...objBusca, busca: value})} >{value}</Link>}
        },
        {
            name: "campanha",
            label: "Campanha"
        },
        { name: "origem", 
          label: "Origem"
        },
        {
            name: "uf",
            label: "UF"
        },
        {
            name: "redeId",
            label: "Rede",
            format: (value) => { return <Link component="button" onClick={(e) => setRedeId(value)} >{value}</Link>}
        },
        {
            name: "cnpj",
            label: "CNPJ",
            format: (value) => { return <Link component="button" onClick={(e) => setObjBusca({...objBusca, busca: value})} >{value}</Link>}
        }
    ];

    const projAtual = getProjetoStorage();

    const [rows, setRows] = React.useState([]);
    const [executing, setExeccuting] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState('');
    const [objBusca, setObjBusca] = React.useState({busca: ''});
    const [redeId, setRedeId] = React.useState('');
    const [serverPage, setServerPage] = React.useState(0);
    const [lstRedes, setLstRedes] = React.useState([]);

    const setProcessando = (value) => {
        Prx_TableCampanha_processando = value;
        setExeccuting(value);
    }

    const serverPageSize = 50;
    

    const Buscar = async () => {

        if (Prx_TableCampanha_processando === true)
            return;
         
        setRows([]);
        setProcessando(true);
        try {
            const retorno = await httpProxy.buscaCampanha(projAtual.id, redeId, objBusca.busca);
            if (retorno.sucesso === true && retorno.data !== undefined) {
                setRows(retorno.data);
            } else {
                setRows([]);
            }
        } catch (error) {
            setUrl(httpProxy.getUrl());
            setError(error);
        }
        
        setProcessando(false);
    }

    const carregarRedes = async () => {
        try {
            const {data} = await relatorioHttp.obterLista('rede', projAtual.id);
            const lista = [
                {text: '...Selecione', value: ''},
                ...data
            ];
            setLstRedes(lista);
        } catch (error) {
            setError(error);
        }
    }

    React.useEffect(() => {

        if (lstRedes.length === 0) {
            carregarRedes();
        }

        Buscar();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objBusca, redeId]);

    return (<>
        <TsInputBusca
            name="input-busca"
            value={objBusca.busca}
            showAtivo={true}
            showBusca={true}
            submitSearch={(texto, ativo) => setObjBusca({...objBusca, busca: texto, ativo: ativo})}
        >
            <Box sx={{flex: '0 0 auto', paddingRight: 2}}>
                    <SelectCampo
                        id="TsInputBusca-Rede"
                        label="Rede"
                        name="redeId"
                        options={lstRedes}
                        value={redeId || ''}
                        onChange={(e) => setRedeId(e.target.value)}
                        />
                    </Box>
        </TsInputBusca>
        <TsDataGrid
            rows={rows}
            columns={columns}
            queryid={''}
            serverPage={serverPage}
            serverPagesize={serverPageSize}
            onChangePage={(value) => setServerPage(value)}
        />
        <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={executing} />
    </>
    );

}

export default TableCampanha;