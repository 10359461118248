import * as React from 'react';
import { Box } from '@mui/material';
import { monitorCSS } from '../../style';
import TsDialog from '../../formulario/TsDialog';
import ShowError from '../../formulario/ShowError';
import TsTextReadOnly from '../../formulario/TsTextReadOnly';
import TsButton from '../../formulario/TsButton';
/* http */
import transacaoHttp from '../../util/http/transacao-http';
import { httpAutorizadorV4 } from '../../util/http/autorizador-http';
import { getUser } from '../../util/auth';
import ShowAlert from '../../util/ShowAlert';

let TransacaoDetalhe_executing = false;


const TransacaoDetalhe = (props) => {

    const [detalhes, setDetalhes] = React.useState("");
    const [transacao, setTransacao] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [enaCancelar, setEnaCancelar] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState(undefined);
    const [txtCancelar, setTxtCancelar] = React.useState('cancelar transação');
    const {Id, setProcessando} = props;
    const usuario = getUser('powerbi');

    const permit = usuario.userLevel;


    // serviceApi

 
    const cancelarTransacao = async () => {

        if (transacao.status !== 'PROCESSADA') {
            ShowAlert.error('STATUS - Transacao nao pode ser cancelada');
            return;
        }

        if (!transacao.nsuEnviado) {
            ShowAlert.error('NSUENVIADO - Transacao nao pode ser cancelada');
            return;
        }

        if (txtCancelar !== 'confirmar') {
            setTxtCancelar('confirmar');
            ShowAlert.error('Confirme o cancelamento');
            return;
        }

        if (txtCancelar === 'confirmar') {

            setProcessando(true);
            try {
                setTxtCancelar(' ... processando');
                const token = await gerarToken();
                if (!token) {
                    setTxtCancelar('confirmar');
                    ShowAlert.error('Erro ao gerar token no proxy');
                    return;
                }
                const ret = await AtualizarStatus(token, transacao.nsuEnviado);
                setProcessando(false);
                if (ret.sucesso === true) {
                    ShowAlert.error({tipo: 'info', mensagem: 'status atualizado com sucesso'});
                }
                else {
                    setUrl(httpAutorizadorV4.getUrl());
                    setError(ret);
                }
            } catch (error) {
                setProcessando(false);
                setUrl(httpAutorizadorV4.getUrl());
                setError(error);
            } 

            setProcessando(false);
            openDetail();

        }

    }

 
    
    const openDetail = () => {
        if (TransacaoDetalhe_executing) return;
        TransacaoDetalhe_executing = true;
        setTxtCancelar('nop');
        setEnaCancelar(false);
        setError(undefined);
        setProcessando(true);
        setDetalhes(`... obtendo detalhes da transação ${Id} , aguarde`);

        transacaoHttp.get(Id)
            .then(({ data }) => {
                if (data && data.transacaoRecebida && data.transacaoRecebida.registroOriginal) {
                    try {
                        data.transacaoRecebida.registroOriginal = JSON.parse(data.transacaoRecebida.registroOriginal);
                    }
                    catch (e) {

                    }
                }
                setOpen(true);
                setDetalhes(JSON.stringify(data, null, 4));
                try {
                    if (data.transacaoRecebida.statusTransacao === 'PROCESSADA') {
                        setEnaCancelar(true);
                        setTxtCancelar('cancelar transação');
                    }
                    else {
                        setEnaCancelar(false);
                        setTxtCancelar('nop');
                    }
                    const item = data.transacaoRecebida.itens.find(it => it.id === data.transacaoCliente.transacaoRecebidaItemId)
                    setTransacao({
                        id: data.transacaoCliente.id || 0,
                        cpf: data.transacaoRecebida.cpf,
                        cnpj: data.transacaoCliente.cnpj,
                        rede: data.transacaoRecebida.redeId,
                        qtde: data.transacaoCliente.qtde,
                        numCupom: data.transacaoCliente.numCupom,
                        terminal: data.transacaoCliente.terminalTransacao,
                        produtoCodigo: item?.produtoCodigo,
                        desconto: data.transacaoCliente.desconto,
                        descontoProxy: item?.descontoProxy,
                        status: data.transacaoRecebida.statusTransacao,
                        sucesso: data.transacaoRecebida.sucesso,
                        mensagem: data.transacaoRecebida.mensagem,
                        msgProxy: item?.msgProxy,
                        nsuEnviado: data.transacaoRecebida.nsuEnviado,
                    });
                    
                } catch (error) {
                    
                }
                

            })
            .catch((error) => {
                setUrl(transacaoHttp.getUrl());
                setOpen(false);
                setError(error);
            })
            .finally(() => {
                TransacaoDetalhe_executing = false;
                setProcessando(false);
            });
    };

    const gerarToken = async () => {
         
        const http = httpAutorizadorV4;
        const data = { login: usuario?.login, senha: usuario?.senha, cnpj: transacao.cnpj, terminalId: 'PAINEL' };
        let ret = await http.entrarAsync(data);
        
       
        if (ret.sucesso === false || !ret.data) {
            return undefined;
        }

        if (ret.data.token === undefined || ret.data.token.length === 0) {
            return undefined;
        }

        return ret.data.token;

    };

    const AtualizarStatus = async (token, nsuEnviado) => {
        
        
        const http = httpAutorizadorV4;
        const ret = await http.atualizarStatusTransacao(token, nsuEnviado, false);
        return ret;
    };
    

    React.useEffect(() => {
        if (Id) {
            openDetail();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Id]);

     

    return (
    
    <TsDialog onClose={() => setOpen(false)} id="dialog-detalhe-log" open={open} title="Detalhes do log" minWidth={800} >
        {!error && transacao?.cpf && permit > 3 && <>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.cpf} label="cpf" /></Box>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.rede} label="rede" /></Box>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.cnpj} label="cnpj" /></Box>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.terminal} label="terminal" /></Box>
            </Box>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.numCupom} label="num-cupom" /></Box>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.qtde} label="qtde" /></Box>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.produtoCodigo} label="codigo" /></Box>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.status} label="status" /></Box>
            </Box>
            <Box sx={{display: "flex"}}>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.desconto} label="desconto" /></Box>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.descontoProxy} label="desconto-proxy" /></Box>
                <Box sx={{flex: '1 1 auto'}}><TsTextReadOnly value={transacao.msgProxy} label="msg-proxy" /></Box>
                <Box sx={{flex: '1 1 auto', padding: 2}}> { enaCancelar ?
                    <TsButton onClick={(e) => cancelarTransacao()} >{txtCancelar}</TsButton>
                    : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</> }
                </Box>
            </Box>
            </>
            }
        {!error && <Box sx={monitorCSS}>{detalhes}</Box>}
        <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
    </TsDialog>
    );
}

export default TransacaoDetalhe;