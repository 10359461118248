import { Box, Tab, Tabs} from '@mui/material';
import * as React from 'react';
import { Page } from '../Page';
import RedeList from './rede-list';
import RedeDetail from './rede-detail';
import EstabelecimentoList from './estabelecimento-list';
import { getProjetoStorage, getUser } from '../../util/auth';
import relatorioHttp from '../../util/http/relatorio-http';

let RedePage_carregandoLista = false;

const RedePage = () => {
    const [detail, setDetail] = React.useState({open: false, id: 0, pageTitle: "Lista de redes"});
    const projAtual = getProjetoStorage();
    const [objBusca, setObjBusca] = React.useState({busca: '', projetoId: Number(projAtual.id), softhouseId: '', listaSofthouse: []}); 
    const [tabIndex, setTabIndex] = React.useState(0);
    const atual = getUser('rede');

    const carregarSofthouse = async () => {
 
        RedePage_carregandoLista = true;
        try {
            const {data} = await relatorioHttp.obterLista('softhouse', projAtual.id);
            const lista = [];

            if (data.length > 1) {
                setObjBusca({...objBusca, softhouseId: ''});
                lista.push({ text: 'Selecione', value: '' });
            }
            else if (data.length === 1) {
                setObjBusca({...objBusca, softhouseId: data[0].value + ''});
            }
            data.forEach(item => {
                lista.push({text: item.text, value: Number(item.value) + ''});
            });
            
            setObjBusca({...objBusca, listaSofthouse: lista}); 
            
        } catch (error) {
            console.error('erro ao carregar softhouse');
            console.error(error);
        }
        RedePage_carregandoLista = false; 
    }
    

        React.useEffect(() => {
            if ((objBusca.listaSofthouse === undefined || objBusca.listaSofthouse.length === 0) && RedePage_carregandoLista === false) {
                carregarSofthouse();
            }
             
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);


    return (
        <Page title={detail.pageTitle}>
            <Box>
                {detail.open === false ? 
                <>
                <Box>
                    <Tabs value={tabIndex} onChange={(e, page) => setTabIndex(page)} aria-label="Opções da busca">
                        <Tab label="Redes" id="rede-tab-0" aria-labelledby={`rede-tabpanel-0`} />
                        <Tab label="Estabelecimentos" id="rede-tab-1" aria-labelledby={`rede-tabpanel-1`} /> 
                    </Tabs>
                </Box>
                {tabIndex === 0 && <><RedeList setDetail={setDetail}  detail={detail} userLevel={atual.userLevel} objBusca={objBusca} setObjBusca={setObjBusca} softhouse={0}></RedeList></>}
                {tabIndex === 1 && <><EstabelecimentoList setDetail={setDetail} listaSofthouse={objBusca.listaSofthouse}  detail={detail} userLevel={atual.userLevel} /></>}
                </> : 
                <RedeDetail setDetail={setDetail} detail={detail} userLevel={atual.userLevel}></RedeDetail>}
            </Box>
        </Page>
    );
};

export default RedePage;
