// @flow
import { Box, MenuItem, TextField } from '@mui/material';
import * as React from 'react';

const formControlCSS = {
  margin: 1,
  minWidth: 120,
};

// type Props = {
//   name: string;
//   label: string;
//   value?: any;
//   id: string;
//   options: any[];
//   onChange?: (event: any) => void;
//   disabled?: boolean;
// };

const SelectCampo = (props) => {
  const { name, label, value, id, options, sx } = props; // debug
  const debug = props.debug !== undefined ? props.debug : false;
  const [valor, setValor] = React.useState('');

  React.useEffect(() => {
    let val = value;
    if (val === undefined || val === null || val === 'undefined') val = '';
    val = val + '';
    if (options && options.length > 0) {
      if (options.find(opt => opt.value + '' === val) === undefined) {
        val = options[0].value;
      }
    }
    setValor(val);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);



  if (debug === true) {

    return (
      <Box>
        <TextField
            sx={{...sx, ...formControlCSS}}
            value={valor}
            onChange={(e) => props.onChange(e)}
            select // tell TextField to render select
            label={label}
            id={id}
            name={name}
            disabled={props.disabled}
            fullWidth
            >
            {options?.map((item, i) => {
                // return (<option key={i} value={item.valor}>{item.label}</option>)
                return (<MenuItem key={i} value={item.value || ''}>
                  {item.text}
                </MenuItem>)
            })}
        </TextField>
        <Box sx={{fontSize: '9px'}}>
          valor: {value}<br />datatype: {typeof(value)}<br />lista: {JSON.stringify(options)}
        </Box>
      </Box>

    );

  }
  else {

    return (
      <TextField
          sx={{...sx, ...formControlCSS}}
          value={valor}
          onChange={(e) => props.onChange(e)}
          select // tell TextField to render select
          label={label}
          id={id}
          name={name}
          disabled={props.disabled}
          fullWidth
          >
          {options?.map((item, i) => {
              // return (<option key={i} value={item.valor}>{item.label}</option>)
              return (<MenuItem key={i} value={item.value || ''}>
                {item.text}
              </MenuItem>)
          })}
      </TextField>

    );

    /*
    return (
      <FormControl fullWidth sx={{...sx, ...formControlCSS}}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          native
          labelId={labelId}
          label={label}
          id={id}
          name={name}
          value={value || '_'}
          displayEmpty={true}
          disabled={props.disabled}
          onChange={(e) => {
            if (e.target.value === '_')
            e.target.value = '';
            props.onChange(e)
          }}
        >
          {options.map((item, i) => {
            return (
              <option key={i} value={item.value || '_'}>
                {item.text}
              </option>
            );
          })}
        </Select>
      </FormControl>
    );
    */

  }

  
};

export default SelectCampo;
