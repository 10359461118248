/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Box, Checkbox, FormControlLabel, Tab, Tabs } from '@mui/material';
import TsButton from '../../formulario/TsButton';
import SelectCampo from '../../formulario/SelectCampo';
import { TsTextField } from '../../formulario/TsTextField';
import ServiceApi from './ServiceApi';

const FlowControl = (props) => {

  const {pedido, setPedido, showInfo, handleIndex} = props;
  const [operacao, setOperacao] = React.useState(undefined);
  const [executeId, setExecuteId] = React.useState(undefined);
  const [operacaoDisable, setOperacaoDisable] = React.useState(true);
  const [botaoEnviar, setBotaoEnviar] = React.useState({text: 'Enviar', color: 'primary'})
  const [debug, setDebug] = React.useState(false);
   
  const resetPedido = (indice) => {
    const ped = { ...pedido };

    ped.tabIndex = indice;
    ped.pedidoFinalizado = false; 
    showInfo('cls', '');

    if (indice === 0) {
      setPedido(pedido => ({ ...ped, cpnj: '' }));
      showInfo('debug', ' ... SELECIONE UM CNPJ, PARA INICIALIZAR O SIMULADOR');
    }
    if (indice === 1) {
      showInfo('debug', ' ... ABRIR O PDV, ESSE METODO GERA UM TOKEN PARA QUE O SIMULADOR SE COMUNIQUE COM O PROXY');
    } else if (indice === 2) {
      showInfo('debug', ' ... ABRA A SESSÃO, ISSO GERA UM NUMERO DE CUPOM E INDICA O CPF DESSA COMPRA');
    } else if (indice === 3) {
      showInfo('debug', ' ... ADICIONE PRODUTOS A CESTA, ISSO VAI CHAMAR CONSULTAR PRODUTOS NO PROXY');
    } else if (indice === 4) {
      showInfo('debug', ' ... CONCLUA O PEDIDO, ISSO VAI CHAMAR REGISTRA DESCONTOS');
    } else if (indice === 5) {
      showInfo('debug', ' ... ESSE MÉTODO DEVE SER USADO APENAS NO SIMULADOR PARA ADICIONAR UM CPF AO PROGRAMA');
    }

    if (indice <= 1) {
      // fechar pdv
      ped.token = '';
    }

    if (indice <= 2) {
      // fechar sessao
      ped.nome = '';
      ped.cpf = '';
      ped.numSessao = 0;
      ped.sessaoAberta = false;
    }

    if (indice <= 3) {
      // reset cesta
      ped.itens = [];
      ped.cestaValida = false;
    }

    setPedido(pedido => (ped));

    return;
  };

  const handleTabPanel = (newValue) => {
        if (newValue >= 1 && !pedido.cnpj) {
          showInfo('pop-error', 'CNPJ não foi selecionado');
          return;
        }
    
        if (newValue >= 2 && newValue < 5 && !pedido.token) {
          showInfo('pop-error', 'PDV não está aberto');
          return;
        }
    
        if (newValue >= 3 && newValue < 5 && !pedido.sessaoAberta) {
          showInfo('pop-error', 'Sessão não foi aberta');
          return;
        }
    
        if (newValue === 4 && !pedido.cestaValida) {
          showInfo('pop-error', 'A cesta não está válida');
          return;
        }
    
        resetPedido(newValue);
        return;
  };

  
  const EnviarRequest = () => {

       if (operacao === 'continue') {

        if (pedido.tabIndex === 0) {
            return handleTabPanel(1);
        } else if (pedido.tabIndex === 1) {
            return pedido.token ? handleTabPanel(2) : changeOperacao('entrar');
        } else if (pedido.tabIndex === 2) {
            return pedido.sessaoAberta ? handleTabPanel(3) : changeOperacao('consultar_identificacao');
        } else if (pedido.tabIndex === 3) {
            return pedido.cestaValida ? handleTabPanel(4) : changeOperacao('consultar_desconto');
        } else if (pedido.tabIndex === 4) {
            if (pedido.pedidoFinalizado) {
            return handleTabPanel(2);
            } else {
            return pedido.cestaValida ? changeOperacao('registrar_descontos') : undefined;
            }
        } else if (pedido.tabIndex === 5) {
            return changeOperacao('inserir_identificacao');
        }

       }
       else {
        setExecuteId(new Date().getTime());
       }
        
  };

  const changeOperacao = (op) => {
 

    setOperacao(op);
    if (pedido.tabIndex === 3 || pedido.tabIndex === 4) {
      if (op === 'continue') setBotaoEnviar({text: '... continue', color: 'success'});
      else setBotaoEnviar({text: 'Processar', color: 'error'});
      setOperacaoDisable(false);
    }
    else if (op === 'continue') {
      setBotaoEnviar({text: '... continue', color: 'success'});
      setOperacaoDisable(true);
    }
    else if (pedido.tabIndex === 0) { 
      setBotaoEnviar({text: 'Processar', color: 'error'});
      setOperacaoDisable(true);
    }
    else {

      if (op === 'consultar_identificacao' && pedido.tabIndex !== 2
      ) setPedido({...pedido, tabIndex: 2});

      else if (op === 'inserir_identificacao' && pedido.tabIndex !== 5
      ) setPedido({...pedido, tabIndex: 5});
       
      else if (op === 'entrar' && pedido.tabIndex !== 1
      ) setPedido({...pedido, tabIndex: 1});

      else if ((op === 'registrar_descontos' || 
          op === 'atualizar_status_transacao' ||
          op === 'confirmar_descontos') && pedido.tabIndex !== 4
        ) setPedido({...pedido, tabIndex: 4});

        else if (op === 'consultar_desconto' && pedido.tabIndex !== 3
      ) setPedido({...pedido, tabIndex: 3});

      setBotaoEnviar({text: 'Processar', color: 'error'});
      setOperacaoDisable(false);
    }
  }
    
  React.useEffect(() => {
        // error success
        if (pedido.tabIndex === 0) {
            changeOperacao('continue');
        } else if (pedido.tabIndex === 1) {
            pedido.token
            ? changeOperacao('continue')
            : changeOperacao('entrar');
        } else if (pedido.tabIndex === 2) {
            pedido.sessaoAberta
            ? changeOperacao('continue')
            : changeOperacao('consultar_identificacao');
        } else if (pedido.tabIndex === 3) {
          pedido.cestaValida
            ? changeOperacao('continue')
            : changeOperacao('consultar_desconto');
            
        } else if (pedido.tabIndex === 4) {
            
            pedido.pedidoFinalizado
            ? changeOperacao('continue')
            : changeOperacao('registrar_descontos');

        
        } else if (pedido.tabIndex === 5) {
          changeOperacao('inserir_identificacao');
        }
  }, [pedido]);
    
  React.useEffect(() => {
        if (handleIndex !== undefined && handleIndex > 0)
            handleTabPanel(handleIndex)
  }, [handleIndex]);

  const opcoesOperacao = () => { 

    const nullReturn = ['continue', 
    'entrar', 
    'inserir_identificacao', 
    'consultar_identificacao', 
    'consultar_desconto',
    'registrar_venda'
  ];

  if (!operacao || nullReturn.indexOf(operacao) >= 0) {
    return null;
  }
    
  return (<>
  <Box sx={{width: "100%"}}>Opções especiais: {operacao}</Box>  
  <Box sx={{ display: 'flex' }}>
      {operacao === 'atualizar_status_transacao' &&
              <>
                <Box paddingLeft={4} sx={{width: 140}}><TsTextField
                    label="NsuTransacao"
                    name="nsutransacao"
                    value={pedido.nsuTransacao}
                    onChange={(e) => {
                        setPedido(pedido => ({ ...pedido, nsuTransacao: e.target.value}));
                    }}
                     
                /></Box>
                <Box paddingLeft={4} sx={{width: 140}}><TsTextField
                    label="Aprovar"
                    name="aprovar"
                    value={pedido.aprovar}
                    onChange={(e) => {
                        setPedido(pedido => ({ ...pedido, aprovar: e.target.value}));
                    }}
                     
                /></Box>
              </> }
      {operacao === 'registrar_descontos' && 
        <>  
        <Box sx={{width: 140}}><TsTextField
            label="codigoFinalizacao"
            name="codigoFinalizacao"
            value={pedido.codigoFinalizacao}
            onChange={(e) => {
                setPedido(pedido => ({ ...pedido, codigoFinalizacao: e.target.value}));
            }}
        /></Box>
      </>
      }
      {operacao === 'confirmar_descontos' && 
        <>
        <Box paddingLeft={4} sx={{width: 140}}>Terminal: <strong>SIMULADOR</strong></Box>  
        <Box paddingLeft={4} sx={{width: 140}}>Cupom: <strong>{pedido.numSessao}</strong></Box>
        <Box paddingLeft={4} sx={{width: 140}}>Sessao: <strong>{pedido.numSessao}</strong></Box>
        <Box paddingLeft={4} sx={{width: 140}}>CNPJ: <strong>{pedido.cnpj}</strong></Box>
        </>
      }
  </Box>
  
  </>)};


  return (<>
    {opcoesOperacao()}
    <Box sx={{ display: 'flex' }}>
        <TsButton
          color='secondary'
          sx={{ display: 'inline-flex' }}
          disabled={pedido.token ? false : true}
          onClick={(e) => handleTabPanel(1)}
        >
          Fechar PDV
        </TsButton>
        <TsButton
          color='secondary'
          sx={{ display: 'inline-flex' }}
          disabled={pedido.sessaoAberta ? false : true}
          onClick={(e) => handleTabPanel(2)}
        >
          Fechar Sessão
        </TsButton>
        <Box paddingLeft={4} paddingRight={4} sx={{flex: '8 1 auto'}}>
          <SelectCampo
              name="operacao"
              label="Operação"
              id="lst-operacao"
              disabled={operacaoDisable}
              value={operacao}
              onChange={(event) => {changeOperacao(event.target.value)}}
              options={[
                  { value: "continue", text: "continue" },
                  { value: "entrar", text: "entrar" },
                  { value: "inserir_identificacao", text: "inserir_identificacao"},
                  { value: "consultar_identificacao", text: "consultar_identificacao" },
                  { value: "consultar_desconto", text: "consultar_desconto" },
                  { value: "registrar_venda", text: "registrar_venda" },
                  { value: "registrar_descontos", text: "registrar_descontos" },
                  { value: "confirmar_descontos", text: "confirmar_descontos" },
                  { value: "atualizar_status_transacao", text: "atualizar_status_transacao" }
              ]}
          />
        </Box>
        <TsButton color={botaoEnviar.color} sx={{ display: 'inline-flex' }} onClick={(e) => EnviarRequest()}>
          {botaoEnviar.text}
        </TsButton> 
        <Box sx={{width: 40}}>
          <FormControlLabel
                control={<Checkbox
                    checked={debug}
                    color="primary"
                    onClick={(e) => {setDebug(!debug)}}
                />}
                label={"debug"}
            />
        </Box>
      </Box>
 
      <Box paddingTop={2}>
        <Tabs
          value={pedido.tabIndex}
          orientation="horizontal"
          onChange={(e, newValue) => handleTabPanel(newValue)}
          aria-label="Sequencia Simulador"
        >
          <Tab
            label="Selecionar PDV"
            sx={{ fontSize: 12 }}
            disabled={false}
            id="simulador-tab-0"
            aria-labelledby={`simulador-tabpanel-0`}
          />
          <Tab
            label="Abrir PDV"
            sx={{ fontSize: 12 }}
            disabled={pedido.cnpj ? false : true}
            id="simulador-tab-1"
            aria-labelledby={`simulador-tabpanel-1`}
          />
          <Tab
            label="Iniciar Sessão"
            sx={{ fontSize: 12 }}
            disabled={pedido.token ? false : true}
            id="simulador-tab-2"
            aria-labelledby={`simulador-tabpanel-3`}
          />
          <Tab
            label="Adicionar Produtos"
            sx={{ fontSize: 12 }}
            disabled={!pedido.sessaoAberta}
            id="simulador-tab-3"
            aria-labelledby={`simulador-tabpanel-4`}
          />
          <Tab
            label="Finalizar Compra"
            sx={{ fontSize: 12 }}
            disabled={!pedido.cestaValida}
            id="simulador-tab-4"
            aria-labelledby={`simulador-tabpanel-5`}
          />
        </Tabs>
      </Box>
      <ServiceApi operacao={operacao} executeId={executeId} pedido={pedido} setPedido={setPedido} showInfo={showInfo} debug={debug} />
    </>
  );

}

export default FlowControl;