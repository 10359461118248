import * as React from 'react';
import { Box, Button, Link, styled, Typography, Checkbox, FormControlLabel } from '@mui/material'; 
import AddButtonEvent from '../../formulario/AddButtonEvent';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsDialog from '../../formulario/TsDialog';
import {TsTextMask} from '../../formulario/TsTextMask';
import {TsTextField} from '../../formulario/TsTextField';
import TsButton from '../../formulario/TsButton';
import ShowAlert from '../../util/ShowAlert';
import getCep from '../../formulario/cep';

const site_vazio = {
    nome: '',
    lojaId: '0',       
    cnpj: '',     
    endereco: '',   
    numero: '',
    complemento: '',
    cep: '',
    bairro: '',    
    cidade: '', 
    estado: '',
    terminais: '',
    old: {
      nome: '',
      lojaId: '0',       
      cnpj: '',     
      endereco: '',   
      numero: '',
      complemento: '',
      cep: '',
      bairro: '',    
      cidade: '', 
      estado: '',
      terminais: '',     
    }
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


const CadastroSites = (props) => {
    const {setProcessando, rows, setRows} = props;
    const dados = {...props.cadastroData};
    const [estabsFile, setEstabsFile] = React.useState({sucesso: true, mensagem: 'arquivo [csv]'});
    const serverPageSize = 200;
    const {serverPage, setServerPage} = React.useState(0);
    const [showCargaArquivo, setShowCargaArquivo] = React.useState(false);
    const [detail, setDetail] = React.useState({open: false, id: 0, title: 'Lista de localidades'});
    const [site, setSite] = React.useState(site_vazio);
    const [matriz, setMatriz] = React.useState(false);

    
    const columns = [
        {  name: 'nome',        label: 'Nome',       }, 
        {  name: 'cnpj',        label: 'Cnpj',       },
        {  name: 'lojaId',      label: 'Loja',       },
        {  name: 'endereco',    label: 'Endereco',   },
        {  name: 'bairro',      label: 'Bairro',     },
        {  name: 'cidade',      label: 'Cidade',     },
        {  name: 'estado',      label: 'Estado',     },
        {  name: 'id',          label: 'Ações',
          format: ((value) => (<><Link component={Button} onClick={(e) => openEditItem(value)} ><EditIcon /></Link>
                                <Link component={Button} onClick={(e) => deleteItem(value)} ><DeleteIcon /></Link> 
                              </>))
        }, 
      ];

    const openAddItem = () => {
      setSite({...site_vazio, id: -1, cnpj: dados.cnpj});
      setDetail({...detail, open: true, id: -1, title: 'Nova localidade'});
    };
    
    const openEditItem = (index) => {
      const r = rows.find((row) => row.id === index);
      setSite({...r, old: r});
      setDetail({...detail, open: true, id: index, title: 'Alterar localidade'});
    };
    
    const deleteItem = (index) => {
      const lista = rows.filter((row) => row.id !== index);
      setRows(lista);
    };

    const flipMatriz = () => {
  
      if (matriz) {
        setMatriz(false);
        setSite({...site.old, 
            id: site.id, 
            cnpj: dados.cnpj,
            terminais: site.terminais,
            old: site.old,
          });

      }
      else {
        setMatriz(true);
        setSite({
          id: site.id,
          nome: 'Matriz',
          lojaId: '0',       
          cnpj: dados.cnpj,     
          endereco: dados.endereco,   
          numero: dados.numero,
          complemento: dados.complemento,
          cep: dados.cep,
          bairro: dados.bairro,    
          cidade: dados.cidade, 
          estado: dados.uf,
          terminais: site.terminais,
          old: site.old,
        });
        
      }
      
    }

    const atualizarCad = () => {
      
      const lista = [...rows];

      if (site.id === -1) {
        site.id = rows.length;

        lista.forEach(it => {
          if (it.lojaId + '' === site.lojaId + '') {
            ShowAlert.error('ID da Loja deve ser único');
            return;
          }
        });

        lista.push(site);
      }
      else {
        lista.forEach((item, index) => {
          if (item.id === site.id) {
            lista[index] = {...site};
          }
        });
      }
        
      setDetail({...detail, open: false, id: 0, title: 'Lista de Localidades'});
      setRows(lista);
    }

    const processCep = async () => {
      let cep = site.cep;
      cep = cep.replace(/\./g, '').replace(/-/g, '');

      if (cep.length === 8) {
          const data = await getCep(cep);
          if (data.cep) {
              setSite({...site, 
                  endereco: data.logradouro,
                  bairro: data.bairro,
                  cidade: data.localidade,
                  estado: data.uf,
              });
          }
      }
  }
      
    const estabsUpload = async (event) => {

        event.preventDefault();

        //  event.target.elements.fileInput.files[0];
        // const file = event.target.files[0];
        // const element = document.getElementById('estabelecimentos-file-input');
        // const file = element && element.files ? element.files[0] : undefined; 
        
        const file = event.target.files ? event.target.files[0]: undefined;

        if (file && file.size > 0) {
            
            const rx = /^.*\.(csv)$/gi;
            if (!rx.test(file.name)) {
                setEstabsFile({sucesso: false, mensagem: 'extensão inválida [csv]', file: undefined});
            }
            else if (file.size > 100000000) {
                setEstabsFile({sucesso: false, mensagem: 'Tamanho não suportado', file: undefined});
                return;
            }
        
            setProcessando(true);
            try {
                // const retUpload = await uploadFile(file, 'estabelecimentos-dev');
                // if (retUpload.sucesso === false) {
                //     setEstabsFile({sucesso: false, mensagem: retUpload.mensagem});
                // }
                // else {
                //     setCadastroData({...cadastroData, estabelecimentosFile: retUpload.link});
                //     setEstabsFile({sucesso: true, mensagem: file.name.substr(0, 30)});
                // }
                
            } catch (error) {
                setEstabsFile({sucesso: false, mensagem: error.mensagem || error.message});
            }
            setProcessando(false);
        }
    }

    return (<>
        <Box dir="rtl"><AddButtonEvent title="Adicionar item" onClick={(e) => openAddItem()} /></Box>
        <Box >
            <TsDataGrid
            rows={rows}
            columns={columns}
            queryid={''}
            serverPage={serverPage}
            serverPagesize={serverPageSize}
            onChangePage={(value) => setServerPage(value)}
            />
        </Box>
        <Box><Link sx={{cursor: 'pointer'}} onClick={(e) => setShowCargaArquivo(!showCargaArquivo)} >Carregar por arquivo (Avançado)</Link></Box>
        {showCargaArquivo && 
        <Box sx={{display: "flex"}}>
            <Box sx={{flex: "1 1 auto", paddingRight: 2, paddingTop: 1}}>
                <Box>
                    <Button
                        component="label"
                        color={estabsFile.sucesso ? 'primary' : 'error'}
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                    >
                        Lista Estabelecimentos
                        <VisuallyHiddenInput
                            type="file"
                            id='estabelecimentos-file-input'
                            name='estabelecimentos-file-input'
                            accept='.csv'
                            onChange={(event) => void(0)} 
                        />
                    </Button>
                </Box>
                <Box sx={{paddingTop: 1}}>
                    <Typography sx={{fontSize: '11pt', color: '#777'}}>{estabsFile.mensagem || ''}</Typography>
                </Box>
            </Box>
            <Box sx={{flex: "1 1 auto", paddingRight: 2, paddingTop: 2}}>
                <Link href='/estabelecimento.csv' target='top' >Baixar planilha modelo</Link>
            </Box>
        </Box> }
        <TsDialog onClose={() => setDetail({open: false, id: 0, title: "Lista de localidades"})} id="dialog-detalhe-site" open={detail.open} title={detail.title} minWidth={800}>
          <Box sx={{minHeight: '300px'}}>
            <Box dir='rtl' sx={{paddingRight: 2, paddingLeft: 2}}>
                <FormControlLabel sx={{color: '#999'}} control={<Checkbox checked={matriz} onChange={(e) => flipMatriz()} />} label="Endereço Principal" />
            </Box>
            <Box sx={{display: 'flex'}}>
              <Box sx={{flex: '1 1 auto', width: '250px', paddingRight: 2}}>
                <TsTextMask
                    name="cnpj"
                    label="cnpj" 
                    fullWidth
                    mask="00.000.000/0000-00"
                    value={site.cnpj || ''} 
                    disabled={true}
                    onChangeValue={(v) => void(0)}
                />
              </Box>
              <Box sx={{flex: '8 1 auto', paddingRight: 2}}>
                <TsTextField
                    name="nome"
                    label="Nome"
                    fullWidth
                    value={site.nome || ''}
                    disabled={false}
                    onChange={(e) => {setSite({...site, nome: e.target.value})}}
                  />
              </Box>
              <Box sx={{flex: '1 1 auto', width: '200px', paddingRight: 2}}>
                <TsTextField
                    name="lojaId"
                    type='number'
                    label="Loja (pdv)"
                    fullWidth
                    value={site.lojaId || ''}
                    disabled={false}
                    onChange={(e) => {setSite({...site, lojaId: e.target.value})}}
                  />
              </Box>
            </Box>
            <Box sx={{display: 'flex'}}>
              <Box sx={{flex: '8 1 auto', paddingRight: 2}}>
                <TsTextField
                      name="endereco"
                      label="endereco"
                      fullWidth
                      value={site.endereco || ''}
                      disabled={matriz}
                      onChange={(e) => {setSite({...site, endereco: e.target.value})}}
                  />
              </Box>
              <Box sx={{flex: '0 1 auto', width: '150px', paddingRight: 2}}>
                <TsTextField
                      name="numero"
                      label="numero"
                      fullWidth
                      value={site.numero || ''}
                      disabled={matriz}
                      onChange={(e) => {setSite({...site, numero: e.target.value})}}
                  />
                </Box>
              <Box sx={{flex: '0 1 auto', width: '150px', paddingRight: 2}}>
                <TsTextField
                      name="complemento"
                      label="complemento"
                      fullWidth
                      value={site.complemento || ''}
                      disabled={matriz}
                      onChange={(e) => {setSite({...site, complemento: e.target.value})}}
                  />
              </Box>
            </Box>
            <Box sx={{display: 'flex'}}>
              <Box sx={{flex: '8 1 auto', paddingRight: 2}}>
                <TsTextField
                          name="bairro"
                          label="bairro"
                          fullWidth
                          value={site.bairro || ''}
                          disabled={matriz}
                          onChange={(e) => {setSite({...site, bairro: e.target.value})}}
                  />
            </Box>
              <Box sx={{flex: '1 0 auto', width: '300px', paddingRight: 2}}>
                <TsTextMask
                        name="cep"
                        label="cep" 
                        fullWidth
                        mask="00000-000"
                        value={site.cep || ''} 
                        disabled={matriz}
                        onChangeValue={(v) => {setSite({...site, cep: v})}}
                        onBlur={() => {
                          processCep();
                        }}
                  />
              </Box>
            </Box>
            <Box sx={{display: 'flex'}}>
              <Box sx={{flex: '8 1 auto', paddingRight: 2}}>
                <TsTextField
                      name="cidade"
                      label="cidade"
                      fullWidth
                      value={site.cidade || ''}
                      disabled={matriz}
                      onChange={(e) => {setSite({...site, cidade: e.target.value})}}
                />
              </Box>
              <Box sx={{flex: '1 0 auto', width: '300px', paddingRight: 2}}>
                <TsTextField
                      name="estado"
                      label="estado"
                      fullWidth
                      value={site.estado || ''}
                      disabled={matriz}
                      onChange={(e) => {setSite({...site, estado: e.target.value})}}
                />
              </Box>
            </Box>
            <Box sx={{paddingRight: 2}}>
              <TsTextField
                      name="terminais"
                      label="terminais (separar por vírgula)"
                      fullWidth
                      value={site.terminais || ''}
                      disabled={false}
                      onChange={(e) => {setSite({...site, terminais: e.target.value})}}
                />
            </Box>
            <Box sx={{display:'flex'}} >
                <Box sx={{flex: "1 1 auto", paddingRight: 2}}>
                        
                </Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}} >
                    <TsButton
                        onClick={(e) => setDetail({open: false, id: 0, title: "Lista de localidades"})}
                        color="secondary"
                    >Cancelar</TsButton>
                </Box>
                <Box sx={{flex: "0 0 auto", paddingRight: 2}} >
                    <TsButton
                        onClick={(e) => atualizarCad()}
                    >Enviar</TsButton>
                </Box>
            </Box>
          </Box>
        </TsDialog>
    </>);
}

export default CadastroSites;

/*
    field: string;
    headerName?: string;
    description?: string;
    width?: number;
    flex?: number;
    minWidth?: number;
    maxWidth?: number;
    hide?: boolean;
    hideable?: boolean;
    sortable?: boolean;
    sortingOrder?: GridSortDirection[];
    resizable?: boolean;
    editable?: boolean;
    groupable?: boolean;
    pinnable?: boolean;
    sortComparator?: GridComparatorFn<V>;
    type?: GridColType;                     // string | number | boolean;
    valueOptions?: Array<ValueOptions> | ((params: GridValueOptionsParams<R>) => Array<ValueOptions>);
    align?: GridAlignment;
    valueGetter?: (params: GridValueGetterParams<any, R>) => V;
    valueSetter?: (params: GridValueSetterParams<R, V>) => R;
    valueFormatter?: (params: GridValueFormatterParams<V>) => F;
    valueParser?: (value: F | undefined, params?: GridCellParams<V, R, F>) => V;
    cellClassName?: GridCellClassNamePropType;
    renderCell?: (params: GridRenderCellParams<V, R, F>) => React.ReactNode;
    renderEditCell?: (params: GridRenderEditCellParams<V>) => React.ReactNode;
    preProcessEditCellProps?: (params: GridPreProcessEditCellProps) => GridEditCellProps | Promise<GridEditCellProps>;
    headerClassName?: GridColumnHeaderClassNamePropType;
    renderHeader?: (params: GridColumnHeaderParams<V, R, F>) => React.ReactNode;
    headerAlign?: GridAlignment;
    hideSortIcons?: boolean;
    disableColumnMenu?: boolean;
    filterable?: boolean;
    filterOperators?: GridFilterOperator<R, V, F>[];
    getApplyQuickFilterFn?: (value: any, colDef: GridStateColDef, apiRef: React.MutableRefObject<GridApiCommunity>) => null | ((params: GridCellParams<V, R, F>) => boolean);
    disableReorder?: boolean;
    disableExport?: boolean;
    colSpan?: number | ((params: GridCellParams<V, R, F>) => number | undefined);
*/