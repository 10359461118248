export function ConvertError(error, mensagem) {
  let retorno = {
    sucesso: false,
    mensagem: mensagem,
    message: mensagem,
    detalhe: mensagem,
    retCod: -1,
  };

  // const {message, config, code, request, response} = error;

  if (error.response && error.response.status) {
    const response = error.response;
    // console.lo g(" ### ConvertError - error  (RESPONSE)", response);

    retorno.retCod = response.status;
    retorno.mensagem = response.statusText;
    retorno.data = response.data;
    if (response.config && response.config.baseURL && response.config.url) {
      retorno.detalhe = `${response.config.baseURL}/${response.config.url}`;
    } else {
      retorno.detalhe = response.data;
    }

    if (retorno.retCod === 403) {
      retorno.mensagem = 'ACESSO NEGADO';
      return retorno;
    }

    if (retorno.retCod >= 500) {
      retorno.mensagem = 'FALHA INTERNA NA API';
      return retorno;
    }

    if (typeof retorno.data === 'string') {
      if (retorno.data.length === 0) {
        retorno.data = { mensagem: error.message };
      } else {
        try {
          const obj = JSON.parse(retorno.data);
          if (typeof obj === 'object') {
            retorno.data = obj;
          }
        } catch (error) {
          retorno.data = { mensagem: retorno.data };
          //console.warn(' Error - error  (RESPONSE) ### exception ao ler retorno.data', error);
        }
      }
    }

    let data = retorno.data;

    if (typeof data === 'object') {
      retorno.mensagem = data.mensagem ? data.mensagem : retorno.mensagem;
      retorno.retCod = data.retCod ? data.retCod : retorno.retCod;
    } else if (typeof data === 'string') {
      retorno.mensagem = data;
    } else {
      retorno.mensagem = ` ### ConvertError - error  (RESPONSE) ### - retorno.data, tipo desconhecido [${typeof data}]`;
    }
  } else if (error.name === 'AxiosError' || error.message === 'Network Error' || error.code === 'ERR_NETWORK') {
    retorno.retCod = 500;
    retorno.mensagem = 'Erro de Rede';
    retorno.data = undefined;
    retorno.detalhe = { ...error };
  } else {
    console.warn('ConvertError - error (DESCONHECIDO)', error);
  }

  if (error.config) {
    try {
      if (error.config.baseURL && error.config.method) {
        retorno.url = `${error.config.method} - ${error.config.baseURL}/${error.config.url}`;
      }
      if (error.config.data) {
        retorno.requestData = error.config.data;
        retorno.requestData = JSON.parse(error.config.data);
      }
    } catch (error) {}
  }

  return retorno;
}
