import * as React from 'react';
import TsDataGrid from '../../formulario/TsDataGrid';


 
const ProspectLocalidade = (props) => {
    const {open,  rows} = props;
    
    const columns = [
        {  name: 'nome',        label: 'Nome',       }, 
        {  name: 'cnpj',        label: 'Cnpj',       },
        {  name: 'lojaId',      label: 'Loja',       },
        {  name: 'endereco',    label: 'Endereço',   },
        {  name: 'bairro',      label: 'CEP Bairro', },
        {  name: 'cidade',      label: 'Cidade/UF',  },
        {  name: 'terminais',   label: 'terminais',  },
      ];


    if (open) return (<>
       
        <TsDataGrid
            rows={rows}
            columns={columns}
            queryid={''}
            serverPage={0}
            serverPagesize={200}
            onChangePage={(value) => void(0)}
        />
     
        </>);
        else return null;
}

export default ProspectLocalidade;

