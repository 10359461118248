import * as React from 'react';
import distribuidorHttp from '../../util/http/distribuidor-http'; 
import ShowAlert from '../../util/ShowAlert';
import TsDataGrid from '../../formulario/TsDataGrid';
import {Button, Link} from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ProdutoDetalhe from  '../produto/produto-detalhe';
import TsDialog from '../../formulario/TsDialog';  
 
const RedeProduto = (props) => {

    const {detail, projAtual, setError, setUrl, processando, setProcessando, userLevel} = props;
    const [rows, setRows] = React.useState([]);  
    const [serverPage, setServerPage] = React.useState(0);
    const [openProduto, setOpenProduto] = React.useState(false);
    const [produtoCodigo, setProdutoCodigo] = React.useState("");
    const serverPageSize = 50;
    const objBusca = {busca: ''};
    const queryid = Math.random();
    

    const columns = [
        {
            name: "ean",
            label: "#",
            format: ((value) => {userLevel > 2 ? (<Link component={Button} onClick={(e) => {selectProduto(value)}}><EventNoteIcon /></Link>) : (<></>)})
        },
        {name: "produtoId",label: "id"},
        {name: "ean",label: "ean"},
        {name: "produtoNome",label: "produto"},
        {name: "marcaNome",label: "marca"},
        {name: "distribuidorNome",label: "distribuidor"},
    ];

    const selectProduto = async (ean) => {
        setProdutoCodigo(ean);
        setOpenProduto(true);
    }

    const Buscar = async () => {

        if (processando === true) return;
        setProcessando(true);
        
        try {
            const query = { page: serverPage, nrecPage: serverPageSize, ativo: true, busca: objBusca.busca, projetoId: Number(projAtual.id) };
            const {data} = await distribuidorHttp.listProdutos(0, detail.id, query);
            setProcessando(false);
            if (data.sucesso === true) {
                setRows(data.dados);
            }
            else {
                ShowAlert.error(data.mensagem);
            }
            
            
        } catch (error) {
            setProcessando(false);
            setUrl(distribuidorHttp.getUrl());
            setError(error);
        }
        
    }

    React.useEffect(() => {
        if (props.open === true) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    return (
            
    <>  
         
        <TsDataGrid
            rows={rows}
            columns={columns}
            queryid={queryid}
            serverPage={serverPage}
            serverPagesize={serverPageSize}
            onChangePage={(value) => setServerPage(value)}
            />
        <TsDialog onClose={() => { setOpenProduto(false) }} id="dialog-detalhe-produto" open={openProduto} title="Detalhes do produto" minWidth={800} >
                    {openProduto && userLevel > 2 && <ProdutoDetalhe produtoCodigo={produtoCodigo}></ProdutoDetalhe>}
        </TsDialog>
            
    </>
    )

}


export default RedeProduto;