
export const convertArray = (obj) => {
  if (obj === undefined || obj === null || typeof obj !== 'object') return [['label', 'valor']];

  const arr = [];
  const header = [];
  try {
    obj.forEach((element, index) => {
      const item = [];
      for (const property in element) {
        if (index === 0) {
          if (property === 'annotation') header.push({ role: 'annotation' });
          else header.push(property + '');
        }

        item.push(element[property]);
      }

      if (index === 0) arr.push(header);

      arr.push(item);
    });
    return arr;
  } catch (error) {
    console.log('Error convert arr, obj:', obj);
    console.log('Error convert arr', error);
    return arr;
  }
};

export const homeDataNull = {
  clientesCompradores: '-',
  nTransacoes: '-',
  nItens: '-',
  valorTotalTransacoes: '-',
  buscaNomeRede: '-',
  buscaNomeMarca: '-',
  buscaNomeCanal: '-',
  buscaEanProduto: '-',
  buscaPeriodo: '-',
}


export const resumoDataNull = {
  qtdeVendas: '',
  valorTotalVendas: '',
  descontoTotal: '',
  qtdeItens: '',
  ticketMedio: '',
  nTotalClientes: '',
  nClienteCompra: '',
  percentClienteConversao: '',
  ticketMedioCliente: '',
  descontoMedioCliente: '',
  faixaTicketMedio: [['Faixa', '0'], ['de ate', 0]],
}

export const prodDataNull = {
  marca_qtdeVendas : [],
  marca_valorVendas: [],
  marca_descontoVendas: [],
  prod_qtdeVendas : [],
  prod_valorVendas: [],
  prod_descontoVendas: [],
}

export const redeDataNull = {};

export const cadastroDataNull = {};
