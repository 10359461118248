import * as React from 'react';
import TsDataGrid from '../../formulario/TsDataGrid';
import ListaBase from '../ListaBase';


/*
        this.columnsDetalhes = [
            { name: 'dataTransacao',     key: 'dataTransacao',     show: true,  label: 'data'},
            { name: 'nsu',               key: 'nsu',               show: false, label: 'nsu'},
            { name: 'cnpj',              key: 'cnpj',              show: false, label: 'cnpj'},
            { name: "cupom",             key: "cupom",             show: true,  label: "cupom"},
            { name: "rede",              key: "rede",              show: true,  label: "rede"},
            { name: "industria",         key: "industria",         show: true,  label: "industria"},
            { name: "marca",             key: "marca",             show: true,  label: "marca"},
            { name: "categoria",         key: "categoria",         show: true,  label: "categoria"},
            { name: "ean",               key: "ean",               show: true,  label: "ean"},
            { name: "produto",           key: "produto",           show: false, label: "produto"},
            { name: "quantidadeVendida", key: "quantidadeVendida", show: true,  label: "qtde"},
            { name: "precoTotal",        key: "precoTotal",        show: true,  label: "total"},
            { name: "descontoTotal",     key: "descontoTotal",     show: true,  label: "desconto" },
            { name: "valDescZicard",     key: "valDescZicard",     show: false,  label: "zicard" }
        ];
*/
class DetalhesLista extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;
    }

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
         
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
    }

    render() {
        return (<>
            <TsDataGrid
                rows={this.props.rows}
                columns={this.props.columns}
                queryid={this.queryid}
                serverPage={this.serverPage}
                serverPagesize={this.serverPageSize}
                onChangePage={(value) => this.setServerPage(value)}
            />
        </>)
    }
 
    
}

export default DetalhesLista;
