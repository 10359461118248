
import * as React from 'react';
import { Box } from '@mui/material';
import StopedIcon from '@mui/icons-material/PauseCircle';
import StartedIcon from '@mui/icons-material/PlayCircleFilledWhite';
 

const subTitleCSS = {
    color: "#88c",
    margin: "4px",
    fontSize: 14,
    flex: "2 1 auto"
};

const subTitleContainerCSS = {
    display: "flex",
    background: "#eee",
    padding: "4px"
}

const PdvHeader = (props) => {

    const {pedido} = props;

    return (<Box>
        <Box sx={subTitleContainerCSS}>
            <Box sx={subTitleCSS}>PDV</Box>
            <Box dir="rtl">{pedido.token ? <StartedIcon sx={{ color: "#090" }} /> : <StopedIcon sx={{ color: "#777" }} />}</Box>
        </Box>
        <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: "1 0 200px", fontSize: 12 }}>Canal de Venda: <strong>{pedido.canal}</strong></Box>
            <Box sx={{ flex: "1 0 400px", fontSize: 12 }}>TOKEN: <strong>{pedido.token ? `${pedido.token.substring(0, 40)} ...` : ''}</strong></Box>
        </Box>
        <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: "1 0 200px", fontSize: 12 }}>REDE: <strong>{pedido.rede}</strong></Box>
            <Box sx={{ flex: "1 0 400px", fontSize: 12 }}>CNPJ: <strong>{pedido.cnpj}</strong></Box>
        </Box>
        <Box sx={subTitleContainerCSS}>
            <Box sx={subTitleCSS}>SESSÃO</Box>
            <Box dir="rtl">{pedido.sessaoAberta ? <StartedIcon sx={{ color: "#090" }} /> : <StopedIcon sx={{ color: "#777" }} />}</Box>
        </Box>
        <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: "1 0 200px", fontSize: 12 }}>CPF: <strong>{pedido.cpf}</strong></Box>
            <Box sx={{ flex: "1 0 200px", fontSize: 12 }}>NOME: <strong>{pedido.nome}</strong></Box>
            <Box sx={{ flex: "1 0 200px", fontSize: 12 }}>SESSAO: <strong>{pedido.numSessao}</strong></Box>
        </Box>
    </Box>);
}

export default PdvHeader;
