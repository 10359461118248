
import * as React from 'react';
import { Box } from '@mui/material';
import { getUser } from '../util/auth'; 
import { TsTextField } from '../formulario/TsTextField';
import TsButton from '../formulario/TsButton';
import usuarioHttp from '../util/http/usuario-http';
import authHttp from '../util/http/auth-http';
import TsBackDrop from '../formulario/TsBackDrop';
import ShowAlert from '../util/ShowAlert';

const boxProps = {
    borderColor: '#B9B9B9',
    border: 1
};

const PerfilUsuario = (props) => {

    const closeModal = props.closeModal || void(0);
    const open = props.open;
    const atual = getUser('home'); 
    const [novaSenha1, setNovaSenha1] = React.useState('');
    const [novaSenha2, setNovaSenha2] = React.useState('');
    const [senhaAtual, setSenhaAtual] = React.useState('');
    const [userData, setUserData] = React.useState({id: 0, login: ''});
    const [processando, setProcessando] = React.useState(false);

    const ReadData = () => {

        if (userData.id === atual.id) {
            console.log('Usuario Ja carregado');
            return;
        }
  
        setProcessando(true);
        usuarioHttp
            .get(atual.id)
            .then(({data}) => {

                if (data.dado.id !== atual.id || data.dado.login !== atual.login) {
                    ShowAlert.error('operação inválida');
                    console.log('Usuario Inválido');
                    console.log(`ID: ${atual.id}/${data.dado.id} , LOGIN: ${atual.login}/${data.dado.login}`);
                    return;
                }
                setUserData(data.dado);
              

            })
            .catch((error) => {
                ShowAlert.error('ERRO ao obter dados do usuário, veja console');
                console.log('ERROR', error);
                // this.setError(error);
            })
            .finally(() => {
                //this.setUrl(usuarioHttp.getUrl());
                setProcessando(false);
            });
            
    };

    const ChangePassword = async () => {
        let checkPass = false;
        if (!senhaAtual || !novaSenha1 || !novaSenha2) {
            ShowAlert.error('Para trocar a senha, preencha os dados corretamente');
            return;
        }

        if (novaSenha1 !== novaSenha2) {
            ShowAlert.error('Nova senha não confere');
            return;
        }

        if (novaSenha1.length < 8) {
            ShowAlert.error('Nova senha deve conter pelo menos 8 caracters');
            return;
        }

        const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))');

        if (!mediumPassword.test(novaSenha1)) {
            ShowAlert.error('Nova senha não atende a complexidade necessária');
            return;
        }

        try {
            const {data} = await authHttp.login(atual.login, senhaAtual);
            if (data.token && data.login === atual.login) {
                checkPass = true;
            }
        } catch (error) {
            if (error.data) {
                ShowAlert.error(error.data.message);
                return;
            }
            else {
                ShowAlert.error('ERRO ao obter dados do usuário, veja console');
                console.log('ERROR', error);
                return;
            }
        }

        if (!checkPass) {
            ShowAlert.error('Senha antiga não foi validada');
            return;
        }

        const usuario = {
            id: atual.id,
            login: userData.login,
            senha: novaSenha1
        };

        setProcessando(true);

        try {
            await usuarioHttp.update(atual.id, usuario);
            ShowAlert.info("senha alterada com sucesso");
            setProcessando(false);
        } catch (error) {
            ShowAlert.error("ERRO ao gravar dados");
            console.log("ERROR", error);
        }
        setProcessando(false);
    }

    React.useEffect(() => {
        if (atual.id !== 0) {
            ReadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
 
    return (
        <>
        <Box {...boxProps}
        borderRadius={4}
        padding={1}
        >
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '8 1 auto', padding: 1}}>
                    <TsTextField
                        name="login"
                        label="Login"
                        fullWidth
                        value={atual.login || ''}
                        disabled={true}
                        onChange={(e) => void(0)}
                    />
                </Box>
                <Box sx={{flex: '0 0 auto', padding: 1}}>
                    <TsTextField
                        name="tipo"
                        label="Tipo"
                        fullWidth
                        value={userData.tipo || ''}
                        disabled={true}
                        onChange={(e) => void(0)}
                    />
                </Box>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 0 auto', padding: 1}}>
                    <TsTextField
                        name="descricao"
                        label="Descrição"
                        fullWidth
                        value={userData.descricao || ''}
                        disabled={true}
                        onChange={(e) => void(0)}
                    />
                </Box>
                <Box sx={{flex: '1 0 auto', padding: 1}}>
                    <TsTextField
                        name="perfilPowerBI"
                        label="perfilPowerBI"
                        fullWidth
                        value={userData.perfilPowerBI || ''}
                        disabled={true}
                        onChange={(e) => void(0)}
                    />
                </Box>
            </Box>
            <Box sx={{padding: 1}}>
            <TsTextField
                    name="novaSenha1"
                    label="Senha Atual"
                    fullWidth
                    type="password"
                    value={senhaAtual || ''}
                    disabled={false}
                    onChange={(e) => {setSenhaAtual(e.target.value)}}
            />
            </Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{flex: '1 0 auto', padding: 1}}>
                    <TsTextField
                            name="novaSenha1"
                            label="Nova Senha"
                            fullWidth
                            type="password"
                            value={novaSenha1 || ''}
                            disabled={false}
                            onChange={(e) => {setNovaSenha1(e.target.value)}}
                    />
                </Box>
                <Box sx={{flex: '1 0 auto', padding: 1}}>
                    <TsTextField
                            name="novaSenha2"
                            label="Confirmação"
                            fullWidth
                            type="password"
                            value={novaSenha2 || ''}
                            disabled={false}
                            onChange={(e) => {setNovaSenha2(e.target.value)}}
                    />
                </Box>
                 
            </Box>
            <Box sx={{padding: 1}} dir="rtl">
                <TsButton
                    onClick={(e) => ChangePassword()}
                >Enviar</TsButton>
                <TsButton
                    onClick={(e) => closeModal()}
                    color='secondary'
                >Cancelar</TsButton>
            </Box>

        </Box>
        <TsBackDrop processando={processando} />
        </>
    );
}

export default PerfilUsuario;