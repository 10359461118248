import * as React from 'react';
import { Button, Link } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import distribuidorHttp from '../../util/http/distribuidor-http';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';

const DistribuidorList = (props) => {
    const setDetail = props.setDetail;
    const detail = props.detail;
    const [rows, setRows] = React.useState([]);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [processando, setProcessando] = React.useState(false); 
    const [serverPage, setServerPage] = React.useState(0);
    const serverPageSize = 50;
    const [objBusca, setObjBusca] = React.useState({busca: '', situacao: 'ALL'});
    const [queryid, setQueryid] = React.useState("");
    
    const columns = [
        {
            name: "id",
            label: "Código",
            format: ((value) => (<Link component={Button} onClick={(e) => {setDetail({open: true, id: value, pageTitle: "Detalhes do backlog"})}}><EventNoteIcon /></Link>))
        },
        {
            name: "nome",
            label: "nome"
        },
        {
            name: "dataAtivacao",
            label: "Ativação"
        },
        {
            name: "ativo",
            label: "status",
            format: ((value) => (value ? <>Ativo</> : <>Inativo</>))
        },
        {
            name: "comissaoFaturamento",
            label: "comissao",
            format: ((value) => (value ? <>{(value * 100).toFixed(2)} %</> : <>0 %</>))
        }
    ];

    const Buscar = async () => {
        if (processando) {
            return;
        }
        setQueryid("");
        setProcessando(true);
        
        try {
            setRows([]);
            const query = { page: serverPage, nrecPage: serverPageSize, ativo: true, busca: objBusca.busca };
            const {data} = await distribuidorHttp.busca(query);
            if (data.sucesso === true && data.dados && data.dados.length > 0) {
                setRows(data.dados);
            }
            else {
                ShowAlert.error("Nenhum registro encontrado");
            }
        } catch (error) {
            setError(error);
        } 
        setProcessando(false);
        setUrl(distribuidorHttp.getUrl());
    }

    React.useEffect(() => {
        if (detail.open === false) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    React.useEffect(() => {
        if (detail.open === false) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objBusca]);


    return (
        <div>
            <>
                <TsInputBusca
                    name="input-busca"
                    value={objBusca.busca}
                    showBusca={true}
                    showAtivo={true}
                    showAdd={true}
                    submitSearch={(texto, ativo) => { setObjBusca({...objBusca, ativo: ativo, busca: texto}); }}
                    onClickAdd={(e) => {setDetail({open: true, id: 0, pageTitle: "Novo distribuidor"})}}
                >
                </TsInputBusca>
            </>
            <TsDataGrid
                    rows={rows}
                    columns={columns}
                    queryid={queryid}
                    serverPage={serverPage}
                    serverPagesize={serverPageSize}
                    onChangePage={(value) => setServerPage(value)}
                    />
            <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
            <TsBackDrop processando={processando} />
        </div>)
}

export default DistribuidorList;
