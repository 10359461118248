import { Box} from '@mui/material';
import * as React from 'react';
import { Page } from '../Page';
import SofthouseList from './softhouse-list';
import SofthouseDetail from './softhouse-detail';

const SofthousePage = () => {
    const [detail, setDetail] = React.useState({open: false, id: 0, title: "Lista de Softhouses"}); 

    
    return (
        <Page title={detail.open ? 'Detalhes softhouse' : 'Lista de Softhouses'}>
            <Box>
                {detail.open === false ? 
                <SofthouseList setDetail={setDetail}  detail={detail}></SofthouseList> : 
                <SofthouseDetail setDetail={setDetail} detail={detail}></SofthouseDetail>}
            </Box>
        </Page>
    );
};

export default SofthousePage;
