import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';

import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import FactoryIcon from '@mui/icons-material/Factory';
import StoreIcon from '@mui/icons-material/Storefront';
import ChartIcon from '@mui/icons-material/BarChart';
import TableIcon from '@mui/icons-material/TableChart';
import TransactionIcon from '@mui/icons-material/AttachMoney';
import ProdutoIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import DescontoIcon from '@mui/icons-material/PriceChange';
import { CircularProgress } from '@mui/material';
import { HOMEDIR, setUser, usuarioNull, getUser } from '../../util/auth';

const icones = [
    { chave: "home", componente: HomeIcon},
    { chave: "categoria", componente: CategoryIcon},
    { chave: "fabricante", componente: FactoryIcon},
    { chave: "rede", componente: StoreIcon},
    { chave: "chart", componente: ChartIcon},
    { chave: "cadastro", componente: GroupIcon},
    { chave: "table", componente: TableIcon},
    { chave: "transacao", componente: TransactionIcon},
    { chave: "produto", componente: ProdutoIcon},
    { chave: "desconto", componente: DescontoIcon},
];


const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  /* position: 'absolute', */
  top: '127px',
  left: '2px',
  zIndex: 1100,
  backgroundColor: '#e61414',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  /* position: 'absolute', */
  top: '127px',
  left: '2px',
  zIndex: 1100,
  backgroundColor: '#e61414',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniMenu(props) {
  const itens = props.itens;
  const setOpenPerfil = props.setOpenPerfil;
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const atual = getUser('powerbi');

  const handleDrawerFlip = () => {
    setOpen(!open);
  }

  const ItemIcon = (props) => {
    const icone = icones.find(ic => ic.chave === props.chave);
    // chave componente
    if (icone === undefined) {
        return (<ChartIcon {...props} />);
    }
    else {
        const Icon = icone.componente;
        return (<Icon {...props} />);
    }
  }

/*
MuiDrawer-root MuiDrawer-docked css-1smu4hm-MuiDrawer-docked
*/
const logout = () => {
  setUser(usuarioNull);
  setTimeout(() => {
    window.location.href = `${HOMEDIR}/`;
  }, 500);
};
 
React.useEffect(() => {
    if (itens?.length > 0 && props.show === true) {
        setShow(true);
    }
    else {
        setShow(false);
    }

 }, [itens, props.show])
   
 
  return (
     <>
      <CssBaseline />
      <Drawer 
        variant="permanent" 
        open={open}>
        
        <DrawerHeader sx={{marginTop: '20px'}}>
          <IconButton onClick={handleDrawerFlip}>
            {open ?  <ChevronLeftIcon sx={{color: 'white'}} /> : <ChevronRightIcon sx={{color: 'white'}} />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        <ListItem>
            <ListItemButton
              className={'mini-menu-button'}
              onClick={(e) => setOpenPerfil(true)}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'white'
                  }}
                >
                <PersonIcon fontSize='large' />
              </ListItemIcon>
              <ListItemText primary={atual.login} sx={{ opacity: open ? 1 : 0, color: 'white' }} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              className={'mini-menu-button'}
              onClick={(e) => {logout()}}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'white'
                  }}
                  
                >
                <LogoutIcon fontSize='large' />
              </ListItemIcon>
              <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0, color: 'white' }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        {show ?
        <>
        <List>
          {itens.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={(e) => props.onClick(index)}
                selected={props.selected === index ? true : false}
                className={'mini-menu-button'}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'white'
                  }}
                >
                    <ItemIcon chave={item.menuIcone} fontSize='large'  /> 
                </ListItemIcon>
                <ListItemText primary={item.menuLabel} sx={{ opacity: open ? 1 : 0, color: 'white' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> 
        </>
         : <Box marginTop={4} className='div-centered'><CircularProgress size={24} sx={{color: 'white'}}  /></Box>}
      </Drawer>
      </>
  );
  
}
