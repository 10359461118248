import { Box, Container, Typography } from '@mui/material';
import { mainTitleCSS } from '../style';
import * as React from 'react';

export const Page = (props) => {
  return (
    <Container sx={props.sx || {}} maxWidth='xl'>
      {props.title && <Box paddingTop={3}><Typography sx={mainTitleCSS}>{props.title}</Typography></Box>}
      <Box paddingTop={2}>{props.children}</Box>
    </Container>
  );
};

export const PagePublic = (props) => {
  return (
    <Container sx={props.sx || {}}  maxWidth='xl'>
      {props.title && <Box sx={{padding: 2}}><Typography sx={mainTitleCSS}>{props.title}</Typography></Box>}
      <Box sx={{padding: 2}}>{props.children}</Box>
    </Container>
  );
};
