/* eslint-disable react-hooks/exhaustive-deps */
// @flow 
import * as React from 'react';
import { Box, Grid, InputAdornment, IconButton, Tab, Tabs, Checkbox, FormControlLabel } from '@mui/material';

import ShowError from '../../formulario/ShowError';
import { exemploProgressivo, TxtDouble, TxtInt, TxtString } from '../../util/Util';
import ProdutoBuscaDialog from '../produto/produto-buscar-dialog';
import TsButton from '../../formulario/TsButton';
import SearchIcon from '@mui/icons-material/Search'
import TsDialog from '../../formulario/TsDialog';
import { preWrapCSS } from '../../style';
import SelectCampo from '../../formulario/SelectCampo';
import { getProjetoStorage } from '../../util/auth';
import { TsTextField } from '../../formulario/TsTextField';
import TsFilterChips from '../../formulario/TsFilterChips';
import gestaodescontoHttp from '../../util/http/gestaodesconto-http';
import classificacaoHttp from '../../util/http/classificacao-http';
import CircleIcon from '@mui/icons-material/Circle';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';

import {
    formOfertaDefault,
    formCampanhaDefault,
    formProdutoDefault
} from '../../dominios/gestao-desconto';
import gestaoDescontoHttp from '../../util/http/gestaodesconto-http';
import ofertaHttp from '../../util/http/oferta-http';
import ShowAlert from '../../util/ShowAlert';
import TsBackDrop from '../../formulario/TsBackDrop';

// type Props = {
//     produtoDescontoId: string,
//     campanhaId: string,
//     ofertaId: string,
//     open: boolean,
//     refresh: number,
//     onClose: () => void,
//     default: { inicio: string, final: string, uf: string, rede: string }
// };

const ProdutoDescontoEditar = (props) => {


    const projeto = getProjetoStorage();

    const [produtoDescontoId, setProdutoDescontoId] = React.useState("");
    const [campanhaId, setCampanhaId] = React.useState("");
    const [ofertaId, setOfertaId] = React.useState("");
    
    const [processando, setProcessando] = React.useState(false);
    const [openBuscaProduto, setOpenBuscaProduto] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(true);
    const [listaClassificacoes, setListaClassificacoes] = React.useState([]);
    const [formProduto, setFormProduto] = React.useState(formProdutoDefault);
    const [formCampanha, setFormCampanha] = React.useState(formCampanhaDefault);
    const [formOferta, setFormOferta] = React.useState(formOfertaDefault);
    const [dataInicio, setDataInicio] = React.useState("");
    const [dataFinal, setDataFinal] = React.useState("");
    const [campanhaNome, setCampanhaNome] = React.useState("");
    const [error, setError] = React.useState(undefined);
    const [lstTipoPonto, setLstTipoPonto] = React.useState([]);



    const closeEdit = () => {

    }

    const carregarClassificacoes = () => {
        setProcessando(true);

        classificacaoHttp.list()
            .then((retorno) => {
                if (retorno.data.sucesso === true) {

                    const lista = [{ id: '', label: '' }];
                    retorno.data.dados.forEach((el) => {
                        lista.push({ id: el.id + '', label: el.classificacaoNome });
                    });

                    setListaClassificacoes(lista);
                }
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setProcessando(false);
            });

    }

    const carregarListaPontos = async () => {
        try {
            const {data} = await ofertaHttp.listaTipoPonto();
            const lista = [];
            data.forEach(item => {
                lista.push({value: item.id + '', text: item.descricao});
            });
            setLstTipoPonto([{value: '', text: '...Selecionar'}, ...lista]);
           
        } catch (error) {
            setError(error);
        }
    }

    const openDetail = async () => {

        setProcessando(true);
        setError(undefined);

        try {

            if (lstTipoPonto.length === 0) {
                await carregarListaPontos();
            }

            // props.campanhaId, props.ofertaId, props.produtoDescontoId, props.open
            let _produtoDescontoId = Number(props.produtoDescontoId);
            let _ofertaId = Number(props.ofertaId);
            let _campanhaId = Number(props.campanhaId);

            // salvar session form em variavel e setar no final
            let _formProduto = {
                ...formProdutoDefault,
                alterado: true,
                projetoId: Number(projeto.id),
                campanhaChaveId: Number(_campanhaId),
                qaStatus: ''
            };
            let _formCampanha = {
                ...formCampanhaDefault,
                campanhaChaveId: _campanhaId,
                dataFinal: props.default.final,
                dataInicio: props.default.inicio,
                uf: props.default.uf,
                redeId: Number(props.default.rede),
                projetoId: Number(projeto.id),
                alterado: true
            };
            let _formOferta = {
                ...formOfertaDefault
                , alterado: true
                , campanhaChaveId: _campanhaId
            };

            if (_produtoDescontoId) {
                // carregar dados produto
                const { data } = await gestaoDescontoHttp.obterDescontoProduto(_produtoDescontoId);

                if (data.sucesso === true) {
                    const _produto = data.dado; 
                    let qaStatus = _produto.qaStatus == null ? '' : _produto.qaStatus + '';
                    _formProduto = { ..._produto, precoRef: TxtDouble(_produto.precoRef), qaStatus: qaStatus, alterado: false };
                    _campanhaId = _produto.campanhaChaveId;
                    _ofertaId = _produto.ofertaItemId;
                }
            }

            if (_campanhaId) {
                // carregar dados da campanha
                const { data } = await gestaoDescontoHttp.obterDescontoCampanha(_campanhaId);
                if (data.sucesso === true) {
                    const _campanha = data.dado;
                    _formCampanha = { ..._campanha, alterado: false };
                    _formProduto.campanhaChaveId = _campanha.campanhaChaveId;
                    _formOferta.campanhaChaveId = _campanha.campanhaChaveId;

                }
            }

            if (_ofertaId && _campanhaId) {
                // carregar dados da oferta
                const { data } = await gestaoDescontoHttp.obterDescontoOferta(_ofertaId, _campanhaId);
                if (data.sucesso === true) {
                    const _oferta = data.dado;
                    _oferta.descontoTipoId = _oferta.descontoTipoId === null ? 0 : _oferta.descontoTipoId;
                    let _tabindex = 0;

                    if (_formProduto.codigoKit) {
                        _tabindex = 3;
                    }
                    else if (_oferta.descontoTipoId === 2 || _oferta.descontoTipoId === 1 || _oferta.descontoTipoId === 0)
                        _tabindex = 0;
                    else if (_oferta.descontoTipoId === 4)
                        _tabindex = 1;
                    else if (_oferta.cashbackTipoId)
                        _tabindex = 2

                    _formOferta = { ..._oferta, alterado: false, 
                        desconto: TxtDouble(_oferta.desconto),
                        descontoKit: TxtDouble(_oferta.descontoKit),
                        cashback: TxtDouble(_oferta.cashback),
                        ponto: TxtDouble(_oferta.ponto),
                        pontoTipoId: TxtInt(_oferta.pontoTipoId),
                        valorDescZicard: TxtDouble(_oferta.valorDescZicard), 
                        ofertaTabIndex: _tabindex 
                    };
                }
            }

            setFormOferta(_formOferta);
            setFormCampanha(_formCampanha);
            setFormProduto(_formProduto);

            setDataFinal(_formCampanha.dataFinal);
            setDataInicio(_formCampanha.dataInicio);

            setCampanhaId(_campanhaId ? _campanhaId + '' : '');
            setOfertaId(_ofertaId + '');

            setCampanhaNome(_formCampanha.campanhaNome);


        } catch (error) {
            setError(error);
        } finally {
            setProcessando(false);
        }



    }

    const onSubmit = async (e) => {

        e.preventDefault();
        try {

            // validacao kit
            if(formOferta.ofertaTabIndex === 3) {
                if (!formProduto.codigoKit || !formProduto.qtdeKit || !formOferta.descontoKit) {
                    ShowAlert.error(`Oferta tipo KIT, dados incorretos`);
                    return;
                }
            }
            else {
                formProduto.codigoKit = '';
                formProduto.qtdeKit = '';
                formOferta.descontoKit = '';
            }

            setProcessando(true);
            let produto = {
                id: Number(formProduto.id),
                projetoId: Number(projeto.id),
                produtoCodigo: formProduto.produtoCodigo,
                produtoNome: formProduto.produtoNome,
                precoRef: Number(formProduto.precoRef),
                campanhaChaveId: Number(formCampanha.campanhaChaveId),
                ofertaItemId: Number(formProduto.ofertaItemId),
                codigoKit: formProduto.codigoKit,
                qtdeKit: Number(formProduto.qtdeKit),
                qaStatus: !formProduto.qaStatus || isNaN(formProduto.qaStatus) ? null : Number(formProduto.qaStatus),
                alterado: formProduto.alterado
            };
            let oferta = {
                ofertaItemId: Number(formOferta.ofertaItemId),
                campanhaChaveId: Number(formCampanha.campanhaChaveId),
                desconto: Number(formOferta.desconto),
                descontoKit: Number(formOferta.descontoKit),
                valorDescZicard: Number(formOferta.valorDescZicard),
                descontoTipoId: Number(formOferta.descontoTipoId),
                descontoProgressivo: formOferta.descontoProgressivo,
                cashbackTipoId: Number(formOferta.cashbackTipoId),
                cashback: Number(formOferta.cashback),
                ponto: Number(formOferta.ponto),
                pontoTipoId: Number(formOferta.pontoTipoId),
                alterado: formOferta.alterado,
                flagKit: formOferta.ofertaTabIndex === 3 ? true : false,
            };
            let campanha = {
                campanhaChaveId: Number(campanhaId),
                projetoId: Number(projeto.id),
                campanhaNome: campanhaNome,
                dataInicio: dataInicio,
                dataFinal: dataFinal,
                redeId: Number(formCampanha.redeId),
                uf: formCampanha.uf,
                flagClassificacao: formCampanha.flagClassificacao,
                classificacoes: formCampanha.classificacoes,
                alterado: formCampanha.alterado
            };

            if (formCampanha.alterado === true) {
                campanha.campanhaChaveId = 0;
                //  atualizaDescontoCampanha.   .
                const { data } = await gestaodescontoHttp.atualizaDescontoCampanha(campanha);
                if (data.sucesso !== true || !data.dado?.campanhaChaveId) {
                    throw new Error("Falha ao atualizar campanha", { cause: data });
                }
                campanha = data.dado;
                setCampanhaId(campanha.campanhaChaveId);
                oferta.campanhaChaveId = campanha.campanhaChaveId;
                produto.campanhaChaveId = campanha.campanhaChaveId;
                setFormCampanha({ ...campanha, alterado: false });
                setFormOferta({ ...formOferta, campanhaChaveId: campanha.campanhaChaveId });
                setFormProduto({ ...formProduto, campanhaChaveId: campanha.campanhaChaveId });
            }

            if (formOferta.alterado === true) {
                oferta.ofertaItemId = 0;
                if (oferta.descontoTipoId === 0) {
                    oferta.desconto = 0;
                } else if (oferta.descontoTipoId === 2 && oferta.desconto > 1) {
                    oferta.desconto = oferta.desconto / 100;
                }

                if (oferta.cashbackTipoId === 1 && oferta.cashback > 1) {
                    oferta.cashback = oferta.cashback / 100;
                }
                // atualizaDescontoOferta
                const { data } = await gestaodescontoHttp.atualizaDescontoOferta(campanha.campanhaChaveId, oferta);
                if (data.sucesso !== true || !data.dado?.ofertaItemId) {
                    throw new Error("Falha ao atualizar oferta", { cause: data });
                }
                oferta = data.dado;
                oferta.desconto = TxtDouble(oferta.desconto);
                oferta.descontoKit = TxtDouble(oferta.descontoKit);
                setOfertaId(oferta.ofertaItemId);
                produto.ofertaItemId = oferta.ofertaItemId;

                let _tabindex = formOferta.ofertaTabIndex;
                // if (formProduto.codigoKit) {
                //     _tabindex = 3;
                // }
                // if (oferta.descontoTipoId === 2 || oferta.descontoTipoId === 1 || oferta.descontoTipoId === 0)
                //     _tabindex = 0;
                // else if (oferta.descontoTipoId === 4)
                //     _tabindex = 1;
                // else if (oferta.cashbackTipoId)
                //     _tabindex = 2

                setFormOferta({ ...oferta, alterado: false, ofertaTabIndex: _tabindex });
                setFormProduto({ ...formProduto, ofertaItemId: oferta.ofertaItemId });
            }

            if (formProduto.alterado === true) {
                // atualizaDescontoProduto
                const { data } = await gestaodescontoHttp.atualizaDescontoProduto(produto);
                if (data.sucesso !== true || !data.dado.id) {
                    throw new Error("Falha ao atualizar produto", { cause: data });
                }
                produto = data.dado;
                setFormProduto({ ...produto, alterado: false });
            }

            ShowAlert.error(`Oferta produto ${produto.produtoCodigo} atualizado com sucesso`);
            setProcessando(false);

        } catch (error) {
            setProcessando(false);
            setError(error);
        }

    }

    const closeDialog = () => {
        props.onClose();
    }

    const SelecionarProduto = (value, preco, nome) => {
        setFormProduto({ ...formProduto, produtoCodigo: value, produtoNome: nome, precoRef: preco.toFixed(2) });
        setOpenBuscaProduto(false);
        setOpenDialog(true);
    }

    const CloseSelecionar = (e) => {
        setOpenBuscaProduto(false);
        setOpenDialog(true);
    }

    const inputPropsBusca = {
        InputProps: {
            endAdornment: (
                <InputAdornment position="end" hidden={produtoDescontoId ? true : false}>
                    <IconButton onClick={(e) => {
                        if (!produtoDescontoId) {
                            setOpenBuscaProduto(true);
                            setOpenDialog(false);
                        }
                    }}>
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            )
        }
    }

    const handleTab = (newValue) => {
        let cashBackTipoId = 0;
        let descontoTipoId = 0;

        if (newValue === 0) {
            // desconto normal -  integral ou percentual
            descontoTipoId = 1;
        }
        else if (newValue === 1) {
            // desconto progressivo
            descontoTipoId = 4;
        }
        else if (newValue === 2) {
            // cashback 1 - percentual  , 2 fixo
            cashBackTipoId = 2;
        }
        else {
            // desconto kit - integral ou percentual
            descontoTipoId = 1;
        }

        setFormOferta({
            ...formOferta, ofertaTabIndex: newValue,
            descontoTipoId: descontoTipoId,
            desconto: '',
            descontoKit: '',
            cashback: '',
            descontoProgressivo: '',
            cashbackTipoId: cashBackTipoId
        });

        setFormProduto({...formProduto,
            codigoKit: '',
            qtdeKit: '',
        });
    }

    const deleteProduto = async () => {
        setProcessando(true);
        try {
            await gestaodescontoHttp.deleteProduto(Number(produtoDescontoId), Number(campanhaId), Number(ofertaId));
            setCampanhaId("");
            setFormCampanha({ ...formCampanha, campanhaChaveId: 0, alterado: true });
            setFormOferta({ ...formOferta, campanhaChaveId: 0, alterado: true });
            setFormProduto({ ...formProduto, campanhaChaveId: 0, alterado: true });
        } catch (error) {
            setError(error);
        } finally {
            setProcessando(false);
        }

    }
    
    
    React.useEffect(() => {
        carregarClassificacoes();
        setCampanhaId(props.campanhaId);
        setOfertaId(props.ofertaId);
        setProdutoDescontoId(props.produtoDescontoId);
        setOpenDialog(props.open);
        setOpenBuscaProduto(false);
        openDetail();

    }, [props.campanhaId, props.ofertaId, props.produtoDescontoId, props.open, props.refresh])
    
    React.useEffect(() => {
        // DERMA 1013-BR 2022-12-28-2022-02-02
        if (!campanhaId) {
            const inicio = (dataInicio || '').split('/').reverse().join('-');
            const final = (dataFinal || '').split('/').reverse().join('-');
            const rede = (formCampanha.redeId + '').padStart(4, '0');

            setCampanhaNome(`${projeto.nome} ${rede}-${formCampanha.uf} ${inicio} ${final}`);
        }

    }, [dataInicio, dataFinal])
    


    return (
        <React.Fragment>
            <TsDialog sx={{hidden: processando}}  onClose={() => closeEdit()} id="dialog-desconto" open={openDialog} title="Editar um desconto por produto" minWidth={800} >
                <>
                    <form>
                        <Box margin={1} sx={{ background: "#eee", display: "flex" }}>
                            <Box padding={1} sx={{ flex: "1 0 auto" }} ><CircleIcon sx={{ color: formCampanha.alterado ? "#f00" : "#0f0" }} /></Box>
                            <Box padding={1} sx={{ flex: "1 0 auto" }} ><strong> {campanhaId} </strong></Box>
                            <Box padding={1} sx={{ flex: "8 0 auto" }} >
                                CAMPANHA</Box>
                            <Box sx={{ flex: "1 0 auto" }} >
                                <IconButton
                                    onClick={(e) => {
                                        if (campanhaId) {
                                            deleteProduto();
                                        }
                                    }}
                                >{campanhaId ? <LinkIcon /> : <LinkOffIcon />}</IconButton>
                            </Box>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TsTextField
                                    name="campanhaNome"
                                    label="Nome"
                                    fullWidth
                                    value={TxtString(campanhaNome)}
                                    disabled={campanhaId ? true : false}
                                    onChange={(e) => {
                                        setCampanhaNome(e.target.value);
                                        setFormCampanha({ ...formCampanha, alterado: true, campanhaNome: e.target.value });
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={2}><FormControlLabel
                                control={<Checkbox
                                    disabled={campanhaId ? true : false}
                                    checked={formCampanha.flagClassificacao}
                                    onClick={(e) => {
                                        setFormCampanha(
                                            {
                                                ...formCampanha,
                                                flagClassificacao: !formCampanha.flagClassificacao,
                                                alterado: true
                                            });
                                    }}
                                />}
                                label="Classificação"
                            /></Grid>
                            <Grid item xs={5}>
                                <TsTextField
                                    name="dataInicio"
                                    label="Inicio"
                                    fullWidth
                                    value={TxtString(dataInicio)}
                                    disabled={campanhaId ? true : false}
                                    onChange={(e) => {
                                        setDataInicio(e.target.value);
                                        setFormCampanha({ ...formCampanha, alterado: true, dataInicio: e.target.value });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TsTextField
                                    name="dataFinal"
                                    label="Final"
                                    fullWidth
                                    value={TxtString(dataFinal)}
                                    disabled={campanhaId ? true : false}
                                    onChange={(e) => {
                                        setDataFinal(e.target.value);
                                        setFormCampanha({ ...formCampanha, alterado: true, dataFinal: e.target.value });
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {formCampanha.flagClassificacao &&
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <SelectCampo
                                        id='lstNovaClassificacao'
                                        label=' ... Adicionar'
                                        name='novaClassificacao'
                                        disabled={campanhaId ? true : false}
                                        options={listaClassificacoes.map(el => { return { value: el.id, text: el.label } })}
                                        value=''
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                const item = listaClassificacoes.find(el => el.id === e.target.value);

                                                if (item && item.id) {
                                                    const id = Number(item.id);
                                                    const label = item.label;
                                                    const classificacoes = [...formCampanha.classificacoes];
                                                    if (classificacoes.findIndex((el) => el.id === id) < 0) {
                                                        classificacoes.push({ id: id, classificacao: label });
                                                        setFormCampanha({ ...formCampanha, classificacoes: classificacoes, alterado: true });
                                                    }
                                                }
                                            }
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={8}><TsFilterChips
                                    items={formCampanha.classificacoes.map((el) => el.classificacao)}
                                    onChange={(items) => {
                                        const classificacoes = [];

                                        items.forEach((el) => {
                                            const cl = listaClassificacoes.find((it) => it.label === el);
                                            if (cl) classificacoes.push({ id: Number(cl.id), classificacao: cl.label });

                                        });

                                        setFormCampanha({ ...formCampanha, classificacoes: classificacoes, alterado: true });
                                    }} /></Grid>
                            </Grid>
                        }
                        <Box padding={1} margin={1} sx={{ background: "#eee" }}>
                            <CircleIcon sx={{ color: formProduto.alterado ? "#f00" : "#0f0" }} /> PRODUTO - {formProduto.produtoNome}</Box>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TsTextField
                                    name="produtoCodigo"
                                    label="Codigo"
                                    fullWidth
                                    value={TxtString(formProduto.produtoCodigo)}
                                    onChange={(e) => { setFormProduto({ ...formProduto, produtoCodigo: e.target.value, alterado: true }) }}
                                    {...inputPropsBusca}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TsTextField
                                    name="precoRef"
                                    label="Preço"
                                    tipo="decimal"
                                    fullWidth
                                    value={formProduto.precoRef}
                                    onChange={(e) => { setFormProduto({ ...formProduto, precoRef: TxtDouble(e.target.value), alterado: true }) }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SelectCampo
                                    id='lstQaStatus'
                                    label='qa-status'
                                    name='qaStatus' 
                                    options={[{value: '', text: 'N/A'}, {value: '1', text: 'status-1'}, {value: '2', text: 'status-2'}, {value: '5', text: 'status-5'}]}
                                    value={TxtString(formProduto.qaStatus)}
                                    onChange={(e) => { 
                                        setFormProduto({ ...formProduto, qaStatus: Number(e.target.value), alterado: true });
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ paddingTop: 1 }}>
                            <Grid item xs={4}><SelectCampo
                                    id='lstpontoTipoId'
                                    label='Tipo Ponto'
                                    name='pontoTipoId'
                                    options={lstTipoPonto}
                                    value={TxtInt(formOferta.pontoTipoId)}
                                    onChange={(e) => { 
                                        setFormOferta({ ...formOferta, pontoTipoId: e.target.value, alterado: true });
                                        setFormProduto({ ...formProduto, alterado: true });
                                    }}
                                /></Grid>
                            <Grid item xs={4}><TsTextField
                                    name="ponto"
                                    tipo="decimal"
                                    label="Valor Ponto"
                                    fullWidth
                                    value={TxtDouble(formOferta.ponto)}
                                    onChange={(e) => { 
                                        setFormOferta({ ...formOferta, ponto: TxtDouble(e.target.value), alterado: true });
                                        setFormProduto({ ...formProduto, alterado: true });
                                    }}
                                /></Grid>
                            <Grid item xs={4}>
                                <TsTextField
                                    name="valorDescZicard"
                                    tipo="decimal"
                                    label="Desconto Zicard"
                                    fullWidth
                                    value={formOferta.valorDescZicard}
                                    onChange={(e) => { 
                                        setFormOferta({ ...formOferta, valorDescZicard: TxtDouble(e.target.value), alterado: true });
                                        setFormProduto({ ...formProduto, alterado: true });
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: "flex", background: "#eee" }}>
                            <Box padding={1.5} sx={{ flex: "1 0 auto" }}><CircleIcon sx={{ color: formOferta.alterado ? "#f00" : "#0f0" }} /></Box>
                            <Box sx={{ flex: "8 0 auto" }}>
                                <Tabs
                                    value={formOferta.ofertaTabIndex}
                                    orientation="horizontal"
                                    onChange={(e, newValue) => handleTab(newValue)}
                                    centered
                                    sx={{}}
                                >
                                    <Tab label="Desconto Item" id="oferta-tab-0" ></Tab>
                                    <Tab label="Desconto progressivo" id="oferta-tab-1" ></Tab>
                                    <Tab label="Cashback" id="oferta-tab-2" ></Tab>
                                    <Tab label="Desconto Kit" id="oferta-tab-3" ></Tab>
                                </Tabs>
                            </Box>
                        </Box>

                        {formOferta.ofertaTabIndex === 0 &&
                            <Grid container spacing={3} sx={{ minHeight: 260, paddingTop: 3 }}>
                                <Grid item xs={6}>
                                    <SelectCampo
                                        label='Tipo desconto'
                                        id='sel_tipo_desconto'
                                        value={TxtInt(formOferta.descontoTipoId)}
                                        onChange={(e) => {
                                            setFormOferta({ ...formOferta, descontoTipoId: Number(e.target.value), alterado: true });
                                            setFormProduto({ ...formProduto, alterado: true });
                                        }
                                        }
                                        name='descontoTipoId'
                                        options={[{ value: "0", text: "SEM DESCONTO" },
                                        { value: "1", text: "DESCONTO INTEGRAL" },
                                        { value: "2", text: "DESCONTO PERCENTUAL" }
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TsTextField
                                        name="desconto"
                                        label="Desconto"
                                        tipo="decimal"
                                        fullWidth
                                        value={formOferta.desconto}
                                        onChange={(e) => {
                                            setFormOferta({ ...formOferta, desconto: TxtDouble(e.target.value), alterado: true });
                                            setFormProduto({ ...formProduto, alterado: true });
                                        }}
                                    />
                                </Grid>
                            </Grid>}
                        {formOferta.ofertaTabIndex === 1 &&
                            <>
                                <TsTextField
                                    name="descontoProgressivo"
                                    label="Desconto Progressivo"
                                    fullWidth
                                    value={TxtString(formOferta.descontoProgressivo)}
                                    onChange={(e) => {
                                        setFormOferta({ ...formOferta, descontoProgressivo: e.target.value, alterado: true });
                                        setFormProduto({ ...formProduto, alterado: true });
                                    }}
                                    multiline={true}
                                    minRows={4}
                                />
                                <div><div>Exemplo</div>
                                    <Box sx={{ ...preWrapCSS, fontSize: 12 }} >{exemploProgressivo}</Box>
                                </div>
                            </>
                        }
                        {formOferta.ofertaTabIndex === 2 &&
                            <Grid container spacing={3} sx={{ minHeight: 260, paddingTop: 3 }}>
                                <Grid item xs={6}>
                                    <SelectCampo
                                        label='Tipo cashback'
                                        id='sel_tipo_cashback'
                                        value={TxtInt(formOferta.cashbackTipoId)}
                                        onChange={(e) => {
                                            setFormOferta({ ...formOferta, cashbackTipoId: Number(e.target.value), alterado: true });
                                            setFormProduto({ ...formProduto, alterado: true });
                                        }}
                                        name='cashbackTipoId'
                                        options={[{ value: "", text: "" },
                                        { value: "1", text: "PERCENTUAL DA VENDA" },
                                        { value: "2", text: "FIXO POR ITEM" }
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TsTextField
                                        name="cashback"
                                        label="Cashback"
                                        tipo="decimal"
                                        fullWidth
                                        value={formOferta.cashback}
                                        onChange={(e) => {
                                            setFormOferta({ ...formOferta, cashback: TxtDouble(e.target.value), alterado: true });
                                            setFormProduto({ ...formProduto, alterado: true });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {formOferta.ofertaTabIndex === 3 &&
                            <>
                            <Grid container spacing={3} sx={{ minHeight: 130, paddingTop: 3 }}>
                                <Grid item xs={6}>
                                    <SelectCampo
                                        label='Tipo desconto'
                                        id='sel_tipo_desconto_kit'
                                        value={TxtInt(formOferta.descontoTipoId)}
                                        onChange={(e) => {
                                            setFormOferta({ ...formOferta, descontoTipoId: Number(e.target.value), alterado: true });
                                            setFormProduto({ ...formProduto, alterado: true });
                                        }
                                        }
                                        name='descontoTipoId'
                                        options={[{ value: "0", text: "SEM DESCONTO" },
                                        { value: "1", text: "DESCONTO INTEGRAL" },
                                        { value: "2", text: "DESCONTO PERCENTUAL" }
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TsTextField
                                        name="desconto"
                                        label="Desconto"
                                        tipo="decimal"
                                        fullWidth
                                        value={formOferta.desconto}
                                        onChange={(e) => {
                                            setFormOferta({ ...formOferta, desconto: TxtDouble(e.target.value), alterado: true });
                                            setFormProduto({ ...formProduto, alterado: true });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ minHeight: 130, paddingTop: 3 }}>
                                <Grid item xs={4}>
                                    <TsTextField
                                        name="codigoKit"
                                        label="Código kit"
                                        fullWidth
                                        value={TxtString(formProduto.codigoKit)}
                                        onChange={(e) => { setFormProduto({ ...formProduto, codigoKit: e.target.value, alterado: true }) }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TsTextField
                                        name="qtdeKit"
                                        label="Qtde kit"
                                        fullWidth
                                        value={TxtInt(formProduto.qtdeKit)}
                                        onChange={(e) => { setFormProduto({ ...formProduto, qtdeKit: e.target.value, alterado: true }) }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TsTextField
                                        name="descontoKit"
                                        label="Desconto Kit"
                                        fullWidth
                                        value={TxtInt(formOferta.descontoKit)}
                                        onChange={(e) => { setFormOferta({ ...formOferta, descontoKit: TxtDouble(e.target.value), alterado: true }) }}
                                    />
                                </Grid>
                            </Grid>     
                            </>
                            }
                        
                        {!processando && <Box dir="rtl">
                            <TsButton
                                onClick={(e) => onSubmit(e)}
                            >Enviar</TsButton>
                            <TsButton
                                onClick={(e) => closeDialog()}
                                color='secondary'
                            >Cancelar</TsButton>
                        </Box>}
                        {processando && <Box dir="rtl">
                            ... processando
                        </Box>}

                    </form>
                    {error !== undefined && <ShowError error={error} mensagemPadrao="Erro ao enviar desconto" url="/campanha/alterarDesconto - POST" />}
                </>
            </TsDialog>
            <ProdutoBuscaDialog versaoApi="4" open={openBuscaProduto} onSelect={SelecionarProduto} onClose={CloseSelecionar} cnpj="" />
            <TsBackDrop processando={processando} />
        </React.Fragment>
    );


};

export default ProdutoDescontoEditar;
