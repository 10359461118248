import * as React from 'react';
import { Box, Link } from '@mui/material';

import { format, parseISO } from 'date-fns';
import { Page } from '../Page';
import EventNoteIcon from '@mui/icons-material/EventNote';

/* http */
import transacaoHttp from '../../util/http/transacao-http';
/* datatable */
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ListaBase from '../ListaBase';
import {formatNumber} from '../../util/Util';
import TransacaoDetalhe from './transacao-detalhe';

const selectorCSS = {
    cursor: "pointer"
};

// <TsDataGridx />
class Transacao extends ListaBase {

    constructor(props) {
        super(props);
        this.serverPageSize = 50;
        
        this.state.open = false;
        this.state.detalhes = '';
        this.state.Id = undefined;

        this.columns = [
            {
                name: "id", label: "id",
                format: ((value) => (<Link component="button" onClick={() => this.setId(value)}><EventNoteIcon /></Link>))
            },
            {
                name: "dataTransacao", label: "DATA",
                format: (value) => { return <span style={selectorCSS}>{format(parseISO(value), 'dd/MM/yy HH:mm')}</span> }
            },
            {
                name: 'rede', label: 'REDE'
            },
            {
                name: "cnpj", label: "CNPJ",
                format: (value) => { return <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cnpj: "' + value + '"'})}>{value}</span> }
            },
            {
                name: "cpf", label: "CPF",
                format: (value) => { return <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cpf: "' + value + '"'})}>{value}</span> }
            },
            {
                name: "terminal", label: "Terminal",
            },
            {
                name: "numCupom", label: "numCupom",
                format: (value) => { return <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'cupom: "' + value + '"'})}>{value}</span> }
            },
            {
                name: "codigoProduto", label: "codigoProduto",
                format: (value) => { return <span style={selectorCSS} onClick={() => this.setObjBusca({...this.state.objBusca, busca: 'produto: "' + value + '"'})}>{value}</span> }
            },
            { name: "dclubeTransacaoId", label: "Dclube-ID" },
            { name: "desconto", label: "Desconto",
             format: (value) => (<>{formatNumber(value)}</>) }
        ];
    }

    setId(Id) {
        this.setState({Id: Id});
    }
 

    Buscar = () => {
        this.iniciarBusca();

        const query = { page: this.state.serverPage, nrecPage: this.serverPageSize, ativo: this.state.objBusca.ativo, busca: this.state.objBusca.busca };

        transacaoHttp.busca(query)
            .then(({ data }) => {
                this.setRows(data.dados);
            })
            .catch((error) => {
                this.setError(error);
            })
            .finally(() => {
                this.setProcessando(false);
                this.setBuscando(false);
                this.setUrl(transacaoHttp.getUrl());
            });
    };

    componentDidMount() {
        super.componentDidMount();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.execBuscar === true) this.Buscar();
    }

    render() {
    return (
        <Page title="Listar Transações">
            <Box>
                {this.state.error === undefined &&
                <>
                <TsInputBusca
                    name="input-busca"
                    value={this.state.objBusca.busca}
                    showAtivo={false}
                    showBusca={true}
                    submitSearch={(texto, ativo) => { this.setObjBusca({...this.state.objBusca, ativo: ativo, busca: texto}); }}
                ></TsInputBusca>
                <TsDataGrid
                    rows={this.state.rows}
                    columns={this.columns}
                    queryid={this.state.queryid}
                    serverPage={this.state.serverPage}
                    serverPagesize={this.serverPageSize}
                    onChangePage={(value) => this.setServerPage(value)}
                />
                    
                </>
                    }
                {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}

            </Box>
            {this.state.error !== undefined && <ShowError error={this.state.error} url={this.state.url} mensagemPadrao="Falha ao processar request" />}
            <TsBackDrop processando={this.state.processando} />
            <TransacaoDetalhe   
                Id={this.state.Id}
                setProcessando={(value) => this.setProcessando(value)}
            />
        </Page>
    );
    }
};

export default Transacao;