import * as React from 'react';
import redeHttp from '../../util/http/rede-http';
import {Link, Button} from '@mui/material';
import TsDataGrid from '../../formulario/TsDataGrid';
import TsBackDrop from '../../formulario/TsBackDrop';
import TsInputBusca from '../../formulario/TsInputBusca';
import ShowError from '../../formulario/ShowError';
import ShowAlert from '../../util/ShowAlert';
import EventNoteIcon from '@mui/icons-material/EventNote';
import {getProjetoStorage} from '../../util/auth';

const SofthouseList = (props) => {

    const projAtual = getProjetoStorage();
    const setDetail = props.setDetail;
    const serverPageSize = 50;
    const detail = props.detail;
    const [rows, setRows] = React.useState([]);
    const [error, setError] = React.useState(undefined);
    const [url, setUrl] = React.useState("");
    const [processando, setProcessando] = React.useState(false); 
    const [serverPage, setServerPage] = React.useState(0);
    const [objBusca, setObjBusca] = React.useState({busca: '', projetoId: Number(projAtual.id)});
    const [queryid, setQueryid] = React.useState("");
    


    const columns = [
        {
            name: "id",
            label: "#",
            format: ((value) => (<Link component={Button} onClick={(e) => {setDetail({open: true, id: value, title: "Detalhes de Softhouse"})}}><EventNoteIcon /></Link>))
        },
        {
            name: "id",
            label: "Código"
        },
        {
            name: "nome",
            label: "Softhouse"
        },
        {
            name: "redes",
            label: "redes"
        }
    ];

    const Buscar = async () => {
        if (processando) {
            return;
        }
        setQueryid("");
        setProcessando(true);
        
        try {
            setRows([]);

            const query = { page: serverPage, nrecPage: serverPageSize, projetoId: projAtual.id, busca: objBusca.busca };
            const {data} = await redeHttp.buscaSofthouse(query);
            if (data.sucesso === true && data.dados && data.dados.length > 0) {
                setRows(data.dados);
            }
            else {
                ShowAlert.info(data.mensagem);
            }
        } catch (error) {
            setUrl(redeHttp.getUrl());
            setError(error);
        } 
        setProcessando(false);
        
    }

    React.useEffect(() => {
        if (detail.open === false) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    React.useEffect(() => {
        if (detail.open === false) {
            Buscar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objBusca]);

    return (
    <div>
        <>
            <TsInputBusca
                name="input-busca"
                value={objBusca.busca}
                showBusca={true}
                showAtivo={false}
                showAdd={false}
                submitSearch={(texto, ativo) => { setObjBusca({...objBusca, busca: texto}); }}
            >
            </TsInputBusca>
        </>
        <TsDataGrid
                rows={rows}
                columns={columns}
                queryid={queryid}
                serverPage={serverPage}
                serverPagesize={serverPageSize}
                onChangePage={(value) => setServerPage(value)}
                />
       <ShowError error={error} url={url} mensagemPadrao="Falha ao processar request" />
        <TsBackDrop processando={processando} />
    </div>)
}

export default SofthouseList;