/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { IconButton, Menu as MuiMenu, MenuItem } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import routes from '../routes';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { HOMEDIR } from '../util/auth';

export const Menu = (props) => {
  const { mode, atual, changeMode } = props;
  const [menuRoutes, setMenuRoutes] = React.useState([]);
  const navegate = useNavigate();

  const makeRoutes = () => {
    const tempRoutes = [];
    const adminPerm = atual.usuarioPermit.find((pu) => pu.modulo === 'admin');
    const adminLevel = adminPerm ? adminPerm.permit : 0;

    if (mode && (atual.modePainel === true || atual.modeProxy === true)) {
      routes
        .filter((route) => route.menuOrder > 0)
        .sort((route) => route.menuOrder)
        .forEach((route) => {
          if (atual.id > 0 && (route.mode === mode || route.mode === '')) {
            const permUser = atual.usuarioPermit.find((pu) => pu.modulo === route.modulo);
            let userLevel = permUser ? permUser.permit : 0;
            if (adminLevel > userLevel) userLevel = adminLevel;

            if (route.modulo === 'home' && userLevel < 2) userLevel = 0;

            if (userLevel >= route.permit) {
              tempRoutes.push(route);
            }
          }
        });
    }

    setMenuRoutes(tempRoutes);
    if (changeMode) {
      navegate(`${HOMEDIR}/`);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (e) => {
    props.checkUser();
    setAnchorEl(null);
  };

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  // const handleClose = () => setAnchorEl(null);
  //console.l og("menuRoutes", menuRoutes);

  React.useEffect(() => {
    makeRoutes();
  }, [mode, atual, changeMode]);

  if (menuRoutes.length === 0) {
    return <></>;
  }

  return (
    <div style={{flex: '0 0 auto', width: '30px'}}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="abrir menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MenuIcon />
      </IconButton>
      <MuiMenu
        id="menu-appbar"
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem> ... {props.mode.toUpperCase()}</MenuItem>
        {menuRoutes.map((route, key) => {
          const path = route.path;
          const label = route.menuTitle;
          return (
            <MenuItem key={key} onClick={handleMenu} component={RouterLink} to={path}>
              {label}
            </MenuItem>
          );
        })}
      </MuiMenu>
    </div>
  );
};
