import { validarForm } from './CampoBase';

export const LimitadorCampos = {
  chaveLimitador: { name: 'chaveLimitador', label: 'chaveLimitador', tipo: 'string', max: 50, min: 1 },
  projetoId: { name: 'projetoId', label: 'projetoId', tipo: 'number', max: 9, min: 1 },
  descricao: { name: 'descricao', label: 'descricao', tipo: 'string', max: 150, min: 1 },
  intervaloMinutos: { name: 'intervaloMinutos', label: 'intervaloMinutos', tipo: 'number', max: 9, min: 1 },
  nItensTotalPeriodo: { name: 'nItensTotalPeriodo', label: 'nItensTotalPeriodo', tipo: 'number', max: 9, min: 1 },
  qtdeEanPeriodo: { name: 'qtdeEanPeriodo', label: 'qtdeEanPeriodo', tipo: 'number', max: 9, min: 1 },
  nCompraPeriodo: { name: 'nCompraPeriodo', label: 'nCompraPeriodo', tipo: 'number', max: 9, min: 1 },
};

export const validarLimitador = (camp) => {
  return validarForm(camp, LimitadorCampos);
};
