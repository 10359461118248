import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class RelatorioHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.http = http;
    this.resource = resource;
    this.url = baseUrl;
  }

  resumo(tipo) {
    // resumo_transacao/{projetoId}
    this.url = `/${this.resource}/general_data/sumario - GET`;
    return this.http.get(`${this.resource}/general_data/${tipo}`, this.config);
  }

  obterTokenPowerBi() {
    this.url = `/${this.resource}/powerbi - GET`;
    return this.http.get(`${this.resource}/powerbi`, this.config);
  }

  obterPainelItem(modulo) {
    // /api/relatorio/painel-item/{modulo}
    this.url = `/${this.resource}/painel-item/${modulo} - GET`;
    return this.http.get(`${this.resource}/painel-item/${modulo}`, this.config);
  }

  obterLista(nome, projetoId) {
    // /api/relatorio/obter-lista/{nomeLista}
    if (projetoId) {
      this.url = `/${this.resource}/obter-lista/${nome}?projetoId=${projetoId} - GET`;
      return this.http.get(`${this.resource}/obter-lista/${nome}?projetoId=${projetoId}`, this.config);
    } else {
      this.url = `/${this.resource}/obter-lista/${nome} - GET`;
      return this.http.get(`${this.resource}/obter-lista/${nome}`, this.config);
    }
  }

  obterDashData(modulo, busca) {
    this.url = `/${this.resource}/dash/${modulo} - POST`;
    return this.http.post(`${this.resource}/dash/${modulo}`, { busca: JSON.stringify(busca) }, this.config);
  }
}

const relatorioHttp = new RelatorioHttpResource(httpTsApi, 'relatorio');

export default relatorioHttp;
