import { httpTsApi, baseUrl } from '.';
import HttpResource from './http-resource';

class ProdutoHttpResource extends HttpResource {
  constructor(http, resource) {
    super(http, resource, baseUrl);
    this.http = http;
    this.resource = resource;
    this.url = baseUrl;
  }

  obter(codigo, cnpj, projetoId) {
    this.url = `/${this.resource}/obter/${codigo}?cnpj=${cnpj}&projetoId=${projetoId} - GET`;
    return this.http.get(`${this.resource}/obter/${codigo}?cnpj=${cnpj}&projetoId=${projetoId}`, this.config);
  }

  produtoCampanha(campanhaId, ofertaId) {
    this.url = `/campanha/${campanhaId}/produto - GET`;
    if (ofertaId) {
      return this.http.get(`campanha/${campanhaId}/produto?ofertaId=${ofertaId}`, this.Config());
    } else {
      return this.http.get(`campanha/${campanhaId}/produto`, this.Config());
    }
  }
}

const produtoHttp = new ProdutoHttpResource(httpTsApi, 'produto');

export default produtoHttp;
