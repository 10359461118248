import { validarForm } from './CampoBase';

export const OfertaCampos = {
  campanhaChaveId: { name: 'campanhaChaveId', label: 'Código', tipo: 'number', max: 9, min: 1 },
  ofertaId: { name: 'ofertaId', label: 'Oferta', tipo: 'number', max: 14, min: 1 },

  descontoTipoId: {
    name: 'descontoTipoId',
    label: 'Tipo desconto',
    tipo: 'number',
    max: 9,
    min: 0,
    opcoes: [
      { valor: '', label: '' },
      { valor: '0', label: 'Sem desconto' },
      { valor: '1', label: 'Integral' },
      { valor: '2', label: 'Percentual' },
    ],
  },

  desconto: { name: 'desconto', label: 'Desconto', tipo: 'number', max: 14, min: 0 },
  valorDescZicard: { name: 'valorDescZicard', label: 'Zicard', tipo: 'number', max: 14, min: 0 },
  pontoTipoId: { name: 'pontoTipoId', label: 'Tipo ponto', tipo: 'number', max: 9, min: 1 },
  formulaTotal: { name: 'formulaTotal', label: 'Fórmula ponto', tipo: 'string', max: 200, min: 0 },
  formulaUnitario: { name: 'formulaUnitario', label: 'Fórmula ponto', tipo: 'string', max: 200, min: 0 },
  ponto: { name: 'ponto', label: 'Ponto', tipo: 'number', max: 14, min: 1 },
  canalVendaId: { name: 'canalVendaId', label: 'Canal de venda', tipo: 'number', max: 9, min: 0 },
  descontoProgressivo: { name: 'descontoProgressivo', label: 'Desconto progressivo', tipo: 'string', max: 512, min: 0 },
  cestoCodigo: { name: 'cestoCodigo', label: 'Código da cesta', tipo: 'string', max: 200, min: 0 },
  flgCesto: { name: 'flgCesto', label: 'Cesta', tipo: 'boolean', max: 1, min: 0 },
  limite: { name: 'limite', label: 'Limite Desconto', tipo: 'number', max: 14, min: 0 },
  limitePonto: { name: 'limitePonto', label: 'Limite Pontos', tipo: 'number', max: 14, min: 0 },
  limiteTipoId: { name: 'limiteTipoId', label: 'Tipo limite', tipo: 'number', max: 9, min: 0 },
  qtdeMin: { name: 'qtdeMin', label: 'Quantidade mínima', tipo: 'number', max: 14, min: 0 },
  sorteioTipoId: { name: 'sorteioTipoId', label: 'Tipo sorteio', tipo: 'number', max: 9, min: 0 },
  pacoteProdutos: { name: 'pacoteProdutos', label: 'pacote de Produtos', tipo: 'boolean', max: 1, min: 0 },
  cashback: { name: 'cashback', label: 'Cashback', tipo: 'number', max: 9, min: 0 },

  cashbackTipoId: {
    name: 'cashbackTipoId',
    label: 'Tipo cashback',
    tipo: 'number',
    max: 9,
    min: 0,
    opcoes: [
      { valor: '', label: '' },
      { valor: '1', label: 'Percentual' },
      { valor: '2', label: 'Integral' },
    ],
  },

  ofertaTabIndex: { name: 'ofertaTabIndex', label: 'Tipo Oferta', tipo: 'number', max: 9, min: 1 },
};

export const validarOferta = (oferta) => {
  if (oferta.ofertaTabIndex === 0) {
    if (!oferta.desconto && '' + oferta.descontoTipoId !== '0') {
      return 'Desconto - campo necessário';
    }
    if (!('' + oferta.descontoTipoId)) {
      return 'Tipo desconto - campo necessário';
    }
  } else if (oferta.ofertaTabIndex === 1) {
    if (!oferta.descontoProgressivo) {
      return 'Desconto progressivo - campo necessário';
    }
  } else if (oferta.ofertaTabIndex === 2) {
    if (!oferta.cashback) {
      return 'Cashback - campo necessário';
    }
    if (!oferta.cashbackTipoId) {
      return 'Tipo cashback - campo necessário';
    }
    if (oferta.cashbackTipoId === 1 && oferta.cashback > 1) {
      return 'Cashback deve ser um percentual menor que 1.00';
    }
  } else {
    return 'Tipo oferta não reconhecido';
  }

  return validarForm(oferta, OfertaCampos);
};
